/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */
import UtmBuilderService from "../service/utm-builder.service";

const state = {
  shortenUrlResponse: [{ link: "", target: "" }],
  meta: "",
  statusRequest: {
    message: null,
    value: null
  },
  utmBuilderConfig: null
};

const getters = {
  shortenUrlResponse: state => state.shortenUrlResponse,
  statusRequest: state => state.statusRequest,
  utmBuilderConfig: state => state.utmBuilderConfig,
  meta: state => state.meta
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_SHORTEN_URL_RESPONSE: (state, payload) => {
    state.shortenUrlResponse = payload;
  },

  SET_META: (state, payload) => {
    state.meta = payload;
  },

  SET_UTM_BUILDER_CONFIG: (state, payload) => {
    state.utmBuilderConfig = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  getShortURL: async ({ commit }, payload) => {
    commit("SET_STATUS_REQUEST", { value: "loading-getShortURL" });

    try {
      const response = await UtmBuilderService.getShortURL(payload);
      commit("SET_SHORTEN_URL_RESPONSE", response.data.data);
      commit("SET_META", response.data.meta.meta);

      commit("SET_STATUS_REQUEST", { value: "success-getShortURL" });
    } catch (error) {
      commit("SET_STATUS_REQUEST", {
        value: "error",
        message: error.message
      });
    }
  },

  getUtmBuilderConfig: async ({ commit }) => {
    commit("SET_STATUS_REQUEST", { value: "loading-getUtmBuilderConfig" });

    try {
      const response = await UtmBuilderService.getUtmConfig();
      commit("SET_UTM_BUILDER_CONFIG", response.data);

      commit("SET_STATUS_REQUEST", {
        value: "success-getUtmBuilderConfig"
      });
    } catch (error) {
      commit("SET_STATUS_REQUEST", {
        value: "error",
        message: error.message
      });
    }
  },

  resetData: ({ commit }) => {
    commit("SET_SHORTEN_URL_RESPONSE", [{ link: "", target: "" }]);
    commit("SET_META", "");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
