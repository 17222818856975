/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filtered + name in state ~ filteredUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

// import store from "@/core/store";
// import Vue from "vue";
import { uniq } from "@thinkvn/utils";
import router from "@/core/router";
import SaleService from "../service/sale.service";

const state = {
  activeOrderProcessingIndex: null,
  flashSaleBranchId: 30,
  flashSaleMode: false,
  paymentBranchId: null,
  paymentList: [],
  posDevices: [],
  promotions: [],
  saleModelSiteConfigs: [],
  saleTabActive: null,
  saleTabActiveIndex: null,
  saleTabActiveNewProductOptions: [],
  saleTabActiveProductOption: null,
  saleTabActiveProductOptionIndex: null,
  saleTabs: [],
  statusRequest: {
    message: null,
    value: null
  },
  localSaleTabActiveIndex: localStorage.getItem("TPSALE_TAB_ACTIVE_INDEX")
};

const getters = {
  activeOrderProcessingIndex: state => state.activeOrderProcessingIndex,
  flashSaleBranchId: state => state.flashSaleBranchId,
  flashSaleMode: state => state.flashSaleMode,
  localSaleTabActiveIndex: state => state.localSaleTabActiveIndex,
  paymentBranchId: state => state.paymentBranchId,
  paymentList: state => state.paymentList,
  paymentListTypes: state => {
    return state.paymentList.map(item => item.payment_type);
  },
  paymentFee: state => {
    const paymentItem = state.paymentList.find(item => item.payment_type === 2);

    return paymentItem && paymentItem.payment_fee ? paymentItem.payment_fee : 0;
  },
  paymentTotal: state => {
    return state.paymentList.reduce(
      (total, item) => total + item.payment_amount + item.payment_fee,
      0
    );
  },
  posDevices: state => state.posDevices,
  promotions: state => state.promotions,
  saleModelSiteConfigs: state => state.saleModelSiteConfigs,
  saleTabActive: state => state.saleTabActive,
  saleTabActiveIndex: state => state.saleTabActiveIndex,
  saleTabActiveNewProductOptions: state => state.saleTabActiveNewProductOptions,
  saleTabActiveProductOption: state => state.saleTabActiveProductOption,
  saleTabActiveProductOptionIndex: state =>
    state.saleTabActiveProductOptionIndex,
  saleTabs: state => state.saleTabs,
  statusRequest: state => state.statusRequest,
  typeSaleTabs: state => {
    return state.saleTabs.map(item => item["type"]);
  }
};

const mutations = {
  ADD_SALE_TAB_ITEM: (state, payload) => {
    console.log("ADD_SALE_TAB_ITEM", payload);
    const localSaleTabs = JSON.parse(localStorage.getItem("TPSALE_TABS"));

    if (localSaleTabs && localSaleTabs.length > 0) {
      state.saleTabs = localSaleTabs;
    }

    state.saleTabs.push(payload);
    console.log("state.saleTabs", state.saleTabs);
    state.saleTabs = uniq(state.saleTabs, tab => tab.code + tab.order_type);
    console.log("state.saleTabs", state.saleTabs);
    localStorage.setItem("TPSALE_TABS", JSON.stringify(state.saleTabs));
  },

  ADD_SALE_TAB_ACTIVE_NEW_PRODUCT_OPTION: (state, payload) => {
    state.saleTabActiveNewProductOptions.push(payload);
  },

  CHANGE_SALE_TAB_ITEM: (state, payload) => {
    console.log("CHANGE_SALE_TAB_ITEM", payload);
    const localSaleTabs = JSON.parse(localStorage.getItem("TPSALE_TABS"));

    if (localSaleTabs && localSaleTabs.length > 0) {
      state.saleTabs = localSaleTabs;
    }
    state.saleTabs.splice(payload.index, 1, payload.data);
    localStorage.setItem("TPSALE_TABS", JSON.stringify(state.saleTabs));
  },

  REMOVE_SALE_TAB_ITEM: (state, payload) => {
    state.saleTabs.splice(payload, 1);
    localStorage.setItem("TPSALE_TABS", JSON.stringify(state.saleTabs));
  },

  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ACTIVE_ORDER_PROCESSING_INDEX: (state, payload) => {
    state.activeOrderProcessingIndex = payload;
  },

  SET_FLASH_SALE_MODE: (state, payload) => {
    state.flashSaleMode = payload;
  },

  SET_PAYMENT_BRANCH_ID: (state, payload) => {
    state.paymentBranchId = payload;
  },

  SET_PAYMENT_LIST: (state, payload) => {
    state.paymentList = payload;
  },

  SET_POS_DEVICES: (state, payload) => {
    state.posDevices = payload;
  },

  SET_PROMOTIONS: (state, payload) => {
    state.promotions = payload;
  },

  SET_SALE_MODEL_SITE_CONFIGS: (state, payload) => {
    state.saleModelSiteConfigs = payload;
  },

  SET_SALE_TAB_ACTIVE: (state, payload) => {
    console.log("SET_SALE_TAB_ACTIVE", payload);
    state.saleTabActive = payload;
    state.saleTabs[state.saleTabActiveIndex] = payload;
    localStorage.setItem("TPSALE_TABS", JSON.stringify(state.saleTabs));
  },

  SET_SALE_TAB_ACTIVE_INDEX: (state, payload) => {
    console.log("SET_SALE_TAB_ACTIVE_INDEX", payload);
    state.saleTabActiveIndex = payload;
    state.saleTabActive = state.saleTabs[payload];
    // Update local storage
    localStorage.setItem("TPSALE_TAB_ACTIVE_INDEX", state.saleTabActiveIndex);
  },

  SET_SALE_TAB_ACTIVE_NEW_PRODUCT_OPTION: (state, payload) => {
    state.saleTabActiveNewProductOptions = payload;
  },

  SET_SALE_TAB_ACTIVE_PRODUCT_OPTION: (state, payload) => {
    state.saleTabActiveProductOption = payload;
  },

  SET_SALE_TAB_ACTIVE_PRODUCT_OPTION_INDEX: (state, payload) => {
    state.saleTabActiveProductOptionIndex = payload;
  },

  SET_SALE_TABS: (state, payload) => {
    console.log("SET_SALE_TABS", payload);
    state.saleTabs = payload;
    localStorage.setItem("TPSALE_TABS", JSON.stringify(state.saleTabs));
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  addInvoiceConfirms: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-addInvoiceConfirms" });

      const response = await SaleService.addInvoiceConfirms(payload);

      if (response.status === 201 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-addInvoiceConfirms" });
      }
    } catch (e) {
      if (e.response && e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        if (e.response)
          commit("SET_STATUS_REQUEST", {
            message: e.response.data,
            value: "error-addInvoiceConfirms"
          });
      }
    }
  },

  addSaleTabItem: ({ commit }, payload) => {
    commit("ADD_SALE_TAB_ITEM", payload);
  },

  addSaleTabActiveNewProductOption: ({ commit }, payload) => {
    commit("ADD_SALE_TAB_ACTIVE_NEW_PRODUCT_OPTION", payload);
  },

  changeSaleTabItem: ({ commit }, payload) => {
    commit("CHANGE_SALE_TAB_ITEM", payload);
  },

  getPosDevices: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPosDevices" });

      const response = await SaleService.getPosDevices();

      if (response.status === 200 && response.data.data) {
        const formatedResponse = Object.keys(response.data.data).map(key => {
          return {
            branchId: response.data.data[key],
            name: key
          };
        });

        commit("SET_POS_DEVICES", formatedResponse);
        commit("SET_STATUS_REQUEST", { value: "success-getPosDevices" });
      }
    } catch (e) {
      if (e.response && e.response.status === 500) {
        // router.push({ name: "500" });
      } else {
        if (e.response)
          commit("SET_STATUS_REQUEST", {
            message: e.response.data,
            value: "error-getPosDevices"
          });
      }
    }
  },

  getPromotions: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPromotions" });

      const response = await SaleService.getPromotions();

      if (response.status === 200 && response.data) {
        commit("SET_PROMOTIONS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getPromotions" });
      }
    } catch (e) {
      if (e.response && e.response.status === 500) {
        // router.push({ name: "500" });
      } else {
        if (e.response)
          commit("SET_STATUS_REQUEST", {
            message: e.response.data,
            value: "error-getPromotions"
          });
      }
    }
  },

  removeSaleTabItem: ({ commit }, payload) => {
    commit("REMOVE_SALE_TAB_ITEM", payload);
  },

  setActiveOrderProcessingIndex: ({ commit }, payload) => {
    commit("SET_ACTIVE_ORDER_PROCESSING_INDEX", payload);
  },

  setFlashSaleMode: ({ commit }, payload) => {
    commit("SET_FLASH_SALE_MODE", payload);
  },

  setPaymentBranchId: ({ commit }, payload) => {
    commit("SET_PAYMENT_BRANCH_ID", payload);
  },

  setPaymentList: ({ commit }, payload) => {
    commit("SET_PAYMENT_LIST", payload);
  },

  setSaleModelSiteConfigs: ({ commit }, payload) => {
    commit("SET_SALE_MODEL_SITE_CONFIGS", payload);
  },

  setSaleTabActive: ({ commit }, payload) => {
    console.log("setSaleTabActive", payload);
    commit("SET_SALE_TAB_ACTIVE", payload);
  },

  setSaleTabActiveIndex: ({ commit }, payload) => {
    commit("SET_SALE_TAB_ACTIVE_INDEX", payload);
  },

  setSaleTabActiveNewProductOption: ({ commit }, payload) => {
    commit("SET_SALE_TAB_ACTIVE_NEW_PRODUCT_OPTION", payload);
  },

  setSaleTabActiveProductOption: ({ commit }, payload) => {
    commit("SET_SALE_TAB_ACTIVE_PRODUCT_OPTION", payload);
  },

  setSaleTabActiveProductOptionIndex: ({ commit }, payload) => {
    commit("SET_SALE_TAB_ACTIVE_PRODUCT_OPTION_INDEX", payload);
  },

  setSaleTabs: ({ commit }, payload) => {
    commit("SET_SALE_TABS", payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
