<template>
  <v-text-field
    v-model="model"
    class="text-body-1"
    :dense="dense"
    :flat="flat"
    :outlined="outlined"
    :rules="rules"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-text-field>
</template>

<script>
import validate from "@/core/helpers/validate";

export default {
  name: "TpTextField",

  props: {
    dense: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },

  mixins: [validate]
};
</script>

<style scoped></style>
