const AuthenticationRouter = {
  path: "/login",
  name: "login",
  meta: {
    private: false,
    layout: "default",
    title: "Đăng nhập | ThinkPro CMS"
  },
  component: () => import("@/modules/Authentication")
};

export default AuthenticationRouter;
