export const LIST_ROLES = {
  sale: {
    name: "nhóm quyền giao dịch với khách hàng", // must be lower case string
    routes: [
      "dashboard", // bang dieu khien
      "goods_products", // product list
      "goods_product-options", // product option list
      "goods_serials",

      "not-sold-warehouse_all-serials",
      "not-sold-warehouse_internal-repair-notes",
      "not-sold-warehouse_internal-repair-notes-create",
      "not-sold-warehouse_internal-repair-notes-detail",
      "not-sold-warehouse_internal-guarantee-notes",
      "not-sold-warehouse_internal-guarantee-notes-create",
      "not-sold-warehouse_internal-guarantee-notes-detail",
      "not-sold-warehouse_return-serial-notes",
      "not-sold-warehouse_return-serial-notes-detail",
      "not-sold-warehouse_waiting-processing-notes",
      "not-sold-warehouse_waiting-processing-notes-create",
      "not-sold-warehouse_waiting-processing-notes-detail",
      "not-sold-warehouse_waiting-assembly-notes",
      "not-sold-warehouse_waiting-assembly-notes-create",
      "not-sold-warehouse_waiting-assembly-notes-detail",
      "not-sold-warehouse_media-loan-notes",
      "not-sold-warehouse_media-loan-notes-create",
      "not-sold-warehouse_media-loan-notes-detail",
      "not-sold-warehouse_equipment-for-employee-notes",
      "not-sold-warehouse_equipment-for-employee-notes-create",
      "not-sold-warehouse_equipment-for-employee-notes-detail",
      "not-sold-warehouse_sample-serial-notes",
      "not-sold-warehouse_sample-serial-notes-create",
      "not-sold-warehouse_sample-serial-notes-detail",

      "transaction_sale-receipts", // ds hoa don ban
      "transaction_orders", // ds dat hang
      "transaction_browsing-services", // ds duyet phuc vu
      "transaction_customer-return", // ds doi tra hang
      "transaction_goods-deliveries", // ds chuyen hang,
      "transaction_goods-deliveries-create", // chuyen hang
      "sale", // trang ban hang

      "partner-and-member_customers", // ds khach hang

      "cashflow_receipt-voucher", // ds phieu thu
      "cashflow_cash-transfer-note", // ds phieu chuyen quy

      "print_order-invoice", // in hoa don order
      "print_sale-receipt", // in hoa don ban
      "print_delivery-bill", // in phieu xuat kho
      "print_order-payment-transaction", // in phieu coc

      "guarantee_search", // tra cuu bao hanh
      "guarantee_vouchers", // ds  phieu bao hanh, sua chua
      "guarantee_vouchers-create", //  tao phieu bhsc
      "guarantee_vouchers-detail", // chi tiet phieu bhsc
      "guarantee_packages", // ds goi bao hanh

      "website_comments" // binh luan website
    ]
  },
  accountant: {
    name: "nhóm quyền kế toán tổng hợp", // must be lower case string
    routes: [
      "dashboard", // bang dieu khien
      "goods_products", // product list
      "goods_product-options", // product option list
      "goods_serials", // product serial
      "goods_adding-accessories",
      "goods_removing-accessories",
      "goods_3rd-store",

      "transaction_sale-receipts", // ds hoa don ban
      "transaction_orders", // ds dat hang
      "transaction_browsing-services", // ds duyet phuc vu
      "transaction_goods-receipts_purchase-orders",
      "transaction_goods-receipts_purchase-orders-create",
      "transaction_goods-receipts_purchase-orders-detail",
      "transaction_goods-receipts_notes",
      "transaction_goods-receipts_notes-create",
      "transaction_goods-receipts_notes-detail",
      "transaction_goods-receipts_rebates",
      "transaction_customer-return", // ds doi tra hang
      "transaction_goods-deliveries", // ds chuyen hang,
      "transaction_goods-deliveries-create", // chuyen hang
      "transaction_supplier-return",
      "transaction_supplier-return-create",
      "transaction_goods-destruction",

      // quan ly quy
      "fund_revenue",
      "fund_cod",

      "not-sold-warehouse_all-serials",
      "not-sold-warehouse_internal-repair-notes",
      "not-sold-warehouse_internal-repair-notes-create",
      "not-sold-warehouse_internal-repair-notes-detail",
      "not-sold-warehouse_internal-guarantee-notes",
      "not-sold-warehouse_internal-guarantee-notes-create",
      "not-sold-warehouse_internal-guarantee-notes-detail",
      "not-sold-warehouse_return-serial-notes",
      "not-sold-warehouse_return-serial-notes-detail",
      "not-sold-warehouse_waiting-processing-notes",
      "not-sold-warehouse_waiting-processing-notes-create",
      "not-sold-warehouse_waiting-processing-notes-detail",
      "not-sold-warehouse_waiting-assembly-notes",
      "not-sold-warehouse_waiting-assembly-notes-create",
      "not-sold-warehouse_waiting-assembly-notes-detail",
      "not-sold-warehouse_media-loan-notes",
      "not-sold-warehouse_media-loan-notes-create",
      "not-sold-warehouse_media-loan-notes-detail",
      "not-sold-warehouse_equipment-for-employee-notes",
      "not-sold-warehouse_equipment-for-employee-notes-create",
      "not-sold-warehouse_equipment-for-employee-notes-detail",
      "not-sold-warehouse_sample-serial-notes",
      "not-sold-warehouse_sample-serial-notes-create",
      "not-sold-warehouse_sample-serial-notes-detail",

      "transaction_sale-receipts", // ds hoa don ban
      "transaction_orders", // ds dat hang
      "transaction_customer-return", // ds doi tra hang
      "transaction_goods-deliveries", // ds chuyen hang,
      "transaction_goods-deliveries-create", // chuyen hang
      "sale", // trang ban hang

      "partner-and-member_customers", // ds khach hang
      "partner-and-member_employees",

      "cashflow_receipt-voucher", // ds phieu thu
      "cashflow_cash-transfer-note", // ds phieu chuyen quy

      "print_order-invoice", // in hoa don order
      "print_sale-receipt", // in hoa don ban
      "print_delivery-bill", // in phieu xuat kho
      "print_order-payment-transaction", // in phieu coc

      "guarantee_search", // tra cuu bao hanh
      "guarantee_vouchers", // ds  phieu bao hanh, sua chua
      "guarantee_vouchers-create", //  tao phieu bhsc
      "guarantee_vouchers-detail", // chi tiet phieu bhsc
      "guarantee_packages" // ds goi bao hanh
    ]
  },
  ctvSeo: {
    name: "nhóm quyền ctv seo",
    routes: [
      "goods_products", // product list
      "website_posts",
      "website_posts-create",
      "website_posts-detail",
      "website_infobox"
    ]
  },
  storeManager: {
    name: "nhóm quyền quản lý cửa hàng", // must be lower case string
    routes: [
      "dashboard", // bang dieu khien
      "goods_products", // product list
      "goods_product-options", // product option list

      "not-sold-warehouse_all-serials",
      "not-sold-warehouse_internal-repair-notes",
      "not-sold-warehouse_internal-repair-notes-create",
      "not-sold-warehouse_internal-repair-notes-detail",
      "not-sold-warehouse_internal-guarantee-notes",
      "not-sold-warehouse_internal-guarantee-notes-create",
      "not-sold-warehouse_internal-guarantee-notes-detail",
      "not-sold-warehouse_return-serial-notes",
      "not-sold-warehouse_return-serial-notes-detail",
      "not-sold-warehouse_waiting-processing-notes",
      "not-sold-warehouse_waiting-processing-notes-create",
      "not-sold-warehouse_waiting-processing-notes-detail",
      "not-sold-warehouse_waiting-assembly-notes",
      "not-sold-warehouse_waiting-assembly-notes-create",
      "not-sold-warehouse_waiting-assembly-notes-detail",
      "not-sold-warehouse_media-loan-notes",
      "not-sold-warehouse_media-loan-notes-create",
      "not-sold-warehouse_media-loan-notes-detail",
      "not-sold-warehouse_equipment-for-employee-notes",
      "not-sold-warehouse_equipment-for-employee-notes-create",
      "not-sold-warehouse_equipment-for-employee-notes-detail",
      "not-sold-warehouse_sample-serial-notes",
      "not-sold-warehouse_sample-serial-notes-create",
      "not-sold-warehouse_sample-serial-notes-detail",

      "fund_revenue",
      "goods_serials",

      "transaction_sale-receipts", // ds hoa don ban
      "transaction_orders", // ds dat hang
      "transaction_browsing-services", // ds duyet phuc vu
      "transaction_customer-return", // ds doi tra hang
      "transaction_goods-deliveries", // ds chuyen hang,
      "transaction_goods-deliveries-create", // chuyen hang
      "sale", // trang ban hang

      "partner-and-member_customers", // ds khach hang

      "cashflow_receipt-voucher", // ds phieu thu
      "cashflow_cash-transfer-note", // ds phieu chuyen quy

      "print_order-invoice", // in hoa don
      "print_sale-receipt", // in hoa don ban
      "print_delivery-bill", // in phieu xuat kho
      "print_order-payment-transaction", // in phieu coc

      "guarantee_search", // tra cuu bao hanh
      "guarantee_vouchers", // ds  phieu bao hanh, sua chua
      "guarantee_vouchers-create", //  tao phieu bhsc
      "guarantee_vouchers-detail", // chi tiet phieu bhsc
      "guarantee_packages", // ds goi bao hanh

      "website_comments" // binh luan website
    ]
  },
  content: {
    name: "nhóm quyền viết bài", // must be lower case string
    routes: [
      "dashboard",
      "goods_products",
      "goods_product-options",

      "website_posts",
      "website_posts-create",
      "website_posts-detail",
      "website_post-categories",
      "website_comments" // binh luan website
    ]
  },
  marketing: {
    name: "nhóm quyền marketing", // must be lower case string
    routes: [
      "dashboard",
      "goods_products",
      "goods_product-options",

      "promotion",
      "promotion_create",
      "promotion_detail",

      "website_posts",
      "website_posts-create",
      "website_posts-create",
      "website_posts-detail",
      "website_post-categories",
      "website_appearance",
      "website_appearance-banner",
      "website_appearance-home",
      "website_appearance-mega-menu",
      "website_appearance-search-keywords",
      "website_comments", // binh luan website
      "website_notifications"
    ]
  },
  seo: {
    name: "nhóm quyền seo", // must be lower case string
    routes: [
      "dashboard",
      "goods_products",
      "goods_product-options",
      "goods_brands",
      "goods_product-types",
      "goods_product-groups",

      "website_posts",
      "website_posts-create",
      "website_posts-create",
      "website_posts-detail",
      "website_post-categories",
      "website_appearance",
      "website_appearance-banner",
      "website_appearance-home",
      "website_appearance-mega-menu",
      "website_appearance-search-keywords",
      "website_comments", // binh luan website
      "website_notifications",
      "website_infobox",
      "review"
    ]
  },
  freelancerWriter: {
    name: "nhóm quyền freelancer writer",
    routes: [
      "dashboard",
      "website_posts",
      "website_posts-create",
      "website_posts-detail"
    ]
  },
  productAssistant: {
    name: "nhóm quyền trợ lý ngành hàng",
    routes: [
      "dashboard", // bang dieu khien
      "goods_products", // product list
      "goods_product-options", // product option list
      "goods_3rd-store",
      "goods_brands",
      "goods_product-types",
      "goods_product-groups",

      "promotion",
      "promotion_create",
      "promotion_detail",

      "transaction_sale-receipts", // ds hoa don ban
      "transaction_orders", // ds dat hang

      "website_posts",
      "website_posts-create",
      "website_posts-detail",
      "website_post-categories",
      "website_appearance",
      "website_appearance-general",
      "website_appearance-banner",
      "website_appearance-home",
      "website_appearance-mega-menu",
      "website_appearance-search-keywords",
      "website_comments", // binh luan website
      "website_notifications"
    ]
  },
  inventoryManager: {
    name: "nhóm quyền quản lý kho chung",
    routes: [
      "dashboard", // bang dieu khien
      "goods_products", // product list
      "goods_product-options", // product option list
      "goods_serials",
      "goods_3rd-store",
      "goods_adding-accessories",
      "goods_adding-accessories-create",
      "goods_removing-accessories",
      "goods_removing-accessories-create",
      "goods_inventory",
      "goods_inventory-create",
      "goods_inventory-detail",

      "not-sold-warehouse_all-serials",
      "not-sold-warehouse_internal-repair-notes",
      "not-sold-warehouse_internal-repair-notes-create",
      "not-sold-warehouse_internal-repair-notes-detail",
      "not-sold-warehouse_internal-guarantee-notes",
      "not-sold-warehouse_internal-guarantee-notes-create",
      "not-sold-warehouse_internal-guarantee-notes-detail",
      "not-sold-warehouse_return-serial-notes",
      "not-sold-warehouse_return-serial-notes-detail",
      "not-sold-warehouse_waiting-processing-notes",
      "not-sold-warehouse_waiting-processing-notes-create",
      "not-sold-warehouse_waiting-processing-notes-detail",
      "not-sold-warehouse_waiting-assembly-notes",
      "not-sold-warehouse_waiting-assembly-notes-create",
      "not-sold-warehouse_waiting-assembly-notes-detail",
      "not-sold-warehouse_media-loan-notes",
      "not-sold-warehouse_media-loan-notes-create",
      "not-sold-warehouse_media-loan-notes-detail",
      "not-sold-warehouse_equipment-for-employee-notes",
      "not-sold-warehouse_equipment-for-employee-notes-create",
      "not-sold-warehouse_equipment-for-employee-notes-detail",
      "not-sold-warehouse_sample-serial-notes",
      "not-sold-warehouse_sample-serial-notes-create",
      "not-sold-warehouse_sample-serial-notes-detail",

      "transaction_sale-receipts", // ds hoa don ban
      "transaction_orders", // ds dat hang
      "transaction_browsing-services", // ds duyet phuc vu
      "transaction_goods-receipts_purchase-orders",
      "transaction_goods-receipts_purchase-orders-create",
      "transaction_goods-receipts_purchase-orders-detail",
      "transaction_goods-receipts_notes",
      "transaction_goods-receipts_notes-create",
      "transaction_goods-receipts_notes-detail",
      "transaction_goods-receipts_rebates",
      "transaction_customer-return", // ds doi tra hang
      "transaction_goods-deliveries", // ds chuyen hang,
      "transaction_goods-deliveries-create", // chuyen hang
      "transaction_supplier-return",
      "transaction_supplier-return-create",
      "transaction_goods-destruction",

      "partner-and-member_suppliers", // nha cung cap

      "guarantee_search", // tra cuu bao hanh
      "guarantee_vouchers", // ds  phieu bao hanh, sua chua
      "guarantee_vouchers-create", //  tao phieu bhsc
      "guarantee_vouchers-detail", // chi tiet phieu bhsc

      "sale", // trang ban hang

      "print_order-invoice", // in hoa don order
      "print_sale-receipt", // in hoa don ban
      "print_delivery-bill", // in phieu xuat kho
      "print_order-payment-transaction" // in phieu coc
    ]
  }
};
