import Api from "@/core/apis";

export default {
  cancelCustomerReturn(payload) {
    return Api().put("customerReturn/cancel", payload);
  },

  createCustomerReturn(data) {
    return Api().post("customerReturn/add", data);
  },

  exportReportFile(payload) {
    return Api().post("export-report/return-goods", payload, {
      responseType: "blob"
    });
  },

  getCustomerReturnById(id) {
    return Api().get(`customerReturn/detail/${id}`);
  },

  getCustomerReturns(data) {
    return Api().post("customerReturn/all", data);
  },

  getCustomerReturnTransactions(id) {
    return Api().get(`customerReturn/${id}/transactions`);
  }
};
