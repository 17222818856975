/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import MediaService from "@/core/service/media.service";
import PromotionService from "../service/";
import router from "@/core/router";
import Vue from "vue";
import { catchError } from "@/core/composables/useStore";
import { INIT_PROMOTION_VALUE } from "@/modules/Promotion/constants";
import { arrayToTree } from "performant-array-to-tree";
import { toFormData } from "@vt7/utils";

const state = {
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  promotion: JSON.parse(JSON.stringify(INIT_PROMOTION_VALUE)),
  promotions: [],
  promotionsForOrder: [],
  skusPromotion: [],
  skusPromotionTree: [],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedCover: null,
  uploadedThumbnail: null,
  validateData: { errors: [], warning_skus: [] }
};

const getters = {
  paginationInfo: state => state.paginationInfo,
  promotion: state => state.promotion,
  promotions: state => state.promotions,
  promotionsForOrder: state => state.promotionsForOrder,
  skusPromotion: state => state.skusPromotion,
  skusPromotionTree: state => state.skusPromotionTree,
  statusRequest: state => state.statusRequest,
  uploadedCover: state => state.uploadedCover,
  uploadedThumbnail: state => state.uploadedThumbnail,
  validateData: state => state.validateData
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_PROMOTION: (state, payload) => {
    state.promotion = payload;
  },

  SET_PROMOTIONS: (state, payload) => {
    state.promotions = payload;
  },

  SET_PROMOTIONS_FOR_ORDER: (state, payload) => {
    state.promotionsForOrder = payload;
  },

  SET_SKUS_PROMOTION: (state, payload) => {
    state.skusPromotion = payload;
  },
  SET_SKUS_PROMOTION_TREE: (state, payload) => {
    state.skusPromotionTree = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_COVER: (state, payload) => {
    state.uploadedCover = payload;
  },

  SET_UPLOADED_THUMBNAIL: (state, payload) => {
    state.uploadedThumbnail = payload;
  },

  SET_VALIDATE_DATA: (state, payload) => {
    state.validateData = payload;
  }
};

const actions = {
  createPromotion: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createPromotion" });

      const response = await PromotionService.createPromotion(payload);

      if (response.status === 200 && response.data.data) {
        // Set id
        await Vue.set(state.promotion, "id", response.data.data.id);
        await Vue.set(state.promotion, "status", response.data.data.status);
        await Vue.set(
          state.promotion,
          "usingTimes",
          response.data.data.usingTimes
        );

        // Go to detail page
        await router.replace({
          name: "promotion_detail",
          params: {
            promotionId: response.data.data.id
          }
        });

        commit("SET_STATUS_REQUEST", { value: "success-createPromotion" });
      }
    } catch (e) {
      catchError(e, commit, "createPromotion");
    }
  },

  disablePromotion: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-disablePromotion" });

      const response = await PromotionService.disablePromotion(payload);

      if (response.status === 200 && response.data.data) {
        const promotion = response.data.data;
        // Set end date checker
        promotion.is_time_to = promotion.time_to ? 1 : 0;
        // Set limited using times
        promotion.is_limit_number_using = promotion.limit_number_using ? 1 : 0;
        // Convert ids to number if applied type is 2
        if (promotion.detail.apdung_type === 2) {
          promotion.detail.apdung_type_detail.ids =
            promotion.detail.apdung_type_detail.ids[0];
        }
        // Update promotion
        commit("SET_PROMOTION", promotion);
        commit("SET_STATUS_REQUEST", { value: "success-disablePromotion" });
      }
    } catch (e) {
      catchError(e, commit, "disablePromotion");
    }
  },

  enablePromotion: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-enablePromotion" });

      const response = await PromotionService.enablePromotion(payload);

      if (response.status === 200 && response.data.data) {
        const promotion = response.data.data;
        // Set end date checker
        promotion.is_time_to = promotion.time_to ? 1 : 0;
        // Set limited using times
        promotion.is_limit_number_using = promotion.limit_number_using ? 1 : 0;
        // Convert ids to number if applied type is 2
        if (promotion.detail.apdung_type === 2) {
          promotion.detail.apdung_type_detail.ids =
            promotion.detail.apdung_type_detail.ids[0];
        }
        // Update promotion
        commit("SET_PROMOTION", promotion);
        commit("SET_STATUS_REQUEST", { value: "success-enablePromotion" });
      }
    } catch (e) {
      catchError(e, commit, "enablePromotion");
    }
  },

  getPromotionById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPromotionById" });

      const response = await PromotionService.getPromotionById(payload);

      if (response.status === 200 && response.data.data) {
        const promotion = response.data.data;

        // Set end date checker
        promotion.is_time_to = promotion.time_to ? 1 : 0;

        // Set limited using times
        promotion.is_limit_number_using = promotion.limit_number_using ? 1 : 0;

        // Convert ids to number if applied type is 2
        // if (
        //   promotion.detail.apdung_type === 2 &&
        //   promotion.detail.apdung_type_detail.ids
        // ) {
        //   promotion.detail.apdung_type_detail.ids =
        //     promotion.detail.apdung_type_detail.ids[0];
        // }

        // Convert ids to number if customer received type is 2
        // if (
        //   promotion.detail.tangkhach_type === 2 &&
        //   promotion.detail.tangkhach_type_detail.ids
        // ) {
        //   promotion.detail.tangkhach_type_detail.ids =
        //     promotion.detail.tangkhach_type_detail.ids[0];
        // }

        // Convert codes
        // promotion.detail.codes = promotion.detail.codes.map(item => item.code);

        commit("SET_PROMOTION", promotion);
        commit("SET_STATUS_REQUEST", { value: "success-getPromotionById" });
      }
    } catch (e) {
      catchError(e, commit, "getPromotionById");
    }
  },

  getPromotions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPromotions" });

      const response = await PromotionService.getPromotions(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_PROMOTIONS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getPromotions" });
      }
    } catch (e) {
      catchError(e, commit, "getPromotions");
    }
  },

  getPromotionsForOrder: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPromotionsForOrder" });

      const response = await PromotionService.getPromotionsForOrder();

      if (response.status === 200 && response.data.data) {
        commit("SET_PROMOTIONS_FOR_ORDER", response.data.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-getPromotionsForOrder"
        });
      }
    } catch (e) {
      catchError(e, commit, "getPromotionsForOrder");
    }
  },

  resetPromotion: async ({ commit }) => {
    commit("SET_PROMOTION", JSON.parse(JSON.stringify(INIT_PROMOTION_VALUE)));
  },

  getSkusPromotion: async ({ commit }, { type, search }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSkusPromotion" });

      const response = await PromotionService.getSkusByType(type, search);

      const skusPromotionTree = arrayToTree(response.data.data, {
        dataField: null,
        parentId: "parent_id"
      });

      commit("SET_SKUS_PROMOTION_TREE", skusPromotionTree);

      if (response.status === 200 && response.data.data) {
        commit("SET_SKUS_PROMOTION", response.data.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-getSkusPromotion"
        });
      }
    } catch (e) {
      catchError(e, commit, "getSkusPromotion");
    }
  },

  updatePromotion: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updatePromotion" });

      const response = await PromotionService.updatePromotion(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-updatePromotion" });
      }
    } catch (e) {
      catchError(e, commit, "updatePromotion");
    }
  },

  uploadThumbnail: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadThumbnail" });

      const formData = toFormData({ file: payload, folder: "core/banners" });

      const response = await MediaService.uploadImage(formData);

      if (response.status === 200) {
        await commit("SET_UPLOADED_THUMBNAIL", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadThumbnail" });
      }
    } catch (e) {
      catchError(e, commit, "uploadThumbnail");
    }
  },

  uploadCover: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadCover" });

      const formData = toFormData({ file: payload, folder: "core/banners" });

      const response = await MediaService.uploadImage(formData);

      if (response.status === 200) {
        await commit("SET_UPLOADED_COVER", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadCover" });
      }
    } catch (e) {
      catchError(e, commit, "uploadCover");
    }
  },

  validatePromotion: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-validatePromotion" });

      const response = await PromotionService.validatePromotion(payload);

      if (response.status === 200) {
        commit("SET_VALIDATE_DATA", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-validatePromotion" });
      }
    } catch (e) {
      catchError(e, commit, "validatePromotion");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
