import Api from "@/core/apis";

export default {
  cancelGoodsDelivery(id) {
    return Api().get(`exchangeGoods/delete/${id}`);
  },

  confirmGoodsDelivery(data) {
    return Api().post("exchangeGoods/confirm", data);
  },

  createGoodsDelivery(data) {
    return Api().post("exchangeGoods/add", data);
  },

  exportReportFile(payload) {
    return Api().post("export-report/transportation", payload, {
      responseType: "blob"
    });
  },

  getGoodsDeliveries(data) {
    return Api().post("exchangeGoods/all", data);
  },

  getGoodsDeliveryById(id) {
    return Api().get(`exchangeGoods/detail/${id}`);
  }
};
