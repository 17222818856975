const SaleRouter = {
  path: "/sale",
  name: "sale",
  meta: {
    private: true,
    layout: "sale",
    title: "Bán hàng | ThinkPro CMS"
  },
  component: () => import("@/modules/Sale")
};

export default SaleRouter;
