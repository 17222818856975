import Vue from "vue";

// General
Vue.component("TpInputAvatar", () => import("./general/InputAvatar"));
Vue.component("TpInputImage", () => import("./general/InputImage"));
Vue.component("TpInputCondition", () => import("./general/InputCondition"));
Vue.component("TpInputDate", () => import("./general/InputDate"));
Vue.component("TpInputRangePrice", () => import("./general/InputRangePrice"));
Vue.component("TpInputPrice", () => import("./general/InputPrice"));
Vue.component("TpInputPriceFilter", () => import("./general/InputPriceFilter"));
Vue.component("TpInputTime", () => import("./general/InputTime"));
Vue.component("TpInputTimeFilter", () => import("./general/InputTimeFilter"));
Vue.component("TpBtnOverflow", () => import("./general/BtnOverflow"));
Vue.component("TpRangeSlider", () => import("./general/RangeSlider"));
Vue.component("TpSearchOption", () => import("./general/SearchOption"));
Vue.component("TpInputTimeRange", () => import("./general/InputTimeRange"));

// Layouts
Vue.component("GeneralHeader", () => import("./layouts/GeneralHeader"));
Vue.component("GeneralNavbar", () => import("./layouts/GeneralNavbar"));

// Modals
Vue.component("TpModalAddingAccessories", () =>
  import("./modals/ModalAddingAccessories")
);
Vue.component("TpModalAddRoleServer", () =>
  import("./modals/ModalAddRoleServer")
);
Vue.component("TpModalAlert", () => import("./modals/ModalAlert"));
Vue.component("TpModalCancel", () => import("./modals/ModalCancel"));
Vue.component("TpModalCustomer", () => import("./modals/ModalCustomer"));
Vue.component("TpModalCustomerReturn", () =>
  import("./modals/ModalCustomerReturn")
);
Vue.component("TpModalGoodsDelivery", () =>
  import("./modals/ModalGoodsDelivery")
);
Vue.component("TpModalGoodsDestruction", () =>
  import("./modals/ModalGoodsDestruction")
);
Vue.component("TpModalGoodsReceipts", () =>
  import("./modals/ModalGoodsReceipts")
);
Vue.component("TpModalOrder", () => import("./modals/ModalOrder"));
Vue.component("TpModalProduct", () => import("./modals/ModalProduct"));
Vue.component("TpModalProductOption", () =>
  import("./modals/ModalProductOption")
);
Vue.component("TpModalProductOptionQuickCreate", () =>
  import("./modals/ModalProductOptionQuickCreate")
);
Vue.component("TpModalRemovingAccessories", () =>
  import("./modals/ModalRemovingAccessories")
);
Vue.component("TpModalSaleReceipts", () =>
  import("./modals/ModalSaleReceipts")
);
Vue.component("TpModalSerialPicker", () =>
  import("./modals/ModalSerialPicker")
);
Vue.component("TpModalShowSerial", () => import("./modals/ModalShowSerial"));
Vue.component("TpModalSupplier", () => import("./modals/ModalSupplier"));
Vue.component("TpModalSupplierReturn", () =>
  import("./modals/ModalSupplierReturn")
);
Vue.component("TpModalUtmBuilder", () => import("./modals/ModalUTMBuilder"));
Vue.component("TpFormTransactionsPayment", () =>
  import("./form/FormTransactionsPayment")
);

// Toast
Vue.component("TpToastActionAlert", () => import("./toast/ToastActionAlert"));
