import Api from "@/core/apis";

export default {
  addCashFlow(data) {
    return Api().post(`/cash-flow`, { ...data });
  },

  cancelCashFlow(item) {
    return Api().put(`/cash-flow/${item.id}/cancel`, {
      ...item
    });
  },

  confirmCashFlow(item) {
    return Api().put(`/cash-flow/${item.id}/confirm`, {
      ...item
    });
  },

  getCashFlowList(data) {
    return Api().post("/cash-flow/list", {
      ...data
    });
  },

  getCashFlowId(id) {
    return Api().get(`/cash-flow/${id}`);
  },

  getCashFlowExchangeSource(data) {
    return Api().post(`/cash-flow/exchange/source`, { ...data });
  },

  getCashFlowPaymentDestination(data) {
    return Api().post(`/cash-flow/payment/destination`, { ...data });
  },

  getCashFlowPaymentSource(data) {
    return Api().post(`/cash-flow/payment/source`, { ...data });
  },

  getCashFlowReceiptDestination(data) {
    return Api().post(`/cash-flow/receipt/destination`, { ...data });
  }
};
