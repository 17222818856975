import Api from "@/core/apis";
import ApiAssets from "@/core/apis/assets";

export default {
  cancelGuaranteeVoucher(data) {
    return Api().post("bh_sc/cancel", data);
  },

  createGuaranteeVoucher(data) {
    return Api().post("bh_sc/add", data);
  },

  getGuaranteeVouchers(data) {
    return Api().post("bh_sc/all", data);
  },

  getGuaranteeVoucherById(id) {
    return Api().get(`/bh_sc/detail/${id}`);
  },

  getSerialInfoBySerialNumber(serialNumber) {
    return Api().get(`bh_sc/serialNumber/detail/${serialNumber}`);
  },

  searchGuaranteeInfo(searchKey) {
    return Api().get(`bh_sc/lookUp/${searchKey}`);
  },

  updateGuaranteeVoucher(data) {
    return Api().post("bh_sc/edit", data);
  },

  outerSerial(payload) {
    return Api().post("outer-serials", payload);
  },
  uploadImage(data) {
    return ApiAssets().post("upload", data);
  },
  exportReportGuarantee(payload) {
    return Api().post("export-report/bao-hanh", payload, {
      responseType: "blob"
    });
  },
  exportReportRepair(payload) {
    return Api().post("export-report/sua-chua", payload, {
      responseType: "blob"
    });
  }
};
