import Api from "@/core/apis";

export default {
  createSupplierReturn(data) {
    return Api().post("returnToSupplier/add", data);
  },

  exportReportFile(data) {
    return Api().post("export-report/supplier-return", data, {
      responseType: "blob"
    });
  },

  getSupplierReturnById(id) {
    return Api().get(`returnToSupplier/detail/${id}`);
  },

  getSupplierReturns(data) {
    return Api().post("returnToSupplier/all", data);
  }
};
