import Api from "@/core/apis";

export default {
  createGoodsDestruction(data) {
    return Api().post("removeGoods/add", data);
  },

  getGoodsDestructionById(id) {
    return Api().get(`removeGoods/detail/${id}`);
  },

  getGoodsDesTructionList(data) {
    return Api().post("removeGoods/all", data);
  }
};
