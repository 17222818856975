<template>
  <v-select
    v-model="model"
    class="tp-filter-autocomplete text-body-1"
    :dense="dense"
    :flat="flat"
    :menu-props="menuProps"
    :no-data-text="noDataText"
    :outlined="outlined"
    :rules="rules"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-select>
</template>

<script>
import validate from "@/core/helpers/validate";

export default {
  name: "TpSelect",

  props: {
    dense: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: true
    },
    menuProps: {
      type: Object,
      default: () => {
        return { nudgeTop: "-40px" };
      }
    },
    noDataText: {
      type: String,
      default: "Không có dữ liệu"
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },

  mixins: [validate]
};
</script>

<style scoped></style>
