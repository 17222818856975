export const STATUS_SHIPPING = {
  CHUA_GIAO_HANG: {
    id: 1,
    text: "Chưa giao hàng",
    color: "grey",
    textColor: "grey--text"
  },
  DA_SHIP_MOT_PHAN: {
    id: 2,
    text: "Đã ship một phần",
    color: "orange",
    textColor: "orange--text"
  },
  DA_SHIP_TOAN_BO: {
    id: 3,
    text: "Đã ship toàn bộ",
    color: "purple",
    textColor: "purple--text"
  },
  KHACH_DA_NHAN_MOT_PHAN: {
    id: 4,
    text: "Khách đã nhận một phần",
    color: "blue accent-2",
    textColor: "blue--text text--accent-2"
  },
  DA_GIAO_TOAN_BO: {
    id: 5,
    text: "Đã giao toàn bộ",
    color: "green",
    textColor: "green--text"
  }
};

export const LIST_STATUS_SHIPPING = Object.values(STATUS_SHIPPING);
