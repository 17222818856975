export * from "./order";

const LIST_ORDER_CHANNELS = [
  { id: 1, name: "Website", disabled: true },
  { id: 2, name: "Chi nhánh" },
  { id: 3, name: "Tổng đài" },
  { id: 4, name: "Facebook" },
  { id: 5, name: "Chat Website" }
];

const LIST_SHIPPING_PARTNERS = [
  { id: 1, name: "Giao hàng nhanh" },
  { id: 2, name: "Giao hàng tiết kiệm" },
  { id: 3, name: "J&T Express" },
  { id: 4, name: "Viettel Post" },
  { id: 5, name: "VN Post" },
  { id: 6, name: "Nhất Tín" },
  { id: 7, name: "Đại Phát" },
  { id: 8, name: "Hợp Nhất" },
  { id: 9, name: "EMS" },
  { id: 10, name: "Grab" },
  { id: 11, name: "Gojek" },
  { id: 12, name: "Be" },
  { id: 13, name: "Đơn vị khác" }
];

const PROMOTION_TYPE = {
  reduce_percent: 1,
  reduce_money: 2,
  freeship: 3
};

const PROMOTION_PROVINCIAL_TYPE = {
  all_provincial: 1,
  list_provincial: 2
};

const PRODUCT_PROMOTION_TYPE = {
  give_product: 1,
  reduce_money: 2,
  freeship: 3
};

const PRODUCT_PROMOTION_TYPE_NAME = {
  3: "Phần trăm",
  2: "Số tiền cố định",
  1: "Mua X được Y"
};

export {
  LIST_ORDER_CHANNELS,
  LIST_SHIPPING_PARTNERS,
  PROMOTION_TYPE,
  PROMOTION_PROVINCIAL_TYPE,
  PRODUCT_PROMOTION_TYPE,
  PRODUCT_PROMOTION_TYPE_NAME
};
