/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import CustomerGroupService from "../service/customer-group.service";
import router from "@/core/router";
import store from "@/core/store";
import Vue from "vue";

const state = {
  allCustomerGroups: [],
  customerGroup: {
    name: null,
    description: null,
    dks: []
  },
  customerGroupConditions: [],
  customerGroups: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  allCustomerGroups: state => state.allCustomerGroups,
  customerGroup: state => state.customerGroup,
  customerGroupConditions: state => state.customerGroupConditions,
  customerGroups: state => state.customerGroups,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ALL_CUSTOMER_GROUPS: (state, payload) => {
    state.allCustomerGroups = payload;
  },

  SET_CUSTOMER_GROUP: (state, payload) => {
    state.customerGroup = payload;
  },

  SET_CUSTOMER_GROUP_CONDITIONS: (state, payload) => {
    state.customerGroupConditions = payload;
  },

  SET_CUSTOMER_GROUPS: (state, payload) => {
    state.customerGroups = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createCustomerGroup: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createCustomerGroup" });

      const response = await CustomerGroupService.createCustomerGroup(
        payload.data
      );

      if (response.status === 201 && response.data.data) {
        // Get Customer Groups
        await store.dispatch(
          "CUSTOMER_GROUP/getCustomerGroups",
          payload.routeQuery
        );
        // Set id
        Vue.set(state.customerGroup, "id", response.data.data.id);

        commit("SET_STATUS_REQUEST", { value: "success-createCustomerGroup" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createCustomerGroup"
        });
      }
    }
  },

  deleteCustomerGroups: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteCustomerGroups" });

      const response = await CustomerGroupService.deleteCustomerGroups(
        payload.data
      );

      if (response.status === 200) {
        // Get Customer Groups
        await store.dispatch(
          "CUSTOMER_GROUP/getCustomerGroups",
          payload.routeQuery
        );
        await store.dispatch("CUSTOMER_GROUP/resetCustomerGroup");

        commit("SET_STATUS_REQUEST", { value: "success-deleteCustomerGroups" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-deleteCustomerGroups"
        });
      }
    }
  },

  getAllCustomerGroups: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllCustomerGroups" });

      const response = await CustomerGroupService.getAllCustomerGroups();

      if (response.status === 200 && response.data.data) {
        commit("SET_ALL_CUSTOMER_GROUPS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAllCustomerGroups" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getAllCustomerGroups"
        });
      }
    }
  },

  getCustomerGroupById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCustomerGroupById" });

      const response = await CustomerGroupService.getCustomerGroupById(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_CUSTOMER_GROUP", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getCustomerGroupById" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getCustomerGroupById"
        });
      }
    }
  },

  getCustomerGroupConditions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getCustomerGroupConditions"
      });

      const response = await CustomerGroupService.getCustomerGroupConditions(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_CUSTOMER_GROUP_CONDITIONS", response.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getCustomerGroupConditions"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getCustomerGroupConditions"
        });
      }
    }
  },

  getCustomerGroups: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCustomerGroups" });

      const response = await CustomerGroupService.getCustomerGroups(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_CUSTOMER_GROUPS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getCustomerGroups" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getCustomerGroups"
        });
      }
    }
  },

  resetCustomerGroup: ({ commit }) => {
    commit("SET_CUSTOMER_GROUP", {
      name: null,
      description: null,
      dks: []
    });
  },

  updateCustomerGroup: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateCustomerGroup" });

      const response = await CustomerGroupService.updateCustomerGroup(
        payload.data
      );

      if (response.status === 200 && response.data) {
        // Get Customer Groups
        await store.dispatch(
          "CUSTOMER_GROUP/getCustomerGroups",
          payload.routeQuery
        );

        commit("SET_STATUS_REQUEST", { value: "success-updateCustomerGroup" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateCustomerGroup"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
