import Api from "@/core/apis";

export default {
  createRole(role) {
    return Api().post("role/add", role);
  },

  deleteRole(roleId) {
    return Api().get(`role/delete/${roleId}`);
  },

  getPermissions() {
    return Api().get("permissions/list");
  },

  getRoles() {
    return Api().get("role/list");
  },

  getRolesInvoices() {
    return Api().get("invoices-roles");
  },

  updateRole(roleId, role) {
    return Api().post(`role/edit/${roleId}`, role);
  }
};
