import Api from "@/core/apis";

export default {
  getCurrentUser() {
    return Api().get("currentUser");
  },

  login(data) {
    return Api().post("login", data);
  },

  logout() {
    return Api().get("logout");
  }
};
