import BrowsingService from "../service/browsing.service";
import MediaService from "@/core/service/media.service";

import router from "@/core/router";
import Vue from "vue";
import { catchError } from "@/core/composables";

const state = {
  docsBrowsingService: "",
  browsingService: {},
  browsingServices: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  }
};
const getters = {
  docsBrowsingService: state => state.docsBrowsingService,
  browsingService: state => state.browsingService,
  browsingServices: state => state.browsingServices,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest
};
const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },
  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },
  SET_STATE_VALUE: (state, payload) => {
    state[payload.key] = payload.value;
  }
};
const actions = {
  approveBrowsingService: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-approveBrowsingService" });

      const response = await BrowsingService.approveBrowsingService(payload.id);

      if (response.status === 200 && response.data) {
        const browsingService = state.browsingServices[payload.index];

        browsingService.status = 1;
        await Vue.set(state.browsingServices, payload.index, browsingService);
        commit("SET_STATUS_REQUEST", {
          value: "success-approveBrowsingService"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-approveBrowsingService"
        });
      }
    }
  },

  clearBrowsingService: ({ commit }) => {
    commit("SET_STATE_VALUE", {
      key: "browsingService",
      value: {}
    });
    commit("SET_STATE_VALUE", {
      key: "docsBrowsingService",
      value: ""
    });
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await BrowsingService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportFile", { isRedirected: false });
    }
  },

  getBrowsingService: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getBrowsingService" });

      const response = await BrowsingService.getBrowsingService(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATE_VALUE", {
          key: "browsingService",
          value: response.data.data
        });
        commit("SET_STATUS_REQUEST", { value: "success-getBrowsingService" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getBrowsingService"
        });
      }
    }
  },

  updateBrowser: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateBrowser" });

      const response = await BrowsingService.updateBrowser(payload);

      if (response.status === 200 && response.data) {
        const indexReplace = state.browsingServices
          .map(function(x) {
            return x.id;
          })
          .indexOf(payload.id);
        console.log("indexReplace", indexReplace);
        await Vue.set(state.browsingServices, indexReplace, response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-updateBrowser" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateBrowser"
        });
      }
    }
  },

  getBrowsingServices: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getBrowsingServices" });

      const response = await BrowsingService.getBrowsingServices(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATE_VALUE", {
          key: "browsingServices",
          value: response.data.data
        });

        // Set pagination info
        commit("SET_STATE_VALUE", {
          key: "paginationInfo",
          value: {
            from: response.data.meta.from ? response.data.meta.from : 0,
            to: response.data.meta.to ? response.data.meta.to : 0,
            pageTotal: response.data.meta.last_page,
            itemTotal: response.data.meta.total
          }
        });
        commit("SET_STATUS_REQUEST", { value: "success-getBrowsingServices" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getBrowsingServices"
        });
      }
    }
  },

  rejectBrowsingService: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-rejectBrowsingService" });

      const response = await BrowsingService.rejectBrowsingService(payload.id);

      if (response.status === 200 && response.data) {
        const browsingService = state.browsingServices[payload.index];

        browsingService.status = -1;
        await Vue.set(state.browsingServices, payload.index, browsingService);
        commit("SET_STATUS_REQUEST", {
          value: "success-rejectBrowsingService"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-rejectBrowsingService"
        });
      }
    }
  },

  uploadDocs: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadDocs" });

      // Append folder
      payload.append("folder", "hrm/labor-contracts");
      const response = await MediaService.uploadImage(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATE_VALUE", {
          key: "docsBrowsingService",
          value: response.data
        });
        commit("SET_STATUS_REQUEST", { value: "success-uploadDocs" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-uploadDocs"
        });
      }
    }
  },

  createBrowsingService: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createBrowsingService" });

      const response = await BrowsingService.createBrowsingService(payload);

      if (response.status === 201 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-createBrowsingService"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createBrowsingService"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
