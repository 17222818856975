/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import OrderPolicyAreaService from "../service/order-policy-area.service";
import store from "@/core/store";
import { catchError } from "@/core/composables/useStore";

const state = {
  orderPolicyArea: {
    name: null,
    tinh_thanhpho: null,
    quan_huyens: [],
    status: 1,
    note: null
  },
  orderPolicyAreas: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  orderPolicyArea: state => state.orderPolicyArea,
  orderPolicyAreas: state => state.orderPolicyAreas,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ORDER_POLICY_AREA: (state, payload) => {
    state.orderPolicyArea = payload;
  },

  SET_ORDER_POLICY_AREAS: (state, payload) => {
    state.orderPolicyAreas = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createOrderPolicyArea: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createOrderPolicyArea" });

      const response = await OrderPolicyAreaService.createOrderPolicyArea(
        payload
      );

      if (response.status === 201 && response.data.data) {
        // Update orderPolicyArea
        commit("SET_ORDER_POLICY_AREA", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-createOrderPolicyArea"
        });
      }
    } catch (e) {
      catchError(e, commit, "createOrderPolicyArea");
    }
  },

  deleteOrderPolicyArea: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteOrderPolicyArea" });

      const response = await OrderPolicyAreaService.deleteOrderPolicyArea(
        payload
      );

      if (response.status === 200) {
        // Get orderPolicyAreas
        await store.dispatch("ORDER_POLICY_AREA/getOrderPolicyAreas");
        commit("SET_STATUS_REQUEST", {
          value: "success-deleteOrderPolicyArea"
        });
      }
    } catch (e) {
      catchError(e, commit, "deleteOrderPolicyArea");
    }
  },

  getOrderPolicyAreas: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getOrderPolicyAreas" });

      const response = await OrderPolicyAreaService.getOrderPolicyAreas(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_ORDER_POLICY_AREAS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getOrderPolicyAreas" });
      }
    } catch (e) {
      catchError(e, commit, "getOrderPolicyAreas");
    }
  },

  resetOrderPolicyArea: ({ commit }) => {
    const name = state.orderPolicyAreas
      ? `Khu vực ${state.orderPolicyAreas.length + 1}`
      : null;

    commit("SET_ORDER_POLICY_AREA", {
      name: name,
      tinh_thanhpho: null,
      quan_huyens: [],
      status: 1,
      note: null
    });
  },

  updateOrderPolicyArea: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateOrderPolicyArea" });

      const response = await OrderPolicyAreaService.updateOrderPolicyArea(
        payload
      );

      if (response.status === 200 && response.data.data) {
        // Update orderPolicyArea
        commit("SET_ORDER_POLICY_AREA", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-updateOrderPolicyArea"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateOrderPolicyArea");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
