import Api from "@/core/apis";

export default {
  getInventoryReports(data) {
    return Api().post("inventory-report/all", data);
  },

  getInventoryReportByOptionId(data) {
    return Api().post("inventory-report/detail", data);
  },

  getInventoryReportGeneral(data) {
    return Api().post("inventory-report/general", data);
  },

  exportInventoryReportDetailFile(data) {
    return Api().post("inventory-report/detail/export", data, {
      responseType: "blob"
    });
  },

  exportReportFile(data) {
    return Api().post("inventory-report/export", data, {
      responseType: "blob"
    });
  },

  exportReportFileDataProduct(data){
    return Api().post("inventory-report/export-by-site", data, {
      responseType: "blob"
    });
  },

  importUpdateFile(data){
    return Api().post("inventory-report/import-ttkd", data);
  }
};
