import Api from "@/core/apis";

export default {
  createTag(data) {
    return Api().post("tag/add", data);
  },

  getAllTags() {
    return Api().post("product/tags/getAllTags");
  }
};
