/**
 * A recursive function that removes 'childrenName' property from all nodes that have an empty children array
 * @function
 * @param {Array} nodes - An array of nodes that contains the childrenName property
 * @param {string} childrenName - name of the property that contains children of the node
 * @return {Array} - An array of nodes with the 'childrenName' property removed from nodes that have an empty children array
 */

export const removeEmptyChildrenProperty = (
  nodes,
  childrenName = "children"
) => {
  return nodes.map(node => {
    if (node[childrenName].length === 0) {
      delete node[childrenName];
    } else {
      node[childrenName] = removeEmptyChildrenProperty(node[childrenName]);
    }
    return node;
  });
};
