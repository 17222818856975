<template>
  <v-dialog
    v-model="visible"
    :data-modal="name"
    :fullscreen="fullscreen"
    :hide-overlay="hideOverlay"
    :max-width="maxWidth"
    :no-click-animation="noClickAnimation"
    :origin="origin"
    :overlay-color="overlayColor"
    :persistent="persistent"
    :retain-focus="false"
    :scrollable="scrollable"
    :transition="transition"
    :width="width"
  >
    <slot :close="hide" :payload="payload"></slot>
  </v-dialog>
</template>

<script>
import Modal from "@/core/plugins/modal";

export default {
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    },
    hideOverlay: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: [String, Number],
      default: "none"
    },
    name: {
      type: String,
      required: true
    },
    noClickAnimation: {
      type: Boolean,
      default: true
    },
    origin: {
      type: String,
      default: "center center"
    },
    overlayColor: {
      type: String,
      default: undefined
    },
    overlayOpacity: {
      type: [Number, String],
      default: undefined
    },
    persistent: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    transition: {
      type: [String, Boolean],
      default: "dialog-transition"
    },
    width: {
      type: [String, Number],
      default: "auto"
    }
  },
  data() {
    return {
      payload: {},
      visible: false
    };
  },
  methods: {
    hide() {
      this.$emit("close", {});
      this.visible = false;
      this.payload = {};
    },
    show(params) {
      if (params) {
        const { payload } = params;
        this.$emit("open", payload);
        this.visible = true;
        this.payload = payload;
      }
    }
  },
  beforeMount() {
    // Show event
    Modal.EventBus.$on("show", params => {
      if (this.name === params.name) {
        this.show(params);
      }
    });

    // Hide event
    Modal.EventBus.$on("hide", params => {
      if (this.name === params.name) {
        this.hide();
      }
    });
  }
};
</script>
