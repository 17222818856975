import Api from "@/core/apis";

export default {
  createOrderPolicy(data) {
    return Api().post("order-policies", data);
  },

  deleteOrderPolicy(id) {
    return Api().delete(`order-policies/${id}`);
  },

  getOrderPolicies(data) {
    return Api().post("order-policies/list", data);
  },

  getOrderPolicyById(id) {
    return Api().get(`order-policies/${id}`);
  },

  updateOrderPolicy(data) {
    return Api().put("order-policies", data);
  }
};
