/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import CustomerRankService from "../service/customer-rank.service";
import router from "@/core/router";
import store from "@/core/store";
import Vue from "vue";

const state = {
  customerRank: {
    name: null,
    description: null,
    dks: []
  },
  customerRankConditions: [],
  customerRanks: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  customerRank: state => state.customerRank,
  customerRankConditions: state => state.customerRankConditions,
  customerRanks: state => state.customerRanks,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_CUSTOMER_RANK: (state, payload) => {
    state.customerRank = payload;
  },

  SET_CUSTOMER_RANK_CONDITIONS: (state, payload) => {
    state.customerRankConditions = payload;
  },

  SET_CUSTOMER_RANKS: (state, payload) => {
    state.customerRanks = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createCustomerRank: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createCustomerRank" });

      const response = await CustomerRankService.createCustomerRank(payload);

      if (response.status === 201 && response.data.data) {
        // Set id
        await Vue.set(state.customerRank, "id", response.data.data.id);
        // Update Customer Ranks
        state.customerRanks.push(state.customerRank);

        commit("SET_STATUS_REQUEST", { value: "success-createCustomerRank" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createCustomerRank"
        });
      }
    }
  },

  deleteCustomerRank: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteCustomerRank" });

      const response = await CustomerRankService.deleteCustomerRank(payload.id);

      if (response.status === 200) {
        // Update Customer Ranks
        await state.customerRanks.splice(payload.index, 1);
        await store.dispatch("CUSTOMER_RANK/resetCustomerRank");

        commit("SET_STATUS_REQUEST", { value: "success-deleteCustomerRank" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-deleteCustomerRank"
        });
      }
    }
  },

  getCustomerRankById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCustomerRankById" });

      const response = await CustomerRankService.getCustomerRankById(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_CUSTOMER_RANK", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getCustomerRankById" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getCustomerRankById"
        });
      }
    }
  },

  getCustomerRankConditions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getCustomerRankConditions"
      });

      const response = await CustomerRankService.getCustomerRankConditions(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_CUSTOMER_RANK_CONDITIONS", response.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getCustomerRankConditions"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getCustomerRankConditions"
        });
      }
    }
  },

  getCustomerRanks: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCustomerRanks" });

      const response = await CustomerRankService.getCustomerRanks();

      if (response.status === 200 && response.data.data) {
        commit("SET_CUSTOMER_RANKS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getCustomerRanks" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getCustomerRanks"
        });
      }
    }
  },

  resetCustomerRank: ({ commit }) => {
    commit("SET_CUSTOMER_RANK", {
      name: null,
      description: null,
      dks: []
    });
  },

  updateCustomerRank: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateCustomerRank" });

      const response = await CustomerRankService.updateCustomerRank(
        payload.customerRank
      );

      if (response.status === 200 && response.data) {
        // Update Customer Ranks
        Vue.set(
          state.customerRanks,
          payload.customerRankIndex,
          payload.customerRank
        );

        commit("SET_STATUS_REQUEST", { value: "success-updateCustomerRank" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateCustomerRank"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
