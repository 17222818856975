import Api from "@/core/apis";

export default {
  createVatRequest(data) {
    return Api().post("vat-requests", {
      vat_requests: [{ ...data }]
    });
  },

  getOrderProcessingVatRequest(orderProcessingId) {
    return Api().get(`orders/handles/${orderProcessingId}/vat-request`);
  },

  updateVatRequest(data) {
    return Api().put("vat-requests", {
      vat_requests: [{ ...data }]
    });
  }
};
