/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

// import router from "@/core/router";
import DashboardService from "@/modules/Dashboard/service";
// import CookieFunction from "@/core/utils/cookie";
// import ChartService from "@/modules/Dashboard/service";
// import request from "axios";
import router from "@/core/router";

const state = {
  statusChartStatistic: null,
  chartStatistic: {},
  chartAnalytic: {},
  chartStatisticOptions: {},
  filterChartStatistic: [],
  typeChart: {
    id: "revenue",
    title: "Doanh Thu",
    value: {}
  }
};

const getters = {
  chartStatistic: state => state.chartStatistic,
  chartStatisticOptions: state => state.chartStatisticOptions,
  chartAnalytic: state => state.chartAnalytic,
  statusChartStatistic: state => state.statusChartStatistic,
  filterChartStatistic: state => state.filterChartStatistic,
  typeChart: state => state.typeChart
};

const mutations = {
  SET_STATE_VALUE: (state, payload) => {
    state[payload.key] = payload.value;
  }
};

const actions = {
  getPeriodsChartStatistic: async ({ dispatch, commit }, payload) => {
    try {
      commit("SET_STATE_VALUE", {
        key: "statusChartStatistic",
        value: 0
      });
      const response = await DashboardService.getChart(
        payload.type,
        payload.currentTime
      );
      const responsePrevious = await DashboardService.getChart(
        payload.type,
        payload.previousCycle
      );
      await dispatch("getTotalChartStatistic", payload.total);
      if (response.status === 200 && response.data && response.data.periods) {
        commit("SET_STATE_VALUE", {
          key: "chartStatisticOptions",
          value: {
            chart: {
              type: "spline"
            },
            title: {
              text: ""
            },
            xAxis: {
              categories: response.data.periods.map(a => {
                return Object.keys(a)[0].slice(0, 5);
              })
            },
            yAxis: {
              title: {
                text: "Số tiền"
              }
            },
            series: [
              {
                name: payload.name,
                data: response.data.periods.map(a => {
                  return parseInt(a[Object.keys(a)[0]]);
                }),
                color: "#27BECE"
              },
              {
                name: payload.name + " kỳ trước",
                data: responsePrevious.data.periods.map(a => {
                  return parseInt(a[Object.keys(a)[0]]);
                }),
                dashStyle: "ShortDash",
                color: "#868686"
              }
            ]
          }
        });
        commit("SET_STATE_VALUE", {
          key: "statusChartStatistic",
          value: 1
        });
      }
    } catch (e) {
      if (e && e.response && e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATE_VALUE", {
          key: "statusChartStatistic",
          value: 2
        });
      }
    }
  },
  getTotalChartStatistic: async ({ commit }, payload) => {
    try {
      commit("SET_STATE_VALUE", {
        key: "statusChartStatistic",
        value: 0
      });
      const revenueResponse = await DashboardService.getChart(
        "revenue",
        payload
      );
      const profitResponse = await DashboardService.getChart("profit", payload);
      const returnResponse = await DashboardService.getChart("return", payload);
      const exchangeResponse = await DashboardService.getChart(
        "exchange",
        payload
      );
      const importResponse = await DashboardService.getChart("import", payload);
      const inventoryResponse = await DashboardService.getChart("po", payload);
      const poResponse = await DashboardService.getChart("inventory", payload);
      const quantityResponse = await DashboardService.getChart(
        "quantity",
        payload
      );
      const filterChartStatistic = [
        {
          id: "revenue",
          title: "Doanh Thu",
          value: revenueResponse.data.total
        },
        {
          id: "profit",
          title: "Lợi nhuận",
          value: profitResponse.data.total
        },
        {
          id: "return",
          title: "Trả hàng/Phí trả",
          value: returnResponse.data.total
        },
        {
          id: "exchange",
          title: "Vận chuyển nội bộ",
          value: exchangeResponse.data.total
        },
        {
          id: "po",
          title: "Đặt hàng NCC",
          value: poResponse.data.total
        },
        {
          id: "import",
          title: "Nhập hàng",
          value: importResponse.data.total
        },
        {
          id: "inventory",
          title: "Giá trị kho",
          value: inventoryResponse.data.total
        },
        {
          id: "quantity",
          title: "SL SP",
          value: quantityResponse.data.total
        }
      ];

      commit("SET_STATE_VALUE", {
        key: "filterChartStatistic",
        value: filterChartStatistic
      });
      commit("SET_STATE_VALUE", {
        key: "statusChartStatistic",
        value: 1
      });
    } catch (e) {
      if (e && e.response && e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATE_VALUE", {
          key: "statusChartStatistic",
          value: 2
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
