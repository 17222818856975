export const INIT_CUSTOMER_VALUE = {
  alias: null,
  avatar: null,
  name: null,
  email: null,
  phone: null,
  dob: null,
  gender: null,
  job: null,
  company: null,
  facebook: null,
  cmnd: null,
  note: null,
  birthType: "FULL"
};

export const INIT_CUSTOMER_ADDRESS_VALUE = {
  of_user_id: null,
  name: null,
  phone: null,
  tinh_thanhpho: null,
  quan_huyen: null,
  phuong_xa: null,
  so_nha: null
};
