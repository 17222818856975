import AppToast from "./AppToast";

const Toast = {
  install(Vue) {
    this.EventBus = new Vue();

    Vue.component("tp-toast", AppToast);

    Vue.prototype.$toast = {
      show(params) {
        Toast.EventBus.$emit("show", params);
      },
      hide(params) {
        Toast.EventBus.$emit("hide", params);
      }
    };
  }
};

export default Toast;
