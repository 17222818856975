import Api from "@/core/apis";

export default {
  getSessions() {
    return Api().get("events/dao-buoc-cong-nghe-quan-10/sessions");
  },

  getFlashSaleProductOptionsBySessionId(sessionId) {
    return Api().get(
      `events/dao-buoc-cong-nghe-quan-10/flash-sale/${sessionId}/options`
    );
  },

  handleAfterCancelingSaleReceipt(flashSaleOptionId) {
    return Api().post(
      "events/dao-buoc-cong-nghe-quan-10/flash-sale/sell/cancel/handle-after",
      {
        flash_sale_option_id: flashSaleOptionId
      }
    );
  },

  handleAfterCreatingSaleReceipt(flashSaleOptionId) {
    return Api().post(
      "events/dao-buoc-cong-nghe-quan-10/flash-sale/sell/add/handle-after",
      {
        flash_sale_option_id: flashSaleOptionId
      }
    );
  },

  importFlashSaleProductOptions(data) {
    return Api().post(
      "events/dao-buoc-cong-nghe-quan-10/flash-sale/options/import",
      data
    );
  }
};
