import {
  PROMOTION_TK_OPTION_TANG_TYPE,
  PROMOTION_TK_TYPE
} from "@/core/constant";

export const calculateOrderDiscountAmount = (orderValue, promotion) => {
  if (promotion.tk_type === PROMOTION_TK_TYPE.AMOUNT) {
    return Number(promotion.tk_money);
  }

  if (promotion.tk_type === PROMOTION_TK_TYPE.PERCENT) {
    return Math.round((Number(promotion.tk_percent) * orderValue) / 100);
  }
};

export const calculateTkDiscountAmount = (tkOption, price) => {
  if (!tkOption || !tkOption.tang_value) return price;

  if (tkOption.tang_type === PROMOTION_TK_OPTION_TANG_TYPE.FREE) return price;

  if (tkOption?.tang_type === PROMOTION_TK_OPTION_TANG_TYPE.AMOUNT)
    return Number(tkOption?.tang_value);

  return (Number(tkOption?.tang_value) * price) / 100;
};

export const calculateTkDiscountPercent = (tkOption, price) => {
  if (!tkOption || !tkOption.tang_value) return 0;

  if (tkOption.tang_type === PROMOTION_TK_OPTION_TANG_TYPE.FREE) return 100;

  if (tkOption?.tang_type === PROMOTION_TK_OPTION_TANG_TYPE.PERCENT)
    return tkOption?.tang_value;

  return Math.round((Number(tkOption?.tang_value) / price) * 100);
};

export const getTkOptions = promotion => {
  if (
    promotion.tk_option?.option_list &&
    promotion.tk_option?.option_list.length > 0
  ) {
    return promotion.tk_option?.option_list;
  }

  if (promotion.tk_option?.group && promotion.tk_option?.group.length > 0) {
    return promotion.tk_option?.group[0].options;
  }

  return [];
};
