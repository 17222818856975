/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import CustomizeFolderService from "../service/customize-folder.service";
import MediaService from "@/core/service/media.service";
// import store from "@/core/store";
import Vue from "vue";
// import defu from "@/core/utils/defu";
import { catchError } from "@/core/composables/useStore";
import {
  INIT_CUSTOMIZE_FOLDER_CATEGORY_VALUE,
  INIT_CUSTOMIZE_FOLDER_VALUE
} from "@/modules/Website/constants";
import { arrayToTree } from "performant-array-to-tree";

const state = {
  activeSuggestionTagIndex: null,
  customizeFolder: JSON.parse(JSON.stringify(INIT_CUSTOMIZE_FOLDER_VALUE)),
  customizeFolderCategory: JSON.parse(
    JSON.stringify(INIT_CUSTOMIZE_FOLDER_CATEGORY_VALUE)
  ),
  customizeFolderConditionFilters: {},
  customizeFolderFilters: [],
  customizeFolders: [],
  filtersList: null,
  filtersProductTotal: 0,
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  skusByType: [],
  skusByTypeTree: [],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedCover: null,
  uploadedThumbnail: null
};

const getters = {
  activeSuggestionTag: state => {
    if (
      state.customizeFolderCategory &&
      state.customizeFolderCategory.suggestion_tags[
        state.activeSuggestionTagIndex
      ]
    ) {
      return state.customizeFolderCategory.suggestion_tags[
        state.activeSuggestionTagIndex
      ];
    }

    return null;
  },
  activeSuggestionTagIndex: state => state.activeSuggestionTagIndex,
  customizeFolder: state => state.customizeFolder,
  customizeFolderCategory: state => state.customizeFolderCategory,
  customizeFolderCategoryConditionFilters: state =>
    state.customizeFolderCategory.condition_filters,
  customizeFolderCategoryFilters: state =>
    state.customizeFolderCategory.filters,
  customizeFolders: state => state.customizeFolders,
  filtersList: state => state.filtersList,
  filtersListProperties: state => {
    return Object.keys(state.filtersList);
  },
  filtersProductTotal: state => state.filtersProductTotal,
  paginationInfo: state => state.paginationInfo,
  skusByType: state => state.skusByType,
  skusByTypeTree: state => state.skusByTypeTree,
  statusRequest: state => state.statusRequest,
  uploadedCover: state => state.uploadedCover,
  uploadedThumbnail: state => state.uploadedThumbnail
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ACTIVE_SUGGESTION_TAG_INDEX: (state, payload) => {
    state.activeSuggestionTagIndex = payload;
  },

  SET_CUSTOMIZE_FOLDER: (state, payload) => {
    state.customizeFolder = payload;
  },

  SET_CUSTOMIZE_FOLDER_CATEGORY: (state, payload) => {
    state.customizeFolderCategory = payload;
  },

  SET_CUSTOMIZE_FOLDER_CONDITION_FILTERS: (state, payload) => {
    state.customizeFolderConditionFilters = payload;
  },

  SET_CUSTOMIZE_FOLDER_FILTERS: (state, payload) => {
    state.customizeFolderFilters = payload;
  },

  SET_CUSTOMIZE_FOLDERS: (state, payload) => {
    state.customizeFolders = payload;
  },

  SET_FILTERS_LIST: (state, payload) => {
    state.filtersList = payload;
  },

  SET_FILTERS_PRODUCT_TOTAL: (state, payload) => {
    state.filtersProductTotal = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_SKUS_BY_TYPE: (state, payload) => {
    state.skusByType = payload;
  },

  SET_SKUS_BY_TYPE_TREE: (state, payload) => {
    state.skusByTypeTree = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_COVER: (state, payload) => {
    state.uploadedCover = payload;
  },

  SET_UPLOADED_THUMBNAIL: (state, payload) => {
    state.uploadedCover = payload;
  }
};

const actions = {
  createCustomizeFolder: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createCustomizeFolder" });

      // const customizeFolder = handleConvertCustomizeFolder(payload);

      const response = await CustomizeFolderService.createCustomizeFolder(
        payload
      );

      if (response.status === 201 && response.data) {
        // Set customizeFolder id
        Vue.set(state.customizeFolder, "id", response.data.data.id);
        commit("SET_STATUS_REQUEST", {
          value: "success-createCustomizeFolder"
        });
      }
    } catch (e) {
      catchError(e, commit, "createCustomizeFolder");
    }
  },

  deleteCustomizeFolder: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteCustomizeFolder" });

      const response = await CustomizeFolderService.deleteCustomizeFolder(
        payload.customizeFolderId
      );

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", {
          value: "success-deleteCustomizeFolder"
        });
      }
    } catch (e) {
      catchError(e, commit, "deleteCustomizeFolder");
    }
  },

  getCustomizeFolderById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCustomizeFolderById" });

      const response = await CustomizeFolderService.getCustomizeFolderById(
        payload
      );

      if (response.status === 200 && response.data.data) {
        const customizeFolder = { ...response.data.data };

        commit("SET_CUSTOMIZE_FOLDER", customizeFolder);
        commit("SET_STATUS_REQUEST", {
          value: "success-getCustomizeFolderById"
        });
      }
    } catch (e) {
      catchError(e, commit, "getCustomizeFolderById");
    }
  },

  getCustomizeFolders: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCustomizeFolders" });

      const response = await CustomizeFolderService.getCustomizeFolders(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_CUSTOMIZE_FOLDERS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getCustomizeFolders" });
      }
    } catch (e) {
      catchError(e, commit, "getCustomizeFolders");
    }
  },

  getFiltersListBySharedUrls: async ({ commit }, url) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getFiltersListBySharedUrls"
      });

      const response = await CustomizeFolderService.getFiltersListBySharedUrl(
        url
      );

      if (response.status === 200 && response.data) {
        commit("SET_FILTERS_LIST", response.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-getFiltersListBySharedUrls"
        });
      }
    } catch (e) {
      catchError(e, commit, "getFiltersListBySharedUrls");
    }
  },

  getFilterProductTotal: async ({ commit }, filters) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getFilterProductTotal" });

      const queryFiltersParams = parseFiltersToQueryFiltersParams(filters);

      if (
        !queryFiltersParams.category ||
        queryFiltersParams.category.queryValue.length === 0
      ) {
        queryFiltersParams.category = {
          queryValue: [state.customizeFolderCategory.category_id]
        };
      }

      // Clear empty query
      const _filters = {};

      for (const key in queryFiltersParams) {
        const queryValue = queryFiltersParams[key].queryValue;

        if (queryValue?.length) {
          _filters[key] = { queryValue };
        }
      }

      const response = await CustomizeFolderService.getFilterProductTotal(
        _filters
      );

      if (response.status === 200 && response.data) {
        commit("SET_FILTERS_PRODUCT_TOTAL", response.data.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-getFilterProductTotal"
        });
      }
    } catch (e) {
      catchError(e, commit, "getFilterProductTotal");
    }
  },

  getSkusByType: async ({ commit }, { type, search }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSkusByType" });

      const response = await CustomizeFolderService.getSkusByType(type, search);

      const skusByTypeTree = arrayToTree(response.data.data, {
        dataField: null,
        parentId: "parent_id"
      });

      commit("SET_SKUS_BY_TYPE_TREE", skusByTypeTree);

      if (response.status === 200 && response.data.data) {
        commit("SET_SKUS_BY_TYPE", response.data.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-getSkusByType"
        });
      }
    } catch (e) {
      catchError(e, commit, "getSkusByType");
    }
  },

  resetCustomizeFolder: ({ commit }) => {
    commit(
      "SET_CUSTOMIZE_FOLDER",
      JSON.parse(JSON.stringify(INIT_CUSTOMIZE_FOLDER_VALUE))
    );
  },

  resetCustomizeFolderCategory: ({ commit }) => {
    commit(
      "SET_CUSTOMIZE_FOLDER_CATEGORY",
      JSON.parse(JSON.stringify(INIT_CUSTOMIZE_FOLDER_CATEGORY_VALUE))
    );
  },

  resetCustomizeFolderFilters: ({ commit }) => {
    commit("SET_CUSTOMIZE_FOLDER_FILTERS", []);
  },

  resetFiltersProductTotal: ({ commit }) => {
    commit("SET_FILTERS_PRODUCT_TOTAL", 0);
  },

  setActiveSuggestionTagIndex: ({ commit }, payload) => {
    commit("SET_ACTIVE_SUGGESTION_TAG_INDEX", payload);
  },

  setCustomizeFolder: ({ commit }, payload) => {
    commit("SET_CUSTOMIZE_FOLDER", payload);
  },

  setCustomizeFolderCategory: ({ commit }, payload) => {
    commit("SET_CUSTOMIZE_FOLDER_CATEGORY", payload);
  },

  setCustomizeFolderConditionFilters: ({ commit }, payload) => {
    commit("SET_CUSTOMIZE_FOLDER_CONDITION_FILTERS", payload);
  },

  updateCustomizeFolder: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateCustomizeFolder" });

      // const customizeFolder = handleConvertCustomizeFolder(payload);

      const response = await CustomizeFolderService.updateCustomizeFolder(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-updateCustomizeFolder"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateCustomizeFolder");
    }
  },

  uploadCover: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadCover" });

      // Append folder
      payload.append("folder", "front/folders");

      const response = await MediaService.uploadImage(payload);

      if (response.status === 200 && response.data) {
        commit("SET_UPLOADED_COVER", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadCover" });
      }
    } catch (e) {
      catchError(e, commit, "uploadCover");
    }
  },
  uploadThumbnail: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadThumbnail" });

      // Append folder
      payload.append("folder", "front/folders");

      const response = await MediaService.uploadImage(payload);

      if (response.status === 200 && response.data) {
        commit("SET_UPLOADED_THUMBNAIL", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadThumbnail" });
      }
    } catch (e) {
      catchError(e, commit, "uploadThumbnail");
    }
  }
};

const parseFiltersToQueryFiltersParams = filters => {
  if (Array.isArray(filters)) {
    return filters.reduce((res, property) => {
      // Check selected property
      if (property.selected) {
        let queryValue = [];

        if (property.displayType === "switch") {
          queryValue = [true];
        }

        if (property.displayType === "choices") {
          queryValue = property.getValue
            .filter(item => item.selected)
            .map(item => item[property.need]);
        }

        if (property.displayType === "range") {
          queryValue = [];
        }

        res[property.key] = { queryValue };
      }

      return res;
    }, {});
  } else {
    const filtersObj = JSON.parse(JSON.stringify(filters));

    if (filtersObj.price) {
      filtersObj.price.queryValue = [
        {
          from: filtersObj.price.queryValue[0],
          to: filtersObj.price.queryValue[1]
        }
      ];
    }

    return filtersObj;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
