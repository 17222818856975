/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import HomeSettingService from "../service/home-setting.service";
import MediaService from "@/core/service/media.service";
import { catchError } from "@/core/composables/useStore";
import { INIT_HOME_SETTING } from "../constants";
import { toFormData } from "@vt7/utils";

const state = {
  homeSetting: JSON.parse(JSON.stringify(INIT_HOME_SETTING)),
  statusRequest: {
    message: null,
    value: null
  },
  uploadedImage: null
};

const getters = {
  homeSetting: state => state.homeSetting,
  statusRequest: state => state.statusRequest,
  uploadedImage: state => state.uploadedImage
};

const mutations = {
  SET_HOME_SETTING: (state, payload) => {
    state.homeSetting = Object.assign(
      {},
      JSON.parse(JSON.stringify(INIT_HOME_SETTING)),
      payload
    );
  },

  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_IMAGE: (state, payload) => {
    state.uploadedImage = payload;
  }
};

const actions = {
  getHomeSettingBySite: async ({ commit }, site = "THINKPRO") => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getHomeSettingBySite" });

      const response = await HomeSettingService.getHomeSettingBySite(site);

      if (response.status === 200 && response.data.data) {
        commit("SET_HOME_SETTING", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getHomeSettingBySite" });
      }
    } catch (e) {
      catchError(e, commit, "getHomeSettingBySite");
    }
  },

  updateHomeSetting: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateHomeSetting" });
      const response = await HomeSettingService.updateHomeSetting(payload);

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", { value: "success-updateHomeSetting" });
      }
    } catch (error) {
      catchError(error, commit, "updateHomeSetting");
    }
  },

  uploadBannerImage: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadBannerImage" });

      const formData = toFormData({ file: payload, folder: "core/banners" });

      const response = await MediaService.uploadImage(formData);

      if (response.status === 200 && response.data) {
        commit("SET_UPLOADED_IMAGE", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadBannerImage" });
      }
    } catch (e) {
      catchError(e, commit, "uploadBannerImage");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
