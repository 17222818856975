import Vue from "vue";
import Modal from "@/core/plugins/modal";
import Toast from "@/core/plugins/toast";
import VueApexCharts from "vue-apexcharts";
import HorizontalScroll from "./HorizontalScroll ";
import socketPlugin from "@/core/plugins/socket";

import VueMask from "v-mask";

// Editor
import "@vt7/think-tiptap/dist/style.css";
import { ThinkTipTapEditor } from "@vt7/think-tiptap";

Vue.component("ThinkTiptap", ThinkTipTapEditor);

Vue.use(Modal);
Vue.use(ThinkTipTapEditor);
Vue.use(Toast);
Vue.use(VueMask);

Vue.use(socketPlugin);
Vue.use(HorizontalScroll);

Vue.component("apexchart", VueApexCharts);
