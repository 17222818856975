import CashFlowService from "../service/index";
import router from "@/core/router";
import store from "@/core/store";

//Voucher links model name,
import { UserModelName, StoreName } from "../constant";

export const generateState = () => {
  return {
    cashFlowDetail: {
      branch_id: null,
      sender: {
        model_name: "App\\Models\\User",
        model_id: 100
      },
      receiver: null,
      type: 1,
      apply_type: 1,
      source_apply_type: 1,
      voucher_links: [],
      source_voucher_links: [],
      payment_type: 3,
      payment_amount: 0,
      payment_fee: 0,
      payment_account_id: null,
      note: ""
    },
    cashFlowList: [],
    cashFlowListTotals: {
      total_money: 0
    },
    orders: [],
    paginationInfo: {
      from: 0,
      to: 0,
      pageTotal: 1,
      itemTotal: 0
    },
    statusRequest: {
      message: null,
      value: null
    }
  };
};

export const generateGetter = () => {
  return {
    cashFlowDetail: state => state.cashFlowDetail,
    cashFlowList: state => state.cashFlowList,
    cashFlowListTotals: state => state.cashFlowListTotals,
    statusRequest: state => state.statusRequest,
    orders: state => state.orders,
    paginationInfo: state => state.paginationInfo
  };
};

export const generateMutations = () => {
  return {
    SET_CASH_FLOW_DETAIL: (state, payload) => {
      state.cashFlowDetail = {
        ...payload,
        receiver: payload.receiver
          ? { ...payload.receiver, id: payload.receiver.model_id }
          : {},
        sender: payload.sender
          ? { ...payload.sender, id: payload.sender.model_id }
          : {}
      };
    },

    SET_CASH_FLOW_LIST: (state, payload) => {
      state.cashFlowList = payload;
    },

    SET_CASH_FLOW_LIST_TOTALS: (state, payload) => {
      state.cashFlowListTotals = payload;
    },

    SET_ORDERS: (state, payload) => {
      state.orders = payload;
    },

    SET_PAGINATION_INFO: (state, payload) => {
      state.paginationInfo = payload;
    },

    SET_STATUS_REQUEST: (state, payload) => {
      state.statusRequest.message = payload.message || null;
      state.statusRequest.value = payload.value;
    },

    SET_VOUCHER_LINKS: (state, payload) => {
      state.cashFlowDetail.voucher_links = payload;
    }
  };
};

export const generateActions = (modelName, storeName, orderTypes) => {
  return {
    addCashFlow: async ({ commit, state }, payload) => {
      try {
        commit("SET_STATUS_REQUEST", { value: "loading-addCashFlow" });

        if (!payload) {
          const { cashFlowDetail } = state;
          const { source_voucher_links, voucher_links } = cashFlowDetail;
          let total_voucher_links = voucher_links;
          if (source_voucher_links) {
            total_voucher_links = total_voucher_links.concat(
              source_voucher_links
            );
          }
          let data = {
            ...cashFlowDetail,
            voucher_links: total_voucher_links.map(value => ({
              ...value,
              model_id: value.id
            }))
          };
          const user = store.getters["AUTHENTICATION/user"];
          data.sender = {
            model_id: user.id,
            model_name: UserModelName.User
          };
          if (data.receiver) {
            data.receiver = {
              model_name: data.receiver.model_name,
              model_id: data.receiver.model_id
            };
          }

          let receiver = {
            ...data.receiver
          };
          if (storeName === StoreName.RECEIPT_VOUCHER) {
            data.receiver = { ...data.sender };
            data.sender = receiver;
          }

          data.branch_id = data.branch_id
            ? data.branch_id
            : user.branch_id
            ? user.branch_id
            : 1;
          const response = await CashFlowService.addCashFlow({
            model_name: modelName,
            ...data
          });

          if (response.status < 400 && response.data) {
            // store.dispatch(`${storeName}/getVouchers`);
            store.dispatch(`${storeName}/resetCashFlowDetail`);
            store.dispatch("AUTHENTICATION/getCurrentUser");
            //resetCashFlowDetail
            commit("SET_STATUS_REQUEST", {
              value: "success-addCashFlow"
            });
          }
        } else {
          const response = await CashFlowService.addCashFlow({
            model_name: modelName,
            ...payload
          });

          if (response.status < 400 && response.data) {
            const cashFlowDetail = payload;

            // Set id
            cashFlowDetail.id = response.data;
            store.dispatch(`${storeName}/setCashFlowDetail`, cashFlowDetail);

            // store.dispatch(`${storeName}/resetCashFlowDetail`);
            store.dispatch("AUTHENTICATION/getCurrentUser");
            //resetCashFlowDetail
            commit("SET_STATUS_REQUEST", {
              value: "success-addCashFlow"
            });
          }
        }
      } catch (e) {
        console.log(e);
        // if (e.response.status === 500) {
        //   router.push({ name: "500" });
        // } else {
        //   commit("SET_STATUS_REQUEST", {
        //     message: e.response ? e.response.data : "",
        //     value: "error-addCashFlow",
        //   });
        // }
      }
    },

    getCashFlowList: async ({ commit }, data) => {
      try {
        commit("SET_STATUS_REQUEST", { value: "loading-getCashFlowList" });
        const response = await CashFlowService.getCashFlowList({
          model_name: modelName,
          ...data
        });

        if (response.status === 200 && response.data) {
          commit("SET_CASH_FLOW_LIST", response.data.data);
          commit("SET_PAGINATION_INFO", {
            from: response.data.meta.from ? response.data.meta.from : 0,
            to: response.data.meta.to ? response.data.meta.to : 0,
            pageTotal: response.data.meta.last_page,
            itemTotal: response.data.meta.total
          });
          commit("SET_CASH_FLOW_LIST_TOTALS", {
            total_money: response.data.meta.total_money
              ? parseInt(response.data.meta.total_money)
              : 0
          });
          commit("SET_STATUS_REQUEST", {
            value: "success-getCashFlowList"
          });
        }
      } catch (e) {
        if (e.response && e.response.status === 500) {
          router.push({ name: "500" });
        } else {
          commit("SET_STATUS_REQUEST", {
            message: e.response ? e.response.data : "",
            value: "error-getCashFlowList"
          });
        }
      }
    },

    getOrders: async ({ commit, state }) => {
      try {
        const { cashFlowDetail } = state;

        const { receiver, payment_amount, apply_type } = cashFlowDetail;

        if (!receiver || !receiver.model_name) {
          return;
        }
        const { model_name } = receiver;
        commit("SET_STATUS_REQUEST", { value: "loading-getOrders" });
        let request = null;
        if (storeName === StoreName.RECEIPT_VOUCHER) {
          request = CashFlowService.getCashFlowReceiptDestination;
        } else if (storeName === StoreName.PAYMENT_VOUCHER) {
          request = CashFlowService.getCashFlowPaymentDestination;
        } else {
          request = CashFlowService.getCashFlowExchangeSource;
        }
        const user = store.getters["AUTHENTICATION/user"];
        const response = await request({
          model_name,
          model_id:
            storeName === StoreName.CASH_TRANSFER_NOTE ? user.id : receiver.id
        });

        if (response.status === 200 && response.data) {
          const orderTypesKeys = Object.keys(orderTypes);
          let orders = [];
          orderTypesKeys.forEach(key => {
            orders = orders.concat(
              response.data[key].map(val => ({ ...val, model_name: key }))
            );
          });
          if (apply_type === 2) {
            commit("SET_ORDERS", orders);
            return;
          }
          let amount = payment_amount;
          let dataSet = [];
          for (let index = 0; index < orders.length; index++) {
            let obj = orders[index];
            if (amount > obj.remains - obj.pending) {
              obj.amount = obj.remains - obj.pending;
            } else {
              obj.amount = amount;
            }
            dataSet.push(obj);
            amount = amount - obj.amount;
            if (amount <= 0) {
              break;
            }
          }
          commit("SET_ORDERS", orders);
          commit("SET_VOUCHER_LINKS", dataSet);
          commit("SET_STATUS_REQUEST", {
            value: "success-getOrders"
          });
        }
      } catch (e) {
        if (e.response && e.response.status === 500) {
          router.push({ name: "500" });
        } else {
          commit("SET_STATUS_REQUEST", {
            message: e.response ? e.response.data : "",
            value: "error-getOrders"
          });
        }
      }
    },

    resetCashFlowDetail: ({ commit }) => {
      commit("SET_CASH_FLOW_DETAIL", {
        branch_id: null,
        sender: {},
        receiver: null,
        type: 1,
        apply_type: 1,
        source_apply_type: 1,
        voucher_links: [],
        source_voucher_links: [],
        payment_type: 3,
        payment_amount: 0,
        payment_fee: 0,
        payment_account_id: null,
        note: ""
      });
    },

    resetVoucherLinks: ({ commit }) => {
      commit("SET_VOUCHER_LINKS", []);
    },

    setCashFlowDetail: ({ commit }, data) => {
      commit("SET_CASH_FLOW_DETAIL", data);
    },

    updateCashFlowStatus: async ({ commit, state }, data) => {
      try {
        const { cashFlowDetail } = state;
        const { status, cancel_reasons } = data;
        let request = null;

        commit("SET_STATUS_REQUEST", {
          value:
            status === "CANCEL"
              ? "loading-cancelCashFlow"
              : "loading-confirmCashFlow"
        });

        if (status === "CANCEL") {
          request = CashFlowService.cancelCashFlow;
        } else {
          request = CashFlowService.confirmCashFlow;
        }

        const response = await request({
          ...cashFlowDetail,
          cancel_reasons,
          voucher_links: cashFlowDetail.voucher_links.map(value => ({
            ...value,
            model_id: value.id
          }))
        });

        if (response.status < 400) {
          //store.dispatch(`${storeName}/getVouchers`);
          store.dispatch("AUTHENTICATION/getCurrentUser");
          commit("SET_STATUS_REQUEST", {
            value:
              status === "CANCEL"
                ? "success-cancelCashFlow"
                : "success-confirmCashFlow"
          });
        }
      } catch (e) {
        if (e.response && e.response.status === 500) {
          router.push({ name: "500" });
        } else {
          commit("SET_STATUS_REQUEST", {
            message:
              data.status === "CANCEL"
                ? "Rất tiếc, bạn không thể hủy phiếu lúc này"
                : "Lỗi xác nhận, vui lòng thử lại hoặc thông báo đến bộ phận kỹ thuật",
            value:
              data.status === "CANCEL"
                ? "error-cancelCashFlow"
                : "error-confirmCashFlow"
          });
        }
      }
    }
  };
};
