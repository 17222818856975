import Api from "@/core/apis";

export default {
  createEmployee(data) {
    return Api().post("staff/add", data);
  },

  deleteEmployee(id) {
    return Api().get(`staff/delete/${id}`);
  },

  getAllEmployees(data) {
    return Api().get(`staff/getAll${data ? `?staff_roles=${data}` : ""}`);
  },

  getEmployees(data) {
    return Api().post("staff/all", data);
  },

  updateEmployee(data) {
    return Api().post("staff/edit", data);
  }
};
