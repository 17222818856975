/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import EmployeeService from "../service/employee.service";
import MediaService from "@/core/service/media.service";
import router from "@/core/router";
import store from "@/core/store";
import Vue from "vue";

const state = {
  allEmployees: [],
  employee: {
    name: null,
    avatar: null,
    email: null,
    phone: null,
    role_id: null,
    branch_id: null
  },
  employees: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  },
  uploadedAvatar: null
};

const getters = {
  allEmployees: state => state.allEmployees,
  employee: state => state.employee,
  employees: state => state.employees,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest,
  uploadedAvatar: state => state.uploadedAvatar
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ALL_EMPLOYEES: (state, payload) => {
    state.allEmployees = payload;
  },

  SET_EMPLOYEE: (state, payload) => {
    state.employee = payload;
  },

  SET_EMPLOYEES: (state, payload) => {
    state.employees = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_AVATAR: (state, payload) => {
    state.uploadedAvatar = payload;
  }
};

const actions = {
  createEmployee: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createEmployee" });

      const response = await EmployeeService.createEmployee(payload.data);

      if (response.status === 201 && response.data.data) {
        // Get Employees
        await store.dispatch("EMPLOYEE/getEmployees", payload.routeQuery);
        // Set id
        Vue.set(state.employee, "id", response.data.data.id);
        Vue.set(state.employee, "code", response.data.data.code);

        commit("SET_STATUS_REQUEST", { value: "success-createEmployee" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createEmployee"
        });
      }
    }
  },

  deleteEmployee: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteCustomer" });

      const response = await EmployeeService.deleteEmployee(payload.data);

      if (response.status === 200) {
        // Get Employees
        await store.dispatch("EMPLOYEE/getEmployees", payload.routeQuery);

        commit("SET_STATUS_REQUEST", { value: "success-deleteEmployee" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-deleteEmployee"
        });
      }
    }
  },

  getAllEmployees: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllEmployees" });

      const response = await EmployeeService.getAllEmployees(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_ALL_EMPLOYEES", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAllEmployees" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getAllEmployees"
        });
      }
    }
  },

  getEmployees: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getEmployees" });

      const response = await EmployeeService.getEmployees(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_EMPLOYEES", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getEmployees" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getEmployees"
        });
      }
    }
  },

  resetEmployee: ({ commit }) => {
    commit("SET_EMPLOYEE", {
      name: null,
      avatar: null,
      email: null,
      phone: null,
      role_id: null,
      branch_id: null,
      staff_roles: []
    });
  },

  setEmployee: ({ commit }, payload) => {
    commit("SET_EMPLOYEE", payload);
  },

  updateEmployee: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateEmployee" });

      const response = await EmployeeService.updateEmployee(payload.data);

      if (response.status === 200 && response.data) {
        // Get Employees
        await store.dispatch("EMPLOYEE/getEmployees", payload.routeQuery);

        commit("SET_STATUS_REQUEST", { value: "success-updateEmployee" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateEmployee"
        });
      }
    }
  },

  uploadAvatar: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadAvatar" });

      payload.append("folder", "core/users");

      const response = await MediaService.uploadImage(payload);

      if (response.status === 200 && response.data) {
        await commit("SET_UPLOADED_AVATAR", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadAvatar" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-uploadAvatar"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
