/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import VatRequestService from "../service/vat-request.service";
import { catchError } from "@/core/composables/useStore";
import { INIT_VAT_REQUEST_VALUE } from "@/modules/Transaction/constant";

const state = {
  statusRequest: {
    message: null,
    value: null
  },
  vatRequest: JSON.parse(JSON.stringify(INIT_VAT_REQUEST_VALUE))
};

const getters = {
  statusRequest: state => state.statusRequest,
  vatRequest: state => state.vatRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_VAT_REQUEST: (state, payload) => {
    state.vatRequest = payload;
  }
};

const actions = {
  createVatRequest: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createVatRequest" });

      const response = await VatRequestService.createVatRequest(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_STATUS_REQUEST", { value: "success-createVatRequest" });
      }
    } catch (e) {
      catchError(e, commit, "createVatRequest");
    }
  },

  getOrderProcessingVatRequest: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getOrderProcessingVatRequest"
      });

      const response = await VatRequestService.getOrderProcessingVatRequest(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_VAT_REQUEST", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getOrderProcessingVatRequest"
        });
      }
    } catch (e) {
      catchError(e, commit, "getOrderProcessingVatRequest");
    }
  },

  resetVatRequest: ({ commit }) => {
    commit(
      "SET_VAT_REQUEST",
      JSON.parse(JSON.stringify(INIT_VAT_REQUEST_VALUE))
    );
  },

  updateVatRequest: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateVatRequest" });

      const response = await VatRequestService.updateVatRequest(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_STATUS_REQUEST", { value: "success-updateVatRequest" });
      }
    } catch (e) {
      catchError(e, commit, "updateVatRequest");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
