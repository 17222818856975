import { getYoutubeId } from "@vt7/utils";

export const isAbsolutePath = path => {
  return /^([a-z]+:)/gi.test(path);
};

export const isRelativePath = path => {
  return !isAbsolutePath(path);
};

export const getYoutubeEmbed = url => {
  return `https://www.youtube.com/embed/${getYoutubeId(url)}`;
};

export const getYoutubeThumbnail = url => {
  const youtubeId = getYoutubeId(url);
  return `http://img.youtube.com/vi/${youtubeId}/0.jpg`;
};
