import Api from "@/core/apis";
import axios from "axios";

export default {
  createProduct(data) {
    return Api().post("product/add", data);
  },

  deleteProducts(data) {
    return Api().post("product/delete", data);
  },

  editSpecs(data) {
    return Api().post("product/editSpecs", data);
  },

  getProductById(id) {
    return Api().get(`product/get/${id}`);
  },

  getAllProducts() {
    return Api().post("product/products/list");
  },

  getProducts(data) {
    return Api().post("product/products/list", data);
  },

  importExcel(data) {
    return Api().post("product/products/importExcel", data);
  },

  //TODO Không bỏ được vào env
  openAiCompletions(data) {
    return axios.post(
      "https://api.openai.com/v1/completions",
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer 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`
        }
      }
    );
  },

  searchProducts(data) {
    return Api().post("product/search", data);
  },

  getListProductsV2(data) {
    return Api().$post("v2/list-product", data);
  },

  updateProduct(data) {
    return Api().post("product/edit", data);
  },

  getAllSku() {
    return Api().$get("option/all");
  },

  getProductSpecsById(id) {
    return Api().get(`product/specs/${id}`);
  },

  addProductSpecs(data) {
    return Api().$post("specs/add", data);
  },

  updateProductSpecs(data) {
    return Api().$post("specs/edit", data);
  },

  deleteSpecs(id) {
    return Api().delete("specs/delete/" + id);
  },

  searchSpecs(key) {
    return Api().get("page-setting/list/specs", {
      params: {
        search: key
      }
    });
  }
};
