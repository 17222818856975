import Api from "@/core/apis";

export default {
  createProductType(data) {
    return Api().post("category/add", data);
  },

  deleteProductTypes(data) {
    return Api().post("category/delete", data);
  },

  getAllProductTypes(data) {
    return Api().get(`category/getAll${data ? `?search=${data}` : ""}`);
  },

  getProductTypeById(id) {
    return Api().post(`category/detail/${id}`);
  },

  getProductTypes(data) {
    return Api().post("category/all", data);
  },

  getAttributesByCategoryId(categoryId) {
    return Api().get(`attributes/all?category_id=${categoryId}`);
  },

  getAttributeTemplateByCategoryId(categoryId) {
    return Api().get(`template-attribute/detail?category_id=${categoryId}`);
  },

  updateProductType(data) {
    return Api().post("category/edit", data);
  },

  updateAttributeTemplate(categoryId, data) {
    return Api().post(`template-attribute/${categoryId}`, data);
  },

  sortProductTypes(data) {
    return Api().post("category/sort", { data });
  }
};
