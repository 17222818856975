import Api from "@/core/apis";

export default {
  getPromotions() {
    return Api().get(`/export-goods/promotions`);
  },
  addInvoiceConfirms(data) {
    return Api().post("/invoice-confirms", data);
  },
  getPosDevices() {
    return Api().get("pos/list");
  }
};
