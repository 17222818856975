import {
  LIST_PAYMENT_KIND,
  LIST_PAYMENT_STATUS,
  LIST_PAYMENT_TYPE
} from "@/core/constant";

export const getPaymentKindText = kind => {
  const paymentKind = Object.values(LIST_PAYMENT_KIND).find(
    item => item.id === kind
  );

  return paymentKind ? paymentKind.text : "N/A";
};

export const getPaymentTypeText = type => {
  const paymentType = Object.values(LIST_PAYMENT_TYPE).find(
    item => item.id === type
  );

  return paymentType ? paymentType.text : "N/A";
};

export const getProcessingThuTotal = transactions => {
  return transactions.reduce((total, item) => {
    if (item.status === LIST_PAYMENT_STATUS.PENDING.id) {
      if (
        item.type === LIST_PAYMENT_TYPE.THU.id ||
        item.type === LIST_PAYMENT_TYPE.THU_HO.id
      ) {
        total += item.amount;
      }
    }

    return total;
  }, 0);
};

export const getSuccessfulThuTotal = transactions => {
  return transactions.reduce((total, item) => {
    if (item.status === LIST_PAYMENT_STATUS.SUCCESS.id) {
      if (
        item.type === LIST_PAYMENT_TYPE.THU.id ||
        item.type === LIST_PAYMENT_TYPE.THU_HO.id
      ) {
        total += item.amount;
      }
    }

    return total;
  }, 0);
};

export const getThanhToanNgayTotal = transactions => {
  return transactions.reduce((total, item) => {
    if (
      item.kind === LIST_PAYMENT_KIND.TIEN_MAT.id ||
      item.kind === LIST_PAYMENT_KIND.CHUYEN_KHOAN.id ||
      item.kind === LIST_PAYMENT_KIND.QUET_THE.id ||
      item.kind === LIST_PAYMENT_KIND.TRA_GOP.id
    ) {
      if (item.status !== LIST_PAYMENT_STATUS.CANCEL.id) {
        total += item.amount;
      }
    }

    return total;
  }, 0);
};

export const getThanhToanSauTotal = transactions => {
  return transactions.reduce((total, item) => {
    if (
      item.kind === LIST_PAYMENT_KIND.COD.id &&
      item.status !== LIST_PAYMENT_STATUS.CANCEL.id
    ) {
      total += item.amount;
    }

    return total;
  }, 0);
};

export const getThuTotal = transactions => {
  return transactions.reduce((total, item) => {
    if (item.status !== LIST_PAYMENT_STATUS.CANCEL.id) {
      if (
        item.type === LIST_PAYMENT_TYPE.THU.id ||
        item.type === LIST_PAYMENT_TYPE.THU_HO.id
      ) {
        total += item.amount;
      }
    }

    return total;
  }, 0);
};
