import Api from "@/core/apis";

export default {
  addAnAccess(data) {
    return Api().post("upgrade/add", data);
  },

  deleteAddAccess(data) {
    return Api().get(`upgrade/delete/${data}`);
  },

  exportReportFile(data) {
    return Api().post("export-report/upgrade-gear", data, {
      responseType: "blob"
    });
  },

  getAddAccess(data) {
    return Api().get(`upgrade/detail/${data}`);
  },

  getAddAccesses(data) {
    return Api().post("upgrade/all", data);
  },

  getSerialBySerialNumber(data) {
    return Api().get(`serial/getSerialBySerialNumber/${data}`);
  }
};
