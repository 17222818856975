import io from "socket.io-client";

const socketPlugin = {
  install(Vue) {
    const socket = io(process.env.VUE_APP_SOCKET_URL, {
      autoConnect: false,
      reconnection: true,
      reconnectionAttempts: 3
    });
    Vue.prototype.$socket = socket;
  }
};

export default socketPlugin;
