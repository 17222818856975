export const RECEIPT_STATUS = {
  CHO_THANH_TOAN: 1,
  DOI_TAC_THU_HO_DA_THU_TIEN: 2,
  BI_HUY: 3,
  CHO_CHU_KY: 4,
  CHO_DOI_TAC_THU_HO_THU_TIEN: 5,
  CUA_HANG_DA_THU_TIEN: 6,
  DAO: 7,
  KET_TOAN: 8,
  DA_THANH_TOAN: 9
};

export const RECEIPT_STATUS_ARRAY = [
  {
    value: RECEIPT_STATUS.CHO_THANH_TOAN,
    label: "Chờ thanh toán",
    isLoading: true,
    color: "grey lighten-1",
    tooltip: "Chờ xử lý thanh toán"
  },
  {
    value: RECEIPT_STATUS.DOI_TAC_THU_HO_DA_THU_TIEN,
    label: "Đối tác thu hộ đã thu tiền",
    isLoading: false,
    color: "green darken-2",
    tooltip: "Đối tác thu hộ đã thu tiền, chờ chuyển về tài khoản của công ty"
  },
  {
    value: RECEIPT_STATUS.BI_HUY,
    label: "Bị hủy",
    isLoading: false,
    color: "red darken-2",
    tooltip: "Giao dịch bị huỷ, tiền đã trả cho khách hàng"
  },
  {
    value: RECEIPT_STATUS.CHO_CHU_KY,
    label: "Chờ chữ ký",
    isLoading: true,
    color: "orange lighten-2",
    tooltip: "Bạn cần lấy chữ ký của khách hàng để hoàn tất giao dịch"
  },
  {
    value: RECEIPT_STATUS.CHO_DOI_TAC_THU_HO_THU_TIEN,
    label: "Chờ đối tác thu hộ thu tiền",
    isLoading: false,
    color: "blue darken-2",
    tooltip: "Chờ đối tác thu hộ thu tiền"
  },
  {
    value: RECEIPT_STATUS.CUA_HANG_DA_THU_TIEN,
    label: "Cửa hàng đã thu tiền",
    isLoading: false,
    color: "amber darken-2",
    tooltip: "Cửa hàng trưởng đã xác nhận nhận được tiền với các khoản tiền mặt"
  },
  {
    value: RECEIPT_STATUS.DAO,
    label: "Đảo",
    isLoading: false,
    color: "purple darken-2",
    tooltip:
      "Giao dịch không thành công, tiền đang nằm ở Ngân hàng. Chờ ngân hàng hoàn lại cho khách."
  },
  {
    value: RECEIPT_STATUS.KET_TOAN,
    label: "Kết toán",
    isLoading: false,
    color: "teal darken-2",
    tooltip: "Tiền vẫn nằm bên MPOS hoặc bên 3rd, chưa chuyển về Công ty."
  },
  {
    value: RECEIPT_STATUS.DA_THANH_TOAN,
    label: "Đã thanh toán",
    isLoading: false,
    color: "light-green darken-2",
    tooltip:
      "Công ty đã nhận được tiền (MPOS đã chuyển cho công ty hoặc Khách dùng phương thức chuyển khoản, tiền đã về tài khoản của công ty)"
  }
];

export const getReceiptStatus = id => {
  if (!id) return null;

  const status = RECEIPT_STATUS_ARRAY.find(status => status.value === id);
  if (!status) return null;

  return status;
};

// ("Đang chờ");
// ("Thành công");
// ("Bị hủy");
// ("Chờ chữ ký");
// ("Đối tác thu hộ đã thu tiền");
// ("Cửa hàng đã thu tiền");
// ("Đảo");
// ("Kết toán");
// ("Đã thanh toán");
