import Api from "@/core/apis";

export default {
  createPromotion(data) {
    return Api().post("promotion/add", data);
  },

  disablePromotion(id) {
    return Api().post(`promotion/batTat/${id}`, {
      promotion_id: id,
      bat_tat: 0
    });
  },

  enablePromotion(id) {
    return Api().post(`promotion/batTat/${id}`, {
      promotion_id: id,
      bat_tat: 1
    });
  },

  getPromotionById(id) {
    return Api().get(`promotion/detail/${id}`);
  },

  getPromotions(data) {
    return Api().post("promotion/all", data);
  },

  getPromotionsForOrder() {
    return Api().get("promotions/for-order");
  },

  updatePromotion(data) {
    return Api().post("promotion/edit", data);
  },

  getSkusByType(type, search) {
    return Api().get(`promotion/search-sku?search=${search}&type=${type}`);
  },

  uploadBannerImage(data) {
    return Api().post("banner/thumbnail/upload", data);
  },

  validatePromotion(payload) {
    return Api().post("promotion/validate", payload);
  },

  getAllPromotions() {
    return Api().$get("promotion/getAll");
  }
};
