import Api from "@/core/apis";

export default {
  getTransactionsList(data) {
    return Api().post("/transactions/list", data);
  },
  cancelTransaction(data) {
    return Api().post("/transactions/cancel", data);
  },
  resolveTransaction(data) {
    return Api().post("/confirm-transaction", data);
  },
  exportReportFile(data) {
    return Api().post("/transactions/export", data, {
      responseType: "blob"
    });
  },
  getTransactionById(id) {
    return Api().get("/transactions/" + id);
  }
};
