/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import PageSectionBuildService from "../service/page-section-build.service";
import { catchError } from "@/core/composables/useStore";

const state = {
  pageSectionBuild: {
    page_id: 1,
    position: 1,
    number_of_slots: 11,
    products: [
      {
        id: null,
        position: 1
      },
      {
        id: null,
        position: 2
      },
      {
        id: null,
        position: 3
      },
      {
        id: null,
        position: 4
      },
      {
        id: null,
        position: 5
      },
      {
        id: null,
        position: 6
      },
      {
        id: null,
        position: 7
      },
      {
        id: null,
        position: 8
      },
      {
        id: null,
        position: 9
      },
      {
        id: null,
        position: 10
      },
      {
        id: null,
        position: 11
      }
    ]
  },
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  pageSectionBuild: state => state.pageSectionBuild,
  pageSectionBuildProductIds: state => {
    return state.pageSectionBuild.products
      .filter(item => item.product && item.product.id)
      .map(item => item.product.id);
  },
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGE_SECTION_BUILD: (state, payload) => {
    state.pageSectionBuild = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  getPageSectionBuildById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getPageSectionBuildById"
      });

      const response = await PageSectionBuildService.getPageSectionBuildById(
        payload
      );

      if (response.status === 200 && response.data.data) {
        const pageSectionBuild = response.data.data;

        // Map products
        pageSectionBuild.products = pageSectionBuild.products.map(item => {
          return {
            id: item.id,
            product: item,
            position: item.position
          };
        });

        // Set default products array
        if (pageSectionBuild.products.length < 11) {
          for (let i = 0; i < 11; i++) {
            if (!pageSectionBuild.products[i]) {
              pageSectionBuild.products.push({
                id: null,
                product: null,
                position: i + 1
              });
            }
          }
        }
        // Update pageSectionBuild
        commit("SET_PAGE_SECTION_BUILD", pageSectionBuild);

        commit("SET_STATUS_REQUEST", {
          value: "success-getPageSectionBuildById"
        });
      }
    } catch (e) {
      catchError(e, commit, "getPageSectionBuildById");
    }
  },

  resetPageSectionBuild: ({ commit }) => {
    commit("SET_PAGE_SECTION_BUILD", {
      page_id: 1,
      position: 1,
      number_of_slots: 11,
      products: [
        {
          id: null,
          position: 1
        },
        {
          id: null,
          position: 2
        },
        {
          id: null,
          position: 3
        },
        {
          id: null,
          position: 4
        },
        {
          id: null,
          position: 5
        },
        {
          id: null,
          position: 6
        },
        {
          id: null,
          position: 7
        },
        {
          id: null,
          position: 8
        },
        {
          id: null,
          position: 9
        },
        {
          id: null,
          position: 10
        },
        {
          id: null,
          position: 11
        }
      ]
    });
  },

  updatePageSectionBuild: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updatePageSectionBuild" });

      const pageSectionBuild = payload;

      pageSectionBuild.position = 1;

      pageSectionBuild.products = pageSectionBuild.products
        .filter(item => item.product)
        .map((item, index) => {
          return {
            id: item.product.id,
            position: index + 1
          };
        });

      const response = await PageSectionBuildService.updatePageSectionBuild(
        pageSectionBuild
      );

      if (response.status === 200 && response.data.data) {
        // Update pageSectionBuild

        commit("SET_STATUS_REQUEST", {
          value: "success-updatePageSectionBuild"
        });
      }
    } catch (e) {
      catchError(e, commit, "updatePageSectionBuild");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
