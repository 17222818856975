import Api from "@/core/apis";

export default {
  getInventories(data) {
    return Api().post("/kiemKho/list", data);
  },
  getDetail(inventoryId, branchId) {
    if (inventoryId) {
      return Api().get(`/kiemKho/detail/${inventoryId}`);
    } else {
      return Api().get(`/kiemKho/getSerialsToPickOfABranch/${branchId}`);
    }
  },
  getSerialInfo(serialNumber) {
    return Api().get(`/kiemKho/getSerialDetailForKK/${serialNumber}`);
  },
  completeInventory(payload) {
    return Api().post(`/kiemKho/add`, payload);
  },
  adjustmentImportPrice(payload) {
    return Api().post(`/inventory/adjustment-import-price`, payload);
  }
};
