import Api from "@/core/apis";

export default {
  getArriving(filters) {
    return Api().post("dashboard/quick-list/stocked/is-arriving", filters);
  },
  getLivedLong(filters) {
    return Api().post("dashboard/quick-list/stocked/lived-long", filters);
  },
  getManyInventory(filters) {
    return Api().post("dashboard/quick-list/stocked/a-lot", filters);
  },
  getJustOrders(filters) {
    return Api().post("dashboard/quick-list/stocked/new-order", filters);
  }
};
