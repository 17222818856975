/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import InventoryService from "../service/inventory.service";
import router from "@/core/router";

const state = {
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  inventories: [],
  inventory: {
    branchId: null,
    waiting: [],
    right: [],
    wrong: []
  },
  statusRequest: {
    message: null,
    value: null
  },
  serialInfo: {}
};

const getters = {
  paginationInfo: state => state.paginationInfo,
  inventory: state => state.inventory,
  serialInfo: state => state.serialInfo,
  inventories: state => state.inventories,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_INVENTORY: (state, payload) => {
    state.inventory = payload;
  },

  REMOVE_ALL: state => {
    state.inventory = {
      branchId: null,
      waiting: [],
      right: [],
      wrong: []
    };
    state.serialInfo = {};
  },

  CHECK_RIGHT: (state, payload) => {
    state.inventory.right.push(payload);
    const newWaiting = state.inventory.waiting.filter(e => e.id !== payload.id);
    state.inventory.waiting = newWaiting;
  },

  CHECK_WRONG: (state, payload) => {
    state.inventory.wrong.push(payload);
    const newWaiting = state.inventory.waiting.filter(e => e.id !== payload.id);
    state.inventory.waiting = newWaiting;
  },

  SET_SERIAL_INFO: (state, payload) => {
    state.serialInfo = payload;
  },

  SET_INVENTORIES: (state, payload) => {
    state.inventories = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  adjustmentImportPrice: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-adjustmentImportPrice" });

      const response = await InventoryService.adjustmentImportPrice(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-adjustmentImportPrice"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getInventories: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getInventories" });

      const response = await InventoryService.getInventories(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_INVENTORIES", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getInventories" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },
  getInventory: async ({ commit }, { inventoryId, branchId }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getInventory" });

      const response = await InventoryService.getDetail(inventoryId, branchId);

      if (response.status === 200 && response.data.data) {
        if (!inventoryId) {
          const waiting = response.data.data;
          const inventory = {
            branchId: branchId,
            waiting: waiting,
            right: [],
            wrong: []
          };
          commit("SET_INVENTORY", inventory);
        } else {
          const inventory = response.data.data;
          commit("SET_INVENTORY", inventory);
        }
        commit("SET_STATUS_REQUEST", { value: "success-getInventory" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },
  getSerialInfo: async ({ commit }, { serialNumber }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSerialInfo" });

      const response = await InventoryService.getSerialInfo(serialNumber);

      if (response.status === 200 && response.data.data) {
        commit("SET_SERIAL_INFO", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getSerialInfo" });
      } else {
        commit("SET_STATUS_REQUEST", {
          value: "error-getSerialInfo",
          message: response.message
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },
  checkRight: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-checkRight" });
      commit("CHECK_RIGHT", payload);
      commit("SET_STATUS_REQUEST", { value: "success-checkRight" });
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },
  checkWrong: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-checkWrong" });
      commit("CHECK_WRONG", payload);
      commit("SET_STATUS_REQUEST", { value: "success-checkWrong" });
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },
  removeAll: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-removeAll" });
      commit("REMOVE_ALL");
      commit("SET_STATUS_REQUEST", { value: "success-removeAll" });
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },
  completeInventory: async ({ commit, state }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-completeInventory" });
      const response = await InventoryService.completeInventory(
        state.inventory
      );
      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-completeInventory" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
