import Api from "@/core/apis";

export default {
  getRebateByCode(code) {
    return Api().get(`rebate/detail?rebate_code=${code}`);
  },

  getRebates(data) {
    return Api().post("rebate/all", data);
  }
};
