"use strict";

function compareObjByProperty(a, b, prop) {
  return a[prop] === b[prop];
}

function isObject(val) {
  return val !== null && typeof val === "object";
}

Array.prototype.unique = function() {
  const a = this.concat();

  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (isObject(a[i])) {
        if (compareObjByProperty(a[i], a[j], "value")) {
          a.splice(j--, 1);
        }
      } else if (a[i] === a[j]) {
        a.splice(j--, 1);
      }
    }
  }

  return a;
};

function _defu(baseObj, defaults, namespace = ".", merger) {
  if (!isObject(defaults)) {
    return _defu(baseObj, {}, namespace, merger);
  }

  const obj = Object.assign({}, defaults);

  for (const key in baseObj) {
    if (key === "__proto__" || key === "constructor") {
      continue;
    }

    const val = baseObj[key];

    if (val === null) {
      continue;
    }
    if (merger && merger(obj, key, val, namespace)) {
      continue;
    }
    if (Array.isArray(val) && Array.isArray(obj[key])) {
      obj[key] = obj[key].concat(val).unique();
    } else if (isObject(val) && isObject(obj[key])) {
      obj[key] = _defu(
        val,
        obj[key],
        (namespace ? `${namespace}.` : "") + key.toString(),
        merger
      );
    } else {
      obj[key] = val;
    }
  }
  return obj;
}

function extend(merger) {
  return (...args) => args.reduce((p, c) => _defu(p, c, "", merger), {});
}

const defu = extend();
// eslint-disable-next-line no-unused-vars
defu.fn = extend((obj, key, currentValue, _namespace) => {
  if (typeof obj[key] !== "undefined" && typeof currentValue === "function") {
    obj[key] = currentValue(obj[key]);
    return true;
  }
});
// eslint-disable-next-line no-unused-vars
defu.arrayFn = extend((obj, key, currentValue, _namespace) => {
  if (Array.isArray(obj[key]) && typeof currentValue === "function") {
    obj[key] = currentValue(obj[key]);
    return true;
  }
});

defu.extend = extend;

function comparePriceGetValueArrays(arr1, arr2) {
  let check = true;

  arr1.forEach((item, index) => {
    if (!compareObjByProperty(item, arr2[index], "label")) {
      check = false;

      return false;
    } else return true;
  });

  return check;
}

defu.mergeFilters = extend((sourceObj, targetKey, targetCurrentValue) => {
  /**
   * Case 1: No target price, return source obj price (default of defu, not handling)
   * Case 2: No source price or same price getValue, return target obj price (targetCurrentValue) (handle below)
   * Case 3: Both target and source price, create new prop is ranges, which includes both getValue arrays (handle below)
   */
  if (targetKey === "price" && targetCurrentValue) {
    if (
      !sourceObj[targetKey] ||
      (sourceObj[targetKey].getValue.length ===
        targetCurrentValue.getValue.length &&
        comparePriceGetValueArrays(
          sourceObj[targetKey].getValue,
          targetCurrentValue.getValue
        ))
    ) {
      sourceObj[targetKey] = {
        ...targetCurrentValue,
        selected: !!(
          targetCurrentValue.selected ||
          (sourceObj.price && sourceObj.price.selected)
        )
      };
    } else {
      if (!targetCurrentValue.ranges) {
        sourceObj[targetKey] = {
          ...sourceObj[targetKey],
          ranges: [
            [...targetCurrentValue.getValue],
            [...sourceObj.price.getValue]
          ],
          selected: !!(targetCurrentValue.selected || sourceObj.price.selected)
        };
      } else {
        let isIncluded = false;

        // check targetCurrentValue ranges include sourceObj price getValue
        targetCurrentValue.ranges.forEach(item => {
          if (comparePriceGetValueArrays(item, sourceObj.price.getValue)) {
            isIncluded = true;

            return false;
          } else return true;
        });

        // Nếu tồn tại trong ranges target trước đó, push source obj price getValue vảo target obj price ranges
        if (!isIncluded) {
          sourceObj[targetKey] = {
            ...sourceObj[targetKey],
            ranges: [
              ...targetCurrentValue.ranges,
              [...sourceObj.price.getValue]
            ],
            selected: !!(
              targetCurrentValue.selected || sourceObj.price.selected
            )
          };
        } else {
          sourceObj[targetKey] = {
            ...sourceObj[targetKey],
            ranges: targetCurrentValue.ranges,
            selected: !!(
              targetCurrentValue.selected || sourceObj.price.selected
            )
          };
        }
      }
    }

    return true;
  }
});

module.exports = defu;
