export const showModalAlertError = (
  context,
  { title, message },
  onCancelHandler
) => {
  context.$modal.show({
    name: "modal-alert",
    payload: {
      title: `<span class="red--text text--accent-2">${title}</span>`,
      message: message || null,
      cancelBtnText: "OK",
      onCancelHandler: onCancelHandler || undefined
    }
  });
};

export const showToastActionAlert = (context, { message }) => {
  context.$toast.show({
    name: "toast-action-alert",
    payload: {
      message: message
    }
  });
};
