/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import GoodsDeliveryService from "../service/goods-delivery.service";
import router from "@/core/router";
import store from "@/core/store";
import Vue from "vue";
import { catchError } from "@/core/composables";

// Group Array of Objects by Keys or Property Values
const groupBy = keys => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map(key => obj[key]).join("-");
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, []);

const state = {
  goodsDeliveries: [],
  goodsDelivery: {
    branch_xuat_id: null,
    branch_nhap_id: null,
    donvivanchuyen: null,
    fee: null,
    note: null,
    serials: [],
    options: [],
    distance: null
  },
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  goodsDeliveries: state => state.goodsDeliveries,
  goodsDelivery: state => state.goodsDelivery,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_GOODS_DELIVERIES: (state, payload) => {
    state.goodsDeliveries = payload;
  },

  SET_GOODS_DELIVERY: (state, payload) => {
    state.goodsDelivery = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  cancelGoodsDelivery: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-cancelGoodsDelivery" });

      const response = await GoodsDeliveryService.cancelGoodsDelivery(
        payload.id
      );

      if (response.status === 200 && response.data) {
        const goodsDelivery = state.goodsDeliveries[payload.index];

        goodsDelivery.status = -1;
        await Vue.set(state.goodsDeliveries, payload.index, goodsDelivery);
        commit("SET_STATUS_REQUEST", { value: "success-cancelGoodsDelivery" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-cancelGoodsDelivery"
        });
      }
    }
  },

  confirmGoodsDelivery: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-confirmGoodsDelivery" });

      const response = await GoodsDeliveryService.confirmGoodsDelivery(
        payload.data
      );

      if (response.status === 200 && response.data) {
        const goodsDelivery = state.goodsDeliveries[payload.index];

        goodsDelivery.status = 1;
        await Vue.set(state.goodsDeliveries, payload.index, goodsDelivery);
        commit("SET_STATUS_REQUEST", { value: "success-confirmGoodsDelivery" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-confirmGoodsDelivery"
        });
      }
    }
  },

  createGoodsDelivery: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createGoodsDelivery"
      });

      const response = await GoodsDeliveryService.createGoodsDelivery(payload);

      if (response.status === 200 && response.data) {
        if (
          payload.self_shipping &&
          payload.prepared_by_id &&
          payload.distance
        ) {
          const dataSender = {
            model_name: "App\\Models\\ChuyenHang",
            model_id: response.data,
            user_id: payload.prepared_by_id,
            role: "SHIPPING",
            note: payload.note,
            value: payload.distance
          };
          await store.dispatch("ORDER/addInvoiceParticipant", dataSender);
        } else if (!payload.self_shipping && payload.user_sending) {
          const dataSender = {
            model_name: "App\\Models\\ChuyenHang",
            model_id: response.data,
            user_id: payload.user_sending,
            role: "PACKAGING",
            note: payload.note
          };
          await store.dispatch("ORDER/addInvoiceParticipant", dataSender);
        }
        commit("SET_STATUS_REQUEST", {
          value: "success-createGoodsDelivery"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createGoodsDelivery"
        });
      }
    }
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await GoodsDeliveryService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportFile", { isRedirected: false });
    }
  },

  getGoodsDeliveries: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getGoodsDeliveries" });

      const response = await GoodsDeliveryService.getGoodsDeliveries(payload);

      if (response.status === 200 && response.data) {
        commit("SET_GOODS_DELIVERIES", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getGoodsDeliveries" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getGoodsDeliveries"
        });
      }
    }
  },

  getGoodsDeliveryById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getGoodsDeliveryById" });

      const response = await GoodsDeliveryService.getGoodsDeliveryById(payload);

      if (response.status === 200 && response.data.data) {
        const goodsDelivery = response.data.data,
          serialsArr = goodsDelivery.serials;
        const userInfo = store.getters["AUTHENTICATION/user"];

        // Group by option_id value
        let options = groupBy(["option_id"])(serialsArr).filter(
          item => item !== null
        );

        // Map results
        options = options.map(option => {
          return {
            id: option[0].option_id,
            SKU: option[0].option_sku,
            name: option[0].option_name,
            product: {
              name: option[0].product_name
            },
            price: option[0].option_price,
            serials: option.map(item => item.serial_number)
          };
        });

        goodsDelivery.options = options;
        goodsDelivery.shipping_supplier_id =
          goodsDelivery && goodsDelivery.shipping_supplier_id
            ? parseInt(goodsDelivery.shipping_supplier_id)
            : null;
        goodsDelivery.user_receiving =
          userInfo && userInfo.id ? userInfo.id : null;
        if (
          goodsDelivery.participants &&
          goodsDelivery.participants.length > 0
        ) {
          goodsDelivery.participants.map(item => {
            if (item.role === "PACKAGING") {
              goodsDelivery.user_sending = item.user_id ? item.user_id : null;
            }
            if (item.role === "SHIPPING") {
              goodsDelivery.prepared_by_id = item.user_id ? item.user_id : null;
              goodsDelivery.distance = item.value ? item.value : "";
            }
          });
        }

        commit("SET_GOODS_DELIVERY", goodsDelivery);
        commit("SET_STATUS_REQUEST", { value: "success-getGoodsDeliveryById" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getGoodsDeliveryById"
        });
      }
    }
  },

  resetGoodsDelivery: async ({ commit }) => {
    const user = store.getters["AUTHENTICATION/user"];
    let branch_xuat_id = user && user.branch_id ? user.branch_id : null;
    commit("SET_GOODS_DELIVERY", {
      branch_xuat_id: branch_xuat_id,
      branch_nhap_id: null,
      donvivanchuyen: null,
      user_sending: user.id,
      user_receiving: user.id,
      fee: null,
      note: null,
      serials: [],
      options: [],
      distance: null
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
