export const HEADERS_ORDER_POLICY_TABLE = [
  {
    text: "Tên chính sách",
    align: "start",
    sortable: false,
    value: "name"
  },
  {
    text: "Mô tả",
    align: "start",
    sortable: false,
    value: "description"
  },
  {
    text: "Cập nhật lần cuối",
    align: "start",
    sortable: false,
    value: "updated_at"
  },
  {
    text: "Người cập nhật",
    align: "start",
    sortable: false,
    value: "last_updated_by_user"
  }
];

export const INIT_ORDER_POLICY_VALUE = {
  name: null,
  description: null,
  product_condition: 1,
  address_condition: 1,
  payment_condition: {
    type: null,
    deposit_details: {
      type: 1,
      order_min: null,
      order_max: null,
      value: {
        type: 1,
        value: null
      }
    }
  },
  customer_gifts: [
    { type: 1, tk_type: null, tk_value: null },
    { type: 2, tk_type: null, tk_value: null },
    { type: 3, tk_type: null, tk_value: null },
    { type: 4, tk_type: null, tk_value: null },
    { type: 5, tk_type: null, tk_value: null },
    { type: 6, tk_type: 3, tk_value: null }
  ],
  areas: []
};

export const LIST_HOLDING_TIMES = [
  { id: 1, name: "1 ngày" },
  { id: 2, name: "2 ngày" },
  { id: 3, name: "3 ngày" },
  { id: 4, name: "4 ngày" },
  { id: 5, name: "5 ngày" },
  { id: 6, name: "6 ngày" },
  { id: 7, name: "7 ngày" },
  { id: 8, name: "8 ngày" },
  { id: 9, name: "9 ngày" },
  { id: 10, name: "10 ngày" },
  { id: 11, name: "11 ngày" },
  { id: 12, name: "12 ngày" },
  { id: 13, name: "13 ngày" },
  { id: 14, name: "14 ngày" },
  { id: 15, name: "15 ngày" },
  { id: 30, name: "30 ngày" },
  { id: 45, name: "45 ngày" },
  { id: 60, name: "60 ngày" },
  { id: 90, name: "90 ngày" },
  { id: null, name: "Vĩnh viễn" }
];

export const LIST_PAYMENT_CONDITIONS = [
  {
    id: 1,
    name: "Đặt cọc"
  },
  {
    id: 2,
    name: "Không yêu cầu đặt cọc / thanh toán toàn bộ"
  },
  {
    id: 3,
    name: "Thanh toán toàn bộ"
  }
];

export const LIST_PRODUCT_CONDITIONS = [
  {
    id: 1,
    name: "Hàng có sẵn hoặc sắp về cửa hàng trong khu vực khách kỳ vọng"
  },
  {
    id: 2,
    name: "Hàng không có sẵn hoặc không về cửa hàng trong khu vực khách kỳ vọng"
  },
  {
    id: 3,
    name: "Hàng có sẵn nhưng cần nhập NCC trong nước"
  },
  {
    id: 4,
    name: "Hàng chưa có sẵn, cẩn nhập NCC nước ngoài"
  },
  {
    id: 5,
    name: "Tất cả điều kiện"
  }
];
