/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import RemoveAccessService from "../service/remove-access.service";
import { catchError } from "@/core/composables";

const state = {
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  removeAnAccess: {
    old_option_id: null,
    new_option_id: null,
    gear_ids: []
  },
  oldOption: {},
  newOption: {},
  removeAccess: {},
  removeAccesses: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  paginationInfo: state => state.paginationInfo,
  removeAccess: state => state.removeAccess,
  removeAccesses: state => state.removeAccesses,
  removeAnAccess: state => state.removeAnAccess,
  oldOption: state => state.oldOption,
  newOption: state => state.newOption,
  gears: state => state.gears,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_REMOVE_ACCESS: (state, payload) => {
    state.removeAccess = payload;
  },

  SET_REMOVE_AN_ACCESS: (state, payload) => {
    state.removeAnAccess = payload;
  },

  SET_OLD_OPTION: (state, payload) => {
    state.oldOption = payload;
  },

  SET_NEW_OPTION: (state, payload) => {
    state.newOption = payload;
  },

  SET_GEARS: (state, payload) => {
    state.gears = payload;
  },

  SET_REMOVE_ACCESSES: (state, payload) => {
    state.removeAccesses = payload;
  },

  DELETE_REMOVE_ACCESS: (state, payload) => {
    state.removeAccesses = state.removeAccesses.filter(e => e.id !== payload);
  },

  SET_SEARCH_PRODUCTS: (state, payload) => {
    state.searchProducts = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_THUMBNAIL: (state, payload) => {
    state.uploadedThumbnail = payload;
  }
};

const actions = {
  clearOption: ({ commit, state }, payload) => {
    const removeAnAccess = { ...state.removeAnAccess };
    const gear = { ...removeAnAccess.gear_ids[payload] };

    delete gear["option_id"];

    removeAnAccess.gear_ids[payload] = gear;
    commit("SET_REMOVE_AN_ACCESS", removeAnAccess);
  },

  clearPrice: ({ commit, state }, payload) => {
    const removeAnAccess = { ...state.removeAnAccess };
    const gear = { ...removeAnAccess.gear_ids[payload] };

    delete gear["created_price"];

    removeAnAccess.gear_ids[payload] = gear;
    commit("SET_REMOVE_AN_ACCESS", removeAnAccess);
  },

  deleteRemoveAccess: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteRemoveAccess" });

      const response = await RemoveAccessService.deleteRemoveAccess(payload);

      if (response.status === 200 && response.data) {
        commit("DELETE_REMOVE_ACCESS", payload);
        commit("SET_STATUS_REQUEST", { value: "success-deleteRemoveAccess" });
      }
    } catch (e) {
      catchError(e, commit, "deleteRemoveAccess");
    }
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await RemoveAccessService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (e) {
      catchError(e, commit, "exportReportFile", { isRedirected: false });
    }
  },

  getRemoveAccess: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getRemoveAccess" });

      const response = await RemoveAccessService.getRemoveAccess(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_REMOVE_ACCESS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getRemoveAccess" });
      }
    } catch (e) {
      catchError(e, commit, "getRemoveAccess");
    }
  },

  getRemoveAccesses: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getRemoveAccesses" });

      const response = await RemoveAccessService.getRemoveAccesses(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_REMOVE_ACCESSES", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getRemoveAccesses" });
      }
    } catch (e) {
      catchError(e, commit, "getRemoveAccesses");
    }
  },

  removeAnAccess: async ({ commit, state }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-removeAnAccess" });

      const response = await RemoveAccessService.removeAnAccess(
        state.removeAnAccess
      );

      if (response.status === 200 && response.data) {
        state.removeAnAccess = {
          old_option_id: null,
          new_option_id: null,
          gear_ids: []
        };
        state.oldOption = {};
        state.newOption = {};
        commit("SET_STATUS_REQUEST", { value: "success-removeAnAccess" });
      }
    } catch (e) {
      catchError(e, commit, "removeAnAccess");
    }
  },

  removeGearOne: async ({ commit, state }, payload) => {
    const removeAnAccess = { ...state.removeAnAccess };

    removeAnAccess.gear_ids.splice(payload, 1);

    commit("SET_REMOVE_AN_ACCESS", removeAnAccess);
  },

  removeNewOne: ({ commit, state }) => {
    state.newOption = {};

    const removeAnAccess = { ...state.removeAnAccess };

    removeAnAccess.new_option_id = null;

    commit("SET_REMOVE_AN_ACCESS", removeAnAccess);
  },

  removeOldOne: ({ commit, state }) => {
    state.oldOption = {};

    const removeAnAccess = { ...state.removeAnAccess };

    removeAnAccess.old_option_id = null;

    commit("SET_REMOVE_AN_ACCESS", removeAnAccess);
  },

  resetData: state => {
    state.removeAnAccess = {
      old_option_id: null,
      new_option_id: null,
      gear_ids: []
    };
    state.oldOption = {};
    state.newOption = {};
    state.gears = [];
  },

  setRemoveAnAccessGears: async ({ commit, state }, payload) => {
    if (state.removeAnAccess.gear_ids.find(e => e.serial_number === payload))
      return "Có rùi!";

    commit("SET_STATUS_REQUEST", { value: "loading-setRemoveAnAccessGears" });

    const removeAnAccess = { ...state.removeAnAccess };

    removeAnAccess.gear_ids.push({ serial_number: payload });

    commit("SET_REMOVE_AN_ACCESS", removeAnAccess);
    commit("SET_STATUS_REQUEST", { value: "success-setRemoveAnAccessGears" });
  },

  setRemoveAnAccessGearsOption: async (
    { commit, state },
    { index, option }
  ) => {
    const removeAnAccess = { ...state.removeAnAccess };

    removeAnAccess.gear_ids[index].option_id = option.id;
    removeAnAccess.gear_ids[index].product_name = option.product_name;
    removeAnAccess.gear_ids[index].option_sku = option.SKU;

    commit("SET_REMOVE_AN_ACCESS", removeAnAccess);
  },

  setRemoveAnAccessGearsPrice: async ({ commit, state }, { index, price }) => {
    const removeAnAccess = { ...state.removeAnAccess };

    removeAnAccess.gear_ids[index].created_price = price;

    commit("SET_REMOVE_AN_ACCESS", removeAnAccess);
  },

  setRemoveAnAccessNew: async ({ commit, state }, payload) => {
    commit("SET_STATUS_REQUEST", { value: "loading-setRemoveAnAccessNew" });

    const newOption = payload;
    const removeAnAccess = { ...state.removeAnAccess };

    removeAnAccess.new_option_id = newOption.id;

    commit("SET_REMOVE_AN_ACCESS", removeAnAccess);
    commit("SET_NEW_OPTION", newOption);
    commit("SET_STATUS_REQUEST", { value: "success-setRemoveAnAccessNew" });
  },

  setRemoveAnAccessOld: async ({ commit, state }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-setRemoveAnAccessOld" });

      const response = await RemoveAccessService.getSerialBySerialNumber(
        payload,
        1
      );

      if (response.status === 200 && response.data.data) {
        const removeAnAccess = { ...state.removeAnAccess };

        removeAnAccess.old_option_id = response.data.data.id;

        commit("SET_REMOVE_AN_ACCESS", removeAnAccess);
        commit("SET_OLD_OPTION", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-setRemoveAnAccessOld" });
      }
    } catch (e) {
      catchError(e, commit, "setRemoveAnAccessOld");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
