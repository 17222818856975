import Api from "@/core/apis";

export default {
  createCustomer(data) {
    return Api().post("customer/add", data);
  },

  createCustomerAddress(data) {
    return Api().post("customer/address/add", data);
  },

  deleteCustomer(id) {
    return Api().get(`customer/delete/${id}`);
  },

  deleteCustomerAddress(id) {
    return Api().get(`customer/address/delete/${id}`);
  },

  exportReportFile(data) {
    return Api().post("customer/export", data, {
      responseType: "blob"
    });
  },

  getCustomerById(id) {
    return Api().get(`customer/detail/info/${id}`);
  },

  getCustomerAddresses(id) {
    return Api().get(`customer/address/all/${id}`);
  },

  getCustomers(data) {
    return Api().post("customer/all", data);
  },

  getCustomerTransactionHistory(data) {
    return Api().post(`customer/detail/history/${data.id}`, data.filters);
  },

  updateCustomer(data) {
    return Api().post("customer/edit", data);
  },

  updateCustomerAddress(data) {
    return Api().post("customer/address/edit", data);
  },

  uploadAvatar(data) {
    return Api().post("customer/avatar/upload", data);
  }
};
