/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import InvoiceParticipantService from "../service/invoice-participant.service";
import { catchError } from "@/core/composables/useStore";
import MediaService from "@/core/service/media.service";

const state = {
  invoiceParticipant: {
    model_id: null,
    model_name: null,
    user_id: null,
    role: null,
    note: null,
    value: null,
    docs: []
  },
  invoiceParticipants: [],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedImages: []
};

const getters = {
  invoiceParticipant: state => state.invoiceParticipant,
  invoiceParticipants: state => state.invoiceParticipants,
  statusRequest: state => state.statusRequest,
  uploadedImages: state => state.uploadedImages
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_INVOICE_PARTICIPANT: (state, payload) => {
    state.invoiceParticipant = payload;
  },

  SET_INVOICE_PARTICIPANTS: (state, payload) => {
    state.invoiceParticipants = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_IMAGES: (state, payload) => {
    state.uploadedImages = payload;
  }
};

const actions = {
  createManyInvoiceParticipant: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createManyInvoiceParticipant"
      });

      const response = await InvoiceParticipantService.createManyInvoiceParticipant(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_INVOICE_PARTICIPANT", response.data.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-createManyInvoiceParticipant"
        });
      }
    } catch (e) {
      catchError(e, commit, "createManyInvoiceParticipant");
    }
  },

  createInvoiceParticipant: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createInvoiceParticipant"
      });

      const response = await InvoiceParticipantService.createInvoiceParticipant(
        payload
      );

      if (response.status === 201 && response.data.data) {
        commit("SET_INVOICE_PARTICIPANT", response.data.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-createInvoiceParticipant"
        });
      }
    } catch (e) {
      catchError(e, commit, "createInvoiceParticipant");
    }
  },

  getInvoiceParticipants: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getInvoiceParticipants" });

      const response = await InvoiceParticipantService.getInvoiceParticipants(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_INVOICE_PARTICIPANTS", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getInvoiceParticipants"
        });
      }
    } catch (e) {
      catchError(e, commit, "getInvoiceParticipants");
    }
  },

  getInvoiceParticipantsOfInvoice: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getInvoiceParticipantsOfInvoice"
      });

      const response = await InvoiceParticipantService.getInvoiceParticipantsOfInvoice(
        payload.modelName,
        payload.modelId
      );

      if (response.status === 200 && response.data.data) {
        if (payload.disabledMutationState) {
          commit("SET_STATUS_REQUEST", {
            value: "success-getInvoiceParticipantsOfInvoice"
          });

          return response.data.data;
        } else {
          commit("SET_INVOICE_PARTICIPANTS", response.data.data);
          commit("SET_STATUS_REQUEST", {
            value: "success-getInvoiceParticipantsOfInvoice"
          });
        }
      }
    } catch (e) {
      catchError(e, commit, "getInvoiceParticipantsOfInvoice");
    }
  },

  resetInvoiceParticipant: ({ commit }) => {
    commit("SET_INVOICE_PARTICIPANT", {
      model_id: null,
      model_name: null,
      user_id: null,
      role: null,
      note: null,
      value: null,
      docs: []
    });
  },

  setInvoiceParticipant: ({ commit }, payload) => {
    commit("SET_INVOICE_PARTICIPANT", payload);
  },

  updateInvoiceParticipant: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updateInvoiceParticipant"
      });

      const response = await InvoiceParticipantService.updateInvoiceParticipant(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-updateInvoiceParticipant"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateInvoiceParticipant");
    }
  },

  uploadImages: async ({ commit }, formDatas) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-uploadImages"
      });

      let images = [];

      for (let i = 0; i < formDatas.length; i++) {
        try {
          const formData = formDatas[i];

          // Append folder
          formData.append("folder", "core/participants");

          const response = await MediaService.uploadImage(formData);

          if (response.status === 200 && response.data) {
            images.push(response.data);
          }
        } catch (e) {
          catchError(e, commit, "uploadImages");
        }
      }

      await commit("SET_UPLOADED_IMAGES", images);

      commit("SET_STATUS_REQUEST", {
        value: "success-uploadImages"
      });
    } catch (e) {
      catchError(e, commit, "uploadImages");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
