/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import GuaranteeVoucherCommentService from "../service/guarantee-voucher-comment.service";
import router from "@/core/router";

const state = {
  guaranteeVoucherComments: [],
  statusRequest: {
    message: null,
    value: null
  },
  bill: {
    status: false,
    value: null
  }
};

const getters = {
  guaranteeVoucherComments: state => state.guaranteeVoucherComments,
  statusRequest: state => state.statusRequest,
  bill: state => state.bill
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_GUARANTEE_VOUCHER_COMMENTS: (state, payload) => {
    state.guaranteeVoucherComments = payload;
  },

  SET_NEW_COMMENT: (state, payload) => {
    state.guaranteeVoucherComments.push(payload);
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },
  SET_STATUS_BILL: (state, payload) => {
    state.bill = payload;
  }
};

const actions = {
  createGuaranteeVoucherComment: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createGuaranteeVoucherComment"
      });

      const response = await GuaranteeVoucherCommentService.createGuaranteeVoucherComment(
        payload
      );

      if (response.status === 201 && response.data.data) {
        // Add new comments
        commit("SET_NEW_COMMENT", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-createGuaranteeVoucherComment"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createGuaranteeVoucherComment"
        });
      }
    }
  },

  getGuaranteeVoucherComments: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getGuaranteeVoucherComments"
      });

      const response = await GuaranteeVoucherCommentService.getGuaranteeVoucherComments(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_GUARANTEE_VOUCHER_COMMENTS", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getGuaranteeVoucherComments"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getGuaranteeVoucherComments"
        });
      }
    }
  },

  handlePrintBill: ({ commit }, payload) => {
    commit("SET_STATUS_BILL", payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
