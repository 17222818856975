<template>
  <div id="app">
    <component :is="layout">
      <transition name="component-trans" mode="out-in">
        <router-view></router-view>
      </transition>
      <!-- **************** MODAL ***************** -->
      <!-- Start: Modal alert -->
      <tp-modal-alert></tp-modal-alert>
      <!-- End: Modal alert -->
      <tp-modal-add-role-server />
      <tp-modal-adding-accessories></tp-modal-adding-accessories>
      <tp-modal-customer></tp-modal-customer>
      <tp-modal-cancel></tp-modal-cancel>
      <tp-modal-customer-return></tp-modal-customer-return>
      <tp-modal-goods-receipts></tp-modal-goods-receipts>
      <tp-modal-goods-delivery></tp-modal-goods-delivery>
      <tp-modal-goods-destruction></tp-modal-goods-destruction>
      <tp-modal-order></tp-modal-order>
      <tp-modal-product></tp-modal-product>
      <tp-modal-product-option-quick-create></tp-modal-product-option-quick-create>
      <tp-modal-removing-accessories></tp-modal-removing-accessories>
      <tp-modal-sale-receipts></tp-modal-sale-receipts>
      <tp-modal-serial-picker></tp-modal-serial-picker>
      <tp-modal-show-serial></tp-modal-show-serial>
      <tp-modal-supplier></tp-modal-supplier>
      <tp-modal-supplier-return></tp-modal-supplier-return>
      <tp-modal-utm-builder></tp-modal-utm-builder>
      <!-- **************** TOAST ***************** -->
      <!-- Start: Toast action alert -->
      <tp-toast-action-alert></tp-toast-action-alert>
      <!-- End: Toast action alert -->
    </component>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || "default") + "-layout";
    }
  }
};
</script>

<style lang="scss">
@import "@/core/scss/_global.scss";

#app {
  font-family: ui-sans-serif, -apple-system, "system-ui", "Segoe UI", Helvetica,
    "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
}
</style>
