const NotSoldWarehouseRouter = {
  path: "/not-sold-warehouse",
  component: () => import("@/modules/NotSoldWarehouse"),
  redirect: { name: "not-sold-warehouse_all-serials" },
  children: [
    {
      path: "all-serials",
      name: "not-sold-warehouse_all-serials",
      meta: {
        private: true,
        layout: "general",
        title: "Hàng không kinh doanh | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Hàng không kinh doanh",
            to: { name: "not-sold-warehouse_all-serials" }
          }
        ]
      },
      component: () => import("../pages/NotSoldSerials")
    },
    {
      path: "internal-repair-notes",
      component: () => import("../pages/InternalRepairNote"),
      children: [
        {
          path: "list",
          name: "not-sold-warehouse_internal-repair-notes",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu sửa chữa nội bộ | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Phiếu sửa chữa nội bộ",
                to: { name: "not-sold-warehouse_internal-repair-notes" }
              }
            ]
          },
          component: () => import("../pages/InternalRepairNote/Listing")
        },
        {
          path: "create",
          name: "not-sold-warehouse_internal-repair-notes-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới phiếu sửa chữa nội bộ | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu sửa chữa nội bộ",
                to: { name: "not-sold-warehouse_internal-repair-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới phiếu sửa chữa nội bộ",
                to: { name: "not-sold-warehouse_internal-repair-notes-create" }
              }
            ]
          },
          component: () => import("../pages/InternalRepairNote/Detail")
        },
        {
          path: "detail/:offBizId",
          name: "not-sold-warehouse_internal-repair-notes-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu bảo sửa chữa nội bộ | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu sửa chữa nội bộ",
                to: { name: "not-sold-warehouse_internal-repair-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Nội dung phiếu sửa chữa nội bộ",
                to: { name: "not-sold-warehouse_internal-repair-notes-detail" }
              }
            ]
          },
          component: () => import("../pages/InternalRepairNote/Detail")
        }
      ]
    },
    {
      path: "internal-guarantee-notes",
      component: () => import("../pages/InternalGuaranteeNote"),
      children: [
        {
          path: "list",
          name: "not-sold-warehouse_internal-guarantee-notes",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu bảo hành nội bộ | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Phiếu bảo hành nội bộ",
                to: { name: "not-sold-warehouse_internal-guarantee-notes" }
              }
            ]
          },
          component: () => import("../pages/InternalGuaranteeNote/Listing")
        },
        {
          path: "create",
          name: "not-sold-warehouse_internal-guarantee-notes-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới phiếu bảo hành nội bộ | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu bảo hành nội bộ",
                to: { name: "not-sold-warehouse_internal-guarantee-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới phiếu bảo hành nội bộ",
                to: {
                  name: "not-sold-warehouse_internal-guarantee-notes-create"
                }
              }
            ]
          },
          component: () => import("../pages/InternalGuaranteeNote/Detail")
        },
        {
          path: "detail/:offBizId",
          name: "not-sold-warehouse_internal-guarantee-notes-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới phiếu bảo hành nội bộ | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu bảo hành nội bộ",
                to: { name: "not-sold-warehouse_internal-guarantee-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới phiếu bảo hành nội bộ",
                to: {
                  name: "not-sold-warehouse_internal-guarantee-notes-detail"
                }
              }
            ]
          },
          component: () => import("../pages/InternalGuaranteeNote/Detail")
        }
      ]
    },
    {
      path: "return-serial-notes",
      component: () => import("../pages/ReturnSerialNote"),
      children: [
        {
          path: "list",
          name: "not-sold-warehouse_return-serial-notes",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu hàng trả chờ nhập | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Phiếu hàng trả chờ nhập",
                to: { name: "not-sold-warehouse_return-serial-notes" }
              }
            ]
          },
          component: () => import("../pages/ReturnSerialNote/Listing")
        },
        {
          path: "detail/:offBizId",
          name: "not-sold-warehouse_return-serial-notes-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới phiếu hàng trả chờ nhập | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu hàng trả chờ nhập",
                to: { name: "not-sold-warehouse_return-serial-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới phiếu bảo hành nội bộ",
                to: { name: "not-sold-warehouse_return-serial-notes-detail" }
              }
            ]
          },
          component: () => import("../pages/ReturnSerialNote/Detail")
        }
      ]
    },
    {
      path: "waiting-processing-notes",
      component: () => import("../pages/WaitingProcessingNote"),
      children: [
        {
          path: "list",
          name: "not-sold-warehouse_waiting-processing-notes",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu link kiện chờ xử lỷ | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Phiếu linh kiện chờ xử lý",
                to: { name: "not-sold-warehouse_waiting-processing-notes" }
              }
            ]
          },
          component: () => import("../pages/WaitingProcessingNote/Listing")
        },
        {
          path: "create",
          name: "not-sold-warehouse_waiting-processing-notes-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới phiếu linh kiện chờ xử lý | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu linh kiện chờ xử lý",
                to: { name: "not-sold-warehouse_waiting-processing-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới phiếu linh kiện chờ xử lý",
                to: {
                  name: "not-sold-warehouse_waiting-processing-notes-create"
                }
              }
            ]
          },
          component: () => import("../pages/WaitingProcessingNote/Detail")
        },
        {
          path: "detail/:offBizId",
          name: "not-sold-warehouse_waiting-processing-notes-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu linh kiện chờ xử lý | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu linh kiện chờ xử lý",
                to: { name: "not-sold-warehouse_waiting-processing-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Nội dung phiếu linh kiện chờ xử lý",
                to: {
                  name: "not-sold-warehouse_waiting-processing-notes-detail"
                }
              }
            ]
          },
          component: () => import("../pages/WaitingProcessingNote/Detail")
        }
      ]
    },
    {
      path: "waiting-assembly-notes",
      component: () => import("../pages/WaitingAssemblyNote"),
      children: [
        {
          path: "list",
          name: "not-sold-warehouse_waiting-assembly-notes",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu máy chờ lắp ráp | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Phiếu máy chờ lắp ráp",
                to: { name: "not-sold-warehouse_waiting-assembly-notes" }
              }
            ]
          },
          component: () => import("../pages/WaitingAssemblyNote/Listing")
        },
        {
          path: "create",
          name: "not-sold-warehouse_waiting-assembly-notes-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới phiếu máy chờ lắp ráp | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu máy chờ lắp ráp",
                to: { name: "not-sold-warehouse_waiting-assembly-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới phiếu máy chờ lắp ráp",
                to: {
                  name: "not-sold-warehouse_waiting-assembly-notes-create"
                }
              }
            ]
          },
          component: () => import("../pages/WaitingAssemblyNote/Detail")
        },
        {
          path: "detail/:offBizId",
          name: "not-sold-warehouse_waiting-assembly-notes-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu máy chờ lắp ráp | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu máy chờ lắp ráp",
                to: { name: "not-sold-warehouse_waiting-assembly-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Nội dung phiếu máy chờ lắp ráp",
                to: {
                  name: "not-sold-warehouse_waiting-assembly-notes-detail"
                }
              }
            ]
          },
          component: () => import("../pages/WaitingAssemblyNote/Detail")
        }
      ]
    },
    {
      path: "media-loan-notes",
      component: () => import("../pages/MediaLoanNote"),
      children: [
        {
          path: "list",
          name: "not-sold-warehouse_media-loan-notes",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu cho media mượn | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Phiếu cho media mượn",
                to: { name: "not-sold-warehouse_media-loan-notes" }
              }
            ]
          },
          component: () => import("../pages/MediaLoanNote/Listing")
        },
        {
          path: "create",
          name: "not-sold-warehouse_media-loan-notes-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới phiếu cho media mượn | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu cho media mượn",
                to: { name: "not-sold-warehouse_media-loan-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới phiếu cho media mượn",
                to: { name: "not-sold-warehouse_media-loan-notes-create" }
              }
            ]
          },
          component: () => import("../pages/MediaLoanNote/Detail")
        },
        {
          path: "detail/:offBizId",
          name: "not-sold-warehouse_media-loan-notes-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu cho media mượn | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu cho media mượn",
                to: { name: "not-sold-warehouse_media-loan-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Nội dung phiếu cho media mượn",
                to: { name: "not-sold-warehouse_media-loan-notes-detail" }
              }
            ]
          },
          component: () => import("../pages/MediaLoanNote/Detail")
        }
      ]
    },
    {
      path: "equipment-for-employee-notes",
      component: () => import("../pages/EquipmentForEmployeeNote"),
      children: [
        {
          path: "list",
          name: "not-sold-warehouse_equipment-for-employee-notes",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu cấp cho nhân viên | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Phiếu cấp cho nhân viên",
                to: { name: "not-sold-warehouse_equipment-for-employee-notes" }
              }
            ]
          },
          component: () => import("../pages/EquipmentForEmployeeNote/Listing")
        },
        {
          path: "create",
          name: "not-sold-warehouse_equipment-for-employee-notes-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới phiếu cấp cho nhân viên | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu cấp cho nhân viên",
                to: { name: "not-sold-warehouse_equipment-for-employee-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới phiếu cấp cho nhân viên",
                to: {
                  name: "not-sold-warehouse_equipment-for-employee-notes-create"
                }
              }
            ]
          },
          component: () => import("../pages/EquipmentForEmployeeNote/Detail")
        },
        {
          path: "detail/:offBizId",
          name: "not-sold-warehouse_equipment-for-employee-notes-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu cấp cho nhân viên | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu cấp cho nhân viên",
                to: { name: "not-sold-warehouse_equipment-for-employee-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Nội dung phiếu cấp cho nhân viên",
                to: {
                  name: "not-sold-warehouse_equipment-for-employee-notes-detail"
                }
              }
            ]
          },
          component: () => import("../pages/EquipmentForEmployeeNote/Detail")
        }
      ]
    },

    {
      path: "sample-serial-notes",
      component: () => import("../pages/SampleSerialNote"),
      children: [
        {
          path: "list",
          name: "not-sold-warehouse_sample-serial-notes",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu trưng bày | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Phiếu trưng bày",
                to: { name: "not-sold-warehouse_sample-serial-notes" }
              }
            ]
          },
          component: () => import("../pages/SampleSerialNote/Listing")
        },
        {
          path: "create",
          name: "not-sold-warehouse_sample-serial-notes-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới phiếu trưng bày | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu trưng bày",
                to: { name: "not-sold-warehouse_sample-serial-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới phiếu trưng bày",
                to: { name: "not-sold-warehouse_sample-serial-notes" }
              }
            ]
          },
          component: () => import("../pages/SampleSerialNote/Detail")
        },
        {
          path: "detail/:offBizId",
          name: "not-sold-warehouse_sample-serial-notes-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu trưng bày | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu trưng bày",
                to: { name: "not-sold-warehouse_sample-serial-notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Nội dung phiếu trưng bày",
                to: { name: "not-sold-warehouse_sample-serial-notes-detail" }
              }
            ]
          },
          component: () => import("../pages/SampleSerialNote/Detail")
        }
      ]
    }
  ]
};

export default NotSoldWarehouseRouter;
