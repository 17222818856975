const EventsRouter = {
  path: "/events",
  component: () => import("@/modules/Events"),
  redirect: { name: "events_dbcn" },
  children: [
    {
      path: "dbcn",
      name: "events_dbcn",
      meta: {
        private: true,
        layout: "general",
        title: "Dạo bước công nghệ | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: false,
            exact: true,
            text: "Event",
            to: { name: "event_dbcn" }
          },
          {
            disabled: true,
            exact: true,
            text: "Dạo bước công nghệ",
            to: { name: "event_dbcn" }
          }
        ]
      },
      component: () => import("../pages/Dbcn")
    }
  ]
};

export default EventsRouter;
