import Api from "@/core/apis";

export default {
  getSearchKeywords() {
    return Api().get("getSearchKeywords");
  },

  updateSearchKeywords(data) {
    return Api().post("updateListSearches", data);
  }
};
