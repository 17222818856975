const PromotionRouter = {
  path: "/promotion",
  component: () => import("@/modules/Promotion"),
  redirect: { name: "promotion" },
  children: [
    {
      path: "list",
      name: "promotion",
      meta: {
        private: true,
        layout: "general",
        title: "Khuyến mại | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Khuyến mại",
            to: { name: "promotion" }
          }
        ]
      },
      component: () => import("../pages/Listing")
    },
    {
      path: "create",
      name: "promotion_create",
      meta: {
        private: true,
        layout: "general",
        title: "Khuyến mại | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: false,
            exact: true,
            text: "Khuyến mại",
            to: { name: "promotion" }
          },
          {
            disabled: true,
            exact: true,
            text: "Thêm mới",
            to: { name: "promotion_create" }
          }
        ]
      },
      component: () => import("../pages/Detail")
    },
    {
      path: "detail/:promotionId",
      name: "promotion_detail",
      meta: {
        private: true,
        layout: "general",
        title: "Khuyến mại | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: false,
            exact: true,
            text: "Khuyến mại",
            to: { name: "promotion" }
          },
          {
            disabled: true,
            exact: true,
            text: "Cập nhật",
            to: { name: "promotion_detail" }
          }
        ]
      },
      component: () => import("../pages/Detail")
    }
  ]
};

export default PromotionRouter;
