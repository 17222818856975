import { ATTRIBUTES_DISPLAY_FORMAT } from "@/core/constant";

export const getAttributeText = (attribute, attributeFormatType) => {
  if (!attribute) {
    console.warn(`useAttribute/getAttributeText: Attribute is ${attribute}`);

    return "";
  }

  let value = attribute.pivot?.value;
  let measurement = attribute.measurement;
  const dataType = attribute.data_type;

  if (value === undefined || value === null) return "";

  const format = ATTRIBUTES_DISPLAY_FORMAT[attribute.code];

  if (measurement === "GB" && Number(value) / 1024 > 1) {
    value = `${Number(value) / 1024}`;
    measurement = "TB";
  }

  // Trường hợp có format dữ liệu
  if (format && format[attributeFormatType] && value) {
    if (dataType === "boolean")
      return format[attributeFormatType][Number(value)];

    if (dataType === "array") {
      if (!Array.isArray(value)) return "";

      const valueText = value.length > 1 ? value.join(", ") : value[0];

      return format[attributeFormatType]
        .replace("%value", valueText)
        .replace("%measurement", measurement || "");
    }

    return format[attributeFormatType]
      .replace("%value", value)
      .replace("%measurement", measurement || "");
  }

  // Trường hợp data type là array
  if (dataType === "array") {
    if (!Array.isArray(value)) return "";

    const liText = value.reduce((text, item) => {
      if (item) {
        text += `<li>${item}</li>`;
      }

      return text;
    }, "");

    return `<ul>${liText}</ul>`;
  }

  // Trường hợp data type là boolean, không có format dữ liệu
  if (dataType === "boolean") {
    return Number(value) ? "Có" : "Không";
  }

  return measurement && value ? `${value} ${measurement}` : value;
};

export const getSpecsText = (specs, linking) => {
  if (!specs) return "";

  return specs.reduce((text, attribute) => {
    try {
      if (attribute && attribute.pivot?.value) {
        const displayText = getAttributeText(attribute, "default");

        text += text.length === 0 ? displayText : `${linking}${displayText}`;
      }

      return text;
    } catch (e) {
      return text;
    }
  }, "");
};
