/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import BankAccountService from "../service/bank-account.service";
import store from "@/core/store";
import Vue from "vue";

import {
  INIT_COMPANY_BANK_ACCOUNT_VALUE,
  INIT_OBJECT_BANK_ACCOUNT_VALUE
} from "@/core/constant";
import { catchError } from "@/core/composables/useStore";

const state = {
  allBanks: [],
  companyBankAccount: JSON.parse(
    JSON.stringify(INIT_COMPANY_BANK_ACCOUNT_VALUE)
  ),
  companyBankAccounts: [],
  companyBankMethods: null,
  objectBankAccount: JSON.parse(JSON.stringify(INIT_OBJECT_BANK_ACCOUNT_VALUE)),
  objectBankAccounts: [],
  objectDefaultCompanyBankId: null,
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  allBanks: state => state.allBanks,
  companyBankAccount: state => state.companyBankAccount,
  companyBankAccounts: state => state.companyBankAccounts,
  objectBankAccount: state => state.objectBankAccount,
  objectBankAccounts: state => state.objectBankAccounts,
  objectDefaultCompanyBankId: state => state.objectDefaultCompanyBankId,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ALL_BANKS: (state, payload) => {
    state.allBanks = payload;
  },

  SET_COMPANY_BANK_ACCOUNT: (state, payload) => {
    state.companyBankAccount = payload;
  },

  SET_COMPANY_BANK_ACCOUNTS: (state, payload) => {
    state.companyBankAccounts = payload;
  },

  SET_OBJECT_BANK_ACCOUNT: (state, payload) => {
    state.objectBankAccount = payload;
  },

  SET_OBJECT_BANK_ACCOUNTS: (state, payload) => {
    state.objectBankAccounts = payload;
  },

  SET_OBJECT_DEFAULT_COMPANY_BANK_ID: (state, payload) => {
    state.objectDefaultCompanyBankId = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createCompanyBankAccount: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createCompanyBankAccount"
      });

      const response = await BankAccountService.createCompanyBankAccount(
        payload
      );

      if (response.status === 200 && response.data.data) {
        // Update companyBankAccount
        commit("SET_COMPANY_BANK_ACCOUNT", response.data.data);
        // Get companyBankAccounts
        await store.dispatch("BANK_ACCOUNT/getCompanyBankAccounts");

        commit("SET_STATUS_REQUEST", {
          value: "success-createCompanyBankAccount"
        });
      }
    } catch (e) {
      catchError(e, commit, "createCompanyBankAccount");
    }
  },

  createObjectBankAccount: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createObjectBankAccount"
      });

      const response = await BankAccountService.createObjectBankAccount(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_OBJECT_BANK_ACCOUNT", response.data.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-createObjectBankAccount"
        });
      }
    } catch (e) {
      catchError(e, commit, "createObjectBankAccount");
    }
  },

  deleteCompanyBankAccount: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-deleteCompanyBankAccount"
      });

      const response = await BankAccountService.deleteCompanyBankAccount(
        payload
      );

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", {
          value: "success-deleteCompanyBankAccount"
        });
      }
    } catch (e) {
      catchError(e, commit, "deleteCompanyBankAccount");
    }
  },

  deleteObjectBankAccount: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-deleteObjectBankAccount"
      });

      const response = await BankAccountService.deleteObjectBankAccount(
        payload.bankAccountId,
        payload.object
      );

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", {
          value: "success-deleteObjectBankAccount"
        });
      }
    } catch (e) {
      catchError(e, commit, "deleteObjectBankAccount");
    }
  },

  getAllBanks: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllBanks" });

      const response = await BankAccountService.getAllBanks();

      if (response.status === 200 && response.data.data) {
        commit("SET_ALL_BANKS", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getAllBanks"
        });
      }
    } catch (e) {
      catchError(e, commit, "getAllBanks");
    }
  },

  getCompanyBankAccounts: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCompanyBankAccounts" });

      const response = await BankAccountService.getCompanyBankAccounts();

      if (response.status === 200 && response.data.data) {
        commit("SET_COMPANY_BANK_ACCOUNTS", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getCompanyBankAccounts"
        });
      }
    } catch (e) {
      catchError(e, commit, "getCompanyBankAccounts");
    }
  },

  getObjectBankAccounts: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getObjectBankAccounts" });

      const response = await BankAccountService.getObjectBankAccounts(
        payload.id,
        payload.type
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_OBJECT_BANK_ACCOUNTS", response.data.data);
        commit(
          "SET_OBJECT_DEFAULT_COMPANY_BANK_ID",
          response.data.default_company_bank_id
        );
        commit("SET_STATUS_REQUEST", {
          value: "success-getObjectBankAccounts"
        });
      }
    } catch (e) {
      catchError(e, commit, "getObjectBankAccounts");
    }
  },

  resetCompanyBankAccount: ({ commit }) => {
    commit(
      "SET_COMPANY_BANK_ACCOUNT",
      JSON.parse(JSON.stringify(INIT_COMPANY_BANK_ACCOUNT_VALUE))
    );
  },

  resetObjectBankAccount: ({ commit }) => {
    commit(
      "SET_OBJECT_BANK_ACCOUNT",
      JSON.parse(JSON.stringify(INIT_OBJECT_BANK_ACCOUNT_VALUE))
    );
  },

  setCompanyBankAccount: ({ commit }, payload) => {
    commit("SET_COMPANY_BANK_ACCOUNT", payload);
  },

  setDefaultThinkBankAccountForObject: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-setDefaultThinkBankAccountForObject"
      });

      const response = await BankAccountService.setDefaultThinkBankAccountForObject(
        payload.bankAccountId,
        payload.object
      );

      if (response.status === 200 && response.data.data) {
        await store.dispatch("BANK_ACCOUNT/getObjectBankAccounts", {
          id: payload.object.id,
          type: payload.object.type
        });

        commit("SET_STATUS_REQUEST", {
          value: "success-setDefaultThinkBankAccountForObject"
        });
      }
    } catch (e) {
      catchError(e, commit, "setDefaultThinkBankAccountForObject");
    }
  },

  updateCompanyBankAccount: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updateCompanyBankAccount"
      });

      const response = await BankAccountService.updateCompanyBankAccount(
        payload.companyBankAccount
      );

      if (response.status === 200) {
        // Update companyBankAccounts
        Vue.set(
          state.companyBankAccounts,
          payload.companyBankAccountIndex,
          response.data.data
        );
        // Get companyBankAccounts
        commit("SET_STATUS_REQUEST", {
          value: "success-updateCompanyBankAccount"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateCompanyBankAccount");
    }
  },

  updateObjectBankAccount: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updateObjectBankAccount"
      });

      const response = await BankAccountService.updateObjectBankAccount(
        payload
      );

      if (response.status === 200) {
        // Get companyBankAccounts
        commit("SET_STATUS_REQUEST", {
          value: "success-updateObjectBankAccount"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateObjectBankAccount");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
