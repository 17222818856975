export const INIT_VAT_REQUEST_VALUE = {
  company_name: null,
  company_address: null,
  tax_code: null,
  email: null,
  requester_name: null,
  vat_requestable_id: null,
  vat_requestable_type: null,
  note: null
};
