/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import BrandService from "../service/brand.service";
import MediaService from "@/core/service/media.service";
import { arrayToTree } from "performant-array-to-tree";
import { removeEmptyChildrenProperty } from "@/core/utils";
import router from "@/core/router";
import store from "@/core/store";
import Vue from "vue";
import { INIT_SITE_CONFIG_SEO_DATA } from "@/core/constant";

const state = {
  allBrands: [],
  brandsFlat: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  brand: {
    thumbnail: null,
    cover: null,
    icon: null,
    name: null,
    priority: null,
    description: "",
    seo_data: JSON.parse(JSON.stringify(INIT_SITE_CONFIG_SEO_DATA)),
    sub_brands: [],
    cate_ids: []
  },
  brands: [],
  statusRequest: {
    message: null,
    value: null
  },
  subBrand: {
    cover: null,
    icon: null,
    name: null,
    priority: null,
    description: null,
    seo_data: {
      title: null,
      description: null,
      tags: []
    },
    cate_ids: []
  },
  brandTrees: [],
  uploadedCover: null,
  uploadedIcon: null
};

const getters = {
  allBrands: state => state.allBrands,
  brandsFlat: state => state.brandsFlat,
  brandTrees: state => state.brandTrees,
  paginationInfo: state => state.paginationInfo,
  brand: state => state.brand,
  brands: state => state.brands,
  statusRequest: state => state.statusRequest,
  subBrand: state => state.subBrand,
  uploadedCover: state => state.uploadedCover,
  uploadedIcon: state => state.uploadedIcon
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ALL_BRANDS: (state, payload) => {
    state.allBrands = payload;
  },

  SET_BRANDS_FLAT: (state, payload) => {
    state.brandsFlat = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_BRAND: (state, payload) => {
    state.brand = payload;
  },
  SET_BRAND_TREES: (state, payload) => {
    state.brandTrees = payload;
  },

  SET_BRANDS: (state, payload) => {
    state.brands = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_SUB_BRAND: (state, payload) => {
    state.subBrand = payload;
  },

  SET_UPLOADED_COVER: (state, payload) => {
    state.uploadedCover = payload;
  },

  SET_UPLOADED_ICON: (state, payload) => {
    state.uploadedIcon = payload;
  }
};

const actions = {
  createBrand: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createBrand" });

      const response = await BrandService.createBrand(payload.data);

      if (response.status === 200 && response.data) {
        // Get Brands
        await store.dispatch("BRAND/getBrands", payload.routeQuery);
        // Set id
        Vue.set(state.brand, "id", response.data);
        // Save product types
        if (payload.data.cate_ids.length > 0) {
          await BrandService.addProductTypes({
            id: response.data,
            cate_ids: payload.data.cate_ids,
            type: 1
          });
        }

        commit("SET_STATUS_REQUEST", { value: "success-createBrand" });
        commit("SET_UPLOADED_COVER", null);
        commit("SET_UPLOADED_ICON", null);
      }
    } catch (e) {
      console.log({ e });
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createBrand"
        });
      }
    }
  },

  createSubBrand: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createSubBrand" });

      const response = await BrandService.createSubBrand(payload);

      if (response.status === 200 && response.data) {
        // Set id
        Vue.set(state.subBrand, "id", response.data);
        // Save product types
        if (payload?.cate_ids?.length > 0) {
          await BrandService.addProductTypes({
            id: response.data,
            cate_ids: payload.cate_ids,
            type: 2
          });
        }
        commit("SET_STATUS_REQUEST", { value: "success-createSubBrand" });
      }
    } catch (e) {
      console.log({ e });
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createSubBrand"
        });
      }
    }
  },

  deleteBrands: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteBrands" });

      const response = await BrandService.deleteBrands(payload.data);

      if (response.status === 200) {
        // Get Brands
        await store.dispatch("BRAND/getBrands", payload.routeQuery);

        commit("SET_STATUS_REQUEST", { value: "success-deleteBrands" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-deleteBrands"
        });
      }
    }
  },

  deleteSubBrand: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteSubBrand" });

      const response = await BrandService.deleteSubBrand(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-deleteSubBrand" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-deleteSubBrand"
        });
      }
    }
  },

  getAllBrands: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllBrands" });

      const response = await BrandService.getAllBrands(payload);

      if (response.status === 200 && response.data) {
        commit("SET_ALL_BRANDS", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAllBrands" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },
  sortBrands: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-sortBrands" });

      const response = await BrandService.sortBrands(payload);

      if (response.status === 200 && response.data) {
        await store.dispatch("BRAND/getAllBrands");
        commit("SET_STATUS_REQUEST", { value: "success-sortBrands" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },
  getTreeBrands: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllBrands" });

      const response = await BrandService.getAllBrands(payload);

      if (response.status === 200 && response.data) {
        const brandTrees = arrayToTree(response.data, {
          dataField: null,
          parentId: "parent_id"
        });
        removeEmptyChildrenProperty(brandTrees);

        commit("SET_BRAND_TREES", brandTrees);
        commit("SET_STATUS_REQUEST", { value: "success-getAllBrands" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getBrandById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getBrandById" });

      const response = await BrandService.getBrandById(payload);

      if (response.status === 200 && response.data) {
        commit("SET_BRAND", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-getBrandById" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getBrands: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getBrands" });

      const response = await BrandService.getBrands(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_BRANDS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getBrands" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getBrandsFlat: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getBrandsFlat" });

      const response = await BrandService.getBrandsFlat();

      if (response.status === 200 && response.data.data) {
        commit("SET_BRANDS_FLAT", response.data.data);

        commit("SET_STATUS_REQUEST", { value: "success-getBrands" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getSubBrandById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSubBrandById" });

      const response = await BrandService.getSubBrandById(payload);

      if (response.status === 200 && response.data) {
        commit("SET_SUB_BRAND", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-getSubBrandById" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  resetBrand: ({ commit }) => {
    commit("SET_BRAND", {
      cover: null,
      icon: null,
      name: null,
      priority: null,
      description: null,
      seo_data: JSON.parse(JSON.stringify(INIT_SITE_CONFIG_SEO_DATA)),
      sub_brands: [],
      cate_ids: []
    });
  },

  resetSubBrand: ({ commit }) => {
    commit("SET_SUB_BRAND", {
      cover: null,
      icon: null,
      name: null,
      priority: null,
      description: null,
      seo_data: JSON.parse(JSON.stringify(INIT_SITE_CONFIG_SEO_DATA)),
      cate_ids: []
    });
  },

  setSubBrand: ({ commit }, payload) => {
    commit("SET_SUB_BRAND", payload);
  },

  updateBrand: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateBrand" });

      const response = await BrandService.updateBrand(payload.data);

      console.log("response", response);

      if (response.status === 200 && response.data) {
        // Get Brands
        await store.dispatch("BRAND/getBrands", payload.routeQuery);

        commit("SET_STATUS_REQUEST", { value: "success-updateBrand" });
      }
    } catch (e) {
      if (!e.response) return;

      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateBrand"
        });
      }
    }
  },

  updateSubBrand: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateSubBrand" });

      const response = await BrandService.updateSubBrand(payload);

      if (response.status === 200 && response.data) {
        // Update product types
        if (payload.cate_ids.length > 0) {
          await BrandService.addProductTypes({
            id: response.data,
            cate_ids: payload.cate_ids,
            type: 2
          });
        }
        commit("SET_STATUS_REQUEST", { value: "success-updateSubBrand" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateSubBrand"
        });
      }
    }
  },

  uploadCover: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadCover" });

      // Append folder
      payload.append("folder", "core/brands");

      const response = await MediaService.uploadImage(payload);

      if (response.status === 200 && response.data) {
        await commit("SET_UPLOADED_COVER", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadCover" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-uploadCover"
        });
      }
    }
  },

  uploadIcon: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadIcon" });

      // Append folder
      payload.append("folder", "core/brands");

      const response = await MediaService.uploadImage(payload);

      if (response.status === 200 && response.data) {
        await commit("SET_UPLOADED_ICON", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadIcon" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-uploadIcon"
        });
      }
    }
  },

  handleLoadingBrand: ({ commit }) => {
    commit("SET_STATUS_REQUEST", { value: "loading-createBrand" });
    commit("SET_STATUS_REQUEST", { value: "loading-updateBrand" });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
