export const LIST_VIETNAM_BANKS = [
  {
    name: "Ngân hàng TMCP Á Châu",
    abbreviation: "ABC"
  },
  {
    name: "Ngân hàng TMCP Tiên Phong",
    abbreviation: "TPBank"
  },
  {
    name: "Ngân hàng TMCP Đông Á",
    abbreviation: "Đông Á Bank, DAB"
  },
  {
    name: "Ngân hàng TMCP Đông Nam Á",
    abbreviation: "SeABank"
  },
  {
    name: "Ngân hàng TMCP An Bình",
    abbreviation: "ABBANK"
  },
  {
    name: "Ngân hàng TMCP Bắc Á",
    abbreviation: "BacABank"
  },
  {
    name: "Ngân hàng TMCP Bản Việt",
    abbreviation: "VietCapitalBank"
  },
  {
    name: "Ngân hàng TMCP Hàng hải Việt Nam",
    abbreviation: "MSB"
  },
  {
    name: "Ngân hàng TMCP Kỹ Thương Việt Nam",
    abbreviation: "Techcombank, TCB"
  },
  {
    name: "Ngân hàng TMCP Kiên Long",
    abbreviation: "KienLongBank"
  },
  {
    name: "Ngân hàng TMCP Nam Á",
    abbreviation: "Nam A Bank"
  },
  {
    name: "Ngân hàng TMCP Quốc Dân",
    abbreviation: "National Citizen Bank, NCB"
  },
  {
    name: "Ngân hàng TMCP Việt Nam Thịnh Vượng",
    abbreviation: "VPBank"
  },
  {
    name: "Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh",
    abbreviation: "HDBank"
  },
  {
    name: "Ngân hàng TMCP Phương Đông",
    abbreviation: "Orient Commercial Bank, OCB"
  },
  {
    name: "Ngân hàng TMCP Quân đội",
    abbreviation: "Military Bank, MB"
  },
  {
    name: "Ngân hàng TMCP Đại chúng",
    abbreviation: "PVcombank"
  },
  {
    name: "Ngân hàng TMCP Quốc tế Việt Nam",
    abbreviation: "VIBBank, VIB"
  },
  {
    name: "Ngân hàng TMCP Sài Gòn",
    abbreviation: "Sài Gòn, SCB"
  },
  {
    name: "Ngân hàng TMCP Sài Gòn Công Thương",
    abbreviation: "Saigonbank, SGB"
  },
  {
    name: "Ngân hàng TMCP Sài Gòn – Hà Nội",
    abbreviation: "SHBank, SHB"
  },
  {
    name: "Ngân hàng TMCP Sài Gòn Thương Tín",
    abbreviation: "Sacombank, STB"
  },
  {
    name: "Ngân hàng TMCP Việt Á",
    abbreviation: "VietABank, VAB"
  },
  {
    name: "Ngân hàng TMCP Bảo Việt",
    abbreviation: "BaoVietBank, BVB"
  },
  {
    name: "Ngân hàng TMCP Việt Nam Thương Tín",
    abbreviation: "VietBank"
  },
  {
    name: "Ngân Hàng TMCP Xăng Dầu Petrolimex",
    abbreviation: "Petrolimex Group Bank, PG Bank"
  },
  {
    name: "Ngân Hàng TMCP Xuất Nhập khẩu Việt Nam",
    abbreviation: "Eximbank, EIB"
  },
  {
    name: "Ngân Hàng TMCP Bưu điện Liên Việt",
    abbreviation: "LienVietPostBank, LPB"
  },
  {
    name: "Ngân Hàng TMCP Ngoại thương Việt Nam",
    abbreviation: "Vietcombank, VCB"
  },
  {
    name: "Ngân Hàng TMCP Công Thương Việt Nam",
    abbreviation: "VietinBank, CTG"
  },
  {
    name: "Ngân Hàng TMCP Đầu tư và Phát triển Việt Nam",
    abbreviation: "BIDV"
  },
  {
    name: "Ngân hàng Thương mại TNHH MTV Xây dựng Việt Nam",
    abbreviation: "CB"
  },
  {
    name: "Ngân hàng Thương mại TNHH MTV Đại Dương",
    abbreviation: "Oceanbank"
  },
  {
    name: "Ngân hàng Thương mại TNHH MTV Dầu Khí Toàn Cầu",
    abbreviation: "GPBank"
  },
  {
    name: "Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam",
    abbreviation: "Agribank"
  },
  {
    name: "Ngân hàng TNHH MTV Shinhan Việt Nam",
    abbreviation: "Shinhan Bank"
  },
  {
    name: "Ngân hàng TNHH MTV Standard Chartered (Việt Nam)",
    abbreviation: "Standard Chartered"
  },
  {
    name: "Ngân hàng TNHH MTV HSBC (Việt Nam)",
    abbreviation: "HSBC"
  },
  {
    name: "Ngân hàng Citibank Việt Nam",
    abbreviation: "Citibank"
  },
  {
    name: "Ngân hàng TNHH Indovina",
    abbreviation: "IVB"
  },
  {
    name: "Ngân hàng Liên doanh Việt – Nga",
    abbreviation: "VRB"
  }
];

export const LIST_PAYMENT_KIND = {
  CHUYEN_QUY_VE: {
    id: 1,
    text: "Chuyển quỹ về"
  },
  COD: {
    id: 2,
    text: "COD"
  },
  TRA_GOP: {
    id: 3,
    text: "Trả góp"
  },
  QUET_THE: {
    id: 4,
    text: "Quẹt thẻ"
  },
  CHUYEN_KHOAN: {
    id: 5,
    text: "Chuyển khoản"
  },
  CHI_HOAN_COC: {
    id: 6,
    text: "Chi hoàn cọc"
  },
  CHI_TRA_CONG_NO: {
    id: 7,
    text: "Chi trả công nợ"
  },
  CHI_TIEN_HOA_DON: {
    id: 8,
    text: "Chi tiền hóa đơn"
  },
  THANH_TOAN_LUONG: {
    id: 9,
    text: "Thanh toán lương"
  },
  TIEN_MAT: {
    id: 10,
    text: "Tiền mặt"
  },
  CONG_THANH_TOAN: {
    id: 11,
    text: "Cổng thanh toán"
  }
};

export const LIST_PAYMENT_STATUS = {
  CANCEL: {
    id: 3,
    text: "Đã hủy"
  },
  PENDING: {
    id: 1,
    text: "Đang xử lý"
  },
  SUCCESS: {
    id: 2,
    text: "Thành công"
  }
};

export const LIST_PAYMENT_TYPE = {
  THU: {
    id: 1,
    text: "Thu"
  },
  THU_HO: {
    id: 2,
    text: "Thu hộ"
  },
  CHI: {
    id: 3,
    text: "Chi"
  }
};

export const PAYMENT_KIND_KEY = {
  [LIST_PAYMENT_KIND.TIEN_MAT.id]: "CASH",
  [LIST_PAYMENT_KIND.CHUYEN_KHOAN.id]: "BANK",
  [LIST_PAYMENT_KIND.QUET_THE.id]: "CREDIT",
  [LIST_PAYMENT_KIND.COD.id]: "COD",
  [LIST_PAYMENT_KIND.TRA_GOP.id]: "INSTALLMENT"
};

export const PAYMENT_DEFAULT_VALUE = {
  CASH: {
    receiver_bank_id: null,
    receiverable_id: null,
    receiverable_type: "App\\Models\\Branch",
    kind: LIST_PAYMENT_KIND.TIEN_MAT.id,
    amount: null
  },
  BANK: {
    receiver_bank_id: null,
    receiverable_id: -1,
    receiverable_type: null,
    kind: LIST_PAYMENT_KIND.CHUYEN_KHOAN.id,
    amount: null
  },
  CREDIT: {
    receiver_bank_id: null,
    receiverable_id: null,
    receiverable_type: "App\\Models\\Supplier",
    kind: LIST_PAYMENT_KIND.QUET_THE.id,
    amount: null
  },
  COD: {
    receiver_bank_id: null,
    receiverable_id: null,
    receiverable_type: "App\\Models\\Supplier",
    kind: LIST_PAYMENT_KIND.COD.id,
    amount: null
  },
  INSTALLMENT: {
    receiver_bank_id: null,
    receiverable_id: null,
    receiverable_type: "App\\Models\\Supplier",
    kind: LIST_PAYMENT_KIND.TRA_GOP.id,
    amount: null
  }
};
