/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import TagService from "../service/tag.service";
import router from "@/core/router";
import store from "@/core/store";

const state = {
  allTags: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  },
  tag: {
    name: null
  }
};

const getters = {
  allTags: state => state.allTags,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest,
  tag: state => state.tag
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ALL_TAGS: (state, payload) => {
    state.allTags = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_TAG: (state, payload) => {
    state.tag = payload;
  }
};

const actions = {
  createTag: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createTag" });

      const response = await TagService.createTag(payload);

      if (response.status === 200 && response.data) {
        // Get tags
        await store.dispatch("TAG/getAllTags", payload);
        // Set tag
        commit("SET_TAG", {
          id: response.data,
          name: payload.name
        });
        commit("SET_STATUS_REQUEST", { value: "success-createTag" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createTag"
        });
      }
    }
  },

  getAllTags: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllTags" });

      const response = await TagService.getAllTags();

      if (response.status === 200 && response.data.data) {
        commit("SET_ALL_TAGS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAllTags" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getAllTags"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
