export const ORDER_PAYMENT_STATUS = {
  UNPAID: {
    text: "Chưa thanh toán",
    value: "UNPAID",
    textColor: "red--text text--darken-2"
  },
  CANCEL: {
    text: "Đã hủy",
    value: "CANCEL",
    textColor: "grey--text"
  },
  SUCCESS: {
    text: "Đã thanh toán",
    value: "SUCCESS",
    textColor: "green--text text--darken-2"
  },
  "1_PART": {
    text: "Thanh toán một phần",
    value: "1_PART",
    textColor: "yellow--text text--darken-2"
  }
};

export const LIST_ORDER_PAYMENT_STATUS = Object.values(ORDER_PAYMENT_STATUS);

export const getPaymentStatus = value => {
  const status = LIST_ORDER_PAYMENT_STATUS.find(item => item.value === value);
  return status ? status : null;
};
