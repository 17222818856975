/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import CommentService from "../service/website-comment.service";
import router from "@/core/router";

const state = {
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  },
  websiteComments: []
};

const getters = {
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest,
  websiteComments: state => state.websiteComments
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_WEBSITE_COMMENTS: (state, payload) => {
    state.websiteComments = payload;
  }
};

const actions = {
  getWebsiteComments: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getWebsiteComments" });

      const response = await CommentService.getWebsiteComments(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_WEBSITE_COMMENTS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });

        commit("SET_STATUS_REQUEST", { value: "success-getWebsiteComments" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        await router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getWebsiteComments"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
