export const countReturnedQuantityOfGift = (order, optionId, giftId) => {
  let count = 0;

  order.handles.forEach(orderProcessing => {
    if (orderProcessing.status === 6 && !orderProcessing.cancel_status) {
      // Find option
      const option = orderProcessing.details.find(
        option => option.option_id === optionId
      );
      const gift =
        option && option.gifts.find(gift => gift.option_id === giftId);

      if (gift && gift.serials) count += gift.serials.length;
    }
  });

  return count;
};

export const countReturnedQuantityOfOption = (order, optionId) => {
  let count = 0;

  order.handles.forEach(orderProcessing => {
    if (orderProcessing.status === 6 && !orderProcessing.cancel_status) {
      // Find option
      const option = orderProcessing.details.find(
        option => option.option_id === optionId
      );

      if (option && option.serials) count += option.serials.length;
    }
  });

  return count;
};
