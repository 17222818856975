import { showModalAlertError } from "@/core/composables/useAlert";

export const exportExcelFile = async (
  vm,
  {
    storeName,
    actionStoreName = "exportReportFile",
    payload,
    fileName,
    isCheckExportTime = false
  }
) => {
  let fileNameTime = new Date().toLocaleDateString();

  if (isCheckExportTime) {
    if (!payload.fromDate || !payload.toDate) {
      showModalAlertError(vm, {
        title: "Áp dụng lọc trong khoảng thời gian cụ thể để xuất báo cáo",
        message:
          "Bạn đang áp dụng lọc toàn thời gian hoặc tùy chỉnh lọc trong khoảng thời gian không hợp lệ. " +
          "Hệ thống có thể bị quá tải hoặc gặp lỗi nếu xuất báo cáo trong khoảng thời gian quá dài. " +
          "Nếu bạn cần xuất một lượng lớn dữ liệu, vui lòng liên hệ với bộ phận kỹ thuật để được hỗ trợ"
      });

      return;
    }

    fileNameTime = `-${payload.fromDate}-${payload.toDate}`;
  }

  const data = await vm.$store.dispatch(
    `${storeName}/${actionStoreName}`,
    payload
  );

  const statusRequest = vm.$store.getters[`${storeName}/statusRequest`];

  if (data && statusRequest.value === `success-${actionStoreName}`) {
    const url = URL.createObjectURL(
      new Blob([data], {
        type: "application/vnd.ms-excel"
      })
    );

    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `${fileName}${fileNameTime}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (statusRequest.value === "error-exportReportFile") {
    showModalAlertError(vm, {
      title: "Lỗi xuất báo cáo",
      message: statusRequest.message
    });
  }
};
