const AccountRouter = {
  path: "/account-setting",
  redirect: { name: "account_account-setting" },
  component: () => import("@/modules/Account"),
  children: [
    {
      path: "",
      name: "account_account-setting",
      meta: {
        private: true,
        layout: "general",
        title: "Thiết lập tài khoản | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Thiết lập tài khoản",
            to: { name: "account_account-setting" }
          }
        ]
      },
      component: () => import("../pages/AccountSetting")
    }
  ]
};

export default AccountRouter;
