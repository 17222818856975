import Api from "@/core/apis";

export default {
  getPageSectionBuildById(id) {
    return Api().get(`page-section-builds/${id}`);
  },

  updatePageSectionBuild(data) {
    return Api().put("page-section-builds", data);
  }
};
