/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import ThirdStoreService from "../service/third-store.service";
import router from "@/core/router";
import store from "@/core/store";
import { catchError } from "@/core/composables/useStore";

// import Vue from "vue";

const state = {
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  },
  thirdStore: {
    option_id: null,
    supplier_id: null,
    stock_status: null,
    description: null,
    waiting_time_for_import_goods: null
  },
  thirdStores: []
};

const getters = {
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest,
  thirdStore: state => state.thirdStore,
  thirdStores: state => state.thirdStores
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_THIRD_STORE: (state, payload) => {
    state.thirdStore = payload;
  },

  SET_THIRD_STORES: (state, payload) => {
    state.thirdStores = payload;
  }
};

const actions = {
  createThirdStore: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createThirdStore" });

      const response = await ThirdStoreService.createThirdStore(payload.data);

      if (response.status === 201 && response.data.data) {
        commit("SET_THIRD_STORE", response.data.data);
        // Get products
        await store.dispatch("THIRD_STORE/getThirdStores", payload.routeQuery);

        await commit("SET_STATUS_REQUEST", {
          value: "success-createThirdStore"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createThirdStore"
        });
      }
    }
  },

  deleteThirdStores: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteThirdStores" });

      const response = await ThirdStoreService.deleteThirdStores(payload.data);

      if (response.status === 200) {
        // Get products
        await store.dispatch("THIRD_STORE/getThirdStores", payload.routeQuery);
        commit("SET_STATUS_REQUEST", { value: "success-deleteThirdStores" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-deleteThirdStores"
        });
      }
    }
  },

  exportReportFile: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await ThirdStoreService.exportExcel();

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (e) {
      catchError(e, commit, "exportReportFile", { isRedirected: false });
    }
  },

  getThirdStores: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getThirdStores" });

      const response = await ThirdStoreService.getThirdStores(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_THIRD_STORES", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getThirdStores" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  importExcel: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-importExcel" });

      const response = await ThirdStoreService.importExcel(payload.formData);

      if (response.status === 200 && response.data) {
        // Get products
        await store.dispatch("THIRD_STORE/getThirdStores", payload.routeQuery);
        commit("SET_STATUS_REQUEST", { value: "success-importExcel" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-importExcel"
        });
      }
    }
  },

  resetThirdStore: ({ commit }) => {
    console.log("reset");
    commit("SET_THIRD_STORE", {
      option_id: null,
      supplier_id: null,
      stock_status: null,
      price: null,
      promotion: null,
      description: null
    });
  },

  setThirdStore: ({ commit }, payload) => {
    commit("SET_THIRD_STORE", payload);
  },

  updateThirdStore: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateThirdStore" });

      const response = await ThirdStoreService.updateThirdStore(payload.data);

      if (response.status === 200) {
        // Get products
        await store.dispatch("THIRD_STORE/getThirdStores", payload.routeQuery);
        commit("SET_STATUS_REQUEST", { value: "success-updateThirdStore" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateThirdStore"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
