import Vue from "vue";
import { LIST_ROLES } from "@/core/constant";
import moment from "moment/moment";

Vue.mixin({
  filters: {
    formatCurrency(value, unit) {
      if (unit) {
        return value.toLocaleString("it-IT", {
          style: "currency",
          currency: unit
        });
      }
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    },

    formatTime(date, format = "DD/MM/yyyy") {
      return moment(date).format(format);
    }
  },
  computed: {
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    isSalePermission() {
      if (this.user) {
        const role = this.user.role.toLowerCase();

        if (
          role === LIST_ROLES.sale.name ||
          role === LIST_ROLES.storeManager.name ||
          role === LIST_ROLES.content.name ||
          role === LIST_ROLES.productAssistant.name
        ) {
          return true;
        }
      }

      return false;
    },
    isMarketingPermission() {
      if (this.user) {
        const role = this.user.role.toLowerCase();

        if (role === LIST_ROLES.marketing.name) {
          return true;
        }
      }

      return false;
    },
    isViewedCreatedPrice() {
      if (!this.user) return false;

      const role = this.user.role.toLowerCase();

      return (
        role === LIST_ROLES.accountant.name ||
        role === LIST_ROLES.inventoryManager.name ||
        role === "admin"
      );
    }
  },
  methods: {
    copyToClipboard(str, elementId) {
      if (elementId) {
        const el = document.createElement("input");

        el.value = str;
        document.getElementById(elementId).appendChild(el);
        el.select();
        document.execCommand("copy");
        document.getElementById(elementId).removeChild(el);

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã sao chép vào bộ nhớ tạm"
          }
        });
      } else {
        navigator.clipboard.writeText(str);

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã sao chép vào bộ nhớ tạm"
          }
        });
      }
    }
  }
});
