import router from "@/core/router";
// import store from "@/core/store";
import PurchaseOrderService from "../../Transaction/service/purchase-order.service";
import GoodsReceiptService from "../../Transaction/service/goods-receipt.service";
import InternalRepairNoteService from "../service/interal-repair-note.service";
import { ModelName } from "../constants/constants";
import {
  generateActions,
  generateMutations,
  generateState,
  generateGetter
} from "../utils/store.utils";

const state = {
  ...generateState(),
  purchaseOrder: [],
  goodsReceipts: []
};

const getters = {
  ...generateGetter(),
  purchaseOrder: state => state.purchaseOrder,
  goodsReceipts: state => state.goodsReceipts
};

const mutations = {
  ...generateMutations(),

  SET_GOODS_RECEIPTS: (state, payload) => {
    state.goodsReceipts = payload;
  },
  SET_PURCHASE_ORDERS: (state, payload) => {
    state.purchaseOrder = payload;
  }
};

const actions = {
  ...generateActions(ModelName.repair, "INTERNAL_REPAIR_NOTE"),

  getGoodsReceipts: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getGoodsReceipts" });

      const response = await GoodsReceiptService.getGoodsReceipts({
        type: "all"
      });

      if (response.status === 200 && response.data) {
        commit("SET_GOODS_RECEIPTS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getGoodsReceipts" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getGoodsReceipts"
        });
      }
    }
  },
  getPurchaseOrders: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPurchaseOrders" });

      const response = await PurchaseOrderService.getPurchaseOrders({
        type: "all"
      });

      if (response.status === 200 && response.data) {
        commit("SET_PURCHASE_ORDERS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getPurchaseOrders" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getPurchaseOrders"
        });
      }
    }
  },

  exportReportFile: async ({ commit }, data) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await InternalRepairNoteService.exportExcel(data);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });
        return response.data;
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-exportReportFile"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
