export const PRODUCT_OPTION_TABS_INDEX = {
  GENERAL: 0,
  DETAIL: 1,
  PREORDER: 2
};

export const PRODUCT_OPTION_TABS = [
  { id: "general", text: "Chung" },
  { id: "detail", text: "Cấu hình chi tiết" },
  { id: "preOrder", text: "Pre-order" }
];
