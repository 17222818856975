/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import MediaService from "@/core/service/media.service";
import OrderProcessingService from "../service/order-processing.service";
import { catchError } from "@/core/composables/useStore";
import { INIT_SHIPPING_INFO_VALUE } from "@/modules/Transaction/constant";

const state = {
  shippingInfo: JSON.parse(JSON.stringify(INIT_SHIPPING_INFO_VALUE)),
  shippingPartners: [],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedImages: null
};

const getters = {
  order: state => state.order,
  shippingInfo: state => state.shippingInfo,
  shippingPartners: state => state.shippingPartners,
  statusRequest: state => state.statusRequest,
  uploadedImages: state => state.uploadedImages
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_SHIPPING_INFO: (state, payload) => {
    state.shippingInfo = payload;
  },

  SET_SHIPPING_PARTNERS: (state, payload) => {
    state.shippingPartners = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_IMAGES: (state, payload) => {
    state.uploadedImages = payload;
  }
};

const actions = {
  cancelShipping: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-cancelShipping" });

      const response = await OrderProcessingService.cancelShipping(payload);

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", { value: "success-cancelShipping" });
      }
    } catch (e) {
      catchError(e, commit, "cancelShipping");
    }
  },

  getShippingInfo: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getShippingInfo" });

      const response = await OrderProcessingService.getShippingInfo(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_SHIPPING_INFO", {
          ...response.data.data,
          handle_id: payload,
          self_shipping: response.data.data.self_shipping || 0
        });
        commit("SET_STATUS_REQUEST", { value: "success-getShippingInfo" });
      }
    } catch (e) {
      catchError(e, commit, "getShippingInfo");
    }
  },

  getShippingPartners: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getShippingPartners" });

      const response = await OrderProcessingService.getShippingPartners(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_SHIPPING_PARTNERS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getShippingPartners" });
      }
    } catch (e) {
      catchError(e, commit, "getShippingPartners");
    }
  },

  sendToShippingPartner: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-sendToShippingPartner" });

      const response = await OrderProcessingService.sendToShippingPartner(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-sendToShippingPartner"
        });
        return response;
      }
    } catch (e) {
      catchError(e, commit, "sendToShippingPartner");
    }
  },

  updateShippingInfo: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateShippingInfo" });

      const response = await OrderProcessingService.updateShippingInfo(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_STATUS_REQUEST", { value: "success-updateShippingInfo" });
      }
    } catch (e) {
      catchError(e, commit, "updateShippingInfo");
    }
  },

  updateOrderProcessingStatus: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updateOrderProcessingStatus"
      });

      const response = await OrderProcessingService.updateOrderProcessingStatus(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-updateOrderProcessingStatus"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateOrderProcessingStatus");
    }
  },

  uploadImages: async ({ commit }, formDatas) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-uploadImages"
      });

      let images = [];

      for (let i = 0; i < formDatas.length; i++) {
        try {
          const formData = formDatas[i];

          // Append folder
          formData.append("folder", "core/orders");

          const response = await MediaService.uploadImage(formData);

          if (response.status === 200 && response.data) {
            images.push(response.data);
          }
        } catch (e) {
          catchError(e, commit, "uploadImages");
        }
      }

      await commit("SET_UPLOADED_IMAGES", images);

      commit("SET_STATUS_REQUEST", {
        value: "success-uploadImages"
      });
    } catch (e) {
      catchError(e, commit, "uploadImages");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
