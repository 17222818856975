import Api from "@/core/apis";

export default {
  createBranch(data) {
    return Api().post("branch/add", data);
  },

  deleteBranch(id) {
    return Api().get(`branch/delete/${id}`);
  },

  getAllBranches() {
    return Api().get("branch/getAll");
  },

  getBranches(data) {
    return Api().post("branch/all", data);
  },

  updateBranch(data) {
    return Api().post("branch/edit", data);
  }
};
