/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import InventoryReportService from "../service/inventory-reports.service";
import { catchError } from "@/core/composables/useStore";

const state = {
  inventoryReports: [],
  inventoryReport: [],
  inventoryReportGeneral: [],
  inventoryReportsSummary: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  inventoryReports: state => state.inventoryReports,
  inventoryReport: state => state.inventoryReport,
  inventoryReportGeneral: state => state.inventoryReportGeneral,
  inventoryReportsSummary: state => state.inventoryReportsSummary,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_INVENTORY_REPORTS: (state, payload) => {
    state.inventoryReports = payload;
  },

  SET_INVENTORY_REPORT: (state, payload) => {
    state.inventoryReport = payload;
  },

  SET_INVENTORY_REPORT_GENERAL: (state, payload) => {
    state.inventoryReportGeneral = payload;
  },

  SET_INVENTORY_REPORTS_SUMMARY: (state, payload) => {
    state.inventoryReportsSummary = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  getInventoryReports: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getInventoryReports" });

      const response = await InventoryReportService.getInventoryReports(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_INVENTORY_REPORTS", response.data.data);
        commit("SET_INVENTORY_REPORTS_SUMMARY", response.data.summary);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getInventoryReports" });
      }
    } catch (e) {
      catchError(e, commit, "getInventoryReports");
    }
  },

  getInventoryReportByOptionId: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getInventoryReportByOptionId"
      });

      const response = await InventoryReportService.getInventoryReportByOptionId(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_INVENTORY_REPORT", response.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getInventoryReportByOptionId"
        });
      }
    } catch (e) {
      catchError(e, commit, "getInventoryReportByOptionId");
    }
  },

  getInventoryReportGeneral: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getInventoryReportGeneral"
      });

      const response = await InventoryReportService.getInventoryReportGeneral(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_INVENTORY_REPORT_GENERAL", response.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getInventoryReportGeneral"
        });
      }
    } catch (e) {
      catchError(e, commit, "getInventoryReportGeneral");
    }
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await InventoryReportService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (e) {
      catchError(e, commit, "exportReportFile", { isRedirected: false });
    }
  },

   exportReportFileDataProduct:async({ commit }, payload)=>{
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFileDataProduct" });

      const response = await InventoryReportService.exportReportFileDataProduct(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFileDataProduct" });

        return response.data;
      }
    } catch (e) {
      catchError(e, commit, "exportReportFileDataProduct", { isRedirected: false });
    }
   },

  exportInventoryReportDetailFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-exportInventoryReportDetailFile"
      });

      const response = await InventoryReportService.exportInventoryReportDetailFile(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-exportInventoryReportDetailFile"
        });

        return response.data;
      }
    } catch (e) {
      catchError(e, commit, "exportInventoryReportDetailFile", {
        isRedirected: false
      });
    }
  },

  importUpdateFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-importUpdateFile"
      });

      const response = await InventoryReportService.importUpdateFile(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-importUpdateFile"
        });
      }
    } catch (e) {
      commit("SET_STATUS_REQUEST", {
        value: "error-importUpdateFile"
      })
      
      catchError(e, commit, "importUpdateFile", {
        isRedirected: false
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
