/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import ColorService from "../service/color.service";
import store from "@/core/store";
import { catchError } from "@/core/composables/useStore";

const state = {
  color: {
    name: null,
    code: null,
    color_group_id: null,
    description: null
  },
  colorGroups: [],
  colors: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  color: state => state.color,
  colorGroups: state => state.colorGroups,
  colors: state => state.colors,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_COLOR: (state, payload) => {
    state.color = payload;
  },

  SET_COLOR_GROUPS: (state, payload) => {
    state.colorGroups = payload;
  },

  SET_COLORS: (state, payload) => {
    state.colors = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createColor: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createColor" });

      const response = await ColorService.createColor(payload);

      if (response.status === 201 && response.data.data) {
        // Get colors
        await store.dispatch("COLOR/getColors");

        commit("SET_COLOR", response.data.data);

        commit("SET_STATUS_REQUEST", { value: "success-createColor" });
      }
    } catch (e) {
      catchError(e, commit, "createColor");
    }
  },

  getColorGroups: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getColorGroups" });

      const response = await ColorService.getColorGroups(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_COLOR_GROUPS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getColorGroups" });
      }
    } catch (e) {
      catchError(e, commit, "getColorGroups");
    }
  },

  getColors: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getColors" });

      const response = await ColorService.getColors(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_COLORS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getColors" });
      }
    } catch (e) {
      catchError(e, commit, "getColors");
    }
  },

  resetColor: ({ commit }) => {
    commit("SET_COLOR", {
      name: null,
      code: null,
      color_group_id: null,
      description: null
    });
  },

  setColor: ({ commit }, payload) => {
    commit("SET_COLOR", payload);
  },

  updateColor: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateColor" });

      const response = await ColorService.updateColor(payload);

      if (response.status === 200 && response.data.data) {
        // Get colors
        await store.dispatch("COLOR/getColors");

        commit("SET_STATUS_REQUEST", { value: "success-updateColor" });
      }
    } catch (e) {
      catchError(e, commit, "updateColor");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
