import Api from "@/core/apis";

export default {
  // not bussiness
  listOffBiz(data) {
    return Api().post("/off-bizes/list", {
      ...data
    });
  },

  addOffBiz(data) {
    return Api().post(`/off-bizes`, { ...data });
  },

  editOffBiz(data) {
    return Api().put(`/off-bizes`, { ...data });
  },
  getOffBizById(offBizId) {
    return Api().get(`/off-bizes/${offBizId}`);
  },

  getCommentsOfOffBiz(offBizId) {
    return Api().get(`/off-bizes/${offBizId}/comments`);
  },

  addComment(data) {
    return Api().post(`/off-bizes/comments`, { ...data });
  },

  updateComment(data) {
    return Api().put(`/off-bizes/comments`, { ...data });
  },

  getSerialInfoBySerialNumber(serialNumber, serial_status = 1) {
    return Api().get(`/serials/${serialNumber}`, {
      params: {
        serial_status,
        serial_area: "cms_serial_detail_for_bh_sc"
      }
    });
  }
};
