import Vue from "vue";
import Vuex from "vuex";

import AccountStore from "@/modules/Account/store";
import AddingAccessoriesStore from "@/modules/Goods/store/add-access.store";
import AuthenticationStore from "@/modules/Authentication/store";
import BankAccountStore from "./bank-account.store";
import BannerStore from "@/modules/Website/store/banner.store";
import BranchStore from "@/modules/PartnerAndMember/store/branch.store";
import BrandStore from "@/modules/Goods/store/brand.store";
import BrowsingStore from "@/modules/Transaction/store/browsing.store";
import CashTransferNoteStore from "@/modules/CashFlow/store/cash-transfer-note.store";
import CategoriesBarStore from "@/modules/Website/store/categories-bar.store";
import ColorStore from "./color.store";
import CustomerGroupStore from "@/modules/PartnerAndMember/store/customer-group.store";
import CustomerRankStore from "@/modules/PartnerAndMember/store/customer-rank.store";
import CustomerReturnStore from "@/modules/Transaction/store/customer-return.store";
import CustomerStore from "@/modules/PartnerAndMember/store/customer.store";
import CustomizeFolderStore from "@/modules/Website/store/customize-folder.store";
import DashboardFiltersStore from "@/modules/Dashboard/store/filters.store";
import DashboardStore from "@/modules/Dashboard/store/index";
import DemandStore from "./demand.store";
import EmployeeStore from "@/modules/PartnerAndMember/store/employee.store";
import EquipmentForEmployeeStore from "@/modules/NotSoldWarehouse/store/equipment-for-employee.store";
import EventStore from "@/modules/Events/store";
import GoodsDeliveryStore from "@/modules/Transaction/store/goods-delivery.store";
import GoodsDestructionStore from "@/modules/Transaction/store/goods-destruction.store";
import GoodsReceiptStore from "@/modules/Transaction/store/goods-receipt.store";
import GuaranteePackageStore from "@/modules/Guarantee/store/guarantee-package.store";
import GuaranteeVoucherCommentStore from "@/modules/Guarantee/store/guarantee-voucher-comment.store";
import GuaranteeVoucherStore from "@/modules/Guarantee/store/guarantee-voucher.store";
import HomeSettingStore from "@/modules/Website/store/home-setting.store";
import InternalGuaranteeNote from "@/modules/NotSoldWarehouse/store/internal-guarantee-note.store";
import InternalRepairNote from "@/modules/NotSoldWarehouse/store/internal-repair-note.store";
import InventoryReportsStore from "@/modules/Reports/store/inventory-reports.store";
import InventoryStore from "@/modules/Goods/store/inventory.store";
import InvoiceParticipantStore from "./invoice-participant.store";
import LocalAdministrationStore from "./local-administration.store";
import MediaLoanStore from "@/modules/NotSoldWarehouse/store/media-loan-store";
import MegaMenuStore from "@/modules/Website/store/mega-menu.store";
import ModalSerialPickerStore from "./modal-serial-picker.store";
import ModelSiteConfigStore from "./model-site-config.store";
import OrderPolicy from "@/modules/Policy/store/order-policy.store";
import OrderPolicyArea from "@/modules/Policy/store/order-policy-area.store";
import OrderProcessingStore from "@/modules/Transaction/store/order-processing.store";
import OrderStore from "@/modules/Transaction/store/order.store";
import PageSectionBuildStore from "@/modules/Website/store/page-section-build.store";
import PaymentAccountStore from "@/modules/System/store/payment-account.store.js";
import PaymentTransactionStore from "./payment-transaction.store";
import PaymenVourcherStore from "@/modules/CashFlow/store/paymen-vourcher.store";
import PostCategoryStore from "@/modules/Website/store/post-category.store";
import PostStore from "@/modules/Website/store/post.store";
import ProductGroupStore from "@/modules/Goods/store/product-group.store";
import ProductOptionStore from "@/modules/Goods/store/product-option.store";
import ProductStore from "@/modules/Goods/store/product.store";
import ProductTypeStore from "@/modules/Goods/store/product-type.store";
import PromotionStore from "@/modules/Promotion/store";
import PurchaseOrderStore from "@/modules/Transaction/store/purchase-order.store";
import RebateStore from "@/modules/Transaction/store/rebate.store";
import ReceiptVoucherStore from "@/modules/CashFlow/store/receipt-voucher.store";
import RemovingAccessoriesStore from "@/modules/Goods/store/remove-access.store";
import ReturnSerialNoteStore from "@/modules/NotSoldWarehouse/store/return-serial-note.store";
import ReturnSerialStore from "@/modules/Goods/store/return-serial.store";
import RoleStore from "@/modules/System/store/role.store";
import SaleReceiptStore from "@/modules/Transaction/store/sale-receipt.store";
import SaleStore from "@/modules/Sale/store";
import SampleSerialNoteStore from "@/modules/NotSoldWarehouse/store/sample-serial-note.store";
import SearchKeywordStore from "@/modules/Website/store/search-keyword.store";
import SerialStore from "@/modules/Goods/store/serial.store";
import SiteConfigStore from "../../modules/Website/store/site-config.store";
import SupplierReturnStore from "@/modules/Transaction/store/supplier-return.store";
import SupplierStore from "@/modules/PartnerAndMember/store/supplier.store";
import TagStore from "@/modules/Goods/store/tag.store";
import ThirdStoreStore from "@/modules/Goods/store/third-store.store";
import UtmBuilderStore from "./utm-builder.store";
import VatRequestStore from "@/modules/Transaction/store/vat-request.store";
import WaitingAssemblyNoteStore from "@/modules/NotSoldWarehouse/store/waiting-assembly-note.store";
import WaitingProcessingNoteStore from "@/modules/NotSoldWarehouse/store/waiting-processing-note.store";
import WebsiteCommentStore from "@/modules/Website/store/website-comment.store";
import FundStore from "@/modules/Fund/store/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ACCOUNT: AccountStore,
    ADD_ACCESS: AddingAccessoriesStore,
    AUTHENTICATION: AuthenticationStore,
    BANK_ACCOUNT: BankAccountStore,
    BANNER: BannerStore,
    BRANCH: BranchStore,
    BRAND: BrandStore,
    BROWSING: BrowsingStore,
    CASH_TRANSFER_NOTE: CashTransferNoteStore,
    CATEGORIES_BAR: CategoriesBarStore,
    COLOR: ColorStore,
    CUSTOMER: CustomerStore,
    CUSTOMER_GROUP: CustomerGroupStore,
    CUSTOMER_RANK: CustomerRankStore,
    CUSTOMER_RETURN: CustomerReturnStore,
    CUSTOMIZE_FOLDER: CustomizeFolderStore,
    DASHBOARD: DashboardStore,
    DASHBOARD_FILTERS: DashboardFiltersStore,
    DEMAND: DemandStore,
    EMPLOYEE: EmployeeStore,
    EQUIPMENT_FOR_EMPLOYEE: EquipmentForEmployeeStore,
    EVENT: EventStore,
    GOODS_DELIVERY: GoodsDeliveryStore,
    GOODS_DESTRUCTION: GoodsDestructionStore,
    GOODS_RECEIPT: GoodsReceiptStore,
    GUARANTEE_PACKAGE: GuaranteePackageStore,
    GUARANTEE_VOUCHER: GuaranteeVoucherStore,
    GUARANTEE_VOUCHER_COMMENT: GuaranteeVoucherCommentStore,
    HOME_SETTING: HomeSettingStore,
    INTERNAL_GUARANTEE_NOTE: InternalGuaranteeNote,
    INTERNAL_REPAIR_NOTE: InternalRepairNote,
    INVENTORY: InventoryStore,
    INVENTORY_REPORTS: InventoryReportsStore,
    INVOICE_PARTICIPANT: InvoiceParticipantStore,
    LOCAL_ADMINISTRATION: LocalAdministrationStore,
    MEDIA_LOAN: MediaLoanStore,
    MEGA_MENU: MegaMenuStore,
    MODAL_SERIAL_PICKER: ModalSerialPickerStore,
    MODEL_SITE_CONFIG: ModelSiteConfigStore,
    ORDER: OrderStore,
    ORDER_POLICY: OrderPolicy,
    ORDER_POLICY_AREA: OrderPolicyArea,
    ORDER_PROCESSING: OrderProcessingStore,
    PAGE_SECTION_BUILD: PageSectionBuildStore,
    PAYMENT_ACCOUNT: PaymentAccountStore,
    PAYMENT_TRANSACTION: PaymentTransactionStore,
    PAYMENT_VOUCHER: PaymenVourcherStore,
    POST: PostStore,
    POST_CATEGORY: PostCategoryStore,
    PRODUCT: ProductStore,
    PRODUCT_GROUP: ProductGroupStore,
    PRODUCT_OPTION: ProductOptionStore,
    PRODUCT_TYPE: ProductTypeStore,
    PROMOTION: PromotionStore,
    PURCHASE_ORDER: PurchaseOrderStore,
    REBATE: RebateStore,
    RECEIPT_VOUCHER: ReceiptVoucherStore,
    REMOVE_ACCESS: RemovingAccessoriesStore,
    RETURN_SERIAL: ReturnSerialStore,
    RETURN_SERIAL_NOTE: ReturnSerialNoteStore,
    ROLE: RoleStore,
    SALE: SaleStore,
    SALE_RECEIPT: SaleReceiptStore,
    SAMPLE_SERIAL_NOTE: SampleSerialNoteStore,
    SEARCH_KEYWORD: SearchKeywordStore,
    SERIAL: SerialStore,
    SITE_CONFIG: SiteConfigStore,
    SUPPLIER: SupplierStore,
    SUPPLIER_RETURN: SupplierReturnStore,
    TAG: TagStore,
    THIRD_STORE: ThirdStoreStore,
    UTM_BUILDER: UtmBuilderStore,
    VAT_REQUEST: VatRequestStore,
    WAITING_ASSEMBLY_NOTE: WaitingAssemblyNoteStore,
    WAITING_PROCESSING_NOTE: WaitingProcessingNoteStore,
    WEBSITE_COMMENTS: WebsiteCommentStore,
    FUND: FundStore
  }
});
