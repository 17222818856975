import moment from "moment";

export const formatDateTime = (date, formatType = "DD/MM/YYYY hh:mm") => {
  const dateTime = new Date(date),
    day = dateTime
      .getDate()
      .toString()
      .padStart(2, "0"),
    month = (dateTime.getMonth() + 1).toString().padStart(2, "0"),
    year = dateTime.getFullYear().toString(),
    hours = dateTime
      .getHours()
      .toString()
      .padStart(2, "0"),
    minutes = dateTime
      .getMinutes()
      .toString()
      .padStart(2, "0"),
    seconds = dateTime
      .getSeconds()
      .toString()
      .padStart(2, "0");

  return formatType
    .replace("DD", day)
    .replace("MM", month)
    .replace("YYYY", year)
    .replace("hh", hours)
    .replace("mm", minutes)
    .replace("ss", seconds);
};

export const getDatesBetweenTwoDates = (fromDate, toDate) => {
  const dateTime1 = new Date(fromDate),
    date1 = new Date(
      dateTime1.getFullYear(),
      dateTime1.getMonth(),
      dateTime1.getDate(),
      0,
      0,
      0
    ),
    dateTime2 = new Date(toDate),
    date2 = new Date(
      dateTime2.getFullYear(),
      dateTime2.getMonth(),
      dateTime2.getDate(),
      0,
      0,
      0
    ),
    res = Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24));

  return res;
};

export const timeLeftFromCurent = datetime => {
  const diff = moment.duration(moment(datetime).diff(moment()));
  return diff.humanize(false);
};

export const getTime = date => {
  const dateTime = new Date(date),
    hours = dateTime
      .getHours()
      .toString()
      .padStart(2, "0"),
    mins = dateTime
      .getMinutes()
      .toString()
      .padStart(2, "0");

  return `${hours}:${mins}`;
};

export const nowTime = () => {
  const date = new Date(),
    hour = date
      .getHours()
      .toString()
      .padStart(2, "0"),
    mins = date
      .getMinutes()
      .toString()
      .padStart(2, "0");

  return `${hour}:${mins}`;
};

export const setTime = (date, time) => {
  let dateTime = new Date(date);
  const [hours, mins] = time.split(":");

  dateTime.setHours(parseInt(hours));
  dateTime.setMinutes(parseInt(mins));

  return dateTime;
};

export const today = format => {
  const date = new Date(),
    dd = date
      .getDate()
      .toString()
      .padStart(2, "0"),
    mm = (date.getMonth() + 1).toString().padStart(2, "0"),
    yyyy = date.getFullYear();

  if (format === "dd-mm-yyyy") {
    return `${dd}-${mm}-${yyyy}`;
  }
  if (format === "mm-dd-yyyy") {
    return `${mm}-${dd}-${yyyy}`;
  }

  return `${yyyy}-${mm}-${dd}`;
};

/**
 * @param date1
 * @param date2
 * @description compare dates without regard to time
 * @returns {number} 1 (d1 === d2); 0 (d1 > d2); -1 (d1 < d2)
 */
export const compareTwoDates = (date1, date2) => {
  const d1 = new Date(date1).setHours(0, 0, 0, 0);
  const d2 = new Date(date2).setHours(0, 0, 0, 0);

  if (d1 === d2) return 1;

  if (d1 > d2) return 0;

  return -1;
};

/**
 * Returns the startDate and endDate of a specified date range period.
 * @param {string} period - The date range period to get. Possible values: 'currentMonth', 'lastMonth', 'currentWeek', 'lastWeek'.
 * @returns {Object} An object with startDate and endDate properties representing the start and end dates of the specified date range period.
 */
export const getDateRange = period => {
  const today = new Date();
  let startDate, endDate;

  switch (period) {
    case "currentMonth": {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      break;
    }
    case "lastMonth": {
      const lastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      );
      startDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
      endDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
      break;
    }
    case "currentWeek": {
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - ((today.getDay() + 6) % 7)
      );
      endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 6
      );
      break;
    }
    case "lastWeek": {
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - ((today.getDay() + 6) % 7) - 7
      );
      endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + 6
      );
      break;
    }
    default: {
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      break;
    }
  }

  return {
    startDate,
    endDate
  };
};

const DateTimeFunction = {
  getDatesBetweenTwoDates,
  nowTime,
  timeLeftFromCurent,
  today
};

export default DateTimeFunction;
