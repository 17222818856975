import Vue from "vue";
import App from "./App.vue";
import router from "./core/router";
import store from "./core/store";
import vuetify from "./core/plugins/vuetify/index";
import "@/core/scss/main.scss";

// Global components
import "./core/components";

// TP Vuetify custom
import "./core/plugins/tp-vuetify";
// Middlewares
import "./core/middlewares/guards";

// Plugins
import "./core/plugins";

// Helpers
import "./core/helpers";

// Global layouts
Vue.component("DefaultLayout", () =>
  import("./core/layouts/DefaultLayout.vue")
);
Vue.component("GeneralLayout", () =>
  import("./core/layouts/GeneralLayout.vue")
);
Vue.component("PrintLayout", () => import("./core/layouts/PrintLayout.vue"));
Vue.component("SaleLayout", () => import("./core/layouts/SaleLayout.vue"));

Vue.config.productionTip = false;
Vue.config.devtools = true;
new Vue({
  // @ts-ignore
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
