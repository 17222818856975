/**
 * @name v-horizontal-scroll
 * @description
 * This plugin allows you to scroll horizontally using the mouse wheel.
 * @usage  v-horizontal-scroll
 * @author TuanLA
 */

const HorizontalScroll = {
  install(Vue, options = {}) {
    Vue.directive("horizontal-scroll", {
      bind(el, binding) {
        el.addEventListener("wheel", function(event) {
          event.preventDefault();

          const wheelDelta = event.deltaY;
          const scrollAmount = binding.value || options.scrollAmount || 50;

          el.scrollBy({ left: wheelDelta < 0 ? -scrollAmount : scrollAmount });
        });
      }
    });
  }
};

export default HorizontalScroll;
