import { computed } from "vue";
import store from "@/modules/Authentication/store";

export function usePermission() {
  const user = computed(() => store.state.user);
  const curentUserRole = computed(() => user.value.role.toLowerCase());

  // Check if the user has a specific role
  const hasRole = role => {
    return curentUserRole.value.includes(role.toLowerCase());
  };

  // Check if the user has any of the specified roles
  const hasAnyRole = roles => {
    return roles.some(role =>
      curentUserRole.value.includes(role.toLowerCase())
    );
  };

  return {
    user,
    hasRole,
    hasAnyRole
  };
}
