export const ModelName = {
  repair: "App\\Models\\InternalError",
  guatantee: "App\\Models\\InternalGuarantee",
  mediaLoan: "App\\Models\\ProductBorrowed",
  waitingAssemblyNote: "App\\Models\\LaptopToBeAssembled",
  waitingProcessingNote: "App\\Models\\GearToBeAssembled",
  returnToBeImported: "App\\Models\\ReturnToBeImported",
  productExhibited: "App\\Models\\ProductExhibited",
  productForStaffs: "App\\Models\\ProductForStaffs"
};

// InternalError => Máy nội bộ lỗi
// IntermalGuarantee => Bảo hành nội bộ
// ReturnToBeImported => Máy trả chờ nhập
// GearToBeAssembled => Linh kiện chờ xử lý
// LaptopToBeAssembled => Máy chờ lắp ráp
// ProductBorrowed => Máy cho media mượn
// ProductForStaffs => Máy cho nhân viên mượn
// ProductExhibited => Máy trưng bày

export const SELECTED_ACTIONS = {
  save: 1,
  tranfer: 3,
  approval_for_sale: 2,
  cancel: 0
};

export const SELECTED_FOR_FILTER = {
  tranfer: -3,
  approval_for_sale: -2,
  cancel: -4
};

export const SERIAL_STATUS = {
  MEDIA_LOAN: -11,
  WAITING_ASSEMBLY_NOTE: -9,
  SAMPLE_SERIAL_NOTE: -13,
  WAITING_PROCESSING_NOTE: -8,
  INTERNAL_REPAIR_NOTE: -4,
  RETURN_SERIAL_NOTE: -7,
  INTERNAL_GUARANTEE_NOTE: -6,
  EQUIPMENT_FOR_EMPLOYEE: -12
};
