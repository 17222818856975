/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import CustomerService from "../service/customer.service";
import MediaService from "@/core/service/media.service";
import store from "@/core/store";
import Vue from "vue";
import {
  INIT_CUSTOMER_VALUE,
  INIT_CUSTOMER_ADDRESS_VALUE
} from "@/modules/PartnerAndMember/constant/index";
import { catchError } from "@/core/composables";

const state = {
  customer: JSON.parse(JSON.stringify(INIT_CUSTOMER_VALUE)),
  customerAddress: JSON.parse(JSON.stringify(INIT_CUSTOMER_ADDRESS_VALUE)),
  customerAddresses: [],
  customers: [],
  customerTransactionHistory: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  },
  uploadedAvatar: null
};

const getters = {
  customer: state => state.customer,
  customerAddress: state => state.customerAddress,
  customerAddresses: state => state.customerAddresses,
  customers: state => state.customers,
  customerTransactionHistory: state => state.customerTransactionHistory,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest,
  uploadedAvatar: state => state.uploadedAvatar
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_CUSTOMER: (state, payload) => {
    state.customer = payload;
  },

  SET_CUSTOMER_ADDRESS: (state, payload) => {
    state.customerAddress = payload;
  },

  SET_CUSTOMER_ADDRESSES: (state, payload) => {
    state.customerAddresses = payload;
  },

  SET_CUSTOMERS: (state, payload) => {
    state.customers = payload;
  },

  SET_CUSTOMER_TRANSACTION_HISTORY: (state, payload) => {
    state.customerTransactionHistory = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_AVATAR: (state, payload) => {
    state.uploadedAvatar = payload;
  }
};

const actions = {
  createCustomer: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createCustomer" });

      const response = await CustomerService.createCustomer(payload.data);

      if (response.status === 201 && response.data) {
        // Get Brands
        await store.dispatch("CUSTOMER/getCustomers", payload.routeQuery);
        // Set id
        Vue.set(state.customer, "id", response.data.data.id);
        Vue.set(state.customer, "code", response.data.data.code);

        commit("SET_STATUS_REQUEST", { value: "success-createCustomer" });
      }
    } catch (e) {
      catchError(e, commit, "createCustomer");
    }
  },

  createCustomerAddress: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createCustomerAddress" });

      const response = await CustomerService.createCustomerAddress(
        payload.data
      );

      if (response.status === 200 && response.data) {
        // Set customer address
        Vue.set(state.customerAddresses, payload.index, payload.data);
        // Set id
        Vue.set(state.customerAddress, "id", response.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-createCustomerAddress"
        });
      }
    } catch (e) {
      catchError(e, commit, "createCustomerAddress");
    }
  },

  deleteCustomer: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteCustomer" });

      const response = await CustomerService.deleteCustomer(payload.data);

      if (response.status === 200) {
        // Get Customers
        await store.dispatch("CUSTOMER/getCustomers", payload.routeQuery);

        commit("SET_STATUS_REQUEST", { value: "success-deleteCustomer" });
      }
    } catch (e) {
      catchError(e, commit, "deleteCustomer");
    }
  },

  deleteCustomerAddress: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteCustomerAddress" });

      const response = await CustomerService.deleteCustomerAddress(
        payload.data
      );

      if (response.status === 200) {
        state.customerAddresses.splice(payload.index, 1);
        commit("SET_STATUS_REQUEST", {
          value: "success-deleteCustomerAddress"
        });
      }
    } catch (e) {
      catchError(e, commit, "deleteCustomerAddress");
    }
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await CustomerService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportFile", { isRedirected: false });
    }
  },

  getCustomerById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCustomerById" });

      const response = await CustomerService.getCustomerById(payload);

      if (response.status === 200 && response.data.data) {
        if (response.data.data.dob) {
          response.data.data.birthType = "FULL";
        } else if (response.data.data.yob) {
          response.data.data.birthType = "YEAR";
        } else {
          response.data.data.birthType = "FULL";
        }

        commit("SET_CUSTOMER", { ...response.data.data });
        commit("SET_STATUS_REQUEST", { value: "success-getCustomerById" });
      }
    } catch (e) {
      catchError(e, commit, "getCustomerById");
    }
  },

  getCustomerAddresses: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCustomerAddresses" });

      if (payload) {
        const response = await CustomerService.getCustomerAddresses(payload);

        if (response.status === 200 && response.data.data) {
          commit("SET_CUSTOMER_ADDRESSES", response.data.data);
          commit("SET_STATUS_REQUEST", {
            value: "success-getCustomerAddresses"
          });
        }
      }
    } catch (e) {
      catchError(e, commit, "getCustomerAddresses");
    }
  },

  getCustomers: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCustomers" });

      const response = await CustomerService.getCustomers(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_CUSTOMERS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getCustomers" });
      }
    } catch (e) {
      catchError(e, commit, "getCustomers");
    }
  },

  getCustomerTransactionHistory: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getCustomerTransactionHistory"
      });

      const response = await CustomerService.getCustomerTransactionHistory(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_CUSTOMER_TRANSACTION_HISTORY", response.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getCustomerTransactionHistory"
        });
      }
    } catch (e) {
      catchError(e, commit, "getCustomerTransactionHistory");
    }
  },

  resetCustomer: ({ commit }) => {
    commit("SET_CUSTOMER", JSON.parse(JSON.stringify(INIT_CUSTOMER_VALUE)));
  },

  resetCustomerAddress: ({ commit }) => {
    commit(
      "SET_CUSTOMER_ADDRESS",
      JSON.parse(JSON.stringify(INIT_CUSTOMER_ADDRESS_VALUE))
    );
  },

  resetStatusRequest: ({ commit }) => {
    commit("SET_STATUS_REQUEST", {
      value: null
    });
  },

  setCustomerAddress: ({ commit }, payload) => {
    commit("SET_CUSTOMER_ADDRESS", payload);
  },

  updateCustomer: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateCustomer" });

      const response = await CustomerService.updateCustomer(payload.data);

      if (response.status === 200 && response.data) {
        // Get Brands
        await store.dispatch("CUSTOMER/getCustomers", payload.routeQuery);

        commit("SET_STATUS_REQUEST", { value: "success-updateCustomer" });
      }
    } catch (e) {
      catchError(e, commit, "updateCustomer");
    }
  },

  updateCustomerAddress: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateCustomerAddress" });

      const response = await CustomerService.updateCustomerAddress(
        payload.data
      );

      if (response.status === 200 && response.data) {
        // Set customer address
        Vue.set(state.customerAddresses, payload.index, payload.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-updateCustomerAddress"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateCustomerAddress");
    }
  },

  uploadAvatar: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadAvatar" });

      // Append folder
      payload.append("folder", "core/users");

      const response = await MediaService.uploadImage(payload);

      if (response.status === 200 && response.data) {
        await commit("SET_UPLOADED_AVATAR", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadAvatar" });
      }
    } catch (e) {
      catchError(e, commit, "uploadAvatar");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
