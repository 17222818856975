export const SKU_NGUON_HANG = {
  CHINH_HANG: {
    value: 5,
    label: "Chính hãng"
  },
  NHAP_KHAU: {
    value: 6,
    label: "Nhập khẩu"
  }
};

export const SKU_STATUS = {
  CO_SAN: {
    value: 1,
    label: "Có sẵn",
    color: "green",
    background: "green lighten-5"
  },
  DANG_VE: {
    value: 2,
    label: "Đang về",
    color: "orange",
    background: "amber lighten-5"
  },
  DAT_TRUOC: {
    value: 3,
    label: "Đặt trước",
    color: "blue",
    background: "blue lighten-5"
  },
  LIEN_HE: {
    value: 4,
    label: "Liên hệ",
    color: "purple",
    background: "purple lighten-5"
  },
  NGUNG_KINH_DOANH: {
    value: 5,
    label: "Có sẵn",
    color: "grey darken-3",
    background: "grey lighten-3"
  },
  TAM_HET: {
    value: 6,
    label: "Tạm hết",
    color: "red",
    background: "red lighten-5"
  }
};
