import { ModelName, StoreName, ORDERS_TYPE } from "../constant";

import {
  generateActions,
  generateMutations,
  generateState,
  generateGetter
} from "./store.common";

const state = {
  ...generateState(),
  selectedPaymentMethods: [],
  cashFlowDetail: {
    ...generateState().cashFlowDetail,
    receiver: {
      model_name: "App\\Models\\User",
      model_id: null,
      mode: "EMPLOYEE"
    },
    type: 2
  }
};

const getters = {
  ...generateGetter(),
  selectedPaymentMethods: state => state.selectedPaymentMethods
};

const mutations = {
  ...generateMutations(),
  SET_PAYMENT_METHODS: (state, payload) => {
    state.selectedPaymentMethods = payload;
  }
};

const actions = {
  ...generateActions(
    ModelName.ExchangeVoucher,
    StoreName.CASH_TRANSFER_NOTE,
    ORDERS_TYPE.CASH_TRANSFER_NOTE
  ),
  setPaymentMethods: ({ commit }, data) => {
    commit("SET_PAYMENT_METHODS", data);
  },
  resetCashFlowDetail: ({ commit }) => {
    commit("SET_CASH_FLOW_DETAIL", {
      branch_id: 1,
      sender: {},
      receiver: {
        model_name: "App\\Models\\User",
        model_id: null,
        mode: "EMPLOYEE"
      },
      type: 2,
      apply_type: 1,
      source_apply_type: 1,
      voucher_links: [],
      source_voucher_links: [],
      payment_type: 3,
      payment_amount: 0,
      payment_fee: 0,
      payment_account_id: null,
      note: ""
    });
  },
  setCashFlowDetail: async ({ commit }, data) => {
    const { voucher_links } = data;
    commit("SET_ORDERS", voucher_links);
    commit("SET_CASH_FLOW_DETAIL", {
      ...data
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
