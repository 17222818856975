import { INIT_SITE_CONFIG_SEO_DATA } from "@/core/constant";

export const HEADERS_CUSTOMIZE_FOLDER_TABLE = [
  {
    text: "Tên folder",
    align: "start",
    sortable: false,
    value: "name"
  },
  {
    text: "Mô tả",
    align: "start",
    sortable: false,
    value: "des"
  },
  {
    text: "Cập nhật lần cuối",
    align: "start",
    sortable: false,
    value: "updated_at"
  },
  {
    text: "Người cập nhật",
    align: "start",
    sortable: false,
    value: "last_updated_by_user"
  },
  {
    text: "",
    align: "start",
    sortable: false,
    value: "action"
  }
];

export const INIT_CUSTOMIZE_FOLDER_VALUE = {
  name: "",
  des: null,
  shared_url: "",
  cover: null,
  thumbnail: null,
  cover_mobile: null,
  type: 1,
  seo_data: JSON.parse(JSON.stringify(INIT_SITE_CONFIG_SEO_DATA)),
  options: []
};

export const INIT_CUSTOMIZE_FOLDER_CATEGORY_VALUE = {
  category: null,
  category_id: null,
  condition_filters: null,
  filters: [],
  priority: null,
  options: [],
  title: null,
  type: 1, // 1 - Specific products, 2 - Filter conditions
  suggestion_tags: []
};
