/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import PaymentAccountService from "../service/payment-account.service";
import router from "@/core/router";
import store from "@/core/store";
import Vue from "vue";

const state = {
  paymentAccount: {
    account_number: null,
    account_owner: null,
    account_bank: null,
    account_type: "Cá nhân",
    available_branches: null,
    received_methods: []
  },
  paymentAccounts: [],
  paymentMethods: null,
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  paymentAccount: state => state.paymentAccount,
  paymentAccounts: state => state.paymentAccounts,
  statusRequest: state => state.statusRequest,
  paymentMethods: state => state.paymentMethods
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAYMENT_ACCOUNT: (state, payload) => {
    state.paymentAccount = payload;
  },

  SET_PAYMENT_METHODS: (state, payload) => {
    state.paymentMethods = payload;
  },

  SET_PAYMENT_ACCOUNTS: (state, payload) => {
    state.paymentAccounts = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createPaymentAccount: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createPaymentAccount" });

      const response = await PaymentAccountService.createPaymentAccount(
        payload
      );

      if (response.status === 201 && response.data.data) {
        // Update paymentAccount
        commit("SET_PAYMENT_ACCOUNT", response.data.data);
        // Get paymentAccounts
        await store.dispatch("PAYMENT_ACCOUNT/getPaymentAccounts");
        // Get paymentAccounts
        commit("SET_STATUS_REQUEST", { value: "success-createPaymentAccount" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          value: "error-createPaymentAccount",
          message: "Lỗi không thể thêm mới tài khoản"
        });
      }
    }
  },

  deletePaymentAccount: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deletePaymentAccount" });

      const response = await PaymentAccountService.deletePaymentAccount(
        payload
      );

      if (response.status === 200) {
        // Get paymentAccounts
        await store.dispatch("PAYMENT_ACCOUNT/getPaymentAccounts");
        commit("SET_STATUS_REQUEST", { value: "success-deletePaymentAccount" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getPaymentAccounts: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPaymentAccounts" });

      const response = await PaymentAccountService.getPaymentAccounts();

      if (response.status === 200 && response.data.data) {
        commit("SET_PAYMENT_ACCOUNTS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getPaymentAccounts" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getPaymentAccountsMethods: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getPaymentAccountsMethods"
      });

      const response = await PaymentAccountService.getPaymentAccountsMethod();
      if (response.status === 200 && response.data.data) {
        commit("SET_PAYMENT_METHODS", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getPaymentAccountsMethods"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  resetPaymentAccount: ({ commit }) => {
    commit("SET_PAYMENT_ACCOUNT", {
      account_number: null,
      account_owner: null,
      account_bank: null,
      account_type: "Cá nhân",
      available_branches: null,
      received_methods: []
    });
  },

  setPaymentAccount: ({ commit }, payload) => {
    commit("SET_PAYMENT_ACCOUNT", payload);
  },

  updatePaymentAccount: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updatePaymentAccount" });

      const response = await PaymentAccountService.updatePaymentAccount(
        payload.paymentAccount
      );

      if (response.status === 200) {
        // Update paymentAccounts
        Vue.set(
          state.paymentAccounts,
          payload.paymentAccountIndex,
          response.data.data
        );
        // Get paymentAccounts
        commit("SET_STATUS_REQUEST", { value: "success-updatePaymentAccount" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          value: "error-updatePaymentAccount",
          message: "Lỗi không thể cập nhật tài khoản"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
