/**
 *
 * @param email: string
 * @returns {boolean}
 */
export const regexEmail = email => {
  const isEmail = new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  );

  return isEmail.test(email);
};

/**
 * @param str: string
 * @returns {boolean}
 * @example regexMaxForm('max:3434') // true
 * @example regexMaxForm('max3434') // false
 */
export const regexMaxForm = str => {
  const isMaxForm = /^max:\d+$/;

  return isMaxForm.test(str);
};

/**
 * @param str: string
 * @returns {boolean}
 * @example regexMaxValForm('maxVal:3434') // true
 * @example regexMaxValForm('maxVal3434') // false
 */
export const regexMaxValForm = str => {
  const isMaxValForm = /^maxVal:\d+$/;

  return isMaxValForm.test(str);
};

/**
 * @param str: string
 * @returns {boolean}
 * @example regexMinForm('min:3434') // true
 * @example regexMinForm('min3434') // false
 */
export const regexMinForm = str => {
  const isMinForm = /^min:\d+$/;

  return isMinForm.test(str);
};

/**
 * @param str: string
 * @returns {boolean}
 * @example regexMinValForm('minVal:3434') // true
 * @example regexMinValForm('minVal3434') // false
 */
export const regexMinValForm = str => {
  const isMinValForm = /^minVal:\d+$/;

  return isMinValForm.test(str);
};

/**
 *
 * @param str
 * @returns {boolean}
 */
export const regexNumberString = str => {
  const isNumberString = /^[0-9]+$/;

  return isNumberString.test(str);
};

/**
 * Regex số mobile của tất cả nhà mạng ở Việt Nam, cập nhật đến tháng 11/2020.
 * Bắt đầu bằng +84XX hoặc 0XX
 * @param phone: string
 * @returns {boolean}
 */
export const regexPhone = phone => {
  const isVnMobilePhone = /^(0|\+84|84)?(\s|\.)?((3[2-9])|(5[25689])|(7[06-9])|(8[1-9])|(9[0-46-9]))(\d)(\s|\.)?(\d{3})(\s|\.)?(\d{3})$/;
  return isVnMobilePhone.test(phone);
};

/**
 * @param phone: string
 * @returns {boolean}
 */
export const regexUrl = url => {
  const REGEX_URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return REGEX_URL.test(url);
};
