import { ModelName, StoreName, ORDERS_TYPE, PaymentType } from "../constant";
import store from "@/core/store";
import {
  generateActions,
  generateMutations,
  generateState,
  generateGetter
} from "./store.common";

const state = {
  ...generateState(),
  selectedPaymentMethods: []
};

const getters = {
  ...generateGetter(),
  selectedPaymentMethods: state => state.selectedPaymentMethods
};

const mutations = {
  ...generateMutations(),
  SET_PAYMENT_METHODS: (state, payload) => {
    state.selectedPaymentMethods = payload;
    if (payload[0]) {
      state.cashFlowDetail = { ...state.cashFlowDetail, ...payload[0] };
    }
  }
};

const actions = {
  ...generateActions(
    ModelName.ReceiptVoucher,
    StoreName.RECEIPT_VOUCHER,
    ORDERS_TYPE.RECEIPT_VOUCHER
  ),
  setPaymentMethods: ({ commit }, data) => {
    commit("SET_PAYMENT_METHODS", data);
  },
  setCashFlowDetail: async ({ commit }, data) => {
    const {
      payment_account_id,
      payment_amount,
      payment_fee,
      payment_type,
      sender,
      voucher_links
    } = data;
    commit("SET_PAYMENT_METHODS", [
      {
        payment_amount: payment_amount,
        payment_fee,
        payment_type: payment_type,
        payment_account_id: payment_account_id,
        name: PaymentType[payment_type]
      }
    ]);
    let senderTemp = { ...sender };
    commit("SET_ORDERS", voucher_links);
    commit("SET_CASH_FLOW_DETAIL", {
      ...data,
      sender: data.receiver,
      receiver: senderTemp
    });
    await store.dispatch("CUSTOMER/getCustomers", {
      cur_page: 1,
      per_page: 100,
      search: sender ? sender.model_id : null
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
