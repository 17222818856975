export const SHOW_TYPE = [
  { code: "SHOW_TYPE", value: 1, name: "Chỉ hiển thị danh sách folder con" },
  {
    code: "SHOW_LIST_ON_SET_ON",
    value: 2,
    name: "Chỉ hiển thị danh sách bài viết thuộc folder"
  }
];

export const SEARCH_TYPE = [
  {
    code: "SEARCH_IN_CHILDREN",
    value: 1,
    name: "Filter theo folder con",
    description:
      "Ví dụ có folder Tư vấn chọn Laptop (F1) > Theo ngành, công việc (F2) > Bác sĩ (F3), Kỹ sư (F3)... Chọn option này sẽ giúp user lọc bài viết theo các ngành cụ thể (F3)"
  },
  {
    code: "SEARCH_IN_PRICE_CHILDREN",
    value: 2,
    name: "Filter theo folder con và khoảng giá",
    description:
      "Ví dụ lọc bài viết theo tựa game cụ thể (F3) và khoảng giá trong folder Tư vấn chọn Laptop (F1) > Gaming laptop (F2)"
  },
  {
    code: "SEARCH_IN_DEMAND_PRICE_BRAND",
    value: 3,
    name: "Filter theo nhu cầu, khoảng giá, thương hiệu",
    description:
      "Ví dụ lọc bài viết theo nhu cầu Laptop Văn phòng, Khoảng giá 15-20 triệu, Dell, Lenovo trong folder ThinkPro Review (F1) > Laptop (F2)"
  },
  {
    code: "SEARCH_IN_CATEGORY_BRAND",
    value: 4,
    name: "Filter theo category, thương hiệu",
    description:
      "Ví dụ lọc bài viết theo category Bàn phím, thương hiệu Keychron trong folder ThinkPro Review (F1) > Phụ kiện, linh kiện (F2)"
  }
];
