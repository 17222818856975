/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import EventService from "../service/index";
import router from "@/core/router";

const state = {
  flashSaleProductOptions: [],
  sessions: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  flashSaleProductOptions: state => state.flashSaleProductOptions,
  offlineSessions: state => {
    return state.sessions.filter(session => session.type === 1);
  },
  sessions: state => state.sessions,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_FLASH_SALE_PRODUCT_OPTIONS: (state, payload) => {
    state.flashSaleProductOptions = payload;
  },

  SET_SESSIONS: (state, payload) => {
    state.sessions = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  getFlashSaleProductOptionsBySessionId: async ({ commit }, sessionId) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getFlashSaleProductOptionsBySessionId"
      });

      const response = await EventService.getFlashSaleProductOptionsBySessionId(
        sessionId
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_FLASH_SALE_PRODUCT_OPTIONS", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getFlashSaleProductOptionsBySessionId"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getFlashSaleProductOptionsBySessionId"
        });
      }
    }
  },

  getSessions: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSessions" });

      const response = await EventService.getSessions();

      if (response.status === 200 && response.data.data) {
        commit("SET_SESSIONS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getSessions" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getSessions"
        });
      }
    }
  },

  handleAfterCancelingSaleReceipt: async ({ commit }, flashSaleOptionId) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-handleAfterCancelingSaleReceipt"
      });

      const response = await EventService.handleAfterCancelingSaleReceipt(
        flashSaleOptionId
      );

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", {
          value: "success-handleAfterCancelingSaleReceipt"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-handleAfterCancelingSaleReceipt"
        });
      }
    }
  },

  handleAfterCreatingSaleReceipt: async ({ commit }, flashSaleOptionId) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-handleAfterCreatingSaleReceipt"
      });

      const response = await EventService.handleAfterCreatingSaleReceipt(
        flashSaleOptionId
      );

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", {
          value: "success-handleAfterCreatingSaleReceipt"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-handleAfterCreatingSaleReceipt"
        });
      }
    }
  },

  importFlashSaleProductOptions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-importFlashSaleProductOptions"
      });

      const response = await EventService.importFlashSaleProductOptions(
        payload
      );

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", {
          value: "success-importFlashSaleProductOptions"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-importFlashSaleProductOptions"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
