const PolicyRouter = {
  path: "/website",
  component: () => import("@/modules/Website"),
  redirect: { name: "policy_order-policy" },
  children: [
    {
      path: "order-policies",
      component: () => import("../pages/OrderPolicies"),
      children: [
        {
          path: "list",
          name: "policy_order-policies",
          meta: {
            private: true,
            layout: "general",
            title: "Chính sách mua hàng từ xa | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Chính sách mua hàng từ xa",
                to: { name: "policy_order-policies" }
              }
            ]
          },
          component: () => import("../pages/OrderPolicies/Listing")
        },
        {
          path: "create",
          name: "policy_order-policies-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới chính sách mua hàng từ xa | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Chính sách mua hàng từ xa",
                to: { name: "policy_order-policies" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới",
                to: { name: "policy_order-policies-create" }
              }
            ]
          },
          component: () => import("../pages/OrderPolicies/Detail")
        },
        {
          path: "detail/:orderPolicyId",
          name: "policy_order-policies-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Cập nhật chính sách mua hàng | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Chính sách mua hàng từ xa",
                to: { name: "policy_order-policies" }
              },
              {
                disabled: true,
                exact: true,
                text: "Cập nhật",
                to: { name: "policy_order-policies-detail" }
              }
            ]
          },
          component: () => import("../pages/OrderPolicies/Detail")
        }
      ]
    }
  ]
};

export default PolicyRouter;
