/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import GoodsDestructionService from "../service/goods-destruction.service";
import router from "@/core/router";
import store from "@/core/store";
// import Vue from "vue";

const state = {
  goodsDestruction: {
    branch_name: null,
    created_price: null,
    note: null,
    hoadonnhap_code: null,
    serial_id: null,
    serial_number: null,
    option: {
      SKU: null,
      name: null,
      product_name: null,
      price: null
    }
  },
  goodsDestructionList: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  goodsDestruction: state => state.goodsDestruction,
  goodsDestructionList: state => state.goodsDestructionList,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_GOODS_DESTRUCTION: (state, payload) => {
    state.goodsDestruction = payload;
  },

  SET_GOODS_DESTRUCTION_LIST: (state, payload) => {
    state.goodsDestructionList = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createGoodsDestruction: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createGoodsDestruction"
      });

      const response = await GoodsDestructionService.createGoodsDestruction(
        payload.data
      );

      if (response.status === 200 && response.data) {
        // Get goods destruction list
        store.dispatch(
          "GOODS_DESTRUCTION/getGoodsDesTructionList",
          payload.data
        );
        commit("SET_STATUS_REQUEST", {
          value: "success-createGoodsDestruction"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createGoodsDestruction"
        });
      }
    }
  },

  getGoodsDestructionById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getGoodsDestructionById"
      });

      const response = await GoodsDestructionService.getGoodsDestructionById(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_GOODS_DESTRUCTION", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getGoodsDestructionById"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getGoodsDestructionById"
        });
      }
    }
  },

  getGoodsDesTructionList: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getGoodsDesTructionList"
      });

      const response = await GoodsDestructionService.getGoodsDesTructionList(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_GOODS_DESTRUCTION_LIST", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", {
          value: "success-getGoodsDesTructionList"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getGoodsDesTructionList"
        });
      }
    }
  },

  resetGoodsDestruction: ({ commit }) => {
    commit("SET_GOODS_DESTRUCTION", {
      branch_name: null,
      created_price: null,
      note: null,
      hoadonnhap_code: null,
      serial_id: null,
      serial_number: null,
      option: {
        SKU: null,
        name: null,
        product_name: null,
        price: null
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
