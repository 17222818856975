import { KIND } from "@/modules/Fund/pages/FundRevenue/constants";

export const SUPPLIER_CATEGORY = {
  HANG_HOA: 1,
  BAO_HANH_SUA_CHUA: 2,
  VAN_CHUYEN: 3,
  TRUNG_GIAN_THANH_TOAN: 4,
  TRA_GOP: 5,
  CONG_THANH_TOAN: 6
};

export const LIST_SUPPLIER_CATEGORIES = [
  {
    id: SUPPLIER_CATEGORY.HANG_HOA,
    name: "Hàng hóa",
    color: "green lighten-5 green--text"
  },
  {
    id: SUPPLIER_CATEGORY.BAO_HANH_SUA_CHUA,
    name: "Dịch vụ bảo hành, sửa chữa",
    color: "orange lighten-5 orange--text"
  },
  {
    id: SUPPLIER_CATEGORY.VAN_CHUYEN,
    name: "Dịch vụ vận chuyển",
    color: "deep-purple lighten-5 deep-purple--text"
  },
  {
    id: SUPPLIER_CATEGORY.TRUNG_GIAN_THANH_TOAN,
    name: "Trung gian thanh toán",
    color: "blue lighten-5 blue--text"
  },
  {
    id: SUPPLIER_CATEGORY.TRA_GOP,
    name: "Trả góp",
    color: "pink lighten-5 pink--text"
  },
  {
    id: SUPPLIER_CATEGORY.CONG_THANH_TOAN,
    name: "Cổng thanh toán",
    color: "red lighten-5 red--text"
  }
];

export const KIND_SUPPLIER_MAPPER = {
  [KIND.THU_COD]: SUPPLIER_CATEGORY.VAN_CHUYEN,
  [KIND.THU_QUET_THE]: SUPPLIER_CATEGORY.TRUNG_GIAN_THANH_TOAN,
  [KIND.THU_TRA_GOP]: SUPPLIER_CATEGORY.TRA_GOP
};
