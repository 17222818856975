import { INIT_SITE_CONFIG_SEO_DATA } from "@/core/constant";

export const INIT_PRODUCT_TYPE_VALUE = {
  name: null,
  description: null,
  icon: null,
  parent_id: null,
  priority: null,
  seo_data: JSON.parse(JSON.stringify(INIT_SITE_CONFIG_SEO_DATA)),
  thumbnail: null
};

export const PRODUCT_STATUS = {
  ALL: {
    label: "Tất cả",
    value: "ALL"
  },
  PUBLISHED: {
    label: "Đang KD + SEO",
    value: "PUBLISHED"
  },
  SEO_ONLY: {
    label: "SEO",
    value: "SEO_ONLY"
  },
  HIDDEN: {
    label: "Ẩn web",
    value: "HIDDEN"
  }
};
