import Api from "@/core/apis";

export default {
  getMegaMenus() {
    return Api().get("menu/getMenus");
  },

  updateMegaMenus(data) {
    return Api().post("menu/updateMenus", data);
  }
};
