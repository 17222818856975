import Api from "@/core/apis";

export default {
  createGuaranteeVoucherComment(data) {
    return Api().post("bh_sc/comments/add", data);
  },

  deleteGuaranteeVoucherComment(id) {
    return Api().get(`bh_sc/comments/delete/${id}`);
  },

  getGuaranteeVoucherComments(guaranteeVoucherId) {
    return Api().get(`bh_sc/comments/${guaranteeVoucherId}`);
  },

  updateGuaranteeVoucherComment(data) {
    return Api().post("bh_sc/comments/edit", data);
  }
};
