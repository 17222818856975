/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import SearchKeywordService from "../service/search-keyword.service";
import router from "@/core/router";

const state = {
  searchKeywords: [
    { search: null, url: null },
    { search: null, url: null },
    { search: null, url: null },
    { search: null, url: null },
    { search: null, url: null }
  ],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedImage: null
};

const getters = {
  searchKeywords: state => state.searchKeywords,
  statusRequest: state => state.statusRequest,
  uploadedImage: state => state.uploadedImage
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_SEARCH_KEYWORDS: (state, payload) => {
    state.searchKeywords = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  getSearchKeywords: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSearchKeywords" });

      const response = await SearchKeywordService.getSearchKeywords(payload);

      if (response.status === 200 && response.data) {
        commit("SET_SEARCH_KEYWORDS", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-getSearchKeywords" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getSearchKeywords"
        });
      }
    }
  },

  updateSearchKeywords: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateSearchKeywords" });

      const response = await SearchKeywordService.updateSearchKeywords(payload);

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", { value: "success-updateSearchKeywords" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateSearchKeywords"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
