import { ModelName } from "../constants/constants";
import {
  generateActions,
  generateMutations,
  generateState,
  generateGetter
} from "../utils/store.utils";

const state = {
  ...generateState()
};

const getters = {
  ...generateGetter()
};

const mutations = {
  ...generateMutations()
};

const actions = {
  ...generateActions(ModelName.productForStaffs, "EQUIPMENT_FOR_EMPLOYEE")
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
