export const PROMOTION_TYPE = {
  THEO_SAN_PHAM: 1,
  THEO_HOA_DON: 2
};

export const PROMOTION_TK_TYPE = {
  PERCENT: 1,
  AMOUNT: 2,
  PRODUCT: 3
};

export const PROMOTION_TK_OPTION_TANG_TYPE = {
  FREE: 1,
  PERCENT: 2,
  AMOUNT: 3
};
