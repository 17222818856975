export * from "./order-payment-status";
export * from "./order-processing";
export * from "./order-shipping-status";
export * from "./order";
export * from "./sale-receipt";
export * from "./vat-request";

export const LIST_ORDER_CHANNELS = [
  { id: 1, name: "Website" },
  { id: 2, name: "Chi nhánh" },
  { id: 3, name: "Tổng đài" },
  { id: 4, name: "Facebook" },
  { id: 5, name: "Chat Website" }
];

export const LIST_ORDER_SOURCES = [
  { value: "GOOGLE_SEARCH", label: "Google Search" },
  { value: "YOUTUBE", label: "Youtube" },
  { value: "FRIEND_ADVICE", label: "Bạn bè giới thiệu" },
  { value: "FACEBOOK_ADS", label: "Quảng cáo Facebook" },
  { value: "GOOGLE_ADS", label: "Quảng cáo Google" },
  { value: "KOLS_KOC", label: "KOLs/KOC" },
  { value: "FACEBOOK_FANPAGE", label: "Facebook Fanpage" },
  { value: "FACEBOOK_GROUP", label: "Facebook Group" },
  { value: "SHOPPABLE_IMAGE", label: "Mua hàng qua hình ảnh" },
  { value: "OTHER", label: "Khác" }
];

export const LIST_STATUS_ORDER = [
  { id: 0, text: "Đơn đã hủy", color: "red" },
  { id: 1, text: "Chờ xác nhận", color: "orange" },
  { id: 2, text: "Đã xác nhận", color: "blue" },
  { id: 3, text: "Đang xử lý", color: "indigo" },
  { id: 4, text: "Đã hoàn thành", color: "green" },
  { id: 5, text: "Đang trả hàng", color: "purple" },
  { id: 6, text: "Đã trả hàng", color: "pink" }
];
