import Vue from "vue";

import TpAutocomplete from "@/core/plugins/tp-vuetify/Autocomplete";
import TpCombobox from "@/core/plugins/tp-vuetify/Combobox";
import TpSelect from "@/core/plugins/tp-vuetify/Select";
import TpTextarea from "@/core/plugins/tp-vuetify/Textarea";
import TpTextField from "@/core/plugins/tp-vuetify/TextField";

Vue.use(TpAutocomplete);
Vue.use(TpCombobox);
Vue.use(TpSelect);
Vue.use(TpTextarea);
Vue.use(TpTextField);
