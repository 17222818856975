export * from "./product";
export * from "./product-option";
export * from "./product-sources";
export * from "./product-type";

export const LIST_STATUS_SERIAL = [
  { id: 1, text: "Còn hàng", color: "green" },
  { id: -1, text: "Đã bán", color: "orange" },
  { id: -2, text: "Bị lắp vào máy khác", color: "indigo" },
  { id: -3, text: "Bị tháo", color: "purple" },
  { id: -4, text: "Hỏng nội bộ", color: "red accent-1" },
  { id: -5, text: "Trong máy xử lý hỏng nội bộ", color: "red accent-1" },
  { id: -6, text: "Bảo hành nội bộ", color: "red accent-1" },
  { id: -7, text: "Hàng trả chờ nhập", color: "red accent-1" },
  { id: -8, text: "Linh kiện chờ xử lý", color: "red accent-1" },
  { id: -9, text: "Máy chờ lắp ráp", color: "red accent-1" },
  { id: -10, text: "Trong máy chờ lắp ráp", color: "red accent-1" },
  { id: -11, text: "Media mượn", color: "red accent-1" },
  { id: -12, text: "Cấp cho nhân viên", color: "red accent-1" },
  { id: -13, text: "Trưng bày", color: "red accent-1" },
  { id: -14, text: "Bảo hành/sửa chữa", color: "red accent-1" },
  { id: -15, text: "Linh kiện bảo hành, sửa chữa", color: "red accent-1" },
  { id: -16, text: "Linh kiện bảo hành bị bán ra", color: "red accent-1" },
  { id: -99, text: "Đã hủy", color: "red accent-1" }
];

export const LIST_LAPTOP_PC_ATTR_DETAIL = {
  cpu_name: {
    id: 1,
    code: "cpu_name"
  },
  cpu_brand: {
    id: 2,
    code: "cpu_brand"
  },
  cpu_threads: {
    id: 3,
    code: "cpu_threads"
  },
  cpu_cache: {
    id: 4,
    code: "cpu_cache"
  },
  cpu_cores: {
    id: 5,
    code: "cpu_cores"
  },
  cpu_boost: {
    id: 6,
    code: "cpu_boost"
  },
  cpu_base: {
    id: 7,
    code: "cpu_base"
  },
  cpu_socket: {
    id: 8,
    code: "cpu_socket"
  },
  gpu_onboard: {
    id: 9,
    code: "gpu_onboard"
  },
  gpu_type: {
    id: 10,
    code: "gpu_type"
  },
  gpu_vram: {
    id: 11,
    code: "gpu_vram"
  },
  gpu_other: {
    id: 12,
    code: "gpu_other"
  }
};
