export const TYPES = {
  TYPE_THU: 1,
  TYPE_THU_HO: 2,
  TYPE_CHI: 3
};

export const TYPES_OF_REVENUES = [
  { value: TYPES.TYPE_THU, text: "Thu" },
  { value: TYPES.TYPE_THU_HO, text: "Thu hộ" },
  { value: TYPES.TYPE_CHI, text: "Chi" }
];

export const KIND = {
  THU_CHUYEN_QUY_VE: 1,
  THU_COD: 2,
  THU_TRA_GOP: 3,
  THU_QUET_THE: 4,
  THU_CHUYEN_KHOAN: 5,
  CHI_HOAN_COC: 6,
  CHI_TRA_CONG_NO: 7,
  CHI_TIEN_HOA_DON: 8,
  CHI_TRA_LUONG: 9,
  THU_TIEN_MAT: 10,
  CONG_THANH_TOAN: 11,
  VI_DIEN_TU: 12
};

export const KIND_OF_REVENUES = [
  { value: KIND.THU_CHUYEN_QUY_VE, text: "Thu chuyển quỹ về" },
  { value: KIND.THU_COD, text: "Thu COD" },
  { value: KIND.THU_TRA_GOP, text: "Thu trả góp" },
  { value: KIND.THU_QUET_THE, text: "Thu quẹt thẻ" },
  { value: KIND.THU_CHUYEN_KHOAN, text: "Thu chuyển khoản" },
  { value: KIND.CHI_HOAN_COC, text: "Chi hoàn cọc" },
  { value: KIND.THU_TIEN_MAT, text: "Thu tiền mặt" },
  { value: KIND.CONG_THANH_TOAN, text: "Thu cổng thanh toán" },
  { value: KIND.VI_DIEN_TU, text: "Thu ví điện tử" }
];

export const REVENUE_STATUS = {
  DANG_CHO: 1,
  THANH_CONG: 2,
  DA_HUY: 3
};

export const REVENUE_STATUSES = [
  { value: REVENUE_STATUS.DANG_CHO, text: "Đang chờ" },
  { value: REVENUE_STATUS.THANH_CONG, text: "Thành công" },
  { value: REVENUE_STATUS.DA_HUY, text: "Đã huỷ" }
];

export const TYPE_ACCOUNT_VALUE = [
  { value: "COMPANY", text: "" },
  { value: "BRANCH", text: "CN" }
];

export const ACCOUNT_FILTERING_OPTIONS = [
  {
    name: "Tất cả TK công ty",
    id: "company"
  },
  {
    name: "Tất cả TK chi nhánh",
    id: "branch"
  }
];
