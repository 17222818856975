import request from "axios";
import { createAxiosExtra } from "@thinkvn/fetch/lib/extra";
import store from "@/core/store";

export default () => {
  const fetch = request.create({
    baseURL: `${process.env.VUE_APP_API_URL}`,
    validateStatus: status => {
      // Logout & redirect to login page when unauthorized response
      if (status === 401) {
        store.dispatch("AUTHENTICATION/logout");
      }
      return status >= 200 && status < 300; // default
    }
  });

  createAxiosExtra(fetch);

  return fetch;
};
