import Api from "@/core/apis";

export default {
  createOrderPolicyArea(data) {
    return Api().post("order-policy-areas", data);
  },

  deleteOrderPolicyArea(id) {
    return Api().delete(`order-policy-areas/${id}`);
  },

  getOrderPolicyAreas(data) {
    return Api().post("order-policy-areas/list", data);
  },

  getOrderPolicyAreaById(id) {
    return Api().get(`order-policy-areas/${id}`);
  },

  updateOrderPolicyArea(data) {
    return Api().put("order-policy-areas", data);
  }
};
