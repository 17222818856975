/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import BranchService from "../service/branch.service";
import router from "@/core/router";
import store from "@/core/store";
import Vue from "vue";

const state = {
  allBranches: [],
  branch: {
    name: null,
    tinh_thanhpho: null,
    address: null,
    phone: null,
    type: null,
    map_url: null
  },
  branches: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  allBranches: state => state.allBranches,
  allSaleBranches: state =>
    state.allBranches.filter(branch => branch.type === 1),
  branch: state => state.branch,
  branches: state => state.branches,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ALL_BRANCHES: (state, payload) => {
    state.allBranches = payload;
  },

  SET_BRANCH: (state, payload) => {
    state.branch = payload;
  },

  SET_BRANCHES: (state, payload) => {
    state.branches = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createBranch: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createBranch" });

      const response = await BranchService.createBranch(payload.data);

      if (response.status === 200 && response.data) {
        // Get Branches
        await store.dispatch("BRANCH/getBranches", payload.routeQuery);
        // Set id
        Vue.set(state.branch, "id", response.data);

        commit("SET_STATUS_REQUEST", { value: "success-createBranch" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createBranch"
        });
      }
    }
  },

  deleteBranch: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteCustomer" });

      const response = await BranchService.deleteBranch(payload.data);

      if (response.status === 200) {
        // Get Branches
        await store.dispatch("BRANCH/getBranches", payload.routeQuery);

        commit("SET_STATUS_REQUEST", { value: "success-deleteBranch" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-deleteBranch"
        });
      }
    }
  },

  getAllBranches: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllBranches" });

      const response = await BranchService.getAllBranches();

      if (response.status === 200 && response.data.data) {
        commit("SET_ALL_BRANCHES", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAllBranches" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getAllBranches"
        });
      }
    }
  },

  getBranches: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getBranches" });

      const response = await BranchService.getBranches(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_BRANCHES", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getBranches" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getBranches"
        });
      }
    }
  },

  resetBranch: ({ commit }) => {
    commit("SET_BRANCH", {
      name: null,
      tinh_thanhpho: null,
      address: null,
      phone: null,
      type: null,
      map_url: null
    });
  },

  setBranch: ({ commit }, payload) => {
    commit("SET_BRANCH", payload);
  },

  updateBranch: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateBranch" });

      const response = await BranchService.updateBranch(payload.data);

      if (response.status === 200 && response.data) {
        // Get Branches
        await store.dispatch("BRANCH/getBranches", payload.routeQuery);

        commit("SET_STATUS_REQUEST", { value: "success-updateBranch" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateBranch"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
