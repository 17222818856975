const PartnerAndMemberRouter = {
  path: "/partner-and-member",
  component: () => import("@/modules/PartnerAndMember"),
  redirect: { name: "goods_products" },
  children: [
    {
      path: "customers",
      name: "partner-and-member_customers",
      meta: {
        private: true,
        layout: "general",
        title: "Khách hàng | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Khách hàng",
            to: { name: "partner-and-member_customers" }
          }
        ]
      },
      component: () => import("../pages/Customers")
    },
    {
      path: "customer-groups",
      name: "partner-and-member_customer-groups",
      meta: {
        private: true,
        layout: "general",
        title: "Nhóm khách hàng | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Nhóm khách hàng",
            to: { name: "partner-and-member_customer-groups" }
          }
        ]
      },
      component: () => import("../pages/CustomerGroups")
    },
    {
      path: "customer-ranks",
      name: "partner-and-member_customer-ranks",
      meta: {
        private: true,
        layout: "general",
        title: "Hạng khách hàng | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Hạng khách hàng",
            to: { name: "partner-and-member_customer-ranks" }
          }
        ]
      },
      component: () => import("../pages/CustomerRanks")
    },
    {
      path: "employees",
      name: "partner-and-member_employees",
      meta: {
        private: true,
        layout: "general",
        title: "Nhân viên | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Nhân viên",
            to: { name: "partner-and-member_employees" }
          }
        ]
      },
      component: () => import("../pages/Employees")
    },
    {
      path: "branches",
      name: "partner-and-member_branches",
      meta: {
        private: true,
        layout: "general",
        title: "Chi nhánh | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Chi nhánh",
            to: { name: "partner-and-member_branches" }
          }
        ]
      },
      component: () => import("../pages/Branches")
    },
    {
      path: "suppliers",
      name: "partner-and-member_suppliers",
      meta: {
        private: true,
        layout: "general",
        title: "Nhà cung cấp | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Nhà cung cấp",
            to: { name: "partner-and-member_suppliers" }
          }
        ]
      },
      component: () => import("../pages/Suppliers")
    }
  ]
};

export default PartnerAndMemberRouter;
