import Api from "@/core/apis";

export default {
  getModelSiteConfigsByModelId(modelId, modelType) {
    return Api().post(`model-site-config/detail`, {
      model_type: modelType,
      model_id: modelId
    });
  },

  updateModelSiteConfigs(modelSiteConfigs) {
    return Api().put("model-site-config", modelSiteConfigs);
  }
};
