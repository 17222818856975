export const HEADERS_ORDER_TABLE_MAP = {
  code: {
    text: "Mã ĐH",
    align: "start",
    sortable: false,
    value: "code"
  },
  options: {
    text: "Sản phẩm",
    align: "start",
    sortable: false,
    value: "options"
  },
  customer: {
    text: "Khách hàng",
    align: "start",
    sortable: false,
    value: "customer"
  },
  assigned_to_user: {
    text: "Người phụ trách",
    align: "start",
    sortable: false,
    value: "assigned_to_user.name"
  },
  shipping_status: {
    text: "TT giao hàng (Sắp xếp theo hạn gần nhất)",
    align: "start",
    sortable: false,
    value: "payment_methods_label"
  },

  payment_methods_label: {
    text: "TT thanh toán",
    align: "start",
    sortable: false,
    value: "payment_methods_label1"
  },

  total: {
    text: "Khách phải trả",
    align: "start",
    sortable: false,
    value: "total"
  },
  status: {
    text: "Trạng thái",
    align: "start",
    sortable: false,
    value: "status"
  }
};

export const INIT_ORDER_PROCESSING_VALUE = {
  address_id: null,
  assigned_preorder_amount: null,
  assigned_promotion_amount: null,
  assigned_shipping_amount: null,
  branch_id: null,
  branch_nhan: null,
  branch_nhan_id: null,
  self_shipping: false,
  require_tech_staff: false,
  code: null,
  details: [],
  discount: null,
  handle_fee: null,
  hoa_don_ban_id: null,
  hoa_don_ban_code: null,
  note: null,
  order_id: null,
  packaging_image: null,
  prepared_by_id: null,
  pre_paid_amount: null,
  received_method: null,
  serials: [],
  shipping_image: null,
  shipping_partner: null,
  shipping_tracking_code: null,
  status: 1,
  value: null
};
