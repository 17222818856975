import Vue from "vue";
import request from "axios";
import router from "@/core/router";
import store from "@/core/store";
import { LIST_ROLES } from "@/core/constant";

Vue.config.productionTip = false;
Vue.prototype.$http = request;

// Set authorization token which request to server
const token = localStorage.getItem("_TPUT");

if (token) {
  Vue.prototype.$http.defaults.headers["Authorization"] = token;
}

// Config router before
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("_TPUT");
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);
  let user = store.getters["AUTHENTICATION/user"];

  if (!user && token && to.name !== "500") {
    await store.dispatch("AUTHENTICATION/getCurrentUser");
    user = store.getters["AUTHENTICATION/user"];
  }

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  next();

  if (to.matched.some(record => record.meta.private)) {
    // Check token
    if (token) {
      const roleName = user.role.toLowerCase();
      const routeName = to.name;

      // Check sale, store manager and content role
      if (
        roleName === LIST_ROLES.sale.name ||
        roleName === LIST_ROLES.accountant.name ||
        roleName === LIST_ROLES.storeManager.name ||
        roleName === LIST_ROLES.content.name ||
        roleName === LIST_ROLES.marketing.name ||
        roleName === LIST_ROLES.freelancerWriter.name ||
        roleName === LIST_ROLES.productAssistant.name ||
        roleName === LIST_ROLES.inventoryManager.name
      ) {
        if (
          (roleName === LIST_ROLES.sale.name &&
            LIST_ROLES.sale.routes.includes(routeName)) ||
          (roleName === LIST_ROLES.storeManager.name &&
            LIST_ROLES.storeManager.routes.includes(routeName)) ||
          (roleName === LIST_ROLES.content.name &&
            LIST_ROLES.content.routes.includes(routeName)) ||
          (roleName === LIST_ROLES.marketing.name &&
            LIST_ROLES.marketing.routes.includes(routeName)) ||
          (roleName === LIST_ROLES.freelancerWriter.name &&
            LIST_ROLES.freelancerWriter.routes.includes(routeName)) ||
          (roleName === LIST_ROLES.productAssistant.name &&
            LIST_ROLES.productAssistant.routes.includes(routeName)) ||
          (roleName === LIST_ROLES.inventoryManager.name &&
            LIST_ROLES.inventoryManager.routes.includes(routeName)) ||
          (roleName === LIST_ROLES.accountant.name &&
            LIST_ROLES.accountant.routes.includes(routeName))
        ) {
          next();
          return false;
        } else {
          next({ name: "dashboard" });
          return false;
        }
      } else {
        next();
        return false;
      }
    }
    next({ name: "login" });
  } else if (to.name === "login") {
    if (token) {
      next({ name: "dashboard" });
      return false;
    }
  } else {
    next();
  }
});
