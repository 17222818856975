export const INIT_ORDER_PROCESSING_VALUE = {
  address_id: null,
  assigned_preorder_amount: null,
  assigned_promotion_amount: null,
  assigned_shipping_amount: null,
  branch_id: null,
  branch_nhan: null,
  branch_nhan_id: null,
  cancel_status: 0,
  code: null,
  details: [],
  discount: null,
  distance: "",
  handle_fee: null,
  hoa_don_ban_id: null,
  hoa_don_ban_code: null,
  id: null,
  note: null,
  order_id: null,
  packaging_image: null,
  prepared_by_id: null,
  pre_paid_amount: null,
  received_method: null,
  serials: [],
  shipping_image: null,
  shipping_partner: null,
  shipping_tracking_code: null,
  status: 1,
  user_packing: null,
  distance_user_packing: null,
  user_shipping: null
};

export const INIT_SHIPPING_INFO_VALUE = {
  handle_id: null,
  self_shipping: 0,
  shipping_tracking_code: null,
  cod_amount: null,
  shipping_supplier_code: null,
  shipping_est_date: null,
  shipping_by_id: null,
  docs: null,
  note: null,
  distance: null,
  self: null,
  assigned_shipping_amount: null,
  packaging_info: {
    weight: null,
    height: null,
    length: null,
    width: null,
    request_shipping: null
  },
  payer: "SHOP"
};

export const LIST_ORDER_PROCESSING_STATUS = [
  { value: 1, label: "Chờ gom hàng", color: "orange" },
  { value: 3, label: "Sẵn hàng tại kho", color: "light-blue accent-2" },
  { value: 5, label: "Đang giao hàng", color: "deep-purple accent-2" },
  { value: 6, label: "Đã giao", color: "green lighten-1" }
];

export const findOrderProcessingStatus = status => {
  return LIST_ORDER_PROCESSING_STATUS.find(item => item.value === status);
};
