import Api from "@/core/apis";

export default {
  createSupplier(data) {
    return Api().post("supplier/add", data);
  },

  deleteSupplier(id) {
    return Api().get(`supplier/delete/${id}`);
  },

  getAllSupplierCategories() {
    return Api().get(`supplier/categories`);
  },

  getAllSuppliers(query) {
    if (query) {
      return Api().get("supplier/getAll" + "/" + query);
    }
    return Api().get("supplier/getAll");
  },

  getSupplierById(id) {
    return Api().get(`supplier/detail/info/${id}`);
  },

  getSuppliers(data) {
    return Api().post("supplier/all", data);
  },

  getSupplierTransactionHistory(data) {
    return Api().post(`supplier/detail/history/${data.id}`, data.filters);
  },

  updateSupplier(data) {
    return Api().post("supplier/edit", data);
  }
};
