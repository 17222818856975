export const INIT_SALE_RECEIPT_VALUE = {
  branch_id: null,
  code: null,
  created_at: new Date().toISOString(),
  closing_user_id: null,
  khachhang: null,
  khachhang_id: null,
  note: null,
  options: [],
  phivanchuyen: 0,
  payment_fee: 0,
  promo: null,
  serials: [],
  value: null,
  xuatBu: false,
  order_type: "SALE_RECEIPT",
  site: "THINKPRO",
  promotionReduce: 0,
  total_paid: 0,
  assigned_preorder_amount: 0,
  return_goods_value: 0,
  sc_fee: 0
};
