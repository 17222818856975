import Api from "@/core/apis";

export default {
  createProductGroup(data) {
    return Api().post("group/product/add", data);
  },

  deleteProductGroups(data) {
    return Api().post("group/product/delete", data);
  },

  getAllProductGroups() {
    return Api().get("group/product/getAll");
  },

  getProductGroupConditions() {
    return Api().get("group/product/listDKs");
  },

  getProductGroupById(id) {
    return Api().get(`group/product/detail/${id}`);
  },

  getProductGroups(data) {
    return Api().post("group/product/all", data);
  },

  updateProductGroup(data) {
    return Api().post("group/product/edit", data);
  }
};
