export const INIT_COMPANY_BANK_ACCOUNT_VALUE = {
  number: null,
  name: null,
  bank_id: null
};

export const INIT_OBJECT_BANK_ACCOUNT_VALUE = {
  number: null,
  name: null,
  bank_id: null,
  object: {
    id: null,
    type: null
  }
};

export const BANK_ACCOUNT_OBJECT_TYPE = {
  USER: 1,
  BRANCH: 2,
  SUPPLIER: 3
};
