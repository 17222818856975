/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import OrderPolicyService from "../service/order-policy.service";
import { catchError } from "@/core/composables/useStore";
import { INIT_ORDER_POLICY_VALUE } from "@/modules/Policy/constants";

const state = {
  orderPolicies: [],
  orderPolicy: JSON.parse(JSON.stringify(INIT_ORDER_POLICY_VALUE)),
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  orderPolicies: state => state.orderPolicies,
  orderPolicy: state => state.orderPolicy,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ORDER_POLICIES: (state, payload) => {
    state.orderPolicies = payload;
  },

  SET_ORDER_POLICY: (state, payload) => {
    state.orderPolicy = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createOrderPolicy: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createOrderPolicy" });

      const orderPolicy = payload;

      // Convert areas
      orderPolicy.areas = orderPolicy.areas.map(item => item.id);

      const response = await OrderPolicyService.createOrderPolicy(orderPolicy);

      if (response.status === 201 && response.data.data) {
        // Update orderPolicy
        commit("SET_ORDER_POLICY", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-createOrderPolicy" });
      }
    } catch (e) {
      catchError(e, commit, "createOrderPolicy");
    }
  },

  deleteOrderPolicy: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteOrderPolicy" });

      const response = await OrderPolicyService.deleteOrderPolicy(payload);

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", { value: "success-deleteOrderPolicy" });
      }
    } catch (e) {
      catchError(e, commit, "deleteOrderPolicy");
    }
  },

  getOrderPolicies: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getOrderPolicies" });

      const response = await OrderPolicyService.getOrderPolicies(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_ORDER_POLICIES", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });

        commit("SET_STATUS_REQUEST", { value: "success-getOrderPolicies" });
      }
    } catch (e) {
      catchError(e, commit, "getOrderPolicies");
    }
  },

  getOrderPolicyById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getOrderPolicyById" });

      const response = await OrderPolicyService.getOrderPolicyById(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_ORDER_POLICY", response.data.data);

        commit("SET_STATUS_REQUEST", { value: "success-getOrderPolicyById" });
      }
    } catch (e) {
      catchError(e, commit, "getOrderPolicyById");
    }
  },

  resetOrderPolicy: ({ commit }) => {
    commit(
      "SET_ORDER_POLICY",
      JSON.parse(JSON.stringify(INIT_ORDER_POLICY_VALUE))
    );
  },

  updateOrderPolicy: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateOrderPolicy" });

      const orderPolicy = payload;

      // Convert areas
      orderPolicy.areas = orderPolicy.areas.map(item => item.id);

      const response = await OrderPolicyService.updateOrderPolicy(orderPolicy);

      if (response.status === 200 && response.data.data) {
        // Update orderPolicy
        commit("SET_ORDER_POLICY", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-updateOrderPolicy" });
      }
    } catch (e) {
      catchError(e, commit, "updateOrderPolicy");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
