/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import SerialService from "../service/serial.service";
import router from "@/core/router";
import store from "@/core/store";

const state = {
  forUpdateSerial: {},
  inventoryNumber: 0,
  productOptionSerials: [],
  productOptionStockSerials: [],
  searchedSerials: [],
  searchProductOptions: [],
  selectedSerial: {},
  serialInfo: null,
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  forUpdateSerial: state => state.forUpdateSerial,
  inventoryNumber: state => state.inventoryNumber,
  productOptionSerials: state => state.productOptionSerials,
  productOptionStockSerials: state => state.productOptionStockSerials,
  searchedSerials: state => state.searchedSerials,
  searchProductOptions: state => state.searchProductOptions,
  selectedSerial: state => state.selectedSerial,
  serialInfo: state => state.serialInfo,
  statusRequest: state => state.statusRequest
};

const mutations = {
  AFTER_UPDATE_SKU: state => {
    state.searchProductOptions = [];
  },

  AFTER_UPDATE_SERIAL: (state, { serial_id, new_serial_number }) => {
    state.selectedSerial.serial_number = new_serial_number;
    state.forUpdateSerial.serial_number = new_serial_number;
    state.searchedSerials.forEach(e => {
      if (e.id === serial_id) e.serial_number = new_serial_number;
    });
  },

  DELETE_SERIAL: (state, payload) => {
    let newSearched = [...state.searchedSerials].filter(e => e.id !== payload);
    state.searchedSerials = newSearched;
    state.selectedSerial = {};
  },

  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_FOR_UPDATE_SERIAL: (state, payload) => {
    state.forUpdateSerial = payload;
  },

  SET_INVENTORY_NUMBER: (state, payload) => {
    state.inventoryNumber = payload;
  },

  SET_PRODUCT_OPTION_SERIALS: (state, payload) => {
    state.productOptionSerials = payload;
  },

  SET_PRODUCT_OPTION_STOCK_SERIALS: (state, payload) => {
    state.productOptionStockSerials = payload;
  },

  SET_SEARCH_PRODUCT_OPTIONS: (state, payload) => {
    state.searchProductOptions = payload;
  },

  SET_SEARCHED_SERIALS: (state, payload) => {
    state.searchedSerials = payload;
  },

  SET_SELECTED_SERIAL: (state, payload) => {
    state.selectedSerial = payload;
  },

  SET_SERIAL_INFO: (state, payload) => {
    state.serialInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  deleteSerial: async ({ commit }, serial_id) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-delete-serial" });

      const response = await SerialService.deleteSerial(serial_id);

      if (response.status === 200 && response.data) {
        commit("DELETE_SERIAL", serial_id);
        commit("SET_STATUS_REQUEST", { value: "success-delete-serial" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getForUpdateSerial: async ({ commit }, { serial_id }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-get-for-update-serial" });

      const response = await SerialService.getForUpdateSerial(serial_id);

      if (response.status === 200 && response.data) {
        commit("SET_FOR_UPDATE_SERIAL", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-get-for-update-serial"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getInventoryNumber: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-get-inventory-number" });

      const response = await SerialService.getInventoryNumber();

      if (response.status === 200 && response.data) {
        commit("SET_INVENTORY_NUMBER", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-get-inventory-number" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getSearchProductOptions: async ({ commit }, { search }) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-get-search-product-options"
      });

      const response = await SerialService.getSearchProductOptions(search);

      if (response.status === 200 && response.data) {
        commit("SET_SEARCH_PRODUCT_OPTIONS", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-get-search-product-options"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getSearchedSerials: async ({ commit }, { type, search }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-get-searched-serials" });

      const response = await SerialService.getSearchedSerials(type, search);

      if (response.status === 200 && response.data) {
        const searchedSerials = response.data.data;

        // Get selected serials
        if (searchedSerials && searchedSerials.length > 0) {
          await store.dispatch("SERIAL/getSelectedSerial", {
            serial_id: searchedSerials[0].id
          });
        }

        commit("SET_SEARCHED_SERIALS", searchedSerials);
        commit("SET_STATUS_REQUEST", { value: "success-get-searched-serials" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getSelectedSerial: async ({ commit }, { serial_id }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-get-selected_serial" });

      const response = await SerialService.getSelectedSerial(serial_id);

      if (response.status === 200 && response.data) {
        commit("SET_SELECTED_SERIAL", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-get-selected_serial" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getSerialBySerialNumber: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getSerialBySerialNumber"
      });

      const response = await SerialService.getSerialBySerialNumber(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_SERIAL_INFO", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getSerialBySerialNumber"
        });
      }
    } catch (e) {
      if (e.response && e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message:
            "Không tìm thấy serial hoặc xảy ra lỗi. Vui lòng kiểm tra lại dữ liệu đã nhập.",
          value: "error-getSerialBySerialNumber"
        });
      }
    }
  },

  getSerialsByOptionId: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSerialsByOptionId" });

      const response = await SerialService.getSerialsByOptionId(payload);

      if (response.status === 200 && response.data) {
        commit("SET_PRODUCT_OPTION_SERIALS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getSerialsByOptionId" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getStockSerialsByOptionId: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getStockSerialsByOptionId"
      });

      const response = await SerialService.getStockSerialByOptionId(payload);

      if (response.status === 200 && response.data) {
        commit("SET_PRODUCT_OPTION_STOCK_SERIALS", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getStockSerialsByOptionId"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  updateSerialNumber: async ({ commit }, { serial_id, new_serial_number }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-update-serial-number" });

      const response = await SerialService.updateSerialNumber(
        serial_id,
        new_serial_number
      );

      if (response.status === 200 && response.data) {
        commit("AFTER_UPDATE_SERIAL", { serial_id, new_serial_number });
        commit("SET_STATUS_REQUEST", { value: "success-update-serial-number" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  updateSKU: async ({ commit }, { serial_id, new_option_id }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-update-sku" });

      const response = await SerialService.updateSKU(serial_id, new_option_id);

      if (response.status === 200 && response.data) {
        await store.dispatch("SERIAL/getSelectedSerial", {
          serial_id: serial_id
        });
        await store.dispatch("SERIAL/getForUpdateSerial", {
          serial_id: serial_id
        });
        commit("AFTER_UPDATE_SKU");
        commit("SET_STATUS_REQUEST", { value: "success-update-sku" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
