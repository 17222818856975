<template>
  <v-snackbar
    :absolute="absolute"
    :data-toast="name"
    :bottom="bottom"
    :centered="centered"
    :color="color"
    :content-class="contentClass"
    :elevation="elevation"
    :height="height"
    :left="left"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :min-height="minHeight"
    :min-width="minWidth"
    :multi-line="multiLine"
    :outlined="outlined"
    :right="right"
    :rounded="rounded"
    :shaped="shaped"
    :tag="tag"
    :text="text"
    :tile="tile"
    :timeout="timeout"
    :top="top"
    :transition="transition"
    :vertical="vertical"
    :width="width"
    v-model="visible"
  >
    <slot :payload="payload"></slot>
  </v-snackbar>
</template>

<script>
import Toast from "@/core/plugins/toast";

export default {
  props: {
    absolute: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    contentClass: {
      type: String,
      default: undefined
    },
    elevation: {
      type: [Number, String]
    },
    height: {
      type: [Number, String]
    },
    left: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: [Number, String]
    },
    maxWidth: {
      type: [Number, String]
    },
    minHeight: {
      type: [Number, String]
    },
    minWidth: {
      type: [Number, String]
    },
    multiLine: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      require: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: [Number, String]
    },
    shaped: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: "div"
    },
    text: {
      type: Boolean,
      default: false
    },
    tile: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: [String, Number],
      default: "5000"
    },
    top: {
      type: Boolean,
      default: false
    },
    transition: {
      type: [Boolean, String],
      default: "v-snack-transition"
    },
    vertical: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String]
    }
  },
  data() {
    return {
      payload: {},
      visible: false
    };
  },
  methods: {
    hide() {
      this.visible = false;
      this.payload = {};
    },
    show(params) {
      this.visible = true;
      if (params) {
        this.payload = params.payload;
      }
    }
  },
  beforeMount() {
    // Show event
    Toast.EventBus.$on("show", params => {
      if (this.name === params.name) {
        this.show(params);
      }
    });

    // Hide event
    Toast.EventBus.$on("hide", params => {
      if (this.name === params.name) {
        this.hide();
      }
    });
  }
};
</script>
