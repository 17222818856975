import Vue from "vue";
import VueRouter from "vue-router";

import AccountRouter from "@/modules/Account/router";
import AuthenticationRouter from "@/modules/Authentication/router";
import DashboardRouter from "@/modules/Dashboard/router";
import EventsRouter from "@/modules/Events/routes";
import FundRouter from "@/modules/Fund/router";
import GoodsRouter from "@/modules/Goods/router";
import GuaranteeRouter from "@/modules/Guarantee/router";
import NotSoldRouter from "@/modules/NotSoldWarehouse/router";
import PartnerAndMemberRouter from "@/modules/PartnerAndMember/router";
import PolicyRouter from "@/modules/Policy/router";
import PrintRouter from "@/modules/Print/router";
import PromotionRouter from "@/modules/Promotion/router";
import ReportsRouter from "@/modules/Reports/router";
import SaleRouter from "@/modules/Sale/router";
import SystemRouter from "@/modules/System/router";
import TransactionRouter from "@/modules/Transaction/router";
import WebsiteRouter from "@/modules/Website/router";
import ReviewRouter from "@/modules/Review/router";

Vue.use(VueRouter);

const routes = [
  AccountRouter,
  AuthenticationRouter,
  DashboardRouter,
  EventsRouter,
  FundRouter,
  GoodsRouter,
  GuaranteeRouter,
  NotSoldRouter,
  PartnerAndMemberRouter,
  PolicyRouter,
  PrintRouter,
  PromotionRouter,
  ReportsRouter,
  SaleRouter,
  SystemRouter,
  TransactionRouter,
  WebsiteRouter,
  ReviewRouter,
  /**
   * Config default router
   */
  {
    path: "/",
    redirect: { name: "dashboard" }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/modules/Errors/pages/404")
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/modules/Errors/pages/500")
  },
  {
    path: "/utm-builder",
    name: "utm-builder",
    component: () => import("@/modules/UTMBuilder/page")
  },
  {
    path: "*",
    redirect: { name: "404" }
  }
];

const router = new VueRouter({
  routes
});

export default router;
