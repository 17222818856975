/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import PaymentTransactionService from "@/core/service/payment-transaction.service";
import { catchError } from "@/core/composables/useStore";

const state = {
  statusRequest: {
    message: null,
    value: null
  },
  paymentTransaction: {}
};

const getters = {
  statusRequest: state => state.statusRequest,
  paymentTransaction: state => state.paymentTransaction
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAYMENT_TRANSACTION: (state, payload) => {
    state.paymentTransaction = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  cancelPaymentTransaction: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-cancelPaymentTransaction"
      });

      const response = await PaymentTransactionService.cancelPaymentTransaction(
        payload.transactionId,
        payload.note
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-cancelPaymentTransaction"
        });
      }
    } catch (e) {
      catchError(e, commit, "cancelPaymentTransaction");
    }
  },

  createPaymentTransaction: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createPaymentTransaction"
      });

      const response = await PaymentTransactionService.createPaymentTransaction(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-createPaymentTransaction"
        });
      }
    } catch (e) {
      catchError(e, commit, "createPaymentTransaction");
    }
  },

  getPaymentTransactionById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getPaymentTransactionById"
      });

      const response = await PaymentTransactionService.getPaymentTransactionById(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_PAYMENT_TRANSACTION", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getPaymentTransactionById"
        });
      }
    } catch (e) {
      catchError(e, commit, "getPaymentTransactionById");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
