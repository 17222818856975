import router from "@/core/router";
import DashboardFilters from "../service/filters.api";

const state = {
  status: 0,
  isArriving: [],
  livedLong: [],
  justOrders: [],
  manyInventory: []
};
const getters = {
  status: state => state.status,
  isArriving: state => state.isArriving,
  manyInventory: state => state.manyInventory,
  justOrders: state => state.justOrders,
  livedLong: state => state.livedLong
};
const mutations = {
  SET_STATE_VALUE: (state, payload) => {
    state[payload.key] = payload.value;
  }
};
const actions = {
  getArriving: async ({ commit }, payload) => {
    try {
      commit("SET_STATE_VALUE", {
        key: "status",
        value: 0
      });
      const dataIsArriving = await DashboardFilters.getArriving(payload);
      if (dataIsArriving.status === 200 && dataIsArriving.data) {
        commit("SET_STATE_VALUE", {
          key: "isArriving",
          value: dataIsArriving.data
        });
        commit("SET_STATE_VALUE", {
          key: "status",
          value: 2
        });
      }
    } catch (e) {
      if (e && e.response && e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATE_VALUE", {
          key: "status",
          value: 2
        });
      }
    }
  },
  getManyInventory: async ({ commit }, payload) => {
    try {
      commit("SET_STATE_VALUE", {
        key: "status",
        value: 0
      });
      const dataManyInventory = await DashboardFilters.getManyInventory(
        payload
      );
      if (dataManyInventory.status === 200 && dataManyInventory.data) {
        commit("SET_STATE_VALUE", {
          key: "manyInventory",
          value: dataManyInventory.data
        });
        commit("SET_STATE_VALUE", {
          key: "status",
          value: 2
        });
      }
    } catch (e) {
      if (e && e.response && e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATE_VALUE", {
          key: "status",
          value: 2
        });
      }
    }
  },
  getLivedLong: async ({ commit }, payload) => {
    try {
      commit("SET_STATE_VALUE", {
        key: "status",
        value: 0
      });
      const dataLivedLong = await DashboardFilters.getLivedLong(payload);
      if (dataLivedLong.status === 200 && dataLivedLong.data) {
        commit("SET_STATE_VALUE", {
          key: "livedLong",
          value: dataLivedLong.data
        });
        commit("SET_STATE_VALUE", {
          key: "status",
          value: 2
        });
      }
    } catch (e) {
      if (e && e.response && e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATE_VALUE", {
          key: "status",
          value: 2
        });
      }
    }
  },
  getJustOrders: async ({ commit }, payload) => {
    try {
      commit("SET_STATE_VALUE", {
        key: "status",
        value: 0
      });
      const dataJustOrder = await DashboardFilters.getJustOrders(payload);
      if (dataJustOrder.status === 200 && dataJustOrder.data) {
        commit("SET_STATE_VALUE", {
          key: "justOrders",
          value: dataJustOrder.data
        });
        commit("SET_STATE_VALUE", {
          key: "status",
          value: 2
        });
      }
    } catch (e) {
      if (e && e.response && e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATE_VALUE", {
          key: "status",
          value: 2
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
