/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import GuaranteePackageService from "../service/guarantee-package.service";
import router from "@/core/router";
import store from "@/core/store";
import Vue from "vue";

const state = {
  allGuaranteePackages: [],
  guaranteePackage: {
    code: null,
    name: null,
    time: null,
    description: null,
    notes: null
  },
  guaranteePackages: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  allGuaranteePackages: state => state.allGuaranteePackages,
  guaranteePackage: state => state.guaranteePackage,
  guaranteePackages: state => state.guaranteePackages,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ALL_GUARANTEE_PACKAGES: (state, payload) => {
    state.allGuaranteePackages = payload;
  },

  SET_GUARANTEE_PACKAGE: (state, payload) => {
    state.guaranteePackage = payload;
  },

  SET_GUARANTEE_PACKAGES: (state, payload) => {
    state.guaranteePackages = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createGuaranteePackage: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createGuaranteePackage" });

      const response = await GuaranteePackageService.createGuaranteePackage(
        payload.data
      );

      if (response.status === 201 && response.data.data) {
        // Set id
        Vue.set(state.guaranteePackage, "id", response.data.data.id);
        // Get guarantee packages
        await store.dispatch(
          "GUARANTEE_PACKAGE/getGuaranteePackages",
          payload.routeQuery
        );

        commit("SET_STATUS_REQUEST", {
          value: "success-createGuaranteePackage"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createGuaranteePackage"
        });
      }
    }
  },

  deleteGuaranteePackage: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteGuaranteePackage" });

      const response = await GuaranteePackageService.deleteGuaranteePackage(
        payload.data
      );

      if (response.status === 200 && response.data) {
        // Get Guarantee Packages
        await store.dispatch(
          "GUARANTEE_PACKAGE/getGuaranteePackages",
          payload.routeQuery
        );

        commit("SET_STATUS_REQUEST", {
          value: "success-deleteGuaranteePackage"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-deleteGuaranteePackage"
        });
      }
    }
  },

  getAllGuaranteePackages: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getAllGuaranteePackages"
      });

      const response = await GuaranteePackageService.getAllGuaranteePackages();

      if (response.status === 200 && response.data) {
        commit("SET_ALL_GUARANTEE_PACKAGES", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getAllGuaranteePackages"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getAllGuaranteePackages"
        });
      }
    }
  },

  getGuaranteePackages: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getGuaranteePackages" });

      const response = await GuaranteePackageService.getGuaranteePackages(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_GUARANTEE_PACKAGES", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getGuaranteePackages" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getGuaranteePackages"
        });
      }
    }
  },

  resetGuaranteePackage: async ({ commit }) => {
    commit("SET_GUARANTEE_PACKAGE", {
      code: null,
      name: null,
      time: null,
      description: null,
      notes: null
    });
  },

  setGuaranteePackage: async ({ commit }, payload) => {
    commit("SET_GUARANTEE_PACKAGE", payload);
  },

  updateGuaranteePackage: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateGuaranteePackage" });

      const response = await GuaranteePackageService.updateGuaranteePackage(
        payload.data
      );

      if (response.status === 200 && response.data) {
        // Get Guarantee Packages
        await store.dispatch(
          "GUARANTEE_PACKAGE/getGuaranteePackages",
          payload.routeQuery
        );

        commit("SET_STATUS_REQUEST", {
          value: "success-updateGuaranteePackage"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateGuaranteePackage"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
