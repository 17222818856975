export const HEADERS_POST_CATEGORIES_TABLE = [
  {
    text: "Tên folder",
    align: "start",
    sortable: false,
    value: "name"
  },
  {
    text: "URL",
    align: "start",
    sortable: false,
    value: "slug"
  },
  {
    text: "Số bài viết",
    align: "start",
    sortable: false,
    value: "count"
  },
  {
    text: "Hành động",
    align: "center",
    sortable: false,
    value: "action"
  }
];

export const INIT_POST_VALUE = {
  title: null,
  description: null,
  content: null,
  cover: null,
  cover_mobile: null,
  folder_id: null,
  folder_on_ids: [],
  seo_data: {
    title: null,
    description: null,
    tags: []
  },
  status: 0,
  priority: null,
  published_at: new Date().toISOString(),
  thumbnail: null,
  tags: [],
  type: null,
  widgets: {
    sku_ids: [],
    rating: 0
  },
  faqs: [],
  is_qna: false
};

export const POST_TYPE = [
  { name: "Tin tức", value: 1 },
  { name: "Đánh giá", value: 2 },
  { name: "Kinh nghiệm chọn sản phẩm", value: 3 },
  { name: "Mô tả sản phẩm", value: 4 },
  { name: "Khác", value: 5 }
];
