/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import RoleService from "../service/role.service";
import router from "@/core/router";
import store from "@/core/store";

const state = {
  permissions: [],
  role: {
    name: null,
    permissions: []
  },
  roles: [],
  statusRequest: {
    message: null,
    value: null
  },
  rolesName: [],
  invoices: []
};

const getters = {
  permissions: state => state.permissions,
  role: state => state.role,
  roles: state => state.roles,
  statusRequest: state => state.statusRequest,
  invoices: state => state.invoices,
  rolesName: state => state.rolesName
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PERMISSIONS: (state, payload) => {
    state.permissions = payload;
  },

  SET_ROLE: (state, payload) => {
    state.role = payload;
  },

  SET_ROLES: (state, payload) => {
    state.roles = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_STATE_VALUE: (state, payload) => {
    state[payload.key] = payload.value;
  }
};

const actions = {
  createRole: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createRole" });

      const response = await RoleService.createRole(payload);

      if (response.status === 200) {
        // Get roles
        await store.dispatch("ROLE/getRoles");
        commit("SET_ROLE", state.roles[state.roles.length - 1]);
        commit("SET_STATUS_REQUEST", { value: "success-createRole" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  deleteRole: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteRole" });

      const response = await RoleService.deleteRole(payload);

      if (response.status === 200) {
        commit("SET_ROLE", {
          name: null,
          permissions: []
        });
        // Get roles
        await store.dispatch("ROLE/getRoles");
        commit("SET_STATUS_REQUEST", { value: "success-deleteRole" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getPermissions: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPermissions" });

      const response = await RoleService.getPermissions();

      if (response.status === 200 && response.data) {
        const permissions = [];
        // Loop and convert data
        for (const [i] of Object.entries(response.data)) {
          const parent = response.data[i];
          const item = {
            id: i,
            name: parent.name,
            children: []
          };
          // Loop and convert permission property
          for (const [k] of Object.entries(parent.permission)) {
            item.children.push({
              id: `${i}.${k}`,
              name: parent.permission[k]
            });
          }
          permissions.push(item);
        }
        // Set permissions data
        commit("SET_PERMISSIONS", permissions);
        commit("SET_STATUS_REQUEST", { value: "success-getPermissions" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getRoles: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getRoles" });

      const response = await RoleService.getRoles();

      if (response.status === 200 && response.data.data) {
        commit("SET_ROLES", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getRoles" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getRolesInvoices: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getRoles" });

      const response = await RoleService.getRolesInvoices();

      if (response.status === 200 && response.data.data) {
        const roles = response.data.data.roles;
        let roleName = [];
        roles.map(role =>
          roleName.push({
            text: role.name,
            value: role.code
          })
        );
        commit("SET_STATE_VALUE", {
          key: "rolesName",
          value: roleName
        });
        commit("SET_STATE_VALUE", {
          key: "invoices",
          value: response.data.data.invoices
        });
        commit("SET_STATUS_REQUEST", { value: "success-getRoles" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  resetRole: ({ commit }) => {
    commit("SET_ROLE", {
      name: null,
      permissions: []
    });
  },

  setRole: ({ commit }, payload) => {
    commit("SET_ROLE", payload);
  },

  updateRole: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateRole" });

      const response = await RoleService.updateRole(
        payload.role.id,
        payload.role
      );

      if (response.status === 200) {
        // Get roles
        await store.dispatch("ROLE/getRoles");
        commit("SET_ROLE", state.roles[payload.roleIndex]);
        commit("SET_STATUS_REQUEST", { value: "success-updateRole" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
