import Api from "@/core/apis";

export default {
  createPaymentAccount(data) {
    return Api().post("bank-accounts", data);
  },

  deletePaymentAccount(id) {
    return Api().delete(`bank-accounts/${id}`);
  },

  getPaymentAccounts() {
    return Api().get("bank-accounts");
  },

  updatePaymentAccount(data) {
    return Api().put("bank-accounts", data);
  },
  getPaymentAccountsMethod() {
    return Api().get("bank-accounts/methods");
  }
};
