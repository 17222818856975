import Api from "@/core/apis";

export default {
  approveBrowsingService(data) {
    return Api().put("invoice-participant/approve", {
      ids: [data]
    });
  },

  exportReportFile(data) {
    return Api().post("export-report/take-part-in", data, {
      responseType: "blob"
    });
  },

  getBrowsingService(id) {
    return Api().get(`invoice-participant/${id}`);
  },

  getBrowsingServices(data) {
    return Api().post("invoice-participant/list", data);
  },

  updateBrowser(data) {
    return Api().put("invoice-participant", data);
  },

  rejectBrowsingService(data) {
    return Api().put("invoice-participant/reject", {
      ids: [data]
    });
  },

  getModelIdFromModelName(data) {
    return Api().get(`/invoice-participant/model/list?model_name=${data}`);
  },

  createBrowsingService(data) {
    return Api().post("invoice-participant", data);
  }
};
