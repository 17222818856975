/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */
import { catchError } from "@/core/composables";
import MediaService from "@/core/service/media.service";
import store from "@/core/store";
import { INIT_PRODUCT_TYPE_VALUE } from "@/modules/Goods/constants";
import { arrayToTree } from "performant-array-to-tree";
import Vue from "vue";
import ProductTypeService from "../service/product-type.service";
import { removeEmptyChildrenProperty } from "@/core/utils";

const state = {
  allProductTypes: [],
  attributes: [],
  attributeTemplate: [],
  categories: [],
  categoryTrees: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  productType: JSON.parse(JSON.stringify(INIT_PRODUCT_TYPE_VALUE)),
  productTypes: [],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedThumbnail: null
};

const getters = {
  allProductTypes: state => state.allProductTypes,
  attributes: state => state.attributes,
  attributeTemplate: state => state.attributeTemplate,
  categories: state => state.categories,
  categoryTrees: state => state.categoryTrees,
  paginationInfo: state => state.paginationInfo,
  productType: state => state.productType,
  productTypes: state => state.productTypes,
  statusRequest: state => state.statusRequest,
  uploadedThumbnail: state => state.uploadedThumbnail
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ALL_PRODUCT_TYPES: (state, payload) => {
    state.allProductTypes = payload;
  },

  SET_ATTRIBUTES: (state, payload) => {
    state.attributes = payload;
  },

  SET_ATTRIBUTE_TEMPLATE: (state, payload) => {
    state.attributeTemplate = payload;
  },

  SET_CATEGORIES: (state, payload) => {
    state.categories = payload;
  },

  SET_CATEGORY_TREES: (state, payload) => {
    state.categoryTrees = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_PRODUCT_TYPE: (state, payload) => {
    state.productType = payload;
  },

  SET_PRODUCT_TYPES: (state, payload) => {
    state.productTypes = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_THUMBNAIL: (state, payload) => {
    state.uploadedThumbnail = payload;
  }
};

const actions = {
  createProductType: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createProductType" });

      const response = await ProductTypeService.createProductType(payload.data);

      if (response.status === 200 && response.data) {
        await store.dispatch(
          "PRODUCT_TYPE/getProductTypes",
          payload.routeQuery
        );

        // Get product types
        await store.dispatch("PRODUCT_TYPE/getAllProductTypes");

        await store.dispatch("PRODUCT_TYPE/getTreeProductTypes");
        // Set id
        Vue.set(state.productType, "id", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-createProductType" });
      }
    } catch (e) {
      catchError(e, commit, "createProductType");
    }
  },

  deleteProductTypes: async ({ dispatch, commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteProductTypes" });

      const response = await ProductTypeService.deleteProductTypes(
        payload.data
      );

      if (response.status === 200) {
        // Get product types
        await store.dispatch(
          "PRODUCT_TYPE/getProductTypes",
          payload.routeQuery
        );
        await dispatch("resetProductType");
        commit("SET_STATUS_REQUEST", { value: "success-deleteProductTypes" });
      }
    } catch (e) {
      catchError(e, commit, "deleteProductTypes");
    }
  },

  getAllProductTypes: async ({ commit }, data) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllProductTypes" });

      const response = await ProductTypeService.getAllProductTypes(data);

      if (response.status === 200 && response.data) {
        commit("SET_ALL_PRODUCT_TYPES", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAllProductTypes" });

        const categoryTrees = arrayToTree(response.data, {
          dataField: null,
          parentId: "parent_id"
        });

        commit("SET_CATEGORIES", response.data);

        commit(
          "SET_CATEGORY_TREES",
          removeEmptyChildrenProperty(categoryTrees, "children")
        );
      }
    } catch (e) {
      catchError(e, commit, "getAllProductTypes");
    }
  },

  getAttributesByCategoryId: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getAttributesByCategoryId"
      });

      const response = await ProductTypeService.getAttributesByCategoryId(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_ATTRIBUTES", response.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getAttributesByCategoryId"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        catchError(e, commit, "getAttributesByCategoryId");
      }
    }
  },

  getAttributeTemplateByCategoryId: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getAttributeTemplateByCategoryId"
      });

      const response = await ProductTypeService.getAttributeTemplateByCategoryId(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_ATTRIBUTE_TEMPLATE", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getAttributeTemplateByCategoryId"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        catchError(e, commit, "getAttributeTemplateByCategoryId");
      }
    }
  },

  sortProductTypes: async ({ commit }, data) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-sortProductTypes" });

      const response = await ProductTypeService.sortProductTypes(data);

      if (response.status === 200 && response.data) {
        await store.dispatch("PRODUCT_TYPE/getAllProductTypes");

        commit("SET_STATUS_REQUEST", { value: "success-sortProductTypes" });
      }
    } catch (e) {
      catchError(e, commit, "sortProductTypes");
    }
  },

  getTreeProductTypes: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllProductTypes" });

      const response = await ProductTypeService.getAllProductTypes();

      if (response.status === 200 && response.data) {
        const categoryTrees = arrayToTree(response.data, {
          dataField: null,
          parentId: "parent_id"
        });

        removeEmptyChildrenProperty(categoryTrees);

        commit("SET_CATEGORY_TREES", categoryTrees);
        commit("SET_STATUS_REQUEST", { value: "success-getTreeProductTypes" });
      }
    } catch (e) {
      catchError(e, commit, "getTreeProductTypes");
    }
  },

  getProductTypes: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getProductTypes" });

      const response = await ProductTypeService.getProductTypes(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_PRODUCT_TYPES", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getProductTypes" });
      }
    } catch (e) {
      catchError(e, commit, "getProductTypes");
    }
  },

  getProductTypeById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getProductTypeById" });

      const response = await ProductTypeService.getProductTypeById(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_PRODUCT_TYPE", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getProductTypeById" });
      }
    } catch (e) {
      catchError(e, commit, "getProductTypeById");
    }
  },

  resetProductType: ({ commit }) => {
    commit(
      "SET_PRODUCT_TYPE",
      JSON.parse(JSON.stringify(INIT_PRODUCT_TYPE_VALUE))
    );
  },

  updateAttributeTemplate: async ({ commit }, data) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updateAttributeTemplate"
      });

      const response = await ProductTypeService.updateAttributeTemplate(
        data.categoryId,
        data.payload
      );

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", {
          value: "success-updateAttributeTemplate"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateAttributeTemplate");
    }
  },

  updateProductType: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateProductType" });

      const response = await ProductTypeService.updateProductType(payload.data);

      if (response.status === 200) {
        // Get product types
        await store.dispatch(
          "PRODUCT_TYPE/getProductTypes",
          payload.routeQuery
        );

        await store.dispatch("PRODUCT_TYPE/getAllProductTypes");

        await store.dispatch("PRODUCT_TYPE/getTreeProductTypes");

        commit("SET_STATUS_REQUEST", { value: "success-updateProductType" });
      }
    } catch (e) {
      catchError(e, commit, "updateProductType");
    }
  },

  uploadThumbnail: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadThumbnail" });

      // Append folder
      payload.append("folder", "core/categories");

      const response = await MediaService.uploadImage(payload);

      if (response.status === 200) {
        await commit("SET_UPLOADED_THUMBNAIL", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadThumbnail" });
      }
    } catch (e) {
      catchError(e, commit, "uploadThumbnail");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
