import {
  regexEmail,
  regexMaxForm,
  regexMaxValForm,
  regexMinForm,
  regexMinValForm,
  regexNumberString,
  regexPhone,
  regexUrl
} from "@/core/utils/regex";

import { parseToNumber } from "@/core/utils";

export default {
  props: {
    validate: {
      type: [String, Object],
      default: ""
    },
    value: {}
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    rules() {
      const rules = [];
      const validateTypes = [
        "required",
        "email",
        "tel",
        "number",
        "min",
        "max",
        "minVal",
        "maxVal",
        "url"
      ];

      validateTypes.forEach(type => {
        if (type in this.validator) {
          rules.push(this[type]);
        }
      });

      if ("rules" in this.validator) {
        rules.push(...this.validator.rules);
      }

      return rules;
    },

    validator() {
      if (typeof this.validate === "string") {
        const validate = {};

        this.validate.split("|").forEach(value => {
          if (["required", "email", "tel", "number", "url"].includes(value)) {
            validate[value] = true;
          }

          if (regexMinForm(value)) {
            validate.min = value.replace("min:", "");
          }

          if (regexMaxForm(value)) {
            validate.max = value.replace("max:", "");
          }

          if (regexMinValForm(value)) {
            validate.minVal = value.replace("minVal:", "");
          }

          if (regexMaxValForm(value)) {
            validate.maxVal = value.replace("maxVal:", "");
          }
        });

        return validate;
      }

      return this.validate;
    }
  },

  methods: {
    required(value) {
      return !!value || "Không được bỏ trống.";
    },

    email(value) {
      return (
        !value ||
        (typeof value === "string" &&
          regexEmail(String(value).toLowerCase()) &&
          value.length < 255) ||
        "Không phải email."
      );
    },

    tel(value) {
      return (
        !value ||
        regexPhone(String(value).toLowerCase()) ||
        "Không phải số điện thoại."
      );
    },

    number(value) {
      return !value || regexNumberString(value) || "Các ký tự phải là chữ số.";
    },

    min(value) {
      const min = this.validator.min;

      if (Array.isArray(value)) {
        return (
          !value ||
          value.length >= Number(min) ||
          `Tối thiểu là ${min} giá trị.`
        );
      }

      return (
        !value ||
        String(value).length >= Number(min) ||
        `Độ dài tối thiểu là ${min} ký tự.`
      );
    },

    max(value) {
      const max = this.validator.max;

      if (Array.isArray(value)) {
        return (
          !value || value.length <= Number(max) || `Tối đa là ${max} giá trị.`
        );
      }

      return (
        !value ||
        String(value).length <= Number(max) ||
        `Độ dài tối đa là ${max} ký tự.`
      );
    },

    minVal(value) {
      const minVal = this.validator.minVal;

      return (
        !value ||
        parseToNumber(value) >= parseToNumber(minVal) ||
        `Giá trị tối thiểu là ${minVal}.`
      );
    },

    maxVal(value) {
      const maxVal = this.validator.maxVal;

      return (
        !value ||
        parseToNumber(value) <= parseToNumber(maxVal) ||
        `Giá trị tối đa là ${maxVal}.`
      );
    },

    url(value) {
      return (
        !value ||
        regexUrl(value.toLowerCase()) ||
        "Không phải đường dẫn URL hợp lệ."
      );
    },

    parseToNumber(value) {
      return parseToNumber(value);
    }
  }
};
