import Api from "@/core/apis";

export default {
  createPostCategory(data) {
    return Api().post("/folder/store", data);
  },

  deletePostCategories(data) {
    return Api().post("/folder/destroy-multiple", data);
  },

  getAllPostCategories(data) {
    return Api().post(`/folder/all${data ? `?search=${data}` : ""}`);
  },

  getPostCategories(data) {
    return Api().post("/folder/list", data);
  },

  updatePostCategory({ id, form }) {
    return Api().post(`/folder/${id}/update`, form);
  },

  getPostCategoriesByTreeList() {
    return Api().get("/folder");
  },

  getPostCategoryById(id) {
    return Api().get(`folder/detail/${id}`);
  },

  sortPostCategories(data) {
    return Api().post(`/folder/sort`, { data });
  }
};
