import Api from "@/core/apis";

export default {
  createCustomerGroup(data) {
    return Api().post("customer/group/add", data);
  },

  deleteCustomerGroups(data) {
    return Api().post("customer/group/delete", data);
  },

  getAllCustomerGroups() {
    return Api().get("customer/group/getAll");
  },

  getCustomerGroupById(id) {
    return Api().get(`customer/group/detail/${id}`);
  },

  getCustomerGroupConditions() {
    return Api().get("customer/group/listDKs");
  },

  getCustomerGroups(data) {
    return Api().post("customer/group/all", data);
  },

  updateCustomerGroup(data) {
    return Api().post("customer/group/edit", data);
  }
};
