export const HEADERS_SALE_ORDER_TABLE = [
  {
    text: "STT",
    align: "start",
    sortable: false,
    value: "id"
  },
  {
    text: "Tên sản phẩm",
    align: "start",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Số lượng đặt",
    align: "start",
    sortable: false,
    value: "qty"
  },
  {
    text: "Đơn giá",
    align: "start",
    sortable: false,
    value: "unit_price"
  },
  {
    text: "Chiết khấu",
    align: "start",
    sortable: false,
    value: "rebate_amount"
  },
  {
    text: "Giá sau giảm",
    align: "start",
    sortable: false,
    value: "sale_price"
  },
  {
    text: "Thành tiền",
    align: "start",
    sortable: false,
    value: "total"
  },
  {
    text: "",
    align: "start",
    sortable: false,
    value: "action"
  }
];

export const HEADERS_SALE_ORDER_HISTORY = [
  {
    text: "Mã hóa đơn",
    align: "start",
    sortable: false,
    value: "code"
  },
  {
    text: "Ngày giờ",
    align: "start",
    sortable: false,
    value: "time"
  },
  {
    text: "Khách hàng",
    align: "start",
    sortable: false,
    value: "customer"
  },
  {
    text: "Người chốt đơn",
    align: "start",
    sortable: false,
    value: "employee"
  },
  {
    text: "Chi nhánh",
    align: "start",
    sortable: false,
    value: "branches"
  },
  {
    text: "Tình trạng",
    align: "start",
    sortable: false,
    value: "status"
  },
  {
    text: "Lý do hủy",
    align: "start",
    sortable: false,
    value: "cancel_reason"
  }
];

export const ORDER_STATUS = {
  CHO_XAC_NHAN: 1,
  DA_XAC_NHAN: 2,
  DANG_XU_LY: 3,
  DA_HOAN_THANH: 4, // đơn hoàn thành, thành công
  DANG_TRA_HANG: 5,
  DA_TRA_HANG: 6
};
