const TransactionRouter = {
  path: "/transaction",
  component: () => import("@/modules/Transaction"),
  redirect: { name: "transaction_sale-receipts" },
  children: [
    {
      path: "sale-receipts",
      name: "transaction_sale-receipts",
      meta: {
        private: true,
        layout: "general",
        title: "Hoá đơn bán | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Hoá đơn bán",
            to: { name: "transaction_sale-receipts" }
          }
        ]
      },
      component: () => import("../pages/SaleReceipts")
    },
    {
      path: "browsing-services",
      name: "transaction_browsing-services",
      meta: {
        private: true,
        layout: "general",
        title: "Duyệt phục vụ | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Duyệt phục vụ",
            to: { name: "transaction_browsing-services" }
          }
        ]
      },
      component: () => import("../pages/BrowsingServices")
    },
    {
      path: "orders",
      name: "transaction_orders",
      meta: {
        private: true,
        layout: "general",
        title: "Đặt hàng | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Đặt hàng",
            to: { name: "transaction_orders" }
          }
        ]
      },
      component: () => import("../pages/Orders")
    },
    {
      path: "goods-receipts",
      name: "transaction_goods-receipts",
      redirect: { name: "transaction_goods-receipts_purchase-orders" },
      component: () => import("../pages/GoodsReceipts"),
      children: [
        {
          path: "purchase-orders",
          name: "transaction_goods-receipts_purchase-orders",
          meta: {
            private: true,
            layout: "general",
            title: "Đơn đặt NCC | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Nhập hàng",
                to: { name: "transaction_goods-receipts_purchase-orders" }
              },
              {
                disabled: true,
                exact: true,
                text: "Đơn đặt NCC",
                to: { name: "transaction_goods-receipts_purchase-orders" }
              }
            ]
          },
          component: () =>
            import("../pages/GoodsReceipts/PurchaseOrders/Listing")
        },
        {
          path: "purchase-orders/create",
          name: "transaction_goods-receipts_purchase-orders-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới đơn đặt NCC | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Nhập hàng",
                to: { name: "transaction_goods-receipts_purchase-orders" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới đơn đặt NCC",
                to: {
                  name: "transaction_goods-receipts_purchase-orders-create"
                }
              }
            ]
          },
          component: () =>
            import("../pages/GoodsReceipts/PurchaseOrders/Detail")
        },
        {
          path: "purchase-orders/detail/:purchaseOrderId",
          name: "transaction_goods-receipts_purchase-orders-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Cập nhật đơn đặt NCC | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Nhập hàng",
                to: { name: "transaction_goods-receipts_purchase-orders" }
              },
              {
                disabled: true,
                exact: true,
                text: "Cập nhật đơn đặt NCC",
                to: {
                  name: "transaction_goods-receipts_purchase-orders-detail"
                }
              }
            ]
          },
          component: () =>
            import("../pages/GoodsReceipts/PurchaseOrders/Detail")
        },
        {
          path: "notes",
          name: "transaction_goods-receipts_notes",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu nhập hàng | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Nhập hàng",
                to: { name: "transaction_goods-receipts_purchase-orders" }
              },
              {
                disabled: true,
                exact: true,
                text: "Phiếu nhập hàng",
                to: { name: "transaction_goods-receipts_notes" }
              }
            ]
          },
          component: () =>
            import("../pages/GoodsReceipts/GoodsReceiptNotes/Listing")
        },
        {
          path: "notes/create",
          name: "transaction_goods-receipts_notes-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới phiếu nhập | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Nhập hàng",
                to: { name: "transaction_goods-receipts_purchase-orders" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu nhập hàng",
                to: { name: "transaction_goods-receipts_notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới phiếu nhập",
                to: { name: "transaction_goods-receipts_notes-create" }
              }
            ]
          },
          component: () =>
            import("../pages/GoodsReceipts/GoodsReceiptNotes/Detail")
        },
        {
          path: "notes/detail/:goodsReceiptId",
          name: "transaction_goods-receipts_notes-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Cập nhật phiếu nhập | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Nhập hàng",
                to: { name: "transaction_goods-receipts_purchase-orders" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu nhập hàng",
                to: { name: "transaction_goods-receipts_notes" }
              },
              {
                disabled: true,
                exact: true,
                text: "Cập nhật phiếu nhập",
                to: { name: "transaction_goods-receipts_notes-detail" }
              }
            ]
          },
          component: () =>
            import("../pages/GoodsReceipts/GoodsReceiptNotes/Detail")
        },
        {
          path: "rebates",
          name: "transaction_goods-receipts_rebates",
          meta: {
            private: true,
            layout: "general",
            title: "Chiết khấu thương mại | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Nhập hàng",
                to: { name: "transaction_goods-receipts_purchase-orders" }
              },
              {
                disabled: true,
                exact: true,
                text: "Chiết khấu thương mại",
                to: { name: "transaction_goods-receipts_rebates" }
              }
            ]
          },
          component: () => import("../pages/GoodsReceipts/Rebates")
        }
      ]
    },
    {
      path: "goods-deliveries",
      component: () => import("../pages/GoodsDeliveries"),
      children: [
        {
          path: "list",
          name: "transaction_goods-deliveries",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu chuyển hàng | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Phiếu chuyển hàng",
                to: { name: "transaction_goods-deliveries" }
              }
            ]
          },
          component: () => import("../pages/GoodsDeliveries/Listing")
        },
        {
          path: "create",
          name: "transaction_goods-deliveries-create",
          meta: {
            private: true,
            layout: "general",
            title: "Chuyển hàng | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Chuyển hàng",
                to: { name: "transaction_goods-deliveries-create" }
              }
            ]
          },
          component: () => import("../pages/GoodsDeliveries/Detail")
        }
      ]
    },
    {
      path: "customer-return",
      name: "transaction_customer-return",
      meta: {
        private: true,
        layout: "general",
        title: "Khách trả hàng | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Khách trả hàng",
            to: { name: "transaction_customer-return" }
          }
        ]
      },
      component: () => import("../pages/CustomerReturn")
    },
    {
      path: "supplier-return",
      component: () => import("../pages/SupplierReturn"),
      children: [
        {
          path: "list",
          name: "transaction_supplier-return",
          meta: {
            private: true,
            layout: "general",
            title: "Trả hàng NCC | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Trả hàng NCC",
                to: { name: "transaction_supplier-return" }
              }
            ]
          },
          component: () => import("../pages/SupplierReturn/Listing")
        },
        {
          path: "create",
          name: "transaction_supplier-return-create",
          meta: {
            private: true,
            layout: "general",
            title: "Trả hàng NCC | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Trả hàng NCC",
                to: { name: "transaction_supplier-return-create" }
              }
            ]
          },
          component: () => import("../pages/SupplierReturn/Detail")
        }
      ]
    },
    {
      path: "goods-destruction",
      name: "transaction_goods-destruction",
      meta: {
        private: true,
        layout: "general",
        title: "Hủy hàng | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Hủy hàng",
            to: { name: "transaction_goods-destruction" }
          }
        ]
      },
      component: () => import("../pages/GoodsDestruction")
    }
  ]
};

export default TransactionRouter;
