import Api from "@/core/apis";

export default {
  deleteSerial(serial_id) {
    return Api().get(`/serial/serials/delete/${serial_id}`);
  },

  getForUpdateSerial(serial_id) {
    return Api().get(`/serial/getDetailForChange/${serial_id}`);
  },

  getInventoryNumber() {
    return Api().get("/serial/tonkho/soluong");
  },

  getSearchProductOptions(search) {
    return Api().post("/option/search", { search });
  },

  getSearchedSerials(type, search) {
    return Api().post("/serial/search/forPick", { type, search });
  },

  getSelectedSerial(serial_id) {
    return Api().get(`/serial/serials/detail/${serial_id}`);
  },

  getSerialBySerialNumber(serialNumber) {
    return Api().get(`/serials/${serialNumber}`);
  },

  getSerialsByOptionId(optionId) {
    return Api().get(`/serial/serials/getByOptionId/${optionId}`);
  },

  getStockSerialByOptionId(optionId) {
    return Api().get(`/serial/getStockSerialsByOptionId/${optionId}`);
  },

  updateSerialNumber(serial_id, new_serial_number) {
    return Api().post("/serial/changeSerialInfo", {
      serial_id,
      new_serial_number
    });
  },

  updateSKU(serial_id, new_option_id) {
    return Api().post("/serial/changeSerialInfo", { serial_id, new_option_id });
  }
};
