import Api from "@/core/apis";

export default {
  createCustomerRank(data) {
    return Api().post("customer/rank/add", data);
  },

  deleteCustomerRank(id) {
    return Api().post("customer/rank/delete", {
      ids: [id]
    });
  },

  getCustomerRankById(id) {
    return Api().get(`customer/rank/detail/${id}`);
  },

  getCustomerRankConditions() {
    return Api().get("customer/rank/listDKs");
  },

  getCustomerRanks() {
    return Api().get("customer/rank/all");
  },

  updateCustomerRank(data) {
    return Api().post("customer/rank/edit", data);
  }
};
