import Api from "@/core/apis";

export default {
  getReturnSerials(data) {
    return Api().post("returnsForImport/all", data);
  },

  importReturnSerial(data) {
    return Api().post(`returnsForImport/import`, data);
  }
};
