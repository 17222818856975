/***
 * Nhóm các item trong 1 object array theo trường nào đó
 * @param array
 * @param property
 * @return array
 * @example const students: [ { class_id: 1, name: 'A' }, { class_id: 2, name: 'B' }, { class_id: 1, name: 'C' } ]
 * groupByProperties(students, 'class_id'
 * result: [ [{ class_id: 1, name: 'A' }, { class_id: 1, name: 'C' }], [{ class_id: 2, name: 'B' }] ]
 */
export const groupByProperty = (array, property) => {
  const res = array.reduce((objectsByKeyValue, obj) => {
    const key = obj[property];

    if (!objectsByKeyValue[key]) {
      objectsByKeyValue[key] = [];
    }

    objectsByKeyValue[key].push(obj);

    return objectsByKeyValue;
  }, {});

  return Object.values(res);
};
