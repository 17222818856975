/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import CustomerReturnService from "../service/customer-return.service";
import router from "@/core/router";
import { catchError } from "@/core/composables";
// import store from "@/core/store";
// import Vue from "vue";

// Group Array of Objects by Keys or Property Values
const groupBy = keys => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map(key => obj[key]).join("-");
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, []);

const state = {
  customerReturn: {
    code: null,
    khachhang_id: null,
    branch_id: null,
    value: null,
    phitrahang: 0,
    note: null,
    options: [],
    serials: []
  },
  customerReturnListTotals: {
    total_fee: 0,
    total_value: 0,
    total_goods_value: 0
  },
  customerReturns: [],
  customerReturnPaymentTransactions: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  },
  totalFee: 0,
  totalValue: 0
};

const getters = {
  customerReturn: state => state.customerReturn,
  customerReturnListTotals: state => state.customerReturnListTotals,
  customerReturnPaymentTransactions: state =>
    state.customerReturnPaymentTransactions,
  customerReturns: state => state.customerReturns,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest,
  totalFee: state => state.totalFee,
  totalValue: state => state.totalValue
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_CUSTOMER_RETURN: (state, payload) => {
    state.customerReturn = payload;
  },

  SET_CUSTOMER_RETURN_LIST_TOTALS: (state, payload) => {
    state.customerReturnListTotals = payload;
  },

  SET_CUSTOMER_RETURN_PAYMENT_TRANSACTIONS: (state, payload) => {
    state.customerReturnPaymentTransactions = payload;
  },

  SET_CUSTOMER_RETURNS: (state, payload) => {
    state.customerReturns = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_TOTAL_FEE: (state, payload) => {
    state.totalFee = payload;
  },
  SET_TOTAL_VALUE: (state, payload) => {
    state.totalValue = payload;
  }
};

const actions = {
  cancelCustomerReturn: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createCustomerReturn"
      });

      const response = await CustomerReturnService.cancelCustomerReturn(
        payload
      );

      if (response.status === 200 && response.data) {
        // TODO: KHANH - SET VALY WHEN CANCEL SUCCESS
        commit("SET_STATUS_REQUEST", {
          value: "success-createCustomerReturn"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createCustomerReturn"
        });
      }
    }
  },

  createCustomerReturn: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createCustomerReturn"
      });

      const response = await CustomerReturnService.createCustomerReturn(
        payload
      );

      if (response.status === 200 && response.data) {
        // Set id
        state.customerReturn.id = response.data;
        commit("SET_CUSTOMER_RETURN", state.customerReturn);

        commit("SET_STATUS_REQUEST", {
          value: "success-createCustomerReturn"
        });

        return response.data;
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createCustomerReturn"
        });
      }
    }
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await CustomerReturnService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportFile", { isRedirected: false });
    }
  },

  getCustomerReturnById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getCustomerReturnById"
      });

      const response = await CustomerReturnService.getCustomerReturnById(
        payload
      );

      if (response.status === 200 && response.data.data) {
        const customerReturn = response.data.data,
          returnedSerialsArr = customerReturn.serials;

        // Group by option_id value
        let returnedOptions = groupBy(["option_id"])(returnedSerialsArr).filter(
          item => item !== null
        );

        // Map results
        returnedOptions = returnedOptions.map(option => {
          return {
            id: option[0].option_id,
            SKU: option[0].option_sku,
            name: option[0].option_name,
            product: {
              name: option[0].product_name
            },
            return_price: option[0].return_price,
            serials: option.map(item => item.serial_number)
          };
        });

        customerReturn.options = returnedOptions;

        // Handle sale receipt serials
        if (customerReturn.exportGood) {
          const soldSerialsArr = customerReturn.exportGood.serials;

          // Group by option_id value
          let soldOptions = groupBy(["option_id"])(soldSerialsArr).filter(
            item => item !== null
          );

          // Map results
          soldOptions = soldOptions.map(option => {
            return {
              id: option[0].option_id,
              SKU: option[0].option_sku,
              name: option[0].option_name,
              product: {
                name: option[0].product_name
              },
              unit_sold_price: Number(option[0].unit_sold_price),
              sold_price: Number(option[0].sold_price),
              serials: option.map(item => item.serial_number)
            };
          });

          customerReturn.exportGood.options = soldOptions;
        }

        commit("SET_CUSTOMER_RETURN", customerReturn);
        commit("SET_STATUS_REQUEST", {
          value: "success-getCustomerReturnById"
        });
      }
    } catch (e) {
      if (e.response && e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getCustomerReturnById"
        });
      }
    }
  },

  getCustomerReturnTransactions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getCustomerReturnTransactions"
      });

      const response = await CustomerReturnService.getCustomerReturnTransactions(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_CUSTOMER_RETURN_PAYMENT_TRANSACTIONS", response.data.data);
      }
    } catch (e) {
      commit("SET_STATUS_REQUEST", {
        message: e.response.data,
        value: "error-getCustomerReturnTransactions"
      });
    }
  },

  getCustomerReturns: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getCustomerReturns"
      });

      const response = await CustomerReturnService.getCustomerReturns(payload);

      if (response.status === 200 && response.data) {
        commit("SET_CUSTOMER_RETURNS", response.data.data);
        // Set pagination
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        // Set totals
        commit("SET_CUSTOMER_RETURN_LIST_TOTALS", {
          total_fee: response.data.meta.total_fee
            ? response.data.meta.total_fee
            : 0,
          total_value: response.data.meta.total_value
            ? response.data.meta.total_value
            : 0,
          total_goods_value: response.data.meta.total_goods_value
            ? response.data.meta.total_goods_value
            : 0
        });
        commit("SET_STATUS_REQUEST", {
          value: "success-getCustomerReturns"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getCustomerReturns"
        });
      }
    }
  },

  resetCustomerReturn: ({ commit }) => {
    commit("SET_CUSTOMER_RETURN", {
      branch_id: null,
      supplier_id: null,
      note: null,
      options: [],
      serials: []
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
