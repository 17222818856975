import { computed, nextTick, unref, watch } from "vue";
import { isArray, isNumeric, toArray } from "@thinkvn/utils";
import { useRoute, useRouter } from "./";
import { useLazyValue } from "./useLazyValue";

const defaultTransform = (value, defaultValue) => {
  if (isNumeric(value)) {
    value = Number(value);
  }

  if (isArray(defaultValue)) {
    value = toArray(value).map(item => (isNumeric(item) ? Number(item) : item));
  }

  return value;
};

export const useRouteQuery = (
  name,
  defaultValue,
  transform = defaultTransform
) => {
  const route = useRoute();
  const router = useRouter();

  return computed({
    get() {
      let data = route.query[name];

      return transform(data == null ? defaultValue : data, defaultValue);
    },
    set(value) {
      nextTick(() => {
        router
          .push({
            query: {
              ...route.query,
              [name]:
                value === defaultValue || value === null ? undefined : value
            }
          })
          .catch(() => {});
      });
    }
  });
};

export const useQueryValue = (modelValue, key, debounce = 0) => {
  const { lazyValue, internalValue } = useLazyValue(modelValue, debounce);

  const queryModel = useRouteQuery(unref(key));

  watch(internalValue, value => {
    if (!unref(key)) return;

    if (value && typeof value === "string") {
      return (queryModel.value = value);
    }

    if (typeof value === "number") {
      return (queryModel.value = String(value));
    }

    if (isArray(value) && value.length) {
      return (queryModel.value = value.join(","));
    }

    queryModel.value = undefined;
  });

  return { lazyValue, internalValue };
};
