import Api from "@/core/apis";

export default {
  createCompanyBankAccount(data) {
    return Api().post("account-bank/store", data);
  },

  createObjectBankAccount(data) {
    return Api().post("account-bank/store-to-object", data);
  },

  deleteCompanyBankAccount(id) {
    return Api().post("account-bank/delete", {
      account_bank_ids: [id]
    });
  },

  deleteObjectBankAccount(id, object) {
    return Api().post("account-bank/delete-of-object", {
      account_bank_ids: [id],
      object: {
        id: object.id,
        type: object.type
      }
    });
  },

  getAllBanks() {
    return Api().get("account-bank/index");
  },

  getCompanyBankAccounts() {
    return Api().get("account-bank/list");
  },

  getObjectBankAccounts(objectId, objectType) {
    return Api().post("account-bank/object-list", {
      object: {
        id: objectId,
        type: objectType
      }
    });
  },

  setDefaultThinkBankAccountForObject(bankAccountId, object) {
    return Api().post("account-bank/set-default-to-object", {
      account_bank_id: bankAccountId,
      object: {
        id: object.id,
        type: object.type
      }
    });
  },

  updateCompanyBankAccount(data) {
    return Api().post("account-bank/update", data);
  },

  updateObjectBankAccount(data) {
    return Api().post("account-bank/update-to-object", data);
  },

  getDebtHistory(id) {
    return Api().get(`customer/debtHistory/${id}`);
  },

  getBankAccountQR(params) {
    return Api().get("bank-accounts/qr", { params });
  },

  checkBankAccountQr(id) {
    return Api().get("bank-accounts/check-qr/" + id);
  }
};
