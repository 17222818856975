const ReviewRouter = {
  path: "/review",
  name: "review",
  component: () => import("@/modules/Review"),
  meta: {
    private: true,
    layout: "general",
    title: "Quản lý đánh giá | ThinkPro CMS",
    breadcrumbs: [
      {
        disabled: false,
        exact: true,
        text: "Bảng điều khiển",
        to: { name: "dashboard" }
      },
      {
        disabled: true,
        exact: true,
        text: "Quản lý đánh giá",
        to: { name: "review" }
      }
    ]
  }
};

export default ReviewRouter;
