import { ModelName, StoreName, UserModelName, ORDERS_TYPE } from "../constant";
import store from "@/core/store";
import router from "@/core/router";
import {
  generateActions,
  generateMutations,
  generateState,
  generateGetter
} from "./store.common";
import CashFlowService from "../service/index";

const state = {
  ...generateState(),
  sourceOrders: []
};

const getters = {
  ...generateGetter(),
  sourceOrders: state => state.sourceOrders
};

const mutations = {
  ...generateMutations(),
  SET_SOURCE_ORDERS: (state, payload) => {
    state.sourceOrders = payload;
  },
  SET_SOURCE_VOUCHER_LINKS: (state, payload) => {
    state.cashFlowDetail.source_voucher_links = payload;
  }
};

const actions = {
  ...generateActions(
    ModelName.PaymentVoucher,
    StoreName.PAYMENT_VOUCHER,
    ORDERS_TYPE.PAYMENT_VOUCHER
  ),
  setCashFlowDetail: async ({ commit }, data) => {
    const { voucher_links } = data;
    const source_voucher_links = voucher_links.filter(value =>
      Object.keys(ORDERS_TYPE.PAYMENT_VOUCHER_SOURCE).includes(value.model_name)
    );
    const des_voucher_links = voucher_links.filter(value =>
      Object.keys(ORDERS_TYPE.PAYMENT_VOUCHER).includes(value.model_name)
    );
    commit("SET_ORDERS", des_voucher_links);
    await commit("SET_CASH_FLOW_DETAIL", {
      ...data,
      voucher_links: des_voucher_links,
      source_voucher_links,
      source_apply_type: data.source_apply_type
    });
  },
  getSourceOrders: async ({ commit, state }) => {
    try {
      const { cashFlowDetail, sourceOrders } = state;
      const { payment_amount, source_apply_type } = cashFlowDetail;
      if (!payment_amount) {
        return;
      }
      if (sourceOrders.length > 0) {
        let amount = payment_amount;
        let dataSet = [];
        for (let index = 0; index < sourceOrders.length; index++) {
          let obj = sourceOrders[index];
          if (amount > obj.remains - obj.pending) {
            obj.amount = obj.remains - obj.pending;
          } else {
            obj.amount = amount;
          }
          dataSet.push(obj);

          amount = amount - obj.amount;
          if (amount <= 0) {
            break;
          }
        }

        commit("SET_SOURCE_VOUCHER_LINKS", dataSet);
        return;
      }
      const user = store.getters["AUTHENTICATION/user"];
      const response = await CashFlowService.getCashFlowPaymentSource({
        model_name: UserModelName.User,
        model_id: user.id
      });

      if (response.status === 200 && response.data) {
        const orderTypesKeys = Object.keys(ORDERS_TYPE.PAYMENT_VOUCHER_SOURCE);
        let orders = [];
        orderTypesKeys.forEach(key => {
          orders = orders.concat(
            response.data[key].map(val => ({ ...val, model_name: key }))
          );
        });
        if (source_apply_type === 2) {
          commit("SET_SOURCE_ORDERS", orders);
          return;
        }
        let amount = payment_amount;
        let dataSet = [];
        for (let index = 0; index < orders.length; index++) {
          let obj = orders[index];
          if (amount > obj.remains - obj.pending) {
            obj.amount = obj.remains - obj.pending;
          } else {
            obj.amount = amount;
          }
          dataSet.push(obj);
          amount = amount - obj.amount;
          if (amount <= 0) {
            break;
          }
        }

        commit("SET_SOURCE_ORDERS", orders);
        commit("SET_SOURCE_VOUCHER_LINKS", dataSet);
        commit("SET_STATUS_REQUEST", {
          value: "success-getSourceOrders"
        });
      }
    } catch (e) {
      if (e.response && e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response ? e.response.data : "",
          value: "error-getSourceOrders"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
