/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import CategoriesBarService from "../service/categories-bar.service";
import { catchError } from "@/core/composables/useStore";

const state = {
  categoriesBarList: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  categoriesBarList: state => state.categoriesBarList,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_CATEGORIES_BAR_LIST: (state, payload) => {
    state.categoriesBarList = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  getCategoriesBar: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getCategoriesBar"
      });

      const response = await CategoriesBarService.getCategoriesBar(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_CATEGORIES_BAR_LIST", response.data.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-getCategoriesBar"
        });
      }
    } catch (e) {
      catchError(e, commit, "getCategoriesBar");
    }
  },

  updateCategoriesBar: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updateCategoriesBar"
      });

      const response = await CategoriesBarService.updateCategoriesBar(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-updateCategoriesBar"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateCategoriesBar");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
