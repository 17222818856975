export const ATTRIBUTES_DISPLAY_FORMAT = {
  // Laptop & PC --------------------
  battery_capacity: {
    sku_version: "Pin %value %measurement"
  },
  bluetooth: {
    sku_feature: "Bluetooth %value",
    sku_feature_detail: "Bluetooth: %value"
  },
  cpu_base: {
    sku_feature: "Base %value %measurement"
  },
  cpu_boost: {
    sku_feature: "Boost %value %measurement"
  },
  cpu_cache: {
    sku_feature: "Bộ nhớ đệm %value %measurement"
  },
  cpu_cores: {
    sku_feature: "%value nhân"
  },
  cpu_threads: {
    sku_feature: "%value luồng"
  },
  dim_high: {
    sku_feature: "Dày %value",
    sku_feature_detail: "Dày %value %measurement"
  },
  dim_long: {
    sku_feature: "Dài %value",
    sku_feature_detail: "Dài %value %measurement"
  },
  dim_wide: {
    sku_feature: "Rộng %value",
    sku_feature_detail: "Rộng %value %measurement"
  },
  display_srgb: {
    sku_feature: "%value% sRGB"
  },
  display_size: {
    sku_version: '%value"',
    sku_feature: '%value"',
    sku_feature_detail: '%value"'
  },
  display_resolution: {
    sku_feature_detail: "%value Pixel"
  },
  display_touch: {
    sku_feature: ["", "Cảm ứng"],
    sku_feature_detail: ["Không", "Có"]
  },
  io_35_headphone: {
    sku_feature: "Jack tai nghe 3.5",
    sku_feature_detail: "1 Jack 3.5 mm"
  },
  io_kensington: {
    sku_feature: "Khóa %value"
  },
  io_micro_sd: {
    sku_feature: "Khe cắm thẻ nhớ MicroSD",
    sku_feature_detail: "1 khe cắm thẻ nhớ MicroSD"
  },
  io_power_port: {
    sku_feature: "%value"
  },
  lap_io_lan: {
    sku_feature: "%value LAN",
    sku_feature_detail: " %value LAN"
  },
  io_sd: {
    sku_feature: "Khe cắm thẻ nhớ SD",
    sku_feature_detail: "1 khe cắm thẻ nhớ SD"
  },
  io_thunderbolt: {
    sku_feature: ["", "Thunderbolt"],
    sku_feature_detail: ["", "1 Thunderbolt"]
  },
  io_usba: {
    sku_feature: "%value x USB-A",
    sku_feature_detail: "%value USB-A"
  },
  io_usbc: {
    sku_feature: "%value x Type-C",
    sku_feature_detail: "%value Type-C"
  },
  lap_io_usb30: {
    sku_feature_detail: "%value USB 3.0"
  },
  lap_io_usb20: {
    sku_feature_detail: "%value USB 2.0"
  },
  io_display_port: {
    sku_feature: "%value x DisplayPort",
    sku_feature_detail: "%value DisplayPort"
  },
  io_mini_display_port: {
    sku_feature: "%value x MiniDisplayPort",
    sku_feature_detail: "%value MiniDisplayPort"
  },
  io_hdmi: {
    sku_feature: "%value x HDMI",
    sku_feature_detail: "%value HDMI"
  },
  lap_io_vga: {
    sku_feature_detail: "%value VGA"
  },
  ram_bus: {
    sku_feature: "bus %value%measurement",
    sku_feature_detail: "%value%measurement"
  },
  ram_installed: {
    default: "RAM %value",
    sku_feature: "%value%measurement"
  },
  sl_face_reg: {
    sku_feature_detail: ["Không", "Có"]
  },
  storage_25_slot: {
    sku_feature: "NVMe %value",
    sku_feature_detail: "NVMe %value"
  },
  storage_hdd: {
    default: "HDD %value%measurement",
    sku_feature: "HDD %value%measurement",
    sku_feature_detail: "%value%measurement"
  },
  storage_m2_slot: {
    sku_feature: "M.2 %value",
    sku_feature_detail: "M.2 %value"
  },
  storage_ssd: {
    default: "SSD %value%measurement",
    sku_feature: "SSD %value%measurement",
    sku_feature_detail: "%value%measurement"
  },
  webcam: {
    sku_feature: "%value"
  },
  wifi: {
    sku_feature: "Wifi %value",
    sku_feature_detail: "Wireless: %value"
  },

  // Balo, túi xách, túi chống sốc ----------------------
  balo_compatible_laptop: {
    default: "Laptop %value"
  },
  balo_laptop_container: {
    sku_feature: ["Không", "Có"]
  },
  balo_size_length: {
    sku_feature: "Dài %value %measurement"
  },
  balo_size_width: {
    sku_feature: "Rộng %value %measurement"
  },
  balo_size_height: {
    sku_feature: "Cao %value %measurement"
  },

  // Cáp -----------------------
  cable_length: {
    default: "Độ dài dây %value%measurement"
  },

  // Case -----------------------
  case_support_mainboard: {
    default: "Hỗ trợ main board %value"
  },
  case_size_length: {
    sku_feature: "Dài %value %measurement"
  },
  case_size_width: {
    sku_feature: "Rộng %value %measurement"
  },
  case_size_height: {
    sku_feature: "Cao %value %measurement"
  },

  // Sạc ---------------------
  charger_output_usb_a: {
    default: "%value USB A"
  },
  charger_output_usb_c: {
    default: "Đầu ra: %value USB C"
  },
  charger_wattage: {
    default: "Công suất sạc: %value"
  },
  charger_size_length: {
    sku_feature: "Dài %value %measurement"
  },
  charger_size_width: {
    sku_feature: "Rộng %value %measurement"
  },
  charger_size_height: {
    sku_feature: "Cao %value %measurement"
  },

  // Ổ cứng ---------------------
  hard_drive_line: {
    default: "Dòng ổ cứng %value"
  },

  // Chuột ----------------------
  mouse_connect_through: {
    default: "Kết nối qua %value"
  },
  mouse_sound_click: {
    sku_feature: ["Không", "Có"]
  },
  mouse_size_length: {
    sku_feature: "Dài %value %measurement"
  },
  mouse_size_width: {
    sku_feature: "Rộng %value %measurement"
  },
  mouse_size_height: {
    sku_feature: "Cao %value %measurement"
  },

  // Cổng chuyển ------------------------
  port_output_display_port: {
    default: "%value DisplayPort"
  },
  port_output_hdmi: {
    default: "%value HDMI"
  },
  port_output_micro_sd: {
    default: "%value MicroSD"
  },
  port_output_sd: {
    default: "%value SD"
  },
  port_output_sound: {
    default: "%value Cổng âm thanh"
  },
  port_output_usb_a: {
    default: "%value USB A"
  },
  port_output_usb_c: {
    default: "%value USB C"
  },
  port_output_vga: {
    default: "%value Cổng VGA"
  },
  port_size_length: {
    sku_feature: "Dài %value %measurement"
  },
  port_size_width: {
    sku_feature: "Rộng %value %measurement"
  },
  port_size_height: {
    sku_feature: "Cao %value %measurement"
  },

  // Tản nhiệt --------------------------
  radiator_compatible_laptop: {
    default: "Laptop %value"
  },
  radiator_size_length: {
    sku_feature: "Dài %value %measurement"
  },
  radiator_size_width: {
    sku_feature: "Rộng %value %measurement"
  },
  radiator_size_height: {
    sku_feature: "Cao %value %measurement"
  },

  // Ram -------------------------
  ram_compatible_devices: {
    default: "Tương thích %value"
  },

  // Phần mềm --------------------------
  software_account_max: {
    default: "%value tài khoản",
    sku_feature: "%value tài khoản"
  },
  software_device_max: {
    default: "%value thiết bị",
    sku_feature: "%value thiết bị sử dụng cùng lúc"
  },

  // Màn hình
  screen_size: {
    sku_feature: '%value"'
  },
  screen_size_length: {
    sku_feature: "Dài %value %measurement"
  },
  screen_size_width: {
    sku_feature: "Rộng %value %measurement"
  },
  screen_size_height: {
    sku_feature: "Cao %value %measurement"
  },

  // Loa --------------------------
  speaker_connect_through: {
    default: "Kết nối qua %value"
  },
  speaker_size_length: {
    sku_feature: "Dài %value %measurement"
  },
  speaker_size_width: {
    sku_feature: "Rộng %value %measurement"
  },
  speaker_size_height: {
    sku_feature: "Cao %value %measurement"
  },

  // Keyboard --------------------------
  keyboard_size_length: {
    sku_feature: "Dài: %value %measurement"
  },
  keyboard_size_width: {
    sku_feature: "Rộng: %value %measurement"
  },
  keyboard_size_height: {
    sku_feature: "Cao: %value %measurement"
  },

  // Ghế công thái học -------------------
  ergonomic_chair_backrest_material: {
    default: "Lưng %value"
  },
  ergonomic_chair_plate_material: {
    default: "Mâm %value"
  },
  ergonomic_chair_piston: {
    default: "Piston %value"
  },

  // Bàn nâng hạ -------------------
  table_dim_height_min_max: {
    default: "Cao %value"
  },
  table_dim_width_surface: {
    default: "Rộng %value%measurement"
  },
  table_dim_long_surface: {
    default: "Dài %value%measurement"
  },

  // Máy chơi game -------------------
  console_gaming_ram: {
    default: "RAM %value"
  },
  console_gaming_storage: {
    default: "Bộ nhớ %value%measurement"
  }
};
