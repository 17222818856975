/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import OrderService from "../service/order.service";
import orderFilterService from "../service/order-filter.service";
import store from "@/core/store";
import { catchError } from "@/core/composables/useStore";
import { PROMOTION_TK_TYPE } from "@/core/constant";
import {
  getSuccessfulThuTotal,
  getThanhToanNgayTotal,
  getThanhToanSauTotal,
  getThuTotal
} from "@/core/composables";
import { INIT_ORDER_PROCESSING_VALUE } from "@/modules/Transaction/constant";

const state = {
  order: null,
  orderActiveSubTab: 0,
  orderListTotals: {
    total_value: 0
  },
  countIncomplete: {
    incomplete_branch: 0,
    incomplete_me: 0
  },
  orderExportTransactions: [],
  orderOnlinePayments: [],
  orderPaymentTransactions: [],
  orderPaymentTransactionsMetaData: null,
  orderProcessing: JSON.parse(JSON.stringify(INIT_ORDER_PROCESSING_VALUE)),
  orders: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  savedFilter: [],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedImage: null,
  invoiceParticipant: {},
  deposit: []
};

const getters = {
  order: state => state.order,
  orderActiveSubTab: state => state.orderActiveSubTab,
  orderLeftAmounts: state => {
    if (!state.order) {
      return {
        leftShippingAmount: 0,
        leftPromotionAmount: 0,
        leftPaidAmount: 0
      };
    }

    const orderLeftAmounts = {
      leftShippingAmount: state.order.shipping_fee
        ? state.order.shipping_fee
        : 0,
      leftPromotionAmount: state.order.discount ? state.order.discount : 0,
      leftPaidAmount: state.order.pre_paid_amount
        ? state.order.pre_paid_amount
        : 0
    };

    state.order.handles.forEach(item => {
      if (!item.cancel_status) {
        if (item.assigned_shipping_amount) {
          orderLeftAmounts.leftShippingAmount -= item.assigned_shipping_amount;
        }
        if (item.assigned_promotion_amount) {
          orderLeftAmounts.leftPromotionAmount -=
            item.assigned_promotion_amount;
        }
        if (item.assigned_preorder_amount) {
          orderLeftAmounts.leftPaidAmount -= item.assigned_preorder_amount;
        }
      }
    });

    return orderLeftAmounts;
  },
  orderListTotals: state => state.orderListTotals,
  countIncomplete: state => state.countIncomplete,
  orderExportTransactions: state => state.orderExportTransactions,
  orderOnlinePayments: state => state.orderOnlinePayments,
  orderPaymentTransactions: state => state.orderPaymentTransactions,
  orderPaymentTransactionsMetaData: state =>
    state.orderPaymentTransactionsMetaData,
  orderPaymentTransactionsLength: state =>
    state.orderPaymentTransactions.length +
    state.orderExportTransactions.length +
    state.orderOnlinePayments.length,
  orderProcessing: state => state.orderProcessing,
  orders: state => state.orders,
  orderGoodsTotal: state => {
    return state.order.details.reduce((total, item) => {
      const giftTotal = item.gifts.reduce(
        (giftTotal, gift) =>
          giftTotal + gift.qty * (gift.unit_price - gift.rebate_amount),
        0
      );

      return (
        total + item.qty * (item.unit_price - item.rebate_amount) + giftTotal
      );
    }, 0);
  },
  orderThanhToanNgayTotal: state => {
    return getThanhToanNgayTotal(state.orderPaymentTransactions);
  },
  orderThanhToanSauTotal: state => {
    return getThanhToanSauTotal(state.orderPaymentTransactions);
  },
  orderSuccessfulThuTotal: state => {
    return getSuccessfulThuTotal(state.orderPaymentTransactions);
  },
  orderThuTotal: state => {
    return getThuTotal(state.orderPaymentTransactions);
  },
  orderTotal: (state, getter) => {
    let total = getter.orderGoodsTotal;

    if (state.order.shipping_fee) {
      total += parseInt(state.order.shipping_fee);
    }
    if (state.order.payment_fee) {
      total += parseInt(state.order.payment_fee);
    }
    if (state.order.sc_fee) {
      total += parseInt(state.order.sc_fee);
    }
    if (state.order.discount) {
      total -= parseInt(state.order.discount);
    }

    return total;
  },
  paginationInfo: state => state.paginationInfo,
  savedFilter: state => state.savedFilter,
  statusRequest: state => state.statusRequest,
  totalValue: state => state.totalValue,
  uploadedImage: state => state.uploadedImage,
  invoiceParticipant: state => state.invoiceParticipant,
  deposit: state => state.deposit
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ORDER: (state, payload) => {
    state.order = payload;
  },

  SET_ORDER_ACTIVE_SUB_TAB: (state, payload) => {
    state.orderActiveSubTab = payload;
  },

  SET_ORDER_LIST_TOTALS: (state, payload) => {
    state.orderListTotals = payload;
  },

  SET_COUNT_INCOMPLETE: (state, payload) => {
    state.countIncomplete = payload;
  },

  SET_ORDER_EXPORT_TRANSACTIONS: (state, payload) => {
    state.orderExportTransactionsder = payload;
  },

  SET_ORDER_ONLINE_PAYMENTS: (state, payload) => {
    state.orderOnlinePayments = payload;
  },

  SET_ORDER_PAYMENT_TRANSACTIONS: (state, payload) => {
    state.orderPaymentTransactions = payload;
  },

  SET_ORDER_PAYMENT_TRANSACTIONS_META_DATA: (state, payload) => {
    state.orderPaymentTransactionsMetaData = payload;
  },

  SET_ORDER_PROCESSING: (state, payload) => {
    state.orderProcessing = payload;
  },

  SET_ORDERS: (state, payload) => {
    state.orders = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_SAVED_FILTER: (state, payload) => {
    state.savedFilter = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_TOTAL_VALUE: (state, payload) => {
    state.totalValue = payload;
  },

  SET_UPLOADED_IMAGE: (state, payload) => {
    state.uploadedImage = payload;
  },
  SET_INVOICE_PARTICIPANT: (state, payload) => {
    state.invoiceParticipant = payload;
  }
};

const actions = {
  addInvoiceParticipant: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-addInvoiceParticipant" });

      const response = await OrderService.addInvoiceParticipant({
        model_id: payload.model_id,
        model_name: payload.model_name,
        user_id: payload.user_id,
        role: payload.role ? payload.role : "TECHNIQUE",
        note: payload.note,
        value: payload.value ? payload.value : ""
      });

      if (response.status === 201 && response.data.data) {
        // commit("SET_INVOICE_PARTICIPANT", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-addInvoiceParticipant"
        });
      }
    } catch (e) {
      catchError(e, commit, "addInvoiceParticipant");
    }
  },

  cancelOrder: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-cancelOrder" });

      const response = await OrderService.cancelOrder(
        payload.id,
        payload.cancelReason
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_ORDER", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-cancelOrder" });
      }
    } catch (e) {
      catchError(e, commit, "cancelOrder");
    }
  },

  cancelOrderProcessing: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-cancelOrderProcessing" });

      const response = await OrderService.cancelOrderProcessing(
        payload.id,
        payload.cancelReason
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_ORDER_PROCESSING", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-cancelOrderProcessing"
        });
      }
    } catch (e) {
      catchError(e, commit, "cancelOrderProcessing");
    }
  },

  checkPromotionCode: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-checkPromotionCode" });

      const response = await OrderService.checkPromotionCode(
        payload.orderId,
        payload.code
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-checkPromotionCode"
        });

        return response.data.data;
      }
    } catch (e) {
      catchError(e, commit, "checkPromotionCode");
    }
  },

  completeOrder: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-completeOrder" });

      const response = await OrderService.completeOrder(payload);

      if (response.status === 200 && response.data.data) {
        // Get order processing
        await store.dispatch(
          "ORDER/getOrderProcessingById",
          state.orderProcessing.id
        );
        // Set order
        commit("SET_ORDER", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-completeOrder" });
      }
    } catch (e) {
      catchError(e, commit, "completeOrder");
    }
  },

  createOrder: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createOrder" });

      const order = handleFormatOrderParam(payload);

      const response = await OrderService.createOrder(order);

      if (response.status === 200 && response.data) {
        commit("SET_ORDER", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-createOrder" });
      }
    } catch (e) {
      catchError(e, commit, "createOrder");
    }
  },

  exportRepairInvoice: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportRepairInvoice" });

      const response = await OrderService.exportRepairInvoice(payload);

      if (response.status === 201 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportRepairInvoice" });
        return response;
      }
    } catch (e) {
      catchError(e, commit, "exportRepairInvoice");
    }
  },

  createFilter: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createFilter" });

      const response = await orderFilterService.createFilter(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-createFilter" });
      }
    } catch (e) {
      catchError(e, commit, "createFilter");
    }
  },

  createOrderProcessing: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createOrderProcessing" });

      const response = await OrderService.createOrderProcessing(payload);

      if (response.status === 201 && response.data) {
        commit("SET_ORDER_PROCESSING", {
          ...response.data.data,
          user_packing: payload.user_packing ? payload.user_packing : null,
          user_shipping: payload.user_shipping ? payload.user_shipping : null,
          distance: payload.distance ? payload.distance : null
        });

        // Update order and sale receive data
        await store.dispatch(
          "ORDER/getOrderById",
          store.getters["SALE/saleTabActive"].id
        );
        await store.dispatch("SALE/setSaleTabActive", {
          ...store.getters["SALE/saleTabActive"],
          type: 2
        });

        commit("SET_STATUS_REQUEST", {
          value: "success-createOrderProcessing"
        });
      }
    } catch (e) {
      catchError(e, commit, "createOrderProcessing");
    }
  },

  deleteFilter: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteFilter" });

      const response = await orderFilterService.deleteFilter(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-deleteFilter"
        });
      }
    } catch (e) {
      catchError(e, commit, "deleteFilter");
    }
  },

  editInvoiceParticipant: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-editInvoiceParticipant" });

      const response = await OrderService.editInvoiceParticipant({
        id: payload.id,
        model_id: payload.model_id,
        model_name: payload.model_name,
        user_id: payload.user_id,
        role: payload.role ? payload.role : "TECHNIQUE",
        note: payload.note ? payload.note : "",
        value: payload.value ? payload.value : ""
      });

      if (response.status === 200 && response.data.data) {
        commit("SET_INVOICE_PARTICIPANT", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-editInvoiceParticipant"
        });
      }
    } catch (e) {
      catchError(e, commit, "editInvoiceParticipant");
    }
  },

  exportInvoice: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportInvoice" });

      const response = await OrderService.exportInvoice(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportInvoice" });

        return response.data.data;
      }
    } catch (err) {
      catchError(err, commit, "exportInvoice", { isRedirected: false });
    }
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await OrderService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportFile", { isRedirected: false });
    }
  },

  exportOrderFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportOrderFile" });

      const response = await OrderService.exportExcel(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportOrderFile" });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportOrderFile", { isRedirected: false });
    }
  },

  getFilters: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getFilters" });

      const response = await orderFilterService.getFilters(payload);

      if (response.status === 200 && response.data) {
        commit("SET_SAVED_FILTER", response.data.saved_filters);
        commit("SET_STATUS_REQUEST", { value: "success-getFilters" });
      }
    } catch (e) {
      catchError(e, commit, "getFilters");
    }
  },

  getOrderById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getOrderById" });

      const response = await OrderService.getOrderById(payload);

      if (response.status === 200 && response.data.data) {
        const order = response.data.data;
        const orderPromoIds = response.data.data.promos.map(item => item.id);

        if (order.available_promos) {
          // filter available promotions not in order promo to set default value
          const arr = order.available_promos
            .filter(item => !orderPromoIds.includes(item.id))
            .map(promotion => {
              const obj = {
                id: promotion.id,
                promo: promotion,
                promo_details: []
              };

              // Set init selected prop for money promo
              if (
                promotion.tk_type === PROMOTION_TK_TYPE.PERCENT ||
                promotion.tk_type === PROMOTION_TK_TYPE.AMOUNT
              ) {
                obj.selected = false;
              }

              return obj;
            });

          // Set selected for money promo
          order.promos = order.promos.map(item => {
            if (
              item.promo.tk_type === PROMOTION_TK_TYPE.PERCENT ||
              item.promo.tk_type === PROMOTION_TK_TYPE.AMOUNT
            ) {
              item.selected = true;
            }
            return item;
          });
          order.promos = order.promos.concat(arr);
        }

        commit("SET_ORDER", order);
        commit("SET_STATUS_REQUEST", { value: "success-getOrderById" });
        return order;
      }
    } catch (e) {
      catchError(e, commit, "getOrderById");
    }
  },

  getOrderOnlinePayments: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getOrderOnlinePayments"
      });

      const response = await OrderService.getOrderOnlinePayments(payload);

      if (response.status === 200 && response.data) {
        commit("SET_ORDER_ONLINE_PAYMENTS", response.data.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-getOrderOnlinePayments"
        });
      }
    } catch (e) {
      catchError(e, commit, "getOrderOnlinePayments");
    }
  },

  getOrderPaymentTransactions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getOrderPaymentTransactions"
      });

      const response = await OrderService.getOrderPaymentTransactions(payload);

      if (response.status === 200 && response.data) {
        commit(
          "SET_ORDER_PAYMENT_TRANSACTIONS",
          response.data.data.transactions
        );

        commit(
          "SET_ORDER_PAYMENT_TRANSACTIONS_META_DATA",
          response.data.data.meta
        );

        commit("SET_STATUS_REQUEST", {
          value: "success-getOrderPaymentTransactions"
        });
      }
    } catch (e) {
      catchError(e, commit, "getOrderPaymentTransactions");
    }
  },

  getOrderProcessingById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getOrderProcessingById" });

      const response = await OrderService.getOrderProcessingById(payload);

      if (response.status === 200 && response.data.data) {
        const orderProcessing = response.data.data,
          orderProcessingSerials = orderProcessing.serials;

        const findOrderProcessingSerial = id => {
          return orderProcessingSerials.find(item => item.id === id);
        };

        // Update serials in details
        orderProcessing.details.forEach(option => {
          if (option.serials) {
            // Update info for option serials
            option.serials = option.serials.map(optionSerial => {
              // Find option serial in orderProcessingSerials
              const temp = findOrderProcessingSerial(optionSerial.id);

              if (temp) {
                return { ...temp };
              } else return optionSerial;
            });

            // Update info for gift
            option.gifts.forEach(gift => {
              gift.serials = gift.serials.map(giftSerial => {
                // Find gift serial in orderProcessingSerials
                const temp = findOrderProcessingSerial(giftSerial.id);

                if (temp) {
                  return { ...temp };
                } else return giftSerial;
              });
            });
          } else {
            option.serials = [];
          }
        });
        if (
          orderProcessing.require_tech_staff &&
          orderProcessing.participants &&
          orderProcessing.participants.length > 0
        ) {
          let roles = [];
          roles = orderProcessing.participants.map(item => {
            if (item.role === "PACKAGING")
              orderProcessing.distance = item.value;
            return item.role;
          });
          if (roles.includes("TECHNIQUE"))
            orderProcessing.require_tech_staff_software = true;
          if (roles.includes("TECHNIQUE_HARDWARE"))
            orderProcessing.require_tech_staff_hardware = true;
        }
        if (
          orderProcessing.participants &&
          orderProcessing.participants.length > 0
        ) {
          orderProcessing.participants.map(item => {
            if (item.role === "PACKAGING") {
              orderProcessing.user_packing =
                item.user && item.user.id ? item.user.id : null;
            }
            if (item.role === "SHIPPING") {
              orderProcessing.user_shipping =
                item.user && item.user.id ? item.user.id : null;
              orderProcessing.distance = item.value;
            }
          });
        }

        // Get shipping info
        store.dispatch("ORDER_PROCESSING/getShippingInfo", orderProcessing.id);

        commit("SET_ORDER_PROCESSING", orderProcessing);
        commit("SET_STATUS_REQUEST", {
          value: "success-getOrderProcessingById"
        });

        return orderProcessing;
      }
    } catch (e) {
      console.log(e);
      catchError(e, commit, "getOrderProcessingById");
    }
  },

  getOrders: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getOrders" });

      const response = await OrderService.getOrders(payload);

      if (response.status === 200 && response.data) {
        commit("SET_ORDERS", response.data.data);
        // Set pagination
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        // Set totals
        commit("SET_ORDER_LIST_TOTALS", {
          total_value: response.data.meta.total_value
            ? response.data.meta.total_value
            : 0,
          total_debt_customer: response.data.meta.total_debt_customer
            ? response.data.meta.total_debt_customer
            : 0
        });

        commit("SET_COUNT_INCOMPLETE", {
          incomplete_branch: response.data.meta.incomplete_branch
            ? response.data.meta.incomplete_branch
            : 0,
          incomplete_me: response.data.meta.incomplete_me
            ? response.data.meta.incomplete_me
            : 0
        });

        commit("SET_STATUS_REQUEST", { value: "success-getOrders" });
      }
    } catch (e) {
      catchError(e, commit, "getOrders");
    }
  },

  resetOrderExportTransactions: ({ commit }) => {
    commit("SET_ORDER_EXPORT_TRANSACTIONS", []);
  },

  resetOrderPaymentTransactions: ({ commit }) => {
    commit("SET_ORDER_PAYMENT_TRANSACTIONS", []);
  },

  resetOrderProcessing: async ({ commit }) => {
    commit(
      "SET_ORDER_PROCESSING",
      JSON.parse(JSON.stringify(INIT_ORDER_PROCESSING_VALUE))
    );
  },

  setOrder: ({ commit }, payload) => {
    commit("SET_ORDER", payload);
  },

  setOrderProcessing: ({ commit }, payload) => {
    commit("SET_ORDER_PROCESSING", payload);
  },

  updateFilter: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateFilter" });

      const response = await orderFilterService.updateFilter(
        payload.id,
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-updateFilter" });
      }
    } catch (e) {
      catchError(e, commit, "updateFilter");
    }
  },

  updateOrder: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateOrder" });

      const order = handleFormatOrderParam(payload);

      const response = await OrderService.updateOrder(order);

      if (response.status === 200 && response.data.data) {
        commit("SET_ORDER", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-updateOrder" });
      }
    } catch (e) {
      catchError(e, commit, "updateOrder");
    }
  },

  updateOrderProcessing: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateOrderProcessing" });

      const response = await OrderService.updateOrderProcessing(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_ORDER_PROCESSING", {
          ...response.data.data,
          user_packing: payload.user_packing ? payload.user_packing : null,
          user_shipping: payload.user_shipping ? payload.user_shipping : null,
          distance: payload.distance ? payload.distance : null
        });

        // Update order and sale receive data
        await store.dispatch(
          "ORDER/getOrderById",
          store.getters["SALE/saleTabActive"].id
        );
        await store.dispatch("SALE/setSaleTabActive", {
          ...store.getters["SALE/saleTabActive"],
          type: 2
        });

        commit("SET_STATUS_REQUEST", {
          value: "success-updateOrderProcessing"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateOrderProcessing");
    }
  },

  updateOrderStatus: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateOrderStatus" });

      const response = await OrderService.updateOrderStatus(
        payload.orderId,
        payload.status
      );

      if (response.status === 200 && response.data) {
        commit("SET_ORDER", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-updateOrderStatus" });
      }
    } catch (e) {
      catchError(e, commit, "updateOrderStatus");
    }
  },

  uploadImage: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadImage" });

      const response = await OrderService.uploadImage(payload);

      if (response.status === 200) {
        await commit("SET_UPLOADED_IMAGE", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadImage" });
      }
    } catch (e) {
      catchError(e, commit, "uploadImage");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

const handleFormatOrderParam = initOrder => {
  const order = JSON.parse(JSON.stringify(initOrder));

  order.promos = order.promos.reduce((arr, item) => {
    if (
      item.promo.tk_type === PROMOTION_TK_TYPE.PRODUCT &&
      item.promo_details.length > 0
    ) {
      arr.push({
        id: item.id,
        promo: item.promo,
        promo_details: {
          details: {
            options: item.promo_details.map(option => option.option_id)
          },
          type: 2
        }
      });
    } else if (item.selected) {
      arr.push({
        id: item.id,
        promo: item.promo,
        promo_details: {
          details: {
            options: []
          },
          type: 1
        }
      });
    }

    return arr;
  }, []);

  return order;
};
