import Api from "@/core/apis";

export default {
  getHomeSettingBySite(site) {
    return Api().get(`page-setting/detail?SITE=${site}`);
  },

  updateHomeSetting(payload) {
    return Api().post("page-setting/update", payload);
  }
};
