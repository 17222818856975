/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import SaleReceiptService from "../service/sale-receipt.service";
import store from "@/core/store";
import Vue from "vue";
import { PRODUCT_PROMOTION_TYPE } from "../../Sale/constants";
import {
  catchError,
  getProcessingThuTotal,
  getSuccessfulThuTotal,
  getThuTotal
} from "@/core/composables";
import { formatDateTime } from "@/core/utils";

// Group Array of Objects by Keys or Property Values
const groupBy = keys => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map(key => obj[key]).join("-");
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, []);

const state = {
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  saleReceipt: {},
  saleReceiptListTotals: {
    total_debt: 0,
    total_value: 0
  },
  saleReceiptPaymentTransactions: [],
  saleReceipts: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  paginationInfo: state => state.paginationInfo,
  saleReceipt: state => state.saleReceipt,
  saleReceiptListTotals: state => state.saleReceiptListTotals,
  saleReceiptPaymentTransactions: state => state.saleReceiptPaymentTransactions,
  saleReceiptPaymentTransactionsLength: state =>
    state.saleReceiptPaymentTransactions.length,
  saleReceiptSuccessfullThuTotal: state => {
    return getSuccessfulThuTotal(state.saleReceiptPaymentTransactions);
  },
  saleReceiptProcessingThuTotal: state => {
    return getProcessingThuTotal(state.saleReceiptPaymentTransactions);
  },
  saleReceiptThuTotal: state => {
    return getThuTotal(state.saleReceiptPaymentTransactions);
  },
  saleReceipts: state => state.saleReceipts,
  statusRequest: state => state.statusRequest,
  totalDebt: state => state.totalDebt,
  totalValue: state => state.totalValue
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_SALE_RECEIPT: (state, payload) => {
    state.saleReceipt = payload;
  },

  SET_SALE_RECEIPT_LIST_TOTALS: (state, payload) => {
    state.saleReceiptListTotals = payload;
  },

  SET_SALE_RECEIPT_PAYMENT_TRANSACTIONS: (state, payload) => {
    state.saleReceiptPaymentTransactions = payload;
  },

  SET_SALE_RECEIPTS: (state, payload) => {
    state.saleReceipts = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_TOTAL_DEBT: (state, payload) => {
    state.totalDebt = payload;
  },

  SET_TOTAL_VALUE: (state, payload) => {
    state.totalValue = payload;
  }
};

const actions = {
  cancelSaleReceipt: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-cancelSaleReceipt" });

      const response = await SaleReceiptService.cancelSaleReceipt(
        payload.id,
        payload.cancelReason
      );

      if (response.status === 200 && response.data) {
        const saleReceipt = state.saleReceipts[payload.index];

        saleReceipt.status = -1;

        if (payload.index) {
          await Vue.set(state.saleReceipts, payload.index, saleReceipt);
        }

        commit("SET_STATUS_REQUEST", { value: "success-cancelSaleReceipt" });
      }
    } catch (e) {
      catchError(
        e,
        commit,
        "cancelSaleReceipt",
        "Rất tiếc, bạn không thể hủy hóa đơn bán này"
      );
    }
  },

  createSaleReceipt: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createSaleReceipt" });
      const objectSender = JSON.parse(JSON.stringify(payload));

      // Format created at in case xuat bu
      if (objectSender.xuatBu) {
        objectSender.created_at = formatDateTime(
          objectSender.created_at,
          "DD-MM-YYYY hh:mm"
        );
      } else delete objectSender.created_at;

      const response = await SaleReceiptService.createSaleReceipt(objectSender);

      if (
        (response.status === 201 || response.status === 200) &&
        response.data
      ) {
        const saleReceipt = payload;
        saleReceipt.id = response.data.data.id;

        commit("SET_SALE_RECEIPT", saleReceipt);
        commit("SET_STATUS_REQUEST", { value: "success-createSaleReceipt" });
      }
    } catch (e) {
      catchError(e, commit, "createSaleReceipt");
    }
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await SaleReceiptService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportFile", { isRedirected: false });
    }
  },

  getSaleReceiptById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSaleReceiptById" });

      const response = await SaleReceiptService.getSaleReceiptById(payload);

      if (response.status === 200 && response.data.data) {
        const saleReceipt = response.data.data,
          serialsArr = saleReceipt.serials;

        // Group by option_id value
        let options = groupBy(["option_id"])(serialsArr).filter(
          item => item !== null
        );

        // Map results
        options = options.map(option => {
          let childProducts = [];
          let promoChild = null;
          option.forEach(element => {
            const { promos } = element;
            if (promos) {
              promos.forEach(promo => {
                const { gifts } = promo;
                if (gifts) {
                  promoChild = promo.detail;
                  childProducts = childProducts.concat(gifts);
                }
              });
            }
          });
          return {
            id: option[0].option_id,
            SKU: option[0].option_sku,
            name: option[0].option_name,
            product_name: option[0].product_name,
            unit_sold_price: Number(option[0].unit_sold_price),
            sold_price: option[0].sold_price,
            serials: option,
            allSerials: option,
            serialsId: option.map(item => item.id),
            rebate_amount:
              Number(option[0].unit_sold_price) - Number(option[0].sold_price),
            childProducts,
            promoChild,
            promos: option[0].promos
          };
        });
        options.forEach(item => {
          const { childProducts } = item;
          childProducts.forEach(child => {
            const childOptionIndex = options.findIndex(value => {
              return value.serialsId.find(serialId => serialId === child);
            });

            if (childOptionIndex !== -1) {
              const { tk_option } = item.promoChild || {};
              options[childOptionIndex] = {
                ...options[childOptionIndex],
                parent: item.id,
                promoChild: item.promoChild,
                free: true
              };
              if (tk_option) {
                const { tang_type, tang_value } = tk_option;
                const free = tang_type === PRODUCT_PROMOTION_TYPE.give_product;
                options[childOptionIndex].free = free;
                options[childOptionIndex].tang_value = tang_value;
              }
            }
          });
        });
        saleReceipt.options = options;
        if (saleReceipt.promo) {
          const { money } = saleReceipt.promo;
          // const { tk_type } = detail;

          // if (tk_type === PROMOTION_TYPE.freeship) {
          //   saleReceipt.promotionTitle = "FREESHIP";
          // } else if (
          //   tk_type === PROMOTION_TYPE.reduce_money ||
          //   tk_type === PROMOTION_TYPE.reduce_percent
          // ) {
          //   saleReceipt.promotionTitle = "GIẢM GIÁ";
          // }

          saleReceipt.promotionTitle = "GIẢM GIÁ";
          saleReceipt.promotionReduce = money;
        } else {
          saleReceipt.promotionReduce = 0;
        }
        if (saleReceipt.participants && saleReceipt.participants.length > 0) {
          let roles = saleReceipt.participants.map(item => {
            if (item.role === "PACKAGING") saleReceipt.distance = item.value;
            return item.role;
          });
          if (roles.includes("TECHNIQUE"))
            saleReceipt.require_tech_staff_software = true;
          if (roles.includes("TECHNIQUE_HARDWARE"))
            saleReceipt.require_tech_staff_hardware = true;
        }
        commit("SET_SALE_RECEIPT", saleReceipt);
        commit("SET_STATUS_REQUEST", { value: "success-getSaleReceiptById" });
      }
    } catch (e) {
      console.log(e);
      catchError(e, commit, "getSaleReceiptById");
    }
  },

  getSaleReceiptPaymentTransactions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getSaleReceiptPaymentTransactions"
      });

      const response = await SaleReceiptService.getSaleReceiptPaymentTransactions(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_SALE_RECEIPT_PAYMENT_TRANSACTIONS", response.data.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-getSaleReceiptPaymentTransactions"
        });
      }
    } catch (e) {
      catchError(e, commit, "getSaleReceiptPaymentTransactions");
    }
  },

  getSaleReceipts: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSaleReceipts" });

      const response = await SaleReceiptService.getSaleReceipts(payload);

      if (response.status === 200 && response.data) {
        commit("SET_SALE_RECEIPTS", response.data.data);
        // Set pagination info
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        // Set totals
        commit("SET_SALE_RECEIPT_LIST_TOTALS", {
          total_debt: response.data.meta.total_debt
            ? response.data.meta.total_debt
            : 0,
          total_value: response.data.meta.total_value
            ? response.data.meta.total_value
            : 0,
          total_goods_value: response.data.meta.total_goods_value
            ? response.data.meta.total_goods_value
            : 0
        });

        commit("SET_STATUS_REQUEST", { value: "success-getSaleReceipts" });
      }
    } catch (e) {
      catchError(e, commit, "getSaleReceipts");
    }
  },

  resetSaleReceipt: ({ commit }) => {
    commit("SET_SALE_RECEIPT", {});
  },

  resetSaleReceiptPaymentTransactions: ({ commit }) => {
    commit("SET_SALE_RECEIPT_PAYMENT_TRANSACTIONS", []);
  },

  updateSaleReceiptClosingUser: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updateSaleReceiptClosingUser"
      });

      const response = await SaleReceiptService.updateSaleReceiptClosingUser(
        payload
      );

      if (response.status === 200 && response.data) {
        await store.dispatch("SALE_RECEIPT/getSaleReceiptById", response.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-updateSaleReceiptClosingUser"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateSaleReceiptClosingUser");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
