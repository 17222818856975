const DashboardRouter = {
  path: "/dashboard",
  name: "dashboard",
  meta: {
    private: true,
    layout: "general",
    title: "Bảng điều khiển | ThinkPro CMS",
    breadcrumbs: [
      {
        disabled: true,
        exact: true,
        text: "Bảng điều khiển",
        to: { name: "dashboard" }
      }
    ]
  },
  component: () => import("@/modules/Dashboard")
};

export default DashboardRouter;
