import Api from "@/core/apis";

export default {
  createInvoiceParticipant(data) {
    return Api().post("invoice-participant", data);
  },

  createManyInvoiceParticipant(data) {
    return Api().post("invoice-participant/add-multi", data);
  },

  getInvoiceParticipants(data) {
    return Api().post("invoice-participant/list", data);
  },

  getInvoiceParticipantsOfInvoice(modelName, modelId) {
    return Api().post("invoice-participant/invoices/list", {
      model_name: modelName,
      model_id: modelId
    });
  },

  updateInvoiceParticipant(data) {
    return Api().put("invoice-participant", data);
  }
};
