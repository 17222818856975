export const getAddressText = (address, emptyAddressText = "") => {
  let str = "";

  if (address) {
    if (address.so_nha) {
      str += address.so_nha;
    }

    if (address.phuong_xa) {
      str += str === "" ? `${address.phuong_xa}` : `, ${address.phuong_xa}`;
    }

    if (address.quan_huyen) {
      str += str === "" ? `${address.quan_huyen}` : `, ${address.quan_huyen}`;
    }

    if (address.tinh_thanhpho) {
      str +=
        str === "" ? `${address.tinh_thanhpho}` : `, ${address.tinh_thanhpho}`;
    }
  }

  return str === "" ? emptyAddressText : str;
};
