const GoodsRouter = {
  path: "/goods",
  component: () => import("@/modules/Goods"),
  redirect: { name: "goods_products" },
  children: [
    {
      path: "products",
      name: "goods_products",
      meta: {
        private: true,
        layout: "general",
        title: "Tất cả sản phẩm | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Tất cả sản phẩm",
            to: { name: "goods_products" }
          }
        ]
      },
      component: () => import("../pages/Products")
    },
    {
      path: "product-options",
      name: "goods_product-options",
      meta: {
        private: true,
        layout: "general",
        title: "Tùy chọn sản phẩm | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Tùy chọn sản phẩm",
            to: { name: "goods_product-options" }
          }
        ]
      },
      component: () => import("../pages/ProductOptions")
    },
    {
      path: "serials",
      name: "goods_serials",
      meta: {
        private: true,
        layout: "general",
        title: "Quản lý serials | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Quản lý serials",
            to: { name: "goods_serials" }
          }
        ]
      },
      component: () => import("../pages/Serials")
    },
    {
      path: "3rd-store",
      name: "goods_3rd-store",
      meta: {
        private: true,
        layout: "general",
        title: "Kho bên thứ ba | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Kho bên thứ ba",
            to: { name: "goods_3rd-store" }
          }
        ]
      },
      component: () => import("../pages/3rdStore")
    },
    {
      path: "brands",
      name: "goods_brands",
      meta: {
        private: true,
        layout: "general",
        title: "Thương hiệu | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Thương hiệu",
            to: { name: "goods_brands" }
          }
        ]
      },
      component: () => import("../pages/Brands")
    },
    {
      path: "customer-supply-services",
      name: "customer_supply_services",
      meta: {
        private: true,
        layout: "general",
        title: "Dịch vụ cung cấp | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Dịch vụ cung cấp",
            to: { name: "customer_supply_services" }
          }
        ]
      },
      component: () => import("../pages/CustomerSupplyServices")
    },
    {
      path: "product-types",
      name: "goods_product-types",
      meta: {
        private: true,
        layout: "general",
        title: "Loại sản phẩm | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Loại sản phẩm",
            to: { name: "goods_product-types" }
          }
        ]
      },
      component: () => import("../pages/ProductTypes")
    },
    {
      path: "product-types/attribute-template/:productTypeId",
      name: "goods_product-types",
      meta: {
        private: true,
        layout: "general",
        title: "Template cấu hình | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: false,
            exact: true,
            text: "Loại sản phẩm",
            to: { name: "goods_product-types" }
          },
          {
            disabled: true,
            exact: true,
            text: "Template cấu hình",
            to: { name: "goods_product-types" }
          }
        ]
      },
      component: () =>
        import("../pages/ProductTypes/components/AttributeTemplate/index.vue")
    },
    {
      path: "product-groups",
      name: "goods_product-groups",
      meta: {
        private: true,
        layout: "general",
        title: "Nhóm sản phẩm | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Nhóm sản phẩm",
            to: { name: "goods_product-groups" }
          }
        ]
      },
      component: () => import("../pages/ProductGroups")
    },
    {
      path: "linked-products",
      name: "linked-products",
      meta: {
        private: true,
        layout: "general",
        title: "Linked products | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Linked products",
            to: { name: "linked-products" }
          }
        ]
      },
      component: () => import("../pages/LinkedProduct")
    },
    {
      path: "adding-accessories",
      component: () => import("../pages/AddingAccessories"),
      children: [
        {
          path: "list",
          name: "goods_adding-accessories",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm linh kiện | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Lịch sử thêm linh kiện",
                to: { name: "goods_adding-accessories" }
              }
            ]
          },
          component: () => import("../pages/AddingAccessories/Listing")
        },
        {
          path: "create",
          name: "goods_adding-accessories-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm linh kiện | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm linh kiện",
                to: { name: "goods_adding-accessories" }
              }
            ]
          },
          component: () => import("../pages/AddingAccessories/Detail")
        }
      ]
    },
    {
      path: "removing-accessories",
      component: () => import("../pages/RemovingAccessories"),
      children: [
        {
          path: "list",
          name: "goods_removing-accessories",
          meta: {
            private: true,
            layout: "general",
            title: "Tháo linh kiện | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Lịch sử tháo linh kiện",
                to: { name: "goods_removing-accessories" }
              }
            ]
          },
          component: () => import("../pages/RemovingAccessories/Listing")
        },
        {
          path: "create",
          name: "goods_removing-accessories-create",
          meta: {
            private: true,
            layout: "general",
            title: "Tháo linh kiện | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Tháo linh kiện",
                to: { name: "goods_removing-accessories" }
              }
            ]
          },
          component: () => import("../pages/RemovingAccessories/Detail")
        }
      ]
    },
    {
      path: "inventory",
      component: () => import("../pages/Inventory"),
      redirect: { name: "goods_inventory" },
      children: [
        {
          path: "list",
          name: "goods_inventory",
          meta: {
            private: true,
            layout: "general",
            title: "Kiểm kho | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Kiểm kho",
                to: { name: "goods_inventory" }
              }
            ]
          },
          component: () => import("../pages/Inventory/Listing")
        },
        {
          path: "create",
          name: "goods_inventory-create",
          meta: {
            private: true,
            layout: "general",
            title: "Kiểm kho | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Kiểm kho",
                to: { name: "goods_inventory" }
              }
            ]
          },
          component: () => import("../pages/Inventory/Detail")
        },
        {
          path: ":inventoryId",
          name: "goods_inventory-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Kiểm kho | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Kiểm kho",
                to: { name: "goods_inventory" }
              }
            ]
          },
          component: () => import("../pages/Inventory/Detail")
        }
      ]
    },
    {
      path: "return-serials",
      name: "goods_return-serials",
      meta: {
        private: true,
        layout: "general",
        title: "Hàng trả chờ nhập | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Hàng trả chờ nhập",
            to: { name: "goods_return-serials" }
          }
        ]
      },
      component: () => import("../pages/ReturnSerials")
    }
  ]
};

export default GoodsRouter;
