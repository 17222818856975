/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import ProductOptionService from "../../modules/Goods/service/product-option.service";
import router from "@/core/router";

// Group Array of Objects by Keys or Property Values
const groupBy = keys => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map(key => obj[key]).join("-");
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, []);

const state = {
  branch: null,
  branchSerialsList: [], // product option stock info and list serials group by branch
  filteredBranchSerialsList: [],
  filterData: {
    status: "all",
    branch: {
      type: 1, // 1 - Current branch, 2 - In area, 3 - Specific branch/All branches
      value: null // 1 - null, 2 - City name, 3 - Branch Id
    }
  },
  pickedSerials: [],
  productOption: null,
  statusRequest: {
    message: null,
    value: null
  },
  branch_id_model_add_role_server: null
};

const getters = {
  branch: state => state.branch,
  branch_id_model_add_role_server: state =>
    state.branch_id_model_add_role_server,
  branchSerialsList: state => state.branchSerialsList,
  filteredBranchSerialsList: state => state.filteredBranchSerialsList,
  pickedSerialNumbers: state => {
    return state.pickedSerials.map(item => item.serial_number);
  },
  pickedSerials: state => state.pickedSerials,
  productOption: state => state.productOption,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_BRANCH: (state, payload) => {
    state.branch = payload;
  },
  SET_BRANCH_ID_MODEL_ADD_ROLE_SERVER: (state, payload) => {
    state.branch_id_model_add_role_server = payload;
  },

  SET_BRANCH_SERIALS_LIST: (state, payload) => {
    state.branchSerialsList = payload;
  },

  SET_FILTERED_BRANCH_SERIALS_LIST: (state, payload) => {
    state.filteredBranchSerialsList = payload;
  },

  SET_PICKED_SERIALS: (state, payload) => {
    state.pickedSerials = payload;
  },

  SET_PRODUCT_OPTION: (state, payload) => {
    state.productOption = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  getBranchSerialsList: async ({ commit }, productOptionId) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getBranchSerialsList" });

      const response = await ProductOptionService.getProductOptionSerialsByOptionId(
        productOptionId
      );

      if (response.status === 200 && response.data.data) {
        const serials = response.data.data;

        // Group by branch_id value
        const serialByBranches = groupBy(["branch_id"])(serials).filter(
          item => item !== null
        );

        const branchSerialsList = await Promise.all(
          serialByBranches.map(async item => {
            // Get stock info of branch
            const stockInfo = await ProductOptionService.getProductOptionStockInfoByBranches(
              productOptionId,
              [item[0].branch_id]
            );
            const saleSerialsCount = await item.filter(
                serial => serial.status === 1
              ).length,
              sampleSerialsCount = await item.filter(
                serial => serial.status === -13
              ).length,
              deliverySerialsCount = await item.filter(
                serial => parseInt(serial.exchange_status) === 1
              ).length;

            return {
              branch_id: item[0].branch_id,
              branch_name: item[0].branch_name,
              branch_tinh_thanhpho: item[0].branch_tinh_thanhpho,
              serials: item,
              statistics: {
                sale_serials: saleSerialsCount,
                sample_serials: sampleSerialsCount,
                delivery_serials: deliverySerialsCount
              },
              stock_info: stockInfo.data ? stockInfo.data : null
            };
          })
        );

        commit("SET_BRANCH_SERIALS_LIST", branchSerialsList);
        commit("SET_STATUS_REQUEST", { value: "success-getBranchSerialsList" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  setBranch: ({ commit }, payload) => {
    commit("SET_BRANCH", payload);
  },

  setBranchIDModelAddRoleServer: ({ commit }, payload) => {
    commit("SET_BRANCH_ID_MODEL_ADD_ROLE_SERVER", payload);
  },

  setFilteredBranchSerialsList: ({ commit }, payload) => {
    commit("SET_FILTERED_BRANCH_SERIALS_LIST", payload);
  },

  setPickedSerials: ({ commit }, payload) => {
    commit("SET_PICKED_SERIALS", payload);
  },

  setProductOption: ({ commit }, payload) => {
    commit("SET_PRODUCT_OPTION", payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
