const PrintRouter = {
  path: "/print",
  name: "print",
  component: () => import("@/modules/Print"),
  children: [
    {
      path: "order-invoice/:orderId",
      name: "print_order-invoice",
      meta: {
        private: true,
        layout: "print",
        title: "In hóa đơn | ThinkPro CMS"
      },
      component: () => import("../pages/OrderInvoice")
    },
    {
      path: "sale-receipt/:saleReceiptId",
      name: "print_sale-receipt",
      meta: {
        private: true,
        layout: "print",
        title: "In hóa đơn | ThinkPro CMS"
      },
      component: () => import("../pages/SaleReceipt.vue")
    },
    {
      path: "delivery-bill/:handleId",
      name: "print_delivery-bill",
      meta: {
        private: true,
        layout: "print",
        title: "In phiếu xuất kho | ThinkPro CMS"
      },
      component: () => import("../pages/DeliveryBill")
    },
    {
      path: "order-receipt-voucher/:orderId",
      name: "print_order-receipt-voucher",
      meta: {
        private: true,
        layout: "print",
        title: "In phiếu thu cọc | ThinkPro CMS"
      },
      component: () => import("../pages/OrderReceiptVoucher")
    },
    {
      path: "order-payment-transaction/:paymentTransactionId",
      name: "print_order-payment-transaction",
      meta: {
        private: true,
        layout: "print",
        title: "In phiếu thu cọc | ThinkPro CMS"
      },
      component: () => import("../pages/PaymentTransaction")
    }
  ]
};

export default PrintRouter;
