import Api from "@/core/apis";

export default {
  createPurchaseOrder(data) {
    return Api().post("po/add", data);
  },

  createPurchaseOrderOption(data) {
    return Api().post("po/option/add", data);
  },

  deletePurchaseOrderOption(id) {
    return Api().post("po/option/delete", {
      po_option_id: id
    });
  },

  distributePurchaseOrderOption(data) {
    return Api().post("po/assignToBranch", data);
  },

  forceDeletePurchaseOrder(id) {
    return Api().get(`po/forceDelete/${id}`);
  },

  getPurchaseOrderByCode(code) {
    return Api().get(`po/detail?po_code=${code}&po_area=hdn`);
  },

  getPurchaseOrderById(id) {
    return Api().get(`po/detail?po_id=${id}`);
  },

  getPurchaseOrderOptionById(id) {
    return Api().get(`po/po_option/detail/${id}`);
  },

  getPurchaseOrders(data) {
    return Api().post("po/all", data);
  },

  updatePurchaseOrder(data) {
    return Api().post("po/edit", data);
  },

  updatePurchaseOrderDistributionEstimateDate(data) {
    return Api().put("po/updateEstDate", data);
  },

  updatePurchaseOrderOption(data) {
    return Api().post("po/option/edit", data);
  }
};
