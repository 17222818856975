/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import PostCategoryService from "../service/post-category.service";
import MediaService from "@/core/service/media.service";
import { catchError } from "@/core/composables/useStore";
import store from "@/core/store";
import Vue from "vue";

const state = {
  allPostCategories: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  postCategories: [],
  postCategoriesTreeList: [],
  postCategory: {
    parent_id: null,
    name: null,
    priority: null,
    show_type: null,
    search_type: null,
    seo_data: {
      title: null,
      description: null,
      tags: []
    },
    status: 1,
    thumbnail: "",
    parent_show: 1
  },
  statusRequest: {
    message: null,
    value: null
  },
  uploadedThumbnail: null
};

const getters = {
  allPostCategories: state => state.allPostCategories,
  paginationInfo: state => state.paginationInfo,
  postCategories: state => state.postCategories,
  postCategory: state => state.postCategory,
  postCategoriesTreeList: state => state.postCategoriesTreeList,
  statusRequest: state => state.statusRequest,
  uploadedThumbnail: state => state.uploadedThumbnail
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ALL_POST_CATEGORIES: (state, payload) => {
    state.allPostCategories = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_POST_CATEGORIES: (state, payload) => {
    state.postCategories = payload;
  },

  SET_POST_CATEGORY: (state, payload) => {
    state.postCategory = payload;
  },
  SET_POST_CATEGORIES_BY_TREE: (state, payload) => {
    state.postCategoriesTreeList = payload;
  },

  SET_UPLOADED_THUMBNAIL: (state, payload) => {
    state.uploadedThumbnail = payload;
  }
};

const actions = {
  createPostCategory: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createPostCategory" });

      const response = await PostCategoryService.createPostCategory(
        payload.data
      );

      if ([201, 200].includes(response.status) && response.data) {
        // Get post categories
        await store.dispatch(
          "POST_CATEGORY/getPostCategories",
          payload.routeQuery
        );
        await store.dispatch("POST_CATEGORY/getPostCategoriesByTreeList");
        // Set id
        Vue.set(state.postCategory, "id", response.data.data.id);
        commit("SET_STATUS_REQUEST", { value: "success-createPostCategory" });
      }
    } catch (e) {
      catchError(e, commit, "createPostCategory");
    }
  },

  deletePostCategories: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deletePostCategories" });

      const response = await PostCategoryService.deletePostCategories(
        payload.data
      );

      if (response.status === 200) {
        // Get post categories
        await store.dispatch(
          "POST_CATEGORY/getPostCategories",
          payload.routeQuery
        );
        await store.dispatch("POST_CATEGORY/getPostCategoriesByTreeList");
        commit("SET_STATUS_REQUEST", { value: "success-deletePostCategories" });
      }
    } catch (e) {
      catchError(e, commit, "deletePostCategories");
    }
  },

  getAllPostCategories: async ({ commit }, data) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllPostCategories" });

      const response = await PostCategoryService.getAllPostCategories(data);

      if (response.status === 200 && response.data) {
        commit("SET_ALL_POST_CATEGORIES", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAllPostCategories" });
      }
    } catch (e) {
      catchError(e, commit, "getAllPostCategories");
    }
  },

  sortPostCategories: async ({ commit }, data) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-sortPostCategories" });

      const response = await PostCategoryService.sortPostCategories(data);

      if (response.status === 200 && response.data) {
        await store.dispatch("POST_CATEGORY/getAllPostCategories");

        commit("SET_STATUS_REQUEST", { value: "success-sortPostCategories" });
      }
    } catch (e) {
      catchError(e, commit, "sortPostCategories");
    }
  },

  getPostCategories: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPostCategories" });

      const response = await PostCategoryService.getPostCategories(payload);

      if (response.status === 200 && response.data.data) {
        commit(
          "SET_POST_CATEGORIES",
          response.data.data.map(item => {
            return {
              ...item,
              seo_data: item.seo_data || {}
            };
          })
        );
        commit("SET_PAGINATION_INFO", {
          from: 1,
          to: response.data.pagination.last_page,
          pageTotal: response.data.pagination.last_page,
          itemTotal: response.data.pagination.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getPostCategories" });
      }
    } catch (e) {
      catchError(e, commit, "getPostCategories");
    }
  },

  resetPostCategory: ({ commit }) => {
    commit("SET_POST_CATEGORY", {
      name: null,
      priority: null,
      seo_data: {
        title: null,
        description: null,
        tags: []
      }
    });
  },

  setPostCategory: ({ commit }, payload) => {
    commit("SET_POST_CATEGORY", payload);
  },

  updatePostCategory: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updatePostCategory" });

      const response = await PostCategoryService.updatePostCategory(
        payload.data
      );

      if (response.status === 200) {
        // Get post categories
        await store.dispatch(
          "POST_CATEGORY/getPostCategories",
          payload.routeQuery
        );
        await store.dispatch("POST_CATEGORY/getPostCategoriesByTreeList");
        commit("SET_STATUS_REQUEST", { value: "success-updatePostCategory" });
      }
    } catch (e) {
      catchError(e, commit, "updatePostCategory");
    }
  },

  getPostCategoryById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPostCategory" });

      const response = await PostCategoryService.getPostCategoryById(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_POST_CATEGORY", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getPostCategory" });
      }
    } catch (e) {
      catchError(e, commit, "getPostCategory");
    }
  },

  getPostCategoriesByTreeList: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPostCategories" });

      const response = await PostCategoryService.getPostCategoriesByTreeList();

      if (response.status === 200 && response.data.data) {
        commit("SET_POST_CATEGORIES_BY_TREE", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getPostCategories" });
      }
    } catch (e) {
      catchError(e, commit, "getPostCategoriesByTreeList");
    }
  },

  uploadThumbnail: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadThumbnail" });

      payload.append("folder", "social/articles");
      const response = await MediaService.uploadImage(payload);

      if (response.status === 200 && response.data) {
        commit("SET_UPLOADED_THUMBNAIL", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadThumbnail" });
      }
    } catch (e) {
      catchError(e, commit, "uploadThumbnail");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
