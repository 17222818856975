import Api from "@/core/apis";

export default {
  cancelGoodsReceipt(id) {
    return Api().get(`importGoods/new/destroy/${id}`);
  },

  createGoodsReceipt(data) {
    return Api().post("importGoods/new/add", data);
  },

  forceDeleteGoodsReceipt(id) {
    return Api().get(`importGoods/new/forceDelete/${id}`);
  },

  exportReportFile(payload) {
    return Api().post("export-report/import-goods", payload, {
      responseType: "blob"
    });
  },

  getGoodsReceiptById(id) {
    return Api().get(`importGoods/new/detail/${id}`);
  },

  getGoodsReceipts(data) {
    return Api().post("importGoods/new/all", data);
  },

  updateGoodsReceipt(data) {
    return Api().post("importGoods/new/edit", data);
  },

  updateImportFee(data) {
    return Api().post("importGoods/new/edit-fee", data);
  }
};
