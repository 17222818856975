import router from "@/core/router";
import { ModelName } from "../constants/constants";
import WaitingAssemblyNoteService from "../service/waiting-assembly-note.service";
import {
  generateActions,
  generateMutations,
  generateState,
  generateGetter
} from "../utils/store.utils";

const state = {
  ...generateState()
};

const getters = {
  ...generateGetter()
};

const mutations = {
  ...generateMutations()
};

const actions = {
  ...generateActions(ModelName.waitingAssemblyNote, "WAITING_ASSEMBLY_NOTE"),

  exportReportFile: async ({ commit }, data) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await WaitingAssemblyNoteService.exportExcel(data);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });
        return response.data;
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-exportReportFile"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
