import Api from "@/core/apis";

export default {
  getCategoriesBar() {
    return Api().get("category/getAllBuild");
  },

  updateCategoriesBar(data) {
    return Api().post("category/buildCategoryBar", {
      categories: data
    });
  }
};
