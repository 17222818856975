import MediaService from "../../../core/service/media.service";
import store from "@/core/store";
import SiteConfig from "../service/site-config.service";
import { catchError } from "../../../core/composables/useStore";
import { INIT_SITE_CONFIG_VALUE } from "@/modules/Website/constants";
import { isEmpty, toFormData } from "@vt7/utils";

const state = {
  siteConfig: JSON.parse(JSON.stringify(INIT_SITE_CONFIG_VALUE)),
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {};

const mutations = {
  SET_SITE_CONFIG: (state, payload) => {
    state.siteConfig = payload;
  },

  SET_SITE_ATTRIBUTE: (state, payload) => {
    state.siteConfig[payload.key] = payload.value;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  getSideConfig: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSiteConfig" });

      const response = await SiteConfig.getSideConfig(payload);

      if (response.status === 200 && response.data) {
        await commit("SET_SITE_CONFIG", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-getSideConfig" });
      }
    } catch (e) {
      catchError(e, commit, "getSideConfig");
    }
  },

  uploadImages: async ({ commit }, payload) => {
    try {
      console.log("payload", payload);
      if (isEmpty(payload)) {
        commit("SET_STATUS_REQUEST", { value: "success-uploadImages" });
        return;
      }

      for (const key in payload) {
        commit("SET_STATUS_REQUEST", { value: "loading-uploadImages" });

        const formData = toFormData({
          file: payload[key],
          folder: "core/site-configs"
        });

        const response = await MediaService.uploadImage(formData);

        if (response.status === 200 && response.data) {
          commit("SET_SITE_ATTRIBUTE", {
            key: key,
            value: response.data
          });
          commit("SET_STATUS_REQUEST", { value: "success-uploadImages" });
        } else {
          catchError(commit, "uploadImages", {
            isRedirected: false,
            message: "Lỗi tải ảnh lên"
          });
        }
      }
    } catch (e) {
      catchError(e, commit, "uploadImages");
    }
  },

  updateSiteConfig: async ({ commit }, { site, payload }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateSiteConfig" });

      const response = await SiteConfig.updateSideConfig(site, payload);

      if (response.status === 200) {
        await store.dispatch("SITE_CONFIG/getSideConfig", site);

        commit("SET_STATUS_REQUEST", { value: "success-updateSiteConfig" });
      }
    } catch (e) {
      catchError(e, commit, "updateSiteConfig");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
