/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import MegaMenuService from "../service/mega-menu.service";
import router from "@/core/router";

const state = {
  megaMenus: [],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedImage: null
};

const getters = {
  megaMenus: state => state.megaMenus,
  statusRequest: state => state.statusRequest,
  uploadedImage: state => state.uploadedImage
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_MEGA_MENUS: (state, payload) => {
    state.megaMenus = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  getMegaMenus: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getMegaMenus" });

      const response = await MegaMenuService.getMegaMenus(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_MEGA_MENUS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getMegaMenus" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getMegaMenus"
        });
      }
    }
  },

  updateMegaMenus: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateMegaMenus" });

      const response = await MegaMenuService.updateMegaMenus(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-updateMegaMenus" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateMegaMenus"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
