import Api from "@/core/apis";

export default {
  deleteFilter(id) {
    return Api().post(`user-profile-filters/destroy/${id}`);
  },
  getFilters() {
    return Api().get("user-profile-filters");
  },
  createFilter(data) {
    return Api().post("user-profile-filters/add", data);
  },
  updateFilter(id, data) {
    return Api().post(`user-profile-filters/update/${id}`, data);
  }
};
