import Api from "@/core/apis";

export default {
  createGuaranteePackage(data) {
    return Api().post("guarantee/package/add", data);
  },

  deleteGuaranteePackage(id) {
    return Api().get(`guarantee/package/delete/${id}`);
  },

  getAllGuaranteePackages() {
    return Api().get("guarantee/package/getAll");
  },

  getGuaranteePackages(data) {
    return Api().post("guarantee/package/all", data);
  },

  updateGuaranteePackage(data) {
    return Api().post("guarantee/package/edit", data);
  }
};
