import Api from "@/core/apis";

export default {
  /**
   * @param data // Is object with fields below
   * {
   *   id: Number,
   *   cate_ids: Array,
   *   type: Number // 1 - Brand, 2 - Sub Brand
   * }
   */
  addProductTypes(data) {
    return Api().post("brand/addProductCates", data);
  },

  createBrand(data) {
    return Api().post("brand/add", data);
  },

  createSubBrand(data) {
    return Api().post("brand/sub/add", data);
  },

  deleteBrands(data) {
    return Api().post("brand/delete", data);
  },

  deleteSubBrand(data) {
    return Api().post("brand/sub/delete", data);
  },

  getAllBrands(data) {
    return Api().get(`product/brands/list${data ? `?search=${data}` : ""}`);
  },

  getBrandById(id) {
    return Api().get(`brand/detail/${id}`);
  },

  getBrandProductTypes(data) {
    return Api().post("brand/detailProductCates", data);
  },

  getBrands(data) {
    return Api().post("brand/all", data);
  },

  sortBrands(data) {
    return Api().post("brand/sort", { data });
  },

  getBrandsFlat() {
    return Api().post("brand/flat");
  },

  getSubBrandById(id) {
    return Api().get(`brand/sub/detail/${id}`);
  },

  updateBrand(data) {
    return Api().post("brand/edit", data);
  },

  updateSubBrand(data) {
    return Api().post("brand/sub/edit", data);
  },

  uploadCover(data) {
    return Api().post("brand/cover/upload", data);
  },

  uploadIcon(data) {
    return Api().post("brand/icon/upload", data);
  }
};
