import { INIT_SITE_CONFIG_SEO_DATA } from "@/core/constant";

export const INIT_PRODUCT_VALUE = {
  articles: [
    { article_id: null, articlet: null, site: "THINKPRO" },
    { article_id: null, article: null, site: "GOODSPACE" }
  ],
  code: null,
  name: null,
  thumbnail: null,
  temporary_thumbnail: {
    path: null,
    expired_time: new Date().toISOString()
  },
  brand_id: null,
  sub_brand_id: null,
  category_id: null,
  priority: null,
  ttkd: 1,
  ttrm: 0,
  front_display: 1,
  tags: [],
  images: [],
  seo_data: JSON.parse(JSON.stringify(INIT_SITE_CONFIG_SEO_DATA))
};
