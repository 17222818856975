import Api from "@/core/apis";

export default {
  createThirdStore(data) {
    return Api().post("thirdParty/add", data);
  },

  deleteThirdStores(data) {
    return Api().post("thirdParty/delete", data);
  },

  /**
   * Get third store list items
   * @param {*} data
   * {
   *    cur_page: Number,
   *    per_page: Number,
   *    search: String,
   *    filter: {
   *      supplier_id: Array,
   *      status: Array (1 - Còn hàng, 2 - Hết hàng, 3 -  Đang về),
   *      fromPrice: Number,
   *      toPice: Number
   *    }
   * }
   */

  getThirdStores(data) {
    return Api().post("thirdParty/list", data);
  },

  exportExcel() {
    return Api().post("thirdParty/exportExcel", null, {
      responseType: "blob"
    });
  },

  importExcel(data) {
    return Api().post("thirdParty/importExcel", data);
  },

  updateThirdStore(data) {
    return Api().post("thirdParty/edit", data);
  }
};
