/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import PurchaseOrderService from "../service/purchase-order.service";
import router from "@/core/router";
import store from "@/core/store";
import Vue from "vue";

const state = {
  purchaseOrder: {
    supplier: null,
    pay_deadline: null,
    action: null, // 0 - Draft, 1 - Send request , 2 - Accept , 3 - In progress, 4 - Cancel
    options: []
  },
  purchaseOrderListTotals: {
    total_value: 0
  },
  purchaseOrderOption: {
    po_id: null,
    option_id: null,
    option: null,
    import_price: null,
    import_qty: null,
    has_vat: 1,
    has_gift: 0,
    gifts: [],
    rebates: [],
    assignments: []
  },
  purchaseOrderOptionActiveIndex: null,
  purchaseOrders: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  purchaseOrder: state => state.purchaseOrder,
  purchaseOrderListTotals: state => state.purchaseOrderListTotals,
  purchaseOrderOption: state => state.purchaseOrderOption,
  purchaseOrderOptionActiveIndex: state => state.purchaseOrderOptionActiveIndex,
  purchaseOrderOptionRebateTotal: state => {
    if (state.purchaseOrderOption.rebates.length > 0) {
      let total = 0;

      state.purchaseOrderOption.rebates.forEach(item => {
        if (item.value) {
          total += parseInt(item.value);
        }
      });

      return total;
    } else {
      return 0;
    }
  },
  purchaseOrders: state => state.purchaseOrders,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest,
  totalValue: state => state.totalValue
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PURCHASE_ORDER: (state, payload) => {
    state.purchaseOrder = payload;
  },

  SET_PURCHASE_ORDER_LIST_TOTALS: (state, payload) => {
    state.purchaseOrderListTotals = payload;
  },

  SET_PURCHASE_ORDER_OPTION: (state, payload) => {
    state.purchaseOrderOption = payload;
  },

  SET_PURCHASE_ORDER_OPTION_ACTIVE_INDEX: (state, payload) => {
    state.purchaseOrderOptionActiveIndex = payload;
  },

  SET_PURCHASE_ORDERS: (state, payload) => {
    state.purchaseOrders = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_TOTAL_VALUE: (state, payload) => {
    state.totalValue = payload;
  }
};

const actions = {
  createPurchaseOrder: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createPurchaseOrder"
      });

      const response = await PurchaseOrderService.createPurchaseOrder(payload);

      if (response.status === 200 && response.data) {
        // Set id
        Vue.set(state.purchaseOrder, "id", response.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-createPurchaseOrder"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: "Có lỗi xảy ra, xin vui lòng kiểm tra thông tin và thử lại.",
          value: "error-distributePurchaseOrderOption"
        });
      }
    }
  },

  createPurchaseOrderOption: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createPurchaseOrderOption"
      });

      const response = await PurchaseOrderService.createPurchaseOrderOption(
        payload
      );

      if (response.status === 200 && response.data) {
        // Update purchase order and purchase order option
        await store.dispatch(
          "PURCHASE_ORDER/getPurchaseOrderById",
          state.purchaseOrder.id
        );
        await store.dispatch(
          "PURCHASE_ORDER/getPurchaseOrderOptionById",
          response.data
        );

        commit("SET_STATUS_REQUEST", {
          value: "success-createPurchaseOrderOption"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: "Có lỗi xảy ra, xin vui lòng kiểm tra thông tin và thử lại.",
          value: "error-distributePurchaseOrderOption"
        });
      }
    }
  },

  distributePurchaseOrderOption: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-distributePurchaseOrderOption"
      });

      const response = await PurchaseOrderService.distributePurchaseOrderOption(
        payload.data
      );

      if (response.status === 200 && response.data) {
        // Get purchase order option
        await store.dispatch(
          "PURCHASE_ORDER/getPurchaseOrderOptionById",
          payload.purchaseOrderOptionId
        );
        // Update purchase order option in options of purchase order state
        await store.dispatch(
          "PURCHASE_ORDER/getPurchaseOrderById",
          state.purchaseOrder.id
        );

        commit("SET_STATUS_REQUEST", {
          value: "success-distributePurchaseOrderOption"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: "Có lỗi xảy ra, xin vui lòng kiểm tra thông tin và thử lại.",
          value: "error-distributePurchaseOrderOption"
        });
      }
    }
  },

  deletePurchaseOrderOption: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-deletePurchaseOrderOption"
      });

      const response = await PurchaseOrderService.deletePurchaseOrderOption(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-deletePurchaseOrderOption"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-deletePurchaseOrderOption"
        });
      }
    }
  },

  forceDeletePurchaseOrder: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-forceDeletePurchaseOrder"
      });

      const response = await PurchaseOrderService.forceDeletePurchaseOrder(
        payload
      );

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", {
          value: "success-forceDeletePurchaseOrder"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-forceDeletePurchaseOrder"
        });
      }
    }
  },

  getPurchaseOrderByCode: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPurchaseOrderByCode" });

      const response = await PurchaseOrderService.getPurchaseOrderByCode(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_PURCHASE_ORDER", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getPurchaseOrderByCode"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getPurchaseOrderByCode"
        });
      }
    }
  },

  getPurchaseOrderById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPurchaseOrderById" });

      const response = await PurchaseOrderService.getPurchaseOrderById(payload);

      if (response.status === 200 && response.data.data) {
        response.data.data.action = null;
        commit("SET_PURCHASE_ORDER", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getPurchaseOrderById" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getPurchaseOrderById"
        });
      }
    }
  },

  getPurchaseOrderOptionById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getPurchaseOrderOptionById"
      });

      const response = await PurchaseOrderService.getPurchaseOrderOptionById(
        payload
      );

      if (response.status === 200 && response.data.data) {
        const purchaseOrderOption = {
          option_id: response.data.data.option.id,
          ...response.data.data
        };

        commit("SET_PURCHASE_ORDER_OPTION", purchaseOrderOption);
        commit("SET_STATUS_REQUEST", {
          value: "success-getPurchaseOrderOptionById"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getPurchaseOrderOptionById"
        });
      }
    }
  },

  getPurchaseOrders: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPurchaseOrders" });

      const response = await PurchaseOrderService.getPurchaseOrders(payload);

      if (response.status === 200 && response.data) {
        commit("SET_PURCHASE_ORDERS", response.data.data);
        // Set pagination
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        // Set totals
        commit("SET_PURCHASE_ORDER_LIST_TOTALS", {
          total_value: response.data.meta.total_value
            ? response.data.meta.total_value
            : 0
        });

        commit("SET_STATUS_REQUEST", { value: "success-getPurchaseOrders" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getPurchaseOrders"
        });
      }
    }
  },

  resetPurchaseOrder: async ({ commit }) => {
    commit("SET_PURCHASE_ORDER", {
      supplier: null,
      pay_deadline: null,
      action: null
    });
  },

  resetPurchaseOrderOption: async ({ commit }) => {
    commit("SET_PURCHASE_ORDER_OPTION", {
      po_id: null,
      option_id: null,
      option: null,
      import_price: null,
      import_qty: null,
      has_vat: 1,
      has_gift: 0,
      gifts: [],
      rebates: [],
      assignments: []
    });
  },

  setPurchaseOrder: ({ commit }, payload) => {
    commit("SET_PURCHASE_ORDER", payload);
  },

  setPurchaseOrderOption: ({ commit }, payload) => {
    commit("SET_PURCHASE_ORDER_OPTION", payload);
  },

  setPurchaseOrderOptionActiveIndex: ({ commit }, payload) => {
    commit("SET_PURCHASE_ORDER_OPTION_ACTIVE_INDEX", payload);
  },

  updatePurchaseOrder: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updatePurchaseOrder"
      });

      const response = await PurchaseOrderService.updatePurchaseOrder(payload);

      if (response.status === 200 && response.data) {
        // Get purchase order
        store.dispatch("PURCHASE_ORDER/getPurchaseOrderById", response.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-updatePurchaseOrder"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: "Có lỗi xảy ra, xin vui lòng kiểm tra thông tin và thử lại.",
          value: "error-updatePurchaseOrder"
        });
      }
    }
  },

  updatePurchaseOrderDistributionEstimateDate: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updatePurchaseOrderDistributionEstimateDate"
      });

      const response = await PurchaseOrderService.updatePurchaseOrderDistributionEstimateDate(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-updatePurchaseOrderDistributionEstimateDate"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: "Có lỗi xảy ra, xin vui lòng kiểm tra thông tin và thử lại.",
          value: "error-updatePurchaseOrderDistributionEstimateDate"
        });
      }
    }
  },

  updatePurchaseOrderOption: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updatePurchaseOrderOption"
      });

      const response = await PurchaseOrderService.updatePurchaseOrderOption(
        payload
      );

      if (response.status === 200 && response.data) {
        // Update purchase order option in options of purchase order state
        await store.dispatch(
          "PURCHASE_ORDER/getPurchaseOrderById",
          state.purchaseOrder.id
        );
        await store.dispatch(
          "PURCHASE_ORDER/getPurchaseOrderOptionById",
          payload.id
        );

        commit("SET_STATUS_REQUEST", {
          value: "success-updatePurchaseOrderOption"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: "Có lỗi xảy ra, xin vui lòng kiểm tra thông tin và thử lại.",
          value: "error-updatePurchaseOrderOption"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
