const GuaranteeRouter = {
  path: "/guarantee",
  component: () => import("@/modules/Guarantee"),
  redirect: { name: "guarantee_search" },
  children: [
    {
      path: "search",
      name: "guarantee_search",
      meta: {
        private: true,
        layout: "general",
        title: "Tra cứu bảo hành | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Tra cứu bảo hành",
            to: { name: "guarantee_search" }
          }
        ]
      },
      component: () => import("../pages/Searching")
    },
    {
      path: "vouchers",
      component: () => import("../pages/GuaranteeVouchers"),
      children: [
        {
          path: "list",
          name: "guarantee_vouchers",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu bảo hành/sửa chữa | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Phiếu bảo hành/sửa chữa",
                to: { name: "guarantee_vouchers" }
              }
            ]
          },
          component: () => import("../pages/GuaranteeVouchers/Listing")
        },
        {
          path: "create",
          name: "guarantee_vouchers-create",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu bảo hành/sửa chữa | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu bảo hành/sửa chữa",
                to: { name: "guarantee_vouchers" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới",
                to: { name: "guarantee_vouchers-create" }
              }
            ]
          },
          props: route => ({ type: route.query.type }),
          component: () => import("../pages/GuaranteeVouchers/Detail")
        },
        {
          path: "detail/:guaranteeId",
          name: "guarantee_vouchers-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Phiếu bảo hành/sửa chữa | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Phiếu bảo hành/sửa chữa",
                to: { name: "guarantee_vouchers" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới",
                to: { name: "guarantee_vouchers-create" }
              }
            ]
          },
          props: route => ({ type: route.query.type }),
          component: () => import("../pages/GuaranteeVouchers/Detail")
        }
      ]
    },
    {
      path: "packages",
      name: "guarantee_packages",
      meta: {
        private: true,
        layout: "general",
        title: "Gói bảo hành | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Gói bảo hành",
            to: { name: "guarantee_packages" }
          }
        ]
      },
      component: () => import("../pages/GuaranteePackages")
    }
  ]
};

export default GuaranteeRouter;
