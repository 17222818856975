import Api from "@/core/apis";

export default {
  cancelPaymentTransaction(transactionId, note) {
    return Api().post("transactions/cancel", {
      transaction_id: transactionId,
      note: note
    });
  },

  createPaymentTransaction(data) {
    return Api().post("transactions", {
      transactions: data
    });
  },

  getPaymentTransactionById(id) {
    return Api().get(`transactions/${id}`);
  }
};
