/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import GoodsReceiptService from "../service/goods-receipt.service";
import router from "@/core/router";
import store from "@/core/store";
import { catchError } from "@/core/composables";

const state = {
  goodsReceipt: {
    po: null,
    po_id: null,
    branch_id: null,
    date_ncc: null,
    code_ncc: null,
    transfer_fee: null,
    global_tracking: null,
    is_import: 0,
    po_option_branch_serials: [],
    returned_rebates: [
      {
        code: null,
        value: null,
        left: null
      }
    ],
    generated_rebates: []
  },
  goodsReceiptGifts: [],
  goodsReceiptListTotals: {
    total_bill: 0,
    total_imported: 0,
    total_payment: 0
  },
  goodsReceiptOption: null,
  goodsReceiptOptionActiveIndex: 0,
  goodsReceiptOptions: [],
  goodsReceipts: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  goodsReceipt: state => state.goodsReceipt,
  goodsReceiptGifts: state => state.goodsReceiptGifts,
  goodsReceiptListTotals: state => state.goodsReceiptListTotals,
  goodsReceiptOption: state => state.goodsReceiptOption,
  goodsReceiptOptionActiveIndex: state => state.goodsReceiptOptionActiveIndex,
  goodsReceiptOptionGifts: state => {
    if (state.goodsReceiptGifts.length > 0) {
      return state.goodsReceiptGifts.filter(item => {
        return (
          item.po_option_gift_branch.po_option_branch_id ===
          state.goodsReceiptOption.po_option_branch_id
        );
      });
    }
    return [];
  },
  goodsReceiptOptions: state => state.goodsReceiptOptions,
  goodsReceipts: state => state.goodsReceipts,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_GOODS_RECEIPT: (state, payload) => {
    state.goodsReceipt = payload;
  },

  SET_GOODS_RECEIPT_GIFTS: (state, payload) => {
    state.goodsReceiptGifts = payload;
  },

  SET_GOODS_RECEIPT_LIST_TOTALS: (state, payload) => {
    state.goodsReceiptListTotals = payload;
  },

  SET_GOODS_RECEIPT_OPTION: (state, payload) => {
    state.goodsReceiptOption = payload;
  },

  SET_GOODS_RECEIPT_OPTION_ACTIVE_INDEX: (state, payload) => {
    state.goodsReceiptOptionActiveIndex = payload;
  },

  SET_GOODS_RECEIPT_OPTIONS: (state, payload) => {
    state.goodsReceiptOptions = payload;
  },

  SET_GOODS_RECEIPTS: (state, payload) => {
    state.goodsReceipts = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  cancelGoodsReceipt: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-cancelGoodsReceipt" });

      const response = await GoodsReceiptService.cancelGoodsReceipt(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-cancelGoodsReceipt" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-cancelGoodsReceipt"
        });
      }
    }
  },

  createGoodsReceipt: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createGoodsReceipt"
      });

      const response = await GoodsReceiptService.createGoodsReceipt(payload);

      if (response.status === 200 && response.data) {
        // Get goods receipt
        await store.dispatch(
          "GOODS_RECEIPT/getGoodsReceiptById",
          response.data
        );

        commit("SET_STATUS_REQUEST", {
          value: "success-createGoodsReceipt"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        // router.push({ name: "500" });
        commit("SET_STATUS_REQUEST", {
          message: "Có lỗi xảy ra, xin vui lòng kiểm tra thông tin và thử lại.",
          value: "error-updateGoodsReceipt"
        });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createGoodsReceipt"
        });
      }
    }
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await GoodsReceiptService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportFile", { isRedirected: false });
    }
  },

  forceDeleteGoodsReceipt: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-forceDeleteGoodsReceipt"
      });

      const response = await GoodsReceiptService.forceDeleteGoodsReceipt(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-forceDeleteGoodsReceipt"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-forceDeleteGoodsReceipt"
        });
      }
    }
  },

  getGoodsReceiptById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getGoodsReceiptById" });

      const response = await GoodsReceiptService.getGoodsReceiptById(payload);

      if (response.status === 200 && response.data.data) {
        const goodsReceipt = response.data.data;

        // Define get serials from draft function
        const getSerialsFromDraft = function(id) {
          if (
            goodsReceipt.drafts &&
            goodsReceipt.drafts.po_option_branch_serials
          ) {
            const res = goodsReceipt.drafts.po_option_branch_serials.find(
              item => item.hdn_po_option_branch_id === id
            );

            return res ? res.serials : [];
          }
          return [];
        };

        let goodsReceiptOptions = [],
          goodsReceiptGifts = [];

        // Set po id
        goodsReceipt.po_id = goodsReceipt.po.id ? goodsReceipt.po.id : null;

        // Set options and gifts
        if (
          goodsReceipt.hdn_po_option_branches &&
          goodsReceipt.hdn_po_option_branches.length > 0
        ) {
          // Filter options
          await goodsReceipt.hdn_po_option_branches.forEach(item => {
            if (item.po_option_branch_id) {
              goodsReceiptOptions.push({
                ...item,
                serials: getSerialsFromDraft(item.id)
              });
            }
          });

          // Filter gifts
          await goodsReceipt.hdn_po_option_branches.forEach(item => {
            if (item.po_option_gift_branch_id) {
              goodsReceiptGifts.push({
                ...item,
                serials: getSerialsFromDraft(item.id)
              });
            }
          });
        }

        // Generate rebates when status is 0 - draft and -1 - cancel
        if (goodsReceipt.status === 0 || goodsReceipt.status === -1) {
          const rebates = [];

          goodsReceiptOptions.forEach(item => {
            if (
              item.serials.length > 0 &&
              item.po_option_branch.option.rebates.length > 0
            ) {
              item.po_option_branch.option.rebates.forEach(rebate => {
                rebates.push({
                  po_option_branch_id: item.po_option_branch_id,
                  code: `CKTM-XXXX-XXXX`,
                  total: rebate.value * item.serials.length
                });
              });
            }
          });

          goodsReceipt.generated_rebates = rebates;
        }

        // Get returned rebates from draft
        if (
          goodsReceipt.drafts.returned_rebates &&
          goodsReceipt.drafts.returned_rebates.length > 0
        ) {
          if (goodsReceipt.status === 0 || goodsReceipt.status === -1) {
            goodsReceipt.returned_rebates =
              goodsReceipt.drafts.returned_rebates;
          }
        }

        commit("SET_GOODS_RECEIPT_OPTIONS", goodsReceiptOptions);
        commit("SET_GOODS_RECEIPT_GIFTS", goodsReceiptGifts);
        commit("SET_GOODS_RECEIPT", goodsReceipt);
        commit("SET_STATUS_REQUEST", { value: "success-getGoodsReceiptById" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getGoodsReceiptById"
        });
      }
    }
  },

  getGoodsReceipts: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getGoodsReceipts" });

      const response = await GoodsReceiptService.getGoodsReceipts(payload);

      if (response.status === 200 && response.data) {
        commit("SET_GOODS_RECEIPTS", response.data.data);
        // Set pagination info
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        // Set totals
        commit("SET_GOODS_RECEIPT_LIST_TOTALS", {
          total_bill: response.data.meta.total_bill
            ? response.data.meta.total_bill
            : 0,
          total_imported: response.data.meta.total_imported
            ? response.data.meta.total_imported
            : 0,
          total_payment: response.data.meta.total_payment
            ? response.data.meta.total_payment
            : 0
        });

        commit("SET_STATUS_REQUEST", { value: "success-getGoodsReceipts" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getGoodsReceipts"
        });
      }
    }
  },

  resetGoodsReceipt: async ({ commit }) => {
    commit("SET_GOODS_RECEIPT", {
      po: null,
      po_id: null,
      branch_id: null,
      date_ncc: null,
      code_ncc: null,
      transfer_fee: null,
      global_tracking: null,
      is_import: 0,
      po_option_branch_serials: [],
      returned_rebates: [],
      generated_rebates: [],
      import_fee: 0
    });
  },

  setGoodsReceipt: ({ commit }, payload) => {
    commit("SET_GOODS_RECEIPT", payload);
  },

  setGoodsReceiptGifts: ({ commit }, payload) => {
    commit("SET_GOODS_RECEIPT_GIFTS", payload);
  },

  setGoodsReceiptOption: ({ commit }, payload) => {
    commit("SET_GOODS_RECEIPT_OPTION", payload);
  },

  setGoodsReceiptOptionActiveIndex: ({ commit }, payload) => {
    commit("SET_GOODS_RECEIPT_OPTION_ACTIVE_INDEX", payload);
  },

  setGoodsReceiptOptions: ({ commit }, payload) => {
    commit("SET_GOODS_RECEIPT_OPTIONS", payload);
  },

  updateGoodsReceipt: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updateGoodsReceipt"
      });

      const response = await GoodsReceiptService.updateGoodsReceipt(payload);

      if (response.status === 200 && response.data) {
        // Get goods receipt
        await store.dispatch("GOODS_RECEIPT/getGoodsReceiptById", payload.id);

        commit("SET_STATUS_REQUEST", {
          value: "success-updateGoodsReceipt"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        // router.push({ name: "500" });
        commit("SET_STATUS_REQUEST", {
          message: "Có lỗi xảy ra, xin vui lòng kiểm tra thông tin và thử lại.",
          value: "error-updateGoodsReceipt"
        });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: "Có lỗi xảy ra, xin vui lòng kiểm tra thông tin và thử lại.",
          value: "error-updateGoodsReceipt"
        });
      }
    }
  },

  updateImportFee: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updateImportFee"
      });

      const response = await GoodsReceiptService.updateImportFee(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-updateImportFee"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        commit("SET_STATUS_REQUEST", {
          message: "Có lỗi xảy ra, xin vui lòng kiểm tra thông tin và thử lại.",
          value: "error-updateImportFee"
        });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: "Có lỗi xảy ra, xin vui lòng kiểm tra thông tin và thử lại.",
          value: "error-updateImportFee"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
