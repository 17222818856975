import moment from "moment";
import "moment/locale/vi";
import { timeLeftFromCurent } from "@/core/utils";

moment.locale("vi");

export const PROMOTION_TYPE = {
  2: { value: 2, label: "Giảm tiền" },
  3: { value: 3, label: "Mua X KM Y" },
  4: { value: 4, label: "Tặng quà" },
  5: { value: 5, label: "Flash sale" },
  6: { value: 6, label: "Pre Order" },
  7: { value: 6, label: "Combo" }
};

export const PROMOTION_TYPE_ARRAY = Object.values(PROMOTION_TYPE);

export const PROMOTION_STATUS = {
  1: {
    color: "green",
    text: "Đang chạy",
    relativeDate: (_startDate, endDate) => {
      if (!endDate) return;
      return `- Còn ${timeLeftFromCurent(endDate)}`;
    }
  },
  2: {
    color: "primary",
    text: "Đã lên lịch",
    // eslint-disable-next-line no-unused-vars
    relativeDate: (startDate, _endDate) => {
      if (!startDate) return;
      return `- Còn ${timeLeftFromCurent(startDate)}`;
    }
  },
  3: {
    color: "grey",
    text: "Hết hạn",
    relativeDate: () => {
      return "";
    }
  },
  4: {
    color: "grey",
    text: "Hết lượt sử dụng",
    relativeDate: () => {
      return "";
    }
  }
};
