import Api from "@/core/apis";

export default {
  createProductOption(data) {
    return Api().post("option/add", data);
  },

  deleteProductOptions(data) {
    return Api().post("option/delete", data);
  },

  exportReportFile(payload) {
    return Api().post("export-report/inventory", payload, {
      responseType: "blob"
    });
  },

  getCategoryAttributes(categoryId) {
    return Api().post("attributes/list", {
      filter: { category_id: [categoryId] }
    });
  },

  getProductOptionById(id) {
    return Api().get(`option/get/${id}`);
  },

  getProductOptionInfo(id) {
    return Api().get(`option/option/thongTinChung/${id}`);
  },

  getProductOptions(data) {
    return Api().post("option/options/list", data);
  },

  getProductOptionSerials(data) {
    return Api().post(`option/option/serials/${data.id}`, data.filters);
  },

  getProductOptionSerialsByOptionId(id) {
    return Api().get(`option/${id}/serials`);
  },

  getProductOptionsByProductId(productId) {
    return Api().get(`option/options/list/${productId}`);
  },

  getProductOptionStockInfoByBranches(productOptionId, branchIds) {
    return Api().post(`option/${productOptionId}/additional-info`, {
      branch_id: branchIds
    });
  },

  getProductOptionTransactionHistory(data) {
    return Api().post(`option/option/lichSuGD/${data.id}`, data.filters);
  },

  importExcel(data) {
    return Api().post("option/importExcel", data);
  },

  /**
   * Search product option by code/name of product and sku
   * @param {*} data
   * {
   *  search: String
   * }
   */
  searchProductOptions(data) {
    return Api().post("option/search", data);
  },

  updateBusinessStatus(data) {
    return Api().post("option/edit/editTtkd", data);
  },

  updateListingDisplay(data) {
    return Api().post("option/edit/editFrontDisplay", data);
  },

  updatePreOrder(data) {
    return Api().post("option/edit-preorder", data);
  },

  updateProductOption(data) {
    return Api().post("option/edit", data);
  },

  uploadProductOptionImages(data) {
    return Api().post("option/images/upload", data);
  }
};
