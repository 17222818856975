import Api from "@/core/apis";

export default {
  deleteRemoveAccess(data) {
    return Api().get(`/downgrade/delete/${data}`);
  },

  exportReportFile(data) {
    return Api().post("export-report/downgrade-gear", data, {
      responseType: "blob"
    });
  },

  getRemoveAccess(data) {
    return Api().get(`/downgrade/detail/${data}`);
  },

  getRemoveAccesses(data) {
    return Api().post("/downgrade/all", data);
  },

  getSerialBySerialNumber(data) {
    return Api().get(`/serial/getSerialBySerialNumber/${data}`);
  },

  removeAnAccess(data) {
    return Api().post("/downgrade/add", data);
  }
};
