import Api from "@/core/apis";

export default {
  createDemand(data) {
    return Api().post("demands", data);
  },

  getDemands() {
    return Api().post("demands/list");
  },

  updateDemand(data) {
    return Api().put("demands", data);
  }
};
