/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import ProductGroupService from "../service/product-group.service";
import router from "@/core/router";
import store from "@/core/store";
import Vue from "vue";

const state = {
  allProductGroups: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  },
  productGroup: {
    name: null,
    description: null,
    type: 1,
    list_type: 1,
    dks: []
  },
  productGroupConditions: [],
  productGroups: []
};

const getters = {
  allProductGroups: state => state.allProductGroups,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest,
  productGroup: state => state.productGroup,
  productGroupConditions: state => state.productGroupConditions,
  productGroups: state => state.productGroups
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ALL_PRODUCT_GROUPS: (state, payload) => {
    state.allProductGroups = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_PRODUCT_GROUP: (state, payload) => {
    state.productGroup = payload;
  },

  SET_PRODUCT_GROUP_CONDITIONS: (state, payload) => {
    state.productGroupConditions = payload;
  },

  SET_PRODUCT_GROUPS: (state, payload) => {
    state.productGroups = payload;
  }
};

const actions = {
  createProductGroup: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createProductGroup" });

      const response = await ProductGroupService.createProductGroup(
        payload.data
      );

      if (response.status === 200 && response.data) {
        // Get product groups
        await store.dispatch(
          "PRODUCT_GROUP/getProductGroups",
          payload.routeQuery
        );
        // Set id
        Vue.set(state.productGroup, "id", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-createProductGroup" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createProductGroup"
        });
      }
    }
  },

  deleteProductGroups: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteProductGroups" });

      const response = await ProductGroupService.deleteProductGroups(
        payload.data
      );

      if (response.status === 200) {
        // Get product groups
        await store.dispatch(
          "PRODUCT_GROUP/getProductGroups",
          payload.routeQuery
        );
        commit("SET_STATUS_REQUEST", { value: "success-deleteProductGroups" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-deleteProductGroups"
        });
      }
    }
  },

  getAllProductGroups: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllProductGroups" });

      const response = await ProductGroupService.getAllProductGroups();

      if (response.status === 200 && response.data.data) {
        commit("SET_ALL_PRODUCT_GROUPS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAllProductGroups" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getAllProductGroups"
        });
      }
    }
  },

  getProductGroupById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getProductGroupById" });

      const response = await ProductGroupService.getProductGroupById(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_PRODUCT_GROUP", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getProductGroupById" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getProductGroupConditions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getProductGroupConditions"
      });

      const response = await ProductGroupService.getProductGroupConditions(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_PRODUCT_GROUP_CONDITIONS", response.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getProductGroupConditions"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  getProductGroups: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getProductGroups" });

      const response = await ProductGroupService.getProductGroups(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_PRODUCT_GROUPS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getProductGroups" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      }
    }
  },

  resetProductGroup: ({ commit }) => {
    commit("SET_PRODUCT_GROUP", {
      name: null,
      description: null,
      type: 1,
      list_type: 1,
      dks: []
    });
  },

  updateProductGroup: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateProductGroup" });

      const response = await ProductGroupService.updateProductGroup(
        payload.data
      );

      if (response.status === 200) {
        // Get products
        await store.dispatch(
          "PRODUCT_GROUP/getProductGroups",
          payload.routeQuery
        );
        commit("SET_STATUS_REQUEST", { value: "success-updateProductGroup" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateProductGroup"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
