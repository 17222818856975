/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import ReturnSerialsService from "../service/return-serial.service";
import router from "@/core/router";
import store from "@/core/store";

const state = {
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  returnSerials: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  paginationInfo: state => state.paginationInfo,
  returnSerials: state => state.returnSerials,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_RETURN_SERIALS: (state, payload) => {
    state.returnSerials = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  getReturnSerials: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getReturnSerials" });

      const response = await ReturnSerialsService.getReturnSerials(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_RETURN_SERIALS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getReturnSerials" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getReturnSerials"
        });
      }
    }
  },

  importReturnSerial: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-importReturnSerial" });

      const response = await ReturnSerialsService.importReturnSerial(
        payload.data
      );

      if (response.status === 200) {
        // Get Return Serials
        await store.dispatch(
          "RETURN_SERIAL/getReturnSerials",
          payload.routeQuery
        );

        commit("SET_STATUS_REQUEST", { value: "success-importReturnSerial" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-importReturnSerial"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
