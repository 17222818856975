import Api from "@/core/apis";

export default {
  createPost(data) {
    return Api().post("/post/store", data);
  },

  deletePost(id) {
    return Api().delete(`/post/${id}/delete`);
  },

  getPosts(data) {
    return Api().post("/post", data);
  },

  getPostById(id) {
    return Api().get(`/post/detail/${id}`);
  },

  updatePost(data) {
    const { id } = data;
    return Api().post(`/post/${id}/update`, data);
  }
};
