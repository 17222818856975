<template>
  <v-autocomplete
    v-model="model"
    :dense="dense"
    :flat="flat"
    :no-data-text="noDataText"
    :outlined="outlined"
    :rules="rules"
    class="tp-filter-autocomplete text-body-1"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-autocomplete>
</template>

<script>
import validate from "@/core/helpers/validate";

export default {
  name: "TpAutocomplete",

  props: {
    dense: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: true
    },
    noDataText: {
      type: String,
      default: "Không có dữ liệu"
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },

  mixins: [validate]
};
</script>

<style scoped></style>
