import { computed, getCurrentInstance } from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import router from "@/core/router";
import { DEFAULT_ERROR_MESSAGE } from "@/core/constant";

export const OUT_OF_SCOPE =
  "getCurrentInstance() returned null. Method must be called at the top of a setup() function.";

export function catchError(
  e,
  commit,
  action,
  options = { isRedirected: true, message: DEFAULT_ERROR_MESSAGE }
) {
  if (options.isRedirected && e.response && e.response.status === 500) {
    router.push({ name: "500" });
  } else {
    commit("SET_STATUS_REQUEST", {
      message:
        e.response && e.response.data ? e.response.data : options.message,
      value: `error-${action}`
    });
  }
}

export function useStore() {
  const inst = getCurrentInstance();

  if (inst != null) {
    // @ts-expect-error Vuex already registerd
    return inst.proxy.$store;
  } else {
    console.warn(
      "getCurrentInstance() returned null. Method must be called at the top of a setup() function."
    );
  }
  return undefined;
}

export function createVuexHelpers() {
  return { useState, useGetters, useMutations, useActions };
}

/**
 * Get Vuex state
 *
 * @param args - state name
 */
function useState(...args) {
  const states = mapState(...args);
  const result = {};
  Object.keys(states).forEach(key => {
    result[key] = computed(states[key]);
  });
  return result;
}

/**
 * Get Vuex Getters
 *
 * @param args - getter name
 */
function useGetters(...args) {
  const getters = mapGetters(...args);
  const result = {};
  Object.keys(getters).forEach(key => {
    result[key] = computed(getters[key]);
  });
  return result;
}

/**
 * Get Vue Instance
 */
function getVueInstance() {
  const vm = getCurrentInstance();
  if (vm != null) {
    return vm.proxy;
  }
  throw new Error(OUT_OF_SCOPE);
}

/**
 * Get Vuex Mutations.
 *
 * @param args - mutation name
 * @returns
 */
function useMutations(...args) {
  const vm = getVueInstance();
  const result = {};
  const mutations = mapMutations(...args);
  Object.keys(mutations).forEach(key => {
    result[key] = mutations[key].bind(vm);
  });
  return result;
}

/**
 * Get Vuex actions
 * @param args - Vuex Action
 * @returns
 */
function useActions(...args) {
  const vm = getVueInstance();
  const result = {};
  const actions = mapActions(...args);
  Object.keys(actions).forEach(key => {
    result[key] = actions[key].bind(vm);
  });
  return result;
}
