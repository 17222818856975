import store from "@/core/store";
import _ from "lodash";
import router from "@/core/router";
import NotSoldWarehouseService from "../service/not-sold-warehouse.service";
import { SERIAL_STATUS } from "../constants/constants";

export const generateActions = (modelName, storeName) => {
  return {
    addOffBiz: async ({ commit, state }, data) => {
      try {
        commit("SET_STATUS_REQUEST", { value: "loading-addOffBiz" });
        let serials = [];

        data.options.forEach(option => {
          const arr = option.serials.map(item => item.id);
          serials = serials.concat(arr);
        });
        // return
        const response = await NotSoldWarehouseService.addOffBiz({
          model_name: modelName,
          ...data,
          serials
        });

        if (response.status < 400 && response.data) {
          const { offBizDetail } = state;
          // await store.dispatch(`${storeName}/getListOffBiz`);
          commit("SET_OFF_BIZ_DETAIL", {
            ...offBizDetail,
            ...response.data.data
          });
          commit("SET_STATUS_REQUEST", { value: "success-addOffBiz" });
        }
      } catch (e) {
        if (e.response.status === 500) {
          router.push({ name: "500" });
        } else {
          commit("SET_STATUS_REQUEST", {
            message: e.response.data,
            value: "error-addOffBiz"
          });
        }
      }
    },

    addComment: async ({ commit }, data) => {
      try {
        commit("SET_STATUS_REQUEST", { value: "loading-addComment" });
        const response = await NotSoldWarehouseService.addComment({
          ...data
        });
        if (response.status < 400 && response.data) {
          await store.dispatch(
            `${storeName}/getCommentsOfOffBiz`,
            data.off_biz_id
          );
          commit("SET_STATUS_REQUEST", { value: "success-addComment" });
        }
      } catch (e) {
        if (e.response.status === 500) {
          router.push({ name: "500" });
        } else {
          commit("SET_STATUS_REQUEST", {
            message: e.response.data,
            value: "error-addComment"
          });
        }
      }
    },

    updateOffBiz: async ({ commit, state }, data) => {
      try {
        commit("SET_STATUS_REQUEST", { value: "loading-updateOffBiz" });
        let serials = [];

        (data.options ? data.options : []).forEach(option => {
          const arr = option.serials.map(item => item.id);
          serials = serials.concat(arr);
        });
        let params = {
          id: data.id,
          step: data.nextStep,
          serial_id: data.serial_id ? data.serial_id : data.serial.id,
          branch_id: data.branch_id,
          assigned_to_id: data.assigned_to_id,
          selected_action: data.selected_action,
          tinh_trang_may: data.tinh_trang_may,
          supervisor_id: data.supervisor_id,
          service_fee: data.service_fee,
          service_supplier_id: data.service_supplier_id
            ? data.service_supplier_id
            : null,
          serials
        };
        if (data.next_invoice) {
          params = {
            ...params,
            next_invoice: {
              ...data.next_invoice,
              model_name: modelName
            }
          };
        }

        const response = await NotSoldWarehouseService.editOffBiz(params);
        if (response.status < 400 && response.data) {
          const { offBizDetail } = state;
          commit("SET_OFF_BIZ_DETAIL", {
            ...offBizDetail,
            ...response.data.data
          });
          commit("SET_STATUS_REQUEST", { value: "success-updateOffBiz" });
        }
      } catch (e) {
        if (e.response && e.response.status === 500) {
          router.push({ name: "500" });
        } else {
          if (e.response)
            commit("SET_STATUS_REQUEST", {
              message: e.response.data,
              value: "error-updateOffBiz"
            });
        }
      }
    },

    cancelOffBiz: async ({ commit }, data) => {
      try {
        commit("SET_STATUS_REQUEST", { value: "loading-cancelOffBiz" });
        const response = await NotSoldWarehouseService.editOffBiz({
          id: data.id,
          step: data.step,
          serial_id: data.serial.id,
          branch_id: data.branch_id,
          assigned_to_id: data.assigned_to_id,
          selected_action: 0,
          ly_do_huy: data.ly_do_huy
        });
        if (response.status < 400 && response.data) {
          // await store.dispatch(`${storeName}/getListOffBiz`);
          commit("SET_STATUS_REQUEST", { value: "success-cancelOffBiz" });
        }
      } catch (e) {
        if (e.response.status === 500) {
          router.push({ name: "500" });
        } else {
          commit("SET_STATUS_REQUEST", {
            message: e.response.data,
            value: "error-cancelOffBiz"
          });
        }
      }
    },

    getOffBizById: async ({ commit }, data) => {
      try {
        commit("SET_STATUS_REQUEST", { value: "loading-getOffBizById" });

        const response = await NotSoldWarehouseService.getOffBizById(data);

        if (response.status === 200 && response.data) {
          const { serial, serials, branch_id } = response.data.data;
          // Map results
          // Group by option_id value
          const options = _.groupBy(serials, "option_id");
          // Map results
          await store.dispatch("BRANCH/getAllBranches");
          const branches = await store.getters["BRANCH/allBranches"];
          const optionsRs = await Promise.all(
            Object.keys(options).map(async key => {
              const firstItem = [...options[key]];
              await store.dispatch(
                "SERIAL/getStockSerialsByOptionId",
                firstItem[0].option_id
              );
              const optionSerials =
                store.getters["SERIAL/productOptionStockSerials"];
              let res = [];

              branches.forEach(branch => {
                // Filter serial by branch id
                let arr = optionSerials.filter(
                  item => item.branch_id === branch.id
                );
                arr = arr.map(value => {
                  return {
                    ...value,
                    disabled:
                      (branch_id && branch_id !== branch.id) ||
                      value.status !== 1
                  };
                });
                if (arr.length > 0) {
                  res = [{ header: `${branch.name} (${arr.length})` }].concat(
                    arr
                  );
                }
              });

              return {
                id: firstItem[0].option_id,
                serials: firstItem,
                SKU: firstItem[0].option_sku,
                name: firstItem[0].option_name,
                product_name: firstItem[0].product_name,
                price: firstItem[0].price,
                allSerials: res.concat(firstItem)
              };
            })
          );
          await store.dispatch(`${storeName}/getCommentsOfOffBiz`, data);
          commit("SET_OFF_BIZ_DETAIL", {
            ...response.data.data,
            service_supplier_id:
              response.data.data.service_supplier &&
              response.data.data.service_supplier.id
                ? response.data.data.service_supplier.id
                : null,
            options: optionsRs
          });

          commit("SET_SERIAL_INFO", serial);
          commit("SET_STATUS_REQUEST", {
            value: "success-getOffBizById"
          });
        }
      } catch (e) {
        if (e.response.status === 500) {
          router.push({ name: "500" });
        } else {
          commit("SET_STATUS_REQUEST", {
            message: e.response.data,
            value: "error-getOffBizById"
          });
        }
      }
    },

    getCommentsOfOffBiz: async ({ commit }, data) => {
      try {
        commit("SET_STATUS_REQUEST", { value: "loading-getCommentsOfOffBiz" });

        const response = await NotSoldWarehouseService.getCommentsOfOffBiz(
          data
        );

        if (response.status === 200 && response.data) {
          commit("SET_COMMENTS", response.data.data);
          commit("SET_STATUS_REQUEST", {
            value: "success-getCommentsOfOffBiz"
          });
        }
      } catch (e) {
        if (e.response.status === 500) {
          router.push({ name: "500" });
        } else {
          commit("SET_STATUS_REQUEST", {
            message: e.response.data,
            value: "error-getCommentsOfOffBiz"
          });
        }
      }
    },

    getListOffBiz: async ({ commit }, data) => {
      try {
        commit("SET_STATUS_REQUEST", { value: "loading-getListOffBiz" });
        const response = await NotSoldWarehouseService.listOffBiz({
          model_name: modelName,
          ...data
        });

        if (response.status === 200 && response.data) {
          commit("SET_LIST_OFF_BIZ", response.data.data);
          commit("SET_PAGINATION_INFO", {
            from: response.data.meta.from ? response.data.meta.from : 0,
            to: response.data.meta.to ? response.data.meta.to : 0,
            pageTotal: response.data.meta.last_page,
            itemTotal: response.data.meta.total
          });
          commit("SET_STATUS_REQUEST", {
            value: "success-getListOffBiz"
          });
        }
      } catch (e) {
        if (e.response.status === 500) {
          router.push({ name: "500" });
        } else {
          commit("SET_STATUS_REQUEST", {
            message: e.response.data,
            value: "error-getListOffBiz"
          });
        }
      }
    },

    getSerialInfoBySerialNumber: async ({ commit }, payload) => {
      try {
        const { serial_number, store_name_param } = payload;
        commit("SET_STATUS_REQUEST", {
          value: "loading-getSerialInfoBySerialNumber"
        });

        const response = await NotSoldWarehouseService.getSerialInfoBySerialNumber(
          serial_number,
          store_name_param ? getSerialStatusByStoreName(store_name_param) : 1
        );

        if (response.status === 200 && response.data) {
          commit("SET_SERIAL_INFO", response.data.data);
          commit("SET_STATUS_REQUEST", {
            value: "success-getSerialInfoBySerialNumber"
          });
        }
      } catch (e) {
        if (e.response.status === 500) {
          router.push({ name: "500" });
        } else {
          commit("SET_STATUS_REQUEST", {
            message: e.response.data,
            value: "error-getSerialInfoBySerialNumber"
          });
        }
      }
    },

    setOffBizDetailLocal: ({ commit, state }, data) => {
      let { offBizDetail } = state;
      commit("SET_OFF_BIZ_DETAIL", {
        ...offBizDetail,
        ...data
      });
    },

    setSerialInfo: ({ commit }, payload) => {
      commit("SET_SERIAL_INFO", payload);
    },

    resetOffBizDetail: ({ commit }) => {
      commit("SET_SERIAL_INFO", null);
      //SET_COMMENTS
      commit("SET_COMMENTS", []);
      commit("SET_OFF_BIZ_DETAIL", {
        step: 1,
        serial_id: null,
        branch_id: null,
        assigned_to_id: null,
        supervisor_id: null,
        attached_files: null,
        tinh_trang_may: "",
        ly_do_huy: "",
        options: [],
        expire_date: null,
        address: null,
        selected_action: 1
      });
    }
  };
};

export const generateMutations = () => {
  return {
    SET_PAGINATION_INFO: (state, payload) => {
      state.paginationInfo = payload;
    },
    RESET_STATUS_REQUEST: state => {
      state.statusRequest.message = null;
      state.statusRequest.value = null;
    },

    SET_STATUS_REQUEST: (state, payload) => {
      state.statusRequest.message = payload.message || null;
      state.statusRequest.value = payload.value;
    },

    SET_LIST_OFF_BIZ: (state, payload) => {
      state.listOffBiz = payload;
    },

    SET_OFF_BIZ_DETAIL: (state, payload) => {
      state.offBizDetail = {
        ...payload,
        nextStep: payload.step
      };
    },

    SET_SERIAL_INFO: (state, payload) => {
      state.serialInfo = payload;
    },

    SET_COMMENTS: (state, payload) => {
      state.comments = payload;
    }
  };
};

export const generateState = () => {
  return {
    listOffBiz: [],
    paginationInfo: {
      from: 0,
      to: 0,
      pageTotal: 1,
      itemTotal: 0
    },
    comments: [],
    offBizDetail: {
      step: 1,
      serial_id: null,
      branch_id: null,
      assigned_to_id: null,
      supervisor_id: null,
      attached_files: null,
      tinh_trang_may: "",
      ly_do_huy: "",
      options: [],
      expire_date: null,
      address: null,
      selected_action: 1
    },
    serialInfo: null,
    statusRequest: {
      message: null,
      value: null
    }
  };
};

export const generateGetter = () => {
  return {
    paginationInfo: state => state.paginationInfo,
    statusRequest: state => state.statusRequest,
    listOffBiz: state => state.listOffBiz,
    offBizDetail: state => state.offBizDetail,
    serialInfo: state => state.serialInfo,
    comments: state => state.comments
  };
};

const getSerialStatusByStoreName = storeName => {
  return SERIAL_STATUS[storeName];
};
