import Api from "@/core/apis";

export default {
  createBanner(data) {
    return Api().post("banner/add", data);
  },

  deleteBanner(id) {
    return Api().get(`banner/delete/${id}`);
  },

  getBanners() {
    return Api().get("banner/all");
  },

  getBannerById(id) {
    return Api().post(`banner/detail/${id}`);
  },

  sortBrands(data) {
    return Api().post("banner/sort", { data });
  },

  updateBanner(data) {
    return Api().post("banner/edit", data);
  },

  uploadBannerImage(data) {
    return Api().post("banner/thumbnail/upload", data);
  }
};
