export * from "./promotion-status";

export const HEADERS_PROMOTION_TABLE = [
  {
    text: "Tên khuyến mại",
    align: "start",
    sortable: false,
    value: "name"
  },
  {
    text: "Dạng KM",
    align: "start",
    sortable: false,
    value: "type"
  },
  {
    text: "Kiểu KM",
    align: "start",
    sortable: false,
    value: "promotion_type"
  },
  {
    text: "Highlight",
    align: "start",
    sortable: false,
    value: "note"
  },
  {
    text: "Trạng thái",
    align: "start",
    sortable: false,
    value: "status"
  },
  {
    text: "Lượt SD",
    align: "start",
    sortable: false,
    value: "usingTimes"
  },
  {
    text: "Đường dẫn",
    align: "start",
    sortable: false,
    value: "action"
  }
];

export const INIT_PROMOTION_VALUE = {
  id: null,
  name: "",
  type: 1, // 1 - Products, 2 - Sale receipt
  description: null,
  note: null,
  url: null,
  thumbnail: null,
  dieukien_khachhang_type: 1, // 1 - All, 2 - Customer groups
  dieukien_khachhang_type_detail: null,
  dieukien_chinhanh_type: 1, // 1 - All, 2 - Cities, 3 - Specific branches
  dieukien_chinhanh_type_detail: null,
  dieukien_apdung_channel: "ALL",
  internal_note: null,
  is_apdung_cung: 0,
  is_limit_number_using: 0,
  limit_number_using: null,
  limit_for_user: 1,
  payment_condition_type: null,
  payment_condition_detail: null,
  priority: 3,
  time_from: new Date().toISOString(),
  is_time_to: 0,
  time_to: null,
  detail: {
    type: 2, // 1 - Percent, 2 - Amount, 3 - X get Y
    apdung_type: 1, // 1 - All, 2 - Product group, 3 - Specific products, null - Other
    apdung_type_detail: {
      title: null,
      stock_check: null,
      ids: null,
      options: [],
      soluong: null,
      from_money: null,
      duplicatable: 1
    },
    tangkhach_type: null, // 1 - All, 2 - Product group, 3 - Specific products, null - Other
    tangkhach_type_detail: {
      title: null,
      value: null,
      max_value: null,
      options: [],
      soluong: null,
      ids: null,
      tang_type: 2, // 1 - Free, 2 - Percent, 3 - Amount
      tang_value: null // Number
    },
    is_code: 0,
    codes: null
  },
  slug: ""
};

export const LIST_PROMOTION_PAYMENT_METHODS = [
  {
    id: null,
    name: "Tất cả phương thức thanh toán"
  },
  {
    id: 1,
    name: "Chuyển khoản"
  },
  {
    id: 2,
    name: "Thanh toán qua thẻ ATM"
  },
  {
    id: 3,
    name: "Thanh toán qua VNPAY"
  },
  {
    id: 4,
    name: "Thanh toán qua thẻ quốc tế"
  }
];

export const HEADERS_PRODUCT_TABLE = [
  {
    text: "Sản phẩm",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Mã SKU",
    sortable: false,
    value: "qty"
  },
  {
    text: "Giá gốc",
    sortable: false,
    value: "stock_qty"
  },
  {
    text: "% giảm",
    sortable: false,
    value: "returned_qty"
  },
  {
    text: "Giá sau giảm",
    sortable: false,
    value: "unit_price"
  },
  {
    text: "Đã sales",
    sortable: false,
    value: "rebate_amount"
  },
  {
    text: "SL Khuyến mại",
    sortable: false,
    value: "sale_price"
  },
  {
    text: "Sẵn bán",
    sortable: false,
    value: "available"
  },
  {
    text: "Hành động",
    align: "center",
    sortable: false,
    value: "total"
  }
];
