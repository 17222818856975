import Api from "@/core/apis";

export default {
  getSideConfig(site) {
    return Api().get(`site-config/${site}`);
  },

  updateSideConfig(site, data) {
    return Api().put(`site-config/${site}`, data);
  }
};
