/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import SupplierService from "../service/supplier.service";
import router from "@/core/router";
import store from "@/core/store";
import { catchError } from "@/core/composables";

const state = {
  allSupplierCategories: [],
  allSuppliers: [],
  supplier: {
    acronym: null,
    address: null,
    email: null,
    max_account_balance: null,
    name: null,
    note: null,
    phone: null,
    tinh_thanhpho: null,
    sup_cates: [],
    zone: 2 // 1 - , 2 -
  },
  suppliers: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  },
  supplierTransactionHistory: []
};

const getters = {
  allSupplierCategories: state => state.allSupplierCategories,
  allSuppliers: state => state.allSuppliers,
  supplier: state => state.supplier,
  suppliers: state => state.suppliers,
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest,
  supplierTransactionHistory: state => state.supplierTransactionHistory
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ALL_SUPPLIER_CATEGORIES: (state, payload) => {
    state.allSupplierCategories = payload;
  },

  SET_ALL_SUPPLIERS: (state, payload) => {
    state.allSuppliers = payload;
  },

  SET_SUPPLIER: (state, payload) => {
    state.supplier = payload;
  },

  SET_SUPPLIERS: (state, payload) => {
    state.suppliers = payload;
  },

  SET_SUPPLIER_TRANSACTION_HISTORY: (state, payload) => {
    state.supplierTransactionHistory = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createSupplier: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createSupplier" });

      const response = await SupplierService.createSupplier(payload.data);

      if (response.status === 201 && response.data.data) {
        // Get Suppliers
        await store.dispatch("SUPPLIER/getSuppliers", payload.routeQuery);
        // Update supplier
        commit("SET_SUPPLIER", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-createSupplier" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createSupplier"
        });
      }
    }
  },

  deleteSupplier: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteCustomer" });

      const response = await SupplierService.deleteSupplier(payload.data);

      if (response.status === 200) {
        // Get Suppliers
        await store.dispatch("SUPPLIER/getSuppliers", payload.routeQuery);

        commit("SET_STATUS_REQUEST", { value: "success-deleteSupplier" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-deleteSupplier"
        });
      }
    }
  },

  getAllSupplierCategories: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getAllSupplierCategories"
      });

      const response = await SupplierService.getAllSupplierCategories();

      if (response.status === 200 && response.data) {
        commit("SET_ALL_SUPPLIER_CATEGORIES", response.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getAllSupplierCategories"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getAllSupplierCategories"
        });
      }
    }
  },

  getAllSuppliers: async ({ commit }, query) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllSuppliers" });

      const response = await SupplierService.getAllSuppliers(query);

      if (response.status === 200 && response.data.data) {
        commit("SET_ALL_SUPPLIERS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAllSuppliers" });

        return response.data.data;
      }
    } catch (e) {
      catchError(e, commit, "getAllSuppliers");
    }
  },

  getSupplierById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSupplierById" });

      const response = await SupplierService.getSupplierById(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_SUPPLIER", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getSupplierById" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getSupplierById"
        });
      }
    }
  },

  getSuppliers: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getSuppliers" });

      const response = await SupplierService.getSuppliers(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_SUPPLIERS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getSuppliers" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getSuppliers"
        });
      }
    }
  },

  getSupplierTransactionHistory: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getSupplierTransactionHistory"
      });

      const response = await SupplierService.getSupplierTransactionHistory(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_SUPPLIER_TRANSACTION_HISTORY", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getSupplierTransactionHistory"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getSupplierTransactionHistory"
        });
      }
    }
  },

  resetSupplier: ({ commit }) => {
    commit("SET_SUPPLIER", {
      acronym: null,
      address: null,
      email: null,
      max_account_balance: null,
      name: null,
      note: null,
      phone: null,
      tinh_thanhpho: null,
      sup_cates: [],
      zone: 2 // 1 - , 2 -
    });
  },

  updateSupplier: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateSupplier" });

      const response = await SupplierService.updateSupplier(payload.data);

      if (response.status === 200 && response.data) {
        // Get Suppliers
        await store.dispatch("SUPPLIER/getSuppliers", payload.routeQuery);

        commit("SET_STATUS_REQUEST", { value: "success-updateSupplier" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-updateSupplier"
        });
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
