import Api from "@/core/apis";
import ApiFrontStore from "@/core/apis/front-store";

export default {
  createCustomizeFolder(data) {
    return Api().post("front-folders", data);
  },

  deleteCustomizeFolder(id) {
    return Api().delete(`front-folders/${id}`);
  },

  getCustomizeFolders(data) {
    return Api().post("front-folders/list", data);
  },

  getCustomizeFolderById(id) {
    return Api().get(`front-folders/${id}`);
  },

  updateCustomizeFolder(data) {
    return Api().put("front-folders", data);
  },

  // -----------------------
  getFiltersListBySharedUrl(url) {
    return ApiFrontStore().get(`filters?cate_url=${url}`);
  },

  getFilterProductTotal(filters) {
    return ApiFrontStore().post("filters/filter", {
      filters,
      is_count: true
    });
  },

  getSkusByType(type, search) {
    return Api().get(`promotion/search-sku?search=${search}&type=${type}`);
  }
};
