const ReportsRouter = {
  path: "/reports",
  component: () => import("@/modules/Reports"),
  redirect: { name: "report_inventory" },
  children: [
    {
      path: "inventory",
      name: "report_inventory",
      meta: {
        private: true,
        layout: "general",
        title: "Báo cáo tồn kho | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Báo cáo tồn kho",
            to: { name: "report_inventory" }
          }
        ]
      },
      component: () => import("../pages/InventoryReports")
    }
  ]
};

export default ReportsRouter;
