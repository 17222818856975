import Api from "@/core/apis";

export default {
  getUtmConfig() {
    return Api().get("short-link/config");
  },

  getShortURL(payload) {
    return Api().post("short-link/create", payload);
  }
};
