import Api from "@/core/apis";

export default {
  createColor(data) {
    return Api().post("colors", data);
  },

  getColorGroups() {
    return Api().post("color-groups/list");
  },

  getColors() {
    return Api().post("colors/list");
  },

  updateColor(data) {
    return Api().put("colors", data);
  }
};
