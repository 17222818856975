/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import ModelSiteConfigService from "../service/model-site-config.service";
import store from "@/core/store";
import { catchError } from "@/core/composables/useStore";
import {
  INIT_MODEL_SITE_CONFIG_VALUE,
  LIST_MODEL_SITES
} from "@/core/constant";

const state = {
  modelSiteConfig: JSON.parse(JSON.stringify(INIT_MODEL_SITE_CONFIG_VALUE)),
  modelSiteConfigs: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  modelSiteConfig: state => state.modelSiteConfig,
  modelSiteConfigs: state => state.modelSiteConfigs,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_MODEL_SITE_CONFIG: (state, payload) => {
    state.modelSiteConfig = payload;
  },

  SET_MODEL_SITE_CONFIGS: (state, payload) => {
    state.modelSiteConfigs = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  getModelSiteConfigsByModelId: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getModelSiteConfigsByModelId"
      });

      const response = await ModelSiteConfigService.getModelSiteConfigsByModelId(
        payload.modelId,
        payload.modelType
      );

      if (response.status === 200 && response.data) {
        commit("SET_MODEL_SITE_CONFIGS", response.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getModelSiteConfigsByModelId"
        });

        return response.data;
      }
    } catch (e) {
      catchError(e, commit, "getModelSiteConfigsByModelId");
    }
  },

  resetModelSiteConfig: ({ commit }) => {
    commit(
      "SET_MODEL_SITE_CONFIG",
      JSON.parse(JSON.stringify(INIT_MODEL_SITE_CONFIG_VALUE))
    );
  },

  resetModelSiteConfigs: ({ commit }) => {
    commit("SET_MODEL_SITE_CONFIGS", []);
  },

  setModelSiteConfig: ({ commit }, payload) => {
    commit("SET_MODEL_SITE_CONFIG", payload);
  },

  setModelSiteConfigs: ({ commit }, payload) => {
    commit("SET_MODEL_SITE_CONFIGS", payload);
  },

  updateModelSiteConfigs: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateModelSiteConfigs" });

      const modelSiteConfigs =
        payload.data && payload.data.length > 0
          ? payload.data
          : Object.values(LIST_MODEL_SITES).map((item, index) => {
              return {
                available: index === 0,
                site: item.id,
                model_id: payload.modelId,
                model_type: payload.modelType
              };
            });

      const response = await ModelSiteConfigService.updateModelSiteConfigs(
        modelSiteConfigs
      );

      if (response.status === 200 && response.data) {
        // Get modelSiteConfigs
        if (payload.modelId && payload.modelType) {
          await store.dispatch(
            "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
            {
              modelId: payload.modelId,
              modelType: payload.modelType
            }
          );
        }

        commit("SET_STATUS_REQUEST", {
          value: "success-updateModelSiteConfigs"
        });

        return modelSiteConfigs;
      }
    } catch (e) {
      catchError(e, commit, "updateModelSiteConfigs");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
