export const INIT_MODEL_SITE_CONFIG_VALUE = {
  model_id: null,
  model_type: null,
  site: null,
  available: false
};

export const INIT_SITE_CONFIG_SEO_DATA = [
  {
    title: null,
    site: "THINKPRO",
    description: null,
    tags: []
  },
  {
    title: null,
    site: "GOODSPACE",
    description: null,
    tags: []
  }
];

export const LIST_MODEL_SITES = {
  THINKPRO: {
    id: "THINKPRO",
    text: "ThinkPro",
    logo: require("@/core/assets/images/logo-thinkpro.svg"),
    url: process.env.VUE_APP_THINKPRO_FRONT_STORE
  },
  GOODSPACE: {
    id: "GOODSPACE",
    text: "GoodSpace",
    logo: require("@/core/assets/images/logo-goodspace.svg"),
    url: process.env.VUE_APP_GOODSPACE_FRONT_STORE
  }
};

export const ARRAY_LIST_MODEL_SITES = Object.values(LIST_MODEL_SITES);
