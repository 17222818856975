export const LIST_MODEL_TYPES = {
  BANNER: "App\\Models\\Banner",
  HOA_DON_BAN: "App\\Models\\HoaDonBan",
  POST: "Modules\\Social\\Entities\\Post",
  PRODUCT: "App\\Models\\Product",
  PRODUCT_OPTION: "App\\Models\\ProductOption",
  PRODUCT_TYPE: "App\\Models\\Category",
  ORDER: "App\\Models\\Order",
  FOLDER: "Modules\\FrontStore\\Entities\\Folder\\Folder",
  THIRD_INVENTORY: "App\\Models\\ThirdInventory",
  BHSC: "App\\Models\\BHSC",
  HANDLE: "App\\Models\\OrderHandle",
  USER: "App\\Models\\User",
  SUPPLIER: "App\\Models\\Supplier",
  COMPANY: "App\\Models\\Company",
  KHACH_TRA_HANG: "App\\Models\\KhachTraHang"
};
