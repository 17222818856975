/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import SupplierReturnService from "../service/supplier-return.service";
import router from "@/core/router";
import { catchError } from "@/core/composables";

const state = {
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  statusRequest: {
    message: null,
    value: null
  },
  supplierReturn: {
    branch_id: null,
    supplier_id: null,
    note: null,
    options: [],
    serials: []
  },
  supplierReturns: []
};

const getters = {
  paginationInfo: state => state.paginationInfo,
  statusRequest: state => state.statusRequest,
  supplierReturn: state => state.supplierReturn,
  supplierReturns: state => state.supplierReturns
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_SUPPLIER_RETURN: (state, payload) => {
    state.supplierReturn = payload;
  },

  SET_SUPPLIER_RETURNS: (state, payload) => {
    state.supplierReturns = payload;
  }
};

const actions = {
  createSupplierReturn: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-createSupplierReturn"
      });

      const response = await SupplierReturnService.createSupplierReturn(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", {
          value: "success-createSupplierReturn"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-createSupplierReturn"
        });
      }
    }
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await SupplierReturnService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportFile", { isRedirected: false });
    }
  },

  getSupplierReturnById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getSupplierReturnById"
      });

      const response = await SupplierReturnService.getSupplierReturnById(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_SUPPLIER_RETURN", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getSupplierReturnById"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getSupplierReturnById"
        });
      }
    }
  },

  getSupplierReturns: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getSupplierReturns"
      });

      const response = await SupplierReturnService.getSupplierReturns(payload);

      if (response.status === 200 && response.data) {
        commit("SET_SUPPLIER_RETURNS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", {
          value: "success-getSupplierReturns"
        });
      }
    } catch (e) {
      if (e.response.status === 500) {
        router.push({ name: "500" });
      } else {
        commit("SET_STATUS_REQUEST", {
          message: e.response.data,
          value: "error-getSupplierReturns"
        });
      }
    }
  },

  resetSupplierReturn: ({ commit }) => {
    commit("SET_SUPPLIER_RETURN", {
      branch_id: null,
      supplier_id: null,
      note: null,
      options: [],
      serials: []
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
