/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import AddAccessService from "../service/add-access.service";
import { catchError } from "@/core/composables";

const state = {
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  addAnAccess: {
    old_option_id: null,
    new_option_id: null,
    gear_ids: []
  },
  oldOption: {},
  newOption: {},
  gears: [],
  addAccess: {},
  addAccesses: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  paginationInfo: state => state.paginationInfo,
  addAccess: state => state.addAccess,
  addAccesses: state => state.addAccesses,
  addAnAccess: state => state.addAnAccess,
  oldOption: state => state.oldOption,
  newOption: state => state.newOption,
  gears: state => state.gears,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_ADD_ACCESS: (state, payload) => {
    state.addAccess = payload;
  },

  SET_ADD_AN_ACCESS: (state, payload) => {
    state.addAnAccess = payload;
  },

  SET_OLD_OPTION: (state, payload) => {
    state.oldOption = payload;
  },

  SET_NEW_OPTION: (state, payload) => {
    state.newOption = payload;
  },

  SET_GEARS: (state, payload) => {
    state.gears = payload;
  },

  SET_ADD_ACCESSES: (state, payload) => {
    state.addAccesses = payload;
  },

  DELETE_ADD_ACCESS: (state, payload) => {
    state.addAccesses = state.addAccesses.filter(e => e.id !== payload);
  },

  SET_SEARCH_PRODUCTS: (state, payload) => {
    state.searchProducts = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_THUMBNAIL: (state, payload) => {
    state.uploadedThumbnail = payload;
  }
};

const actions = {
  addAnAccess: async ({ commit, state }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-addAnAccess" });

      const response = await AddAccessService.addAnAccess(state.addAnAccess);

      if (response.status === 200 && response.data) {
        state.addAnAccess = {
          old_option_id: null,
          new_option_id: null,
          gear_ids: []
        };
        state.oldOption = {};
        state.newOption = {};
        state.gears = [];
        commit("SET_STATUS_REQUEST", { value: "success-addAnAccess" });
      }
    } catch (e) {
      catchError(e, commit, "addAnAccess");
    }
  },

  deleteAddAccess: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteAddAccess" });

      const response = await AddAccessService.deleteAddAccess(payload);

      if (response.status === 200 && response.data) {
        commit("DELETE_ADD_ACCESS", payload);
        commit("SET_STATUS_REQUEST", { value: "success-deleteAddAccess" });
      }
    } catch (e) {
      catchError(e, commit, "deleteAddAccess");
    }
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await AddAccessService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (e) {
      catchError(e, commit, "exportReportFile", { isRedirected: false });
    }
  },

  getAddAccesses: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAddAccesses" });

      const response = await AddAccessService.getAddAccesses(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_ADD_ACCESSES", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getAddAccesses" });
      }
    } catch (e) {
      catchError(e, commit, "getAddAccesses");
    }
  },

  getAddAccess: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAddAccess" });

      const response = await AddAccessService.getAddAccess(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_ADD_ACCESS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAddAccess" });
      }
    } catch (e) {
      catchError(e, commit, "getAddAccess");
    }
  },

  removeGearOne: async ({ commit, state }, payload) => {
    state.gears.splice(payload, 1);

    const addAnAccess = { ...state.addAnAccess };

    addAnAccess.gear_ids.splice(payload, 1);
    commit("SET_ADD_AN_ACCESS", addAnAccess);
  },

  removeNewOne: ({ commit, state }) => {
    state.newOption = {};

    const addAnAccess = { ...state.addAnAccess };

    addAnAccess.new_option_id = null;
    commit("SET_ADD_AN_ACCESS", addAnAccess);
  },

  removeOldOne: ({ commit, state }) => {
    state.oldOption = {};

    const addAnAccess = { ...state.addAnAccess };

    addAnAccess.old_option_id = null;
    commit("SET_ADD_AN_ACCESS", addAnAccess);
  },

  resetData: state => {
    state.addAnAccess = {
      old_option_id: null,
      new_option_id: null,
      gear_ids: []
    };
    state.oldOption = {};
    state.newOption = {};
    state.gears = [];
  },

  setAddAnAccessGears: async ({ commit, state }, payload) => {
    if (state.gears.find(e => e.serial_number === payload)) return "Có rùi!";

    try {
      commit("SET_STATUS_REQUEST", { value: "loading-setAddAnAccessGears" });

      const response = await AddAccessService.getSerialBySerialNumber(
        payload,
        2
      );

      if (response.status === 200 && response.data.data) {
        const addAnAccess = { ...state.addAnAccess };

        addAnAccess.gear_ids.push(response.data.data.id);

        const gears = [...state.gears];

        gears.push(response.data.data);

        commit("SET_ADD_AN_ACCESS", addAnAccess);
        commit("SET_GEARS", gears);
        commit("SET_STATUS_REQUEST", { value: "success-setAddAnAccessGears" });
      }
    } catch (e) {
      catchError(e, commit, "setAddAnAccessGears");
    }
  },

  setAddAnAccessNew: async ({ commit, state }, payload) => {
    commit("SET_STATUS_REQUEST", { value: "loading-setAddAnAccessNew" });

    const newOption = payload;
    const addAnAccess = { ...state.addAnAccess };

    addAnAccess.new_option_id = newOption.id;

    commit("SET_ADD_AN_ACCESS", addAnAccess);
    commit("SET_NEW_OPTION", newOption);
    commit("SET_STATUS_REQUEST", { value: "success-setAddAnAccessNew" });
  },

  setAddAnAccessOld: async ({ commit, state }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-setAddAnAccessOld" });

      const response = await AddAccessService.getSerialBySerialNumber(
        payload,
        1
      );

      if (response.status === 200 && response.data.data) {
        const addAnAccess = { ...state.addAnAccess };

        addAnAccess.old_option_id = response.data.data.id;

        commit("SET_ADD_AN_ACCESS", addAnAccess);
        commit("SET_OLD_OPTION", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-setAddAnAccessOld" });
      }
    } catch (e) {
      catchError(e, commit, "setAddAnAccessOld");
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
