/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import LocalAdministrationData from "../assets/dvhcvn.json";

const state = {
  cities: [],
  districts: [],
  statusRequest: {
    message: null,
    value: null
  },
  wards: []
};

const getters = {
  cities: state => state.cities,
  districts: state => state.districts,
  statusRequest: state => state.statusRequest,
  wards: state => state.wards
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_CITIES: (state, payload) => {
    state.cities = payload;
  },

  SET_DISTRICTS: (state, payload) => {
    state.districts = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_WARDS: (state, payload) => {
    state.wards = payload;
  }
};

const actions = {
  getCities: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCities" });

      await commit("SET_CITIES", LocalAdministrationData.data);

      commit("SET_STATUS_REQUEST", { value: "success-getCities" });
    } catch {
      commit("SET_STATUS_REQUEST", {
        message: "Lỗi tải danh sách tỉnh/thành phố",
        value: "error-getCities"
      });
    }
  },

  getDistrictsOfCity: async ({ commit }, payload) => {
    try {
      let checker = false;

      commit("SET_STATUS_REQUEST", { value: "loading-getDistrictsOfCity" });

      // Find districts of city by city name
      state.cities.forEach(city => {
        if (city.name === payload) {
          commit("SET_DISTRICTS", city.districts);
          checker = true;
          return false;
        }
      });

      if (!checker) commit("SET_DISTRICTS", []);

      commit("SET_STATUS_REQUEST", { value: "success-getDistrictsOfCity" });
    } catch {
      commit("SET_STATUS_REQUEST", {
        message: "Lỗi tải danh sách quận/huyện",
        value: "error-getCities"
      });
    }
  },

  getWardsOfDistrict: async ({ commit }, payload) => {
    try {
      let checker = false;

      commit("SET_STATUS_REQUEST", { value: "loading-getWardsOfDistrict" });

      // Find wards of district by district name
      state.districts.forEach(district => {
        if (district.name === payload) {
          commit("SET_WARDS", district.wards);
          checker = true;
          return false;
        }
      });

      if (!checker) commit("SET_WARDS", []);

      commit("SET_STATUS_REQUEST", { value: "success-getWardsOfDistrict" });
    } catch {
      commit("SET_STATUS_REQUEST", {
        message: "Lỗi tải danh sách phường/xã",
        value: "error-getCities"
      });
    }
  },

  resetCities: ({ commit }) => {
    commit("SET_CITIES", []);
  },

  resetDistricts: ({ commit }) => {
    commit("SET_DISTRICTS", []);
  },

  resetWards: ({ commit }) => {
    commit("SET_WARDS", []);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
