/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import BannerService from "../service/banner.service";
import MediaService from "@/core/service/media.service";
import store from "@/core/store";
import Vue from "vue";
import { catchError } from "@/core/composables/useStore";
import { LIST_MODEL_SITES, LIST_MODEL_TYPES } from "@/core/constant";
import { toFormData } from "@vt7/utils";

const handleConvertBannerCategories = banner => {
  banner.categories = banner.categories.map(item => {
    return {
      id: item.id,
      name: item.name,
      slot: item.pivot.slot
    };
  });

  return banner;
};

const state = {
  banner: {
    categories: [],
    category_ids: [],
    description: null,
    image_url: null,
    mb_image_url: null,
    url: null,
    is_displayed: 1,
    sites: []
  },
  formMobileData: null,
  site: "Thinkpro",
  banners: [],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedImage: null
};

const getters = {
  banner: state => state.banner,
  banners: state => state.banners,
  bannersGoodspace: state => {
    return state.banners.filter(banner =>
      banner.sites.includes(LIST_MODEL_SITES.GOODSPACE.id)
    );
  },
  bannersThinkpro: state => {
    return state.banners.filter(banner =>
      banner.sites.includes(LIST_MODEL_SITES.THINKPRO.id)
    );
  },
  bannersActive: state => {
    return state.banners.filter(banner => banner.is_displayed === 1);
  },
  formMobileData: state => state.formMobileData,
  site: state => state.site,
  statusRequest: state => state.statusRequest,
  uploadedImage: state => state.uploadedImage
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_BANNER: (state, payload) => {
    state.banner = payload;
  },

  SET_BANNERS: (state, payload) => {
    state.banners = payload;
  },

  SET_SITE: (state, payload) => {
    state.site = payload;
  },

  SET_FORM_MOBILE: (state, payload) => {
    state.formMobileData = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_IMAGE: (state, payload) => {
    state.uploadedImage = payload;
  }
};

const actions = {
  createBanner: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createBanner" });

      const response = await BannerService.createBanner(payload.data);

      if (response.status === 201 && response.data.data) {
        // creat model site config
        await store.dispatch("MODEL_SITE_CONFIG/updateModelSiteConfigs", {
          data: [
            {
              model_id: response.data.data.id,
              model_type: LIST_MODEL_TYPES.BANNER,
              site: payload.data.sites[0],
              available: true
            }
          ]
        });

        await store.dispatch("BANNER/getBanners");

        commit("SET_STATUS_REQUEST", { value: "success-createBanner" });
      }
    } catch (e) {
      catchError(e, commit, "createBanner");
    }
  },

  deleteBanner: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteBanner" });

      const response = await BannerService.deleteBanner(payload);

      if (response.status === 200) {
        // Get banners
        await store.dispatch("BANNER/getBanners");
        commit("SET_STATUS_REQUEST", { value: "success-deleteBanner" });
      }
    } catch (e) {
      catchError(e, commit, "deleteBanner");
    }
  },

  getBanners: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getBanners" });

      const response = await BannerService.getBanners(payload);

      if (response.status === 200 && response.data.data) {
        const banners = response.data.data.map(item => {
          return handleConvertBannerCategories(item);
        });

        commit("SET_BANNERS", banners);
        commit("SET_STATUS_REQUEST", { value: "success-getBanners" });
      }
    } catch (e) {
      catchError(e, commit, "getBanners");
    }
  },

  getBannerById: async ({ commit }, id) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getBannerById" });

      const response = await BannerService.getBannerById(id);

      if (response.status === 200 && response.data.data) {
        commit("SET_BANNER", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getBannerById" });
      }
    } catch (e) {
      catchError(e, commit, "getBannerById");
    }
  },

  setBanner: ({ commit }, payload) => {
    commit("SET_BANNER", payload);
  },

  resetBanner: ({ commit }) => {
    commit("SET_BANNER", {
      categories: [],
      category_ids: [],
      description: null,
      image_url: null,
      mb_image_url: null,
      url: null,
      is_displayed: 1,
      sites: []
    });
  },

  updateBanner: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateBanner" });

      const response = await BannerService.updateBanner(payload.data);

      if (response.status === 200 && response.data.data) {
        const banner = await handleConvertBannerCategories(response.data.data);

        // Update banner
        Vue.set(state.banners, payload.bannerIndex, banner);
        await store.dispatch("BANNER/getBanners");
        commit("SET_STATUS_REQUEST", { value: "success-updateBanner" });
      }
    } catch (e) {
      catchError(e, commit, "updateBanner");
    }
  },

  sortBanners: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-sortBanners" });

      const response = await BannerService.sortBrands(payload);

      if (response.status === 200 && response.data) {
        await store.dispatch("BRAND/getBanners");
        commit("SET_STATUS_REQUEST", { value: "success-sortBanners" });
      }
    } catch (e) {
      if (e.response.status === 500) {
        catchError(e, commit, "sortBanners");
      }
    }
  },

  uploadBannerImage: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadBannerImage" });

      const formData = toFormData({ file: payload, folder: "core/banners" });

      const response = await MediaService.uploadImage(formData);

      if (response.status === 200 && response.data) {
        commit("SET_UPLOADED_IMAGE", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadBannerImage" });
      }
    } catch (e) {
      catchError(e, commit, "uploadBannerImage");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
