/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import DemandService from "../service/demand.service";
import store from "@/core/store";
import { catchError } from "@/core/composables/useStore";

const state = {
  demand: {
    name: null,
    des: null
  },
  demands: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  demand: state => state.demand,
  demands: state => state.demands,
  statusRequest: state => state.statusRequest
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_DEMAND: (state, payload) => {
    state.demand = payload;
  },

  SET_DEMANDS: (state, payload) => {
    state.demands = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const actions = {
  createDemand: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createDemand" });

      const response = await DemandService.createDemand(payload);

      if (response.status === 201 && response.data.data) {
        // Get demands
        await store.dispatch("DEMAND/getDemands");

        commit("SET_DEMAND", response.data.data);

        commit("SET_STATUS_REQUEST", { value: "success-createDemand" });
      }
    } catch (e) {
      catchError(e, commit, "createDemand");
    }
  },

  getDemands: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getDemands" });

      const response = await DemandService.getDemands(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_DEMANDS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getDemands" });
      }
    } catch (e) {
      catchError(e, commit, "getDemands");
    }
  },

  resetDemand: ({ commit }) => {
    commit("SET_DEMAND", {
      name: null,
      des: null
    });
  },

  setDemand: ({ commit }, payload) => {
    commit("SET_DEMAND", payload);
  },

  updateDemand: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateDemand" });

      const response = await DemandService.updateDemand(payload);

      if (response.status === 200 && response.data.data) {
        // Get demands
        await store.dispatch("DEMAND/getDemands");

        commit("SET_STATUS_REQUEST", { value: "success-updateDemand" });
      }
    } catch (e) {
      catchError(e, commit, "updateDemand");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
