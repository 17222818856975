const WebsiteRouter = {
  path: "/website",
  component: () => import("@/modules/Website"),
  redirect: { name: "website_posts" },
  children: [
    {
      path: "customize-folder",
      component: () => import("../pages/CustomizeFolder"),
      children: [
        {
          path: "list",
          name: "website_customize-folder",
          meta: {
            private: true,
            layout: "general",
            title: "Quản lý folder | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Quản lý folder",
                to: { name: "website_customize-folder" }
              }
            ]
          },
          component: () => import("../pages/CustomizeFolder/Listing")
        },
        {
          path: "create",
          name: "website_customize-folder-create",
          meta: {
            private: true,
            layout: "general",
            title: "Thêm mới folder | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Quản lý folder",
                to: { name: "website_customize-folder" }
              },
              {
                disabled: true,
                exact: true,
                text: "Thêm mới folder",
                to: { name: "website_customize-folder-create" }
              }
            ]
          },
          component: () => import("../pages/CustomizeFolder/Detail")
        },
        {
          path: "detail/:customizeFolderId",
          name: "website_customize-folder-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Cập nhật folder | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Quản lý folder",
                to: { name: "website_customize-folder" }
              },
              {
                disabled: true,
                exact: true,
                text: "Cập nhật folder",
                to: { name: "website_customize-folder-detail" }
              }
            ]
          },
          component: () => import("../pages/CustomizeFolder/Detail")
        }
      ]
    },
    {
      path: "posts",
      component: () => import("../pages/Posts"),
      children: [
        {
          path: "list",
          name: "website_posts",
          meta: {
            private: true,
            layout: "general",
            title: "Bài viết | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Bài viết",
                to: { name: "website_posts" }
              }
            ]
          },
          component: () => import("../pages/Posts/Listing")
        },
        {
          path: "create",
          name: "website_posts-create",
          meta: {
            private: true,
            layout: "general",
            title: "Viết bài mới | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Bài viết",
                to: { name: "website_posts" }
              },
              {
                disabled: true,
                exact: true,
                text: "Viết bài mới",
                to: { name: "website_posts-create" }
              }
            ]
          },
          component: () => import("../pages/Posts/Detail")
        },
        {
          path: "detail/:postId",
          name: "website_posts-detail",
          meta: {
            private: true,
            layout: "general",
            title: "Cập nhật bài viết | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Bài viết",
                to: { name: "website_posts" }
              },
              {
                disabled: true,
                exact: true,
                text: "Chi tiết bài viết",
                to: { name: "website_posts-detail" }
              }
            ]
          },
          component: () => import("../pages/Posts/Detail")
        }
      ]
    },
    {
      path: "post-categories",
      name: "website_post-categories",
      meta: {
        private: true,
        layout: "general",
        title: "Folder bài viết | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Folder bài viết",
            to: { name: "website_post-categories" }
          }
        ]
      },
      component: () => import("../pages/PostCategories")
    },
    {
      path: "appearance",
      name: "website_appearance",
      component: () => import("../pages/Appearance"),
      redirect: { name: "website_appearance-banner" },
      children: [
        {
          path: "general",
          name: "website_appearance-general",
          meta: {
            private: true,
            layout: "general",
            title: "Tùy chỉnh chung | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Website",
                to: { name: "website_posts" }
              },
              {
                disabled: false,
                exact: true,
                text: "Giao diện",
                to: { name: "website_appearance" }
              },
              {
                disabled: true,
                exact: true,
                text: "Chung",
                to: { name: "website_appearance-general" }
              }
            ]
          },
          component: () => import("../pages/Appearance/General")
        },
        {
          path: "banner",
          name: "website_appearance-banner",
          meta: {
            private: true,
            layout: "general",
            title: "Tùy chỉnh banner | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Website",
                to: { name: "website_posts" }
              },
              {
                disabled: false,
                exact: true,
                text: "Giao diện",
                to: { name: "website_appearance" }
              },
              {
                disabled: true,
                exact: true,
                text: "Banner",
                to: { name: "website_appearance-banner" }
              }
            ]
          },
          component: () => import("../pages/Appearance/Banners")
        },
        {
          path: "build-categories-bar",
          name: "website_appearance-build-categories-bar",
          meta: {
            private: true,
            layout: "general",
            title: "Build thanh categories | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Website",
                to: { name: "website_posts" }
              },
              {
                disabled: false,
                exact: true,
                text: "Giao diện",
                to: { name: "website_appearance" }
              },
              {
                disabled: true,
                exact: true,
                text: "Build thanh categories",
                to: { name: "website_appearance-build-categories-bar" }
              }
            ]
          },
          component: () => import("../pages/Appearance/BuildCategoriesBar")
        },
        {
          path: "build-top-laptop",
          name: "website_appearance-build-top-laptop",
          meta: {
            private: true,
            layout: "general",
            title: "Build top laptop | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Website",
                to: { name: "website_posts" }
              },
              {
                disabled: false,
                exact: true,
                text: "Giao diện",
                to: { name: "website_appearance" }
              },
              {
                disabled: true,
                exact: true,
                text: "Build top laptop",
                to: { name: "website_appearance-build-top-laptop" }
              }
            ]
          },
          component: () => import("../pages/Appearance/BuildTopLaptop")
        },
        {
          path: "home",
          name: "website_appearance-home",
          meta: {
            private: true,
            layout: "general",
            title: "Tùy chỉnh trang chủ | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Website",
                to: { name: "website_posts" }
              },
              {
                disabled: false,
                exact: true,
                text: "Giao diện",
                to: { name: "website_appearance" }
              },
              {
                disabled: true,
                exact: true,
                text: "Trang chủ",
                to: { name: "website_appearance-home" }
              }
            ]
          },
          component: () => import("../pages/Appearance/Home")
        },
        {
          path: "mega-menu",
          name: "website_appearance-mega-menu",
          meta: {
            private: true,
            layout: "general",
            title: "Mega Menu | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Website",
                to: { name: "website_posts" }
              },
              {
                disabled: false,
                exact: true,
                text: "Giao diện",
                to: { name: "website_appearance" }
              },
              {
                disabled: true,
                exact: true,
                text: "Mega Menu",
                to: { name: "website_appearance-mega-menu" }
              }
            ]
          },
          component: () => import("../pages/Appearance/MegaMenu")
        },
        {
          path: "search-keywords",
          name: "website_appearance-search-keywords",
          meta: {
            private: true,
            layout: "general",
            title: "Thiết lập từ khóa tìm kiếm | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: false,
                exact: true,
                text: "Website",
                to: { name: "website_posts" }
              },
              {
                disabled: false,
                exact: true,
                text: "Giao diện",
                to: { name: "website_appearance" }
              },
              {
                disabled: true,
                exact: true,
                text: "Từ khóa tìm kiếm",
                to: { name: "website_appearance-search-keywords" }
              }
            ]
          },
          component: () => import("../pages/Appearance/SearchKeywords")
        }
      ]
    },
    {
      path: "comments",
      name: "website_comments",
      meta: {
        private: true,
        layout: "general",
        title: "Bình luận website | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Bình luận website",
            to: { name: "website_comments" }
          }
        ]
      },
      component: () => import("../pages/Comments")
    },
    {
      path: "notifications",
      name: "website_notifications",
      meta: {
        private: true,
        layout: "general",
        title: "Thông báo website | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Thông báo bài viết",
            to: { name: "website_notifications" }
          }
        ]
      },
      component: () => import("../pages/Notifications")
    },
    {
      path: "infobox",
      name: "website_infobox",
      meta: {
        private: true,
        layout: "general",
        title: "SEO & Infobox | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "SEO & Infobox",
            to: { name: "website_infobox" }
          }
        ]
      },
      component: () => import("../pages/Infobox")
    },
    {
      path: "shoppable",
      component: () => import("../pages/Shoppable"),
      children: [
        {
          path: "",
          name: "shoppable",
          meta: {
            private: true,
            layout: "general",
            title: "Shoppable Image | ThinkPro CMS",
            breadcrumbs: [
              {
                disabled: false,
                exact: true,
                text: "Bảng điều khiển",
                to: { name: "dashboard" }
              },
              {
                disabled: true,
                exact: true,
                text: "Mua hàng qua hình ảnh",
                to: { name: "shoppable" }
              }
            ]
          },
          component: () => import("../pages/Shoppable/pages/index.vue")
        }
      ]
    }
  ]
};

export default WebsiteRouter;
