import Api from "@/core/apis";

export default {
  cancelShipping(id) {
    return Api().post(`orders/handle/shipping/cancel/${id}`);
  },

  getShippingInfo(id) {
    return Api().get(`orders/handle/shipping/${id}`);
  },

  getShippingPartners(payload) {
    return Api().post("orders/handles/list/shipping-partner", payload);
  },

  sendToShippingPartner(payload) {
    return Api().post("orders/handle/shipping", payload);
  },

  updateShippingInfo(payload) {
    return Api().put("orders/handle/shipping", payload);
  },

  updateOrderProcessingStatus(data) {
    return Api().put("orders/handles/update-status", data);
  }
};
