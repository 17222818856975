export const parseToNumber = input => {
  if (!input) return;

  if (typeof input === "number") {
    return input;
  }

  const stringWithoutSpaces = input.toString().replace(/\s/g, "");
  const stringWithoutDecimal = stringWithoutSpaces.replace(/\./g, "");
  const parsedNumber = parseInt(stringWithoutDecimal);

  return isNaN(parsedNumber) ? NaN : parsedNumber;
};
