/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import MediaService from "@/core/service/media.service";
import ProductOptionService from "../service/product-option.service";
import store from "@/core/store";
import Vue from "vue";
import { catchError } from "@/core/composables/useStore";
import { INIT_PRODUCT_OPTION_VALUE } from "@/modules/Goods/constants";
import defu from "@/core/utils/defu";

const state = {
  activeTab: 0,
  categoryAttributes: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  productOption: JSON.parse(JSON.stringify(INIT_PRODUCT_OPTION_VALUE)),
  productOptionInfo: null,
  productOptionModelSiteConfigs: [],
  productOptions: [],
  productOptionSerials: [],
  productOptionsOfProduct: [],
  productOptionTransactionHistory: [],
  searchProductOptions: [],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedProductOptionImages: []
};

const getters = {
  activeTab: state => state.activeTab,
  categoryAttributes: state => state.categoryAttributes,
  paginationInfo: state => state.paginationInfo,
  productOption: state => state.productOption,
  productOptionInfo: state => state.productOptionInfo,
  productOptionModelSiteConfigs: state => state.productOptionModelSiteConfigs,
  productOptions: state => state.productOptions,
  productOptionSerials: state => state.productOptionSerials,
  productOptionsOfProduct: state => state.productOptionsOfProduct,
  productOptionTransactionHistory: state =>
    state.productOptionTransactionHistory,
  searchProductOptions: state => state.searchProductOptions,
  statusRequest: state => state.statusRequest,
  uploadedProductOptionImages: state => state.uploadedProductOptionImages
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_ACTIVE_TAB: (state, payload) => {
    state.activeTab = payload;
  },

  SET_CATEGORY_ATTRIBUTES: (state, payload) => {
    state.categoryAttributes = payload;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_PRODUCT_OPTION: (state, payload) => {
    state.productOption = payload;
  },

  SET_PRODUCT_OPTION_INFO: (state, payload) => {
    state.productOptionInfo = payload;
  },

  SET_PRODUCT_OPTION_MODEL_SITE_CONFIGS: (state, payload) => {
    state.productOptionModelSiteConfigs = payload;
  },

  SET_PRODUCT_OPTIONS: (state, payload) => {
    state.productOptions = payload;
  },

  SET_PRODUCT_OPTION_SERIALS: (state, payload) => {
    state.productOptionSerials = payload;
  },

  SET_PRODUCT_OPTIONS_OF_PRODUCT: (state, payload) => {
    state.productOptionsOfProduct = payload;
  },

  SET_PRODUCT_OPTION_TRANSACTION_HISTORY: (state, payload) => {
    state.productOptionTransactionHistory = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_SEARCH_PRODUCT_OPTIONS: (state, payload) => {
    state.searchProductOptions = payload;
  },

  SET_UPLOADED_PRODUCT_OPTION_IMAGES: (state, payload) => {
    state.uploadedProductOptionImages = payload;
  }
};

const actions = {
  createProductOption: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createProductOption" });

      const objectSender = { ...payload.productOption };

      const attrDetailObj = { ...objectSender.attr_details };

      // Handle convert attr_detail to array
      objectSender.attr_details = Object.keys(attrDetailObj).map(
        key => attrDetailObj[key]
      );

      const response = await ProductOptionService.createProductOption(
        objectSender
      );

      if (response.status === 200 && response.data) {
        Vue.set(state.productOption, "id", response.data);
        // Get product option
        await store.dispatch(
          "PRODUCT_OPTION/getProductOptionsByProductId",
          payload.productId
        );
        commit("SET_STATUS_REQUEST", {
          value: "success-createProductOption"
        });
      }
    } catch (e) {
      catchError(e, commit, "createProductOption");
    }
  },

  deleteProductOptions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteProductOptions" });

      const response = await ProductOptionService.deleteProductOptions(
        payload.data
      );

      if (response.status === 200) {
        // Get product options
        if (payload.routeQuery) {
          await store.dispatch(
            "PRODUCT_OPTION/getProductOptions",
            payload.routeQuery
          );
        }
        commit("SET_STATUS_REQUEST", {
          value: "success-deleteProductOptions"
        });
      }
    } catch (e) {
      catchError(e, commit, "deleteProductOptions");
    }
  },

  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await ProductOptionService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportFile", { isRedirected: false });
    }
  },

  getCategoryAttributes: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getCategoryAttributes" });

      const response = await ProductOptionService.getCategoryAttributes(
        payload
      );

      if (response.status === 200 && response.data.data) {
        const arr = response.data.data;

        const attrDetailsArr = arr.reduce((res, item) => {
          return res.concat(item.details);
        }, []);

        const obj = Object.fromEntries(
          attrDetailsArr.map(e => [
            e.code,
            {
              id: e.id,
              code: e.code,
              name: e.name,
              measurement: e.measurement,
              data_type: e.data_type,
              attr_id: e.attr_id,
              attr: e.attr,
              attr_des: null,
              value: null,
              upgradable: false,
              des: null,
              options: e.options
            }
          ])
        );

        commit("SET_CATEGORY_ATTRIBUTES", obj);

        commit("SET_STATUS_REQUEST", {
          value: "success-getCategoryAttributes"
        });
      }
    } catch (e) {
      catchError(e, commit, "getCategoryAttributes");
    }
  },

  getProductOptionById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getProductOptionById" });

      const response = await ProductOptionService.getProductOptionById(
        payload.productOptionId
      );

      if (response.status === 200 && response.data.data) {
        const productOption = {
          ...response.data.data
        };

        // Handle attr_details
        if (payload.productCategoryId) {
          // Case of calling get product option from CardProductOption in ModalProduct
          await store.dispatch(
            "PRODUCT_OPTION/getCategoryAttributes",
            payload.productCategoryId
          );

          if (productOption.attr_details.length === 0) {
            // Handle get attr_details if having payload productCategoryId
            productOption.attr_details = { ...state.categoryAttributes };
          } else {
            // Handle convert attr_details to object
            const productOptionAttributes = Object.fromEntries(
              productOption.attr_details.map(e => [
                e.code,
                {
                  id: e.id,
                  code: e.code,
                  name: e.name,
                  measurement: e.measurement,
                  data_type: e.data_type,
                  attr_id: e.attr_id,
                  attr: e.attribute || e.attr,
                  attr_des: e.pivot.attr_des,
                  value: e.pivot.value,
                  upgradable: e.pivot.upgradable,
                  des: e.pivot.des,
                  options: e.options
                }
              ])
            );

            productOption.attr_details = defu(
              productOptionAttributes,
              state.categoryAttributes
            );
          }
        }

        // Set demands if undefined demands field
        if (!productOption.demands) {
          productOption.demands = [];
        } else {
          productOption.demands = productOption.demands.map(item => {
            return {
              id: item.id,
              name: item.name,
              points: item.pivot.points
            };
          });
        }

        commit("SET_PRODUCT_OPTION", productOption);
        commit("SET_STATUS_REQUEST", {
          value: "success-getProductOptionById"
        });
      }
    } catch (e) {
      catchError(e, commit, "getProductOptionById");
    }
  },

  getProductOptionInfo: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getProductOptionInfo" });

      const response = await ProductOptionService.getProductOptionInfo(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_PRODUCT_OPTION_INFO", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getProductOptionInfo"
        });
      }
    } catch (e) {
      catchError(e, commit, "getProductOptionInfo");
    }
  },

  getProductOptions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getProductOptions" });

      const response = await ProductOptionService.getProductOptions(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_PRODUCT_OPTIONS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getProductOptions" });
      }
    } catch (e) {
      catchError(e, commit, "getProductOptions");
    }
  },

  getProductOptionSerials: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getProductOptionSerials"
      });

      const response = await ProductOptionService.getProductOptionSerials(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_PRODUCT_OPTION_SERIALS", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getProductOptionSerials"
        });
      }
    } catch (e) {
      catchError(e, commit, "getProductOptionSerials");
    }
  },

  getProductOptionsByProductId: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getProductOptionsByProductId"
      });

      const response = await ProductOptionService.getProductOptionsByProductId(
        payload
      );

      if (response.status === 200 && response.data.data) {
        commit("SET_PRODUCT_OPTIONS_OF_PRODUCT", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getProductOptionsByProductId"
        });
      }
    } catch (e) {
      catchError(e, commit, "getProductOptionsByProductId");
    }
  },

  getProductOptionTransactionHistory: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getProductOptionTransactionHistory"
      });

      const response = await ProductOptionService.getProductOptionTransactionHistory(
        payload
      );

      if (response.status === 200 && response.data) {
        commit("SET_PRODUCT_OPTION_TRANSACTION_HISTORY", response.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-getProductOptionTransactionHistory"
        });
      }
    } catch (e) {
      catchError(e, commit, "getProductOptionTransactionHistory");
    }
  },

  importExcel: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-importExcel" });

      const response = await ProductOptionService.importExcel(payload.formData);

      if (response.status === 200 && response.data) {
        // Get products
        await store.dispatch("PRODUCT/getProductOptions", payload.routeQuery);
        commit("SET_STATUS_REQUEST", { value: "success-importExcel" });
      }
    } catch (e) {
      catchError(e, commit, "getProductOptions");
    }
  },

  resetProductOption: ({ commit }) => {
    commit(
      "SET_PRODUCT_OPTION",
      JSON.parse(JSON.stringify(INIT_PRODUCT_OPTION_VALUE))
    );
    commit("SET_STATUS_REQUEST", {
      value: "success-createProductOption"
    });
  },

  resetProductOptionsOfProduct: ({ commit }) => {
    commit("SET_PRODUCT_OPTIONS_OF_PRODUCT", []);
  },

  searchProductOptions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-searchProductOptions" });

      const response = await ProductOptionService.searchProductOptions(payload);

      if (response.status === 200 && response.data) {
        commit("SET_SEARCH_PRODUCT_OPTIONS", response.data.data);
        commit("SET_STATUS_REQUEST", {
          value: "success-searchProductOptions"
        });
      }
    } catch (e) {
      catchError(e, commit, "searchProductOptions");
    }
  },

  setActiveTab: ({ commit }, payload) => {
    commit("SET_ACTIVE_TAB", payload);
  },

  setProductOptionModelSiteConfigs: ({ commit }, payload) => {
    commit("SET_PRODUCT_OPTION_MODEL_SITE_CONFIGS", payload);
  },

  updateBusinessStatus: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updateBusinessStatus"
      });

      const response = await ProductOptionService.updateBusinessStatus(payload);

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", {
          value: "success-updateBusinessStatus"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateBusinessStatus");
    }
  },

  updateListingDisplay: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-updateListingDisplay"
      });

      const response = await ProductOptionService.updateListingDisplay(payload);

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", {
          value: "success-updateListingDisplay"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateListingDisplay");
    }
  },

  updatePreOrder: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updatePreOrder" });

      const response = await ProductOptionService.updatePreOrder(payload);

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", { value: "success-updatePreOrder" });
      }
    } catch (error) {
      catchError(error, commit, "updatePreOrder");
    }
  },

  updateProductOption: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateProductOption" });

      const objectSender = { ...payload.productOption };

      const attrDetailObj = { ...objectSender.attr_details };

      // Handle convert attr_detail to array
      objectSender.attr_details = Object.keys(attrDetailObj).map(
        key => attrDetailObj[key]
      );

      const response = await ProductOptionService.updateProductOption(
        objectSender
      );

      if (response.status === 200) {
        // Get product option
        await store.dispatch(
          "PRODUCT_OPTION/getProductOptionsByProductId",
          payload.productId
        );
        commit("SET_STATUS_REQUEST", {
          value: "success-updateProductOption"
        });
      }
    } catch (e) {
      catchError(e, commit, "updateProductOption");
    }
  },

  uploadProductOptionImages: async ({ commit }, formDatas) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-uploadProductOptionImages"
      });

      let images = [];

      for (let i = 0; i < formDatas.length; i++) {
        try {
          const formData = formDatas[i];

          // Append folder
          formData.append("folder", "core/products");

          const response = await MediaService.uploadImage(formData);

          if (response.status === 200 && response.data) {
            images.push(response.data);
          }
        } catch (e) {
          catchError(e, commit, "uploadProductOptionImages");
        }
      }

      await commit("SET_UPLOADED_PRODUCT_OPTION_IMAGES", images);

      commit("SET_STATUS_REQUEST", {
        value: "success-uploadProductOptionImages"
      });
    } catch (e) {
      catchError(e, commit, "uploadProductOptionImages");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
