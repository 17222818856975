import { effectScope, getCurrentInstance, reactive } from "vue";

function throwNoCurrentInstance(method) {
  if (!getCurrentInstance()) {
    throw new Error(
      "[vue-router]: Missing current instance. " +
        method +
        "() must be called inside <script setup> or setup()."
    );
  }
}

function useRouter() {
  if (process.env.NODE_ENV !== "production") {
    throwNoCurrentInstance("useRouter");
  }

  return getCurrentInstance().proxy.$root.$router;
}

function useRoute() {
  if (process.env.NODE_ENV !== "production") {
    throwNoCurrentInstance("useRoute");
  }

  var root = getCurrentInstance().proxy.$root;
  if (!root._$route) {
    var route = effectScope(true).run(function() {
      return reactive(Object.assign({}, root.$router.currentRoute));
    });
    root._$route = route;

    root.$router.afterEach(function(to) {
      Object.assign(route, to);
    });
  }

  return root._$route;
}

export { useRoute, useRouter };
