export const TABLE_HEADER_CASH_TRANSFER_NOTE = [
  {
    text: "Mã phiếu",
    align: "start",
    sortable: false,
    value: "code"
  },
  {
    text: "Thời gian tạo",
    align: "start",
    sortable: false,
    value: "created_at"
  },
  {
    text: "Chi nhánh",
    align: "start",
    sortable: false,
    value: "branch.name"
  },
  {
    text: "Người chuyển",
    align: "start",
    sortable: false,
    value: "sender.name"
  },
  {
    text: "Người nhận",
    align: "start",
    sortable: false,
    value: "receiver.name"
  },
  {
    text: "Chứng từ",
    align: "start",
    sortable: false,
    value: "voucher_links"
  },
  {
    text: "Số tiền",
    align: "start",
    sortable: false,
    value: "payment_amount"
  },
  {
    text: "Trạng thái",
    align: "center",
    sortable: false,
    value: "status"
  }
];

export const TABLE_HEADER_PAYMENT_VOUCHER = [
  {
    text: "Mã phiếu chi",
    align: "start",
    sortable: false,
    value: "code"
  },
  {
    text: "Loại phiếu chi",
    align: "start",
    sortable: false,
    value: "type"
  },
  {
    text: "Thời gian tạo",
    align: "start",
    sortable: false,
    value: "created_at"
  },
  {
    text: "Chi nhánh",
    align: "start",
    sortable: false,
    value: "branch_name"
  },
  {
    text: "Đối tượng chi",
    align: "start",
    sortable: false,
    value: "receiver.name"
  },
  {
    text: "Người chi",
    align: "start",
    sortable: false,
    value: "sender.name"
  },
  {
    text: "Chứng từ",
    align: "start",
    sortable: false,
    value: "voucher_links"
  },
  {
    text: "Phương thức",
    align: "start",
    sortable: false,
    value: "payment_method"
  },
  {
    text: "Số tiền chi",
    align: "start",
    sortable: false,
    value: "payment_amount"
  },
  {
    text: "Trạng thái",
    align: "center",
    sortable: false,
    value: "status"
  }
];

export const TABLE_HEADER_RECEIPT_VOUCHER = [
  {
    text: "Mã PT",
    align: "start",
    sortable: false,
    value: "code"
  },
  {
    text: "Thời gian tạo",
    align: "start",
    sortable: false,
    value: "created_at"
  },
  {
    text: "Chi nhánh",
    align: "start",
    sortable: false,
    value: "branch.name"
  },
  {
    text: "Đối tượng thu",
    align: "start",
    sortable: false,
    value: "sender.name"
  },
  {
    text: "Người thu",
    align: "start",
    sortable: false,
    value: "receiver.name"
  },
  {
    text: "Chứng từ",
    align: "start",
    sortable: false,
    value: "voucher_links"
  },
  {
    text: "Phương thức",
    align: "start",
    sortable: false,
    value: "payment_method"
  },
  {
    text: "Số tiền thu",
    align: "start",
    sortable: false,
    value: "payment_amount"
  },
  {
    text: "Trạng thái",
    align: "center",
    sortable: false,
    value: "status"
  }
];

/**
 * Model name for api type call
 *
 */

export const ModelName = {
  ReceiptVoucher: "App\\Models\\ReceiptVoucher",
  PaymentVoucher: "App\\Models\\PaymentVoucher",
  ExchangeVoucher: "App\\Models\\ExchangeVoucher"
};

/**
 * User model name: sender and receiver
 */
export const UserModelName = {
  User: "App\\Models\\User",
  Supplier: "App\\Models\\Supplier",
  Brands: "App\\Models\\Brands"
};

/**
 * Vourcher link model name
 */
export const VourcherLinksModelName = {
  BillOfSale: "App\\Models\\HoaDonBan",
  ImportInvoice: "App\\Models\\HoaDonNhap"
};

/**
 * payment type
 */
export const PaymentType = {
  1: "Tiền mặt",
  2: "Quẹt thẻ",
  3: "Chuyển khoản",
  4: "COD",
  5: "Trả góp"
};

export const StoreName = {
  CASH_TRANSFER_NOTE: "CASH_TRANSFER_NOTE",
  PAYMENT_VOUCHER: "PAYMENT_VOUCHER",
  RECEIPT_VOUCHER: "RECEIPT_VOUCHER"
};

export const CashFlowType = [
  { id: 1, label: "Theo chứng từ" },
  { id: 2, label: "Theo lý do khác" }
];

export const ORDERS_TYPE = {
  PAYMENT_VOUCHER: {
    "App\\Models\\HoaDonNhap": "App\\Models\\HoaDonNhap"
  },
  PAYMENT_VOUCHER_SOURCE: {
    "App\\Models\\ExchangeVoucher": "App\\Models\\ExchangeVoucher"
  },
  CASH_TRANSFER_NOTE: {
    "App\\Models\\ReceiptVoucher": "App\\Models\\ReceiptVoucher"
  },
  RECEIPT_VOUCHER: {
    "App\\Models\\HoaDonBan": "App\\Models\\HoaDonBan"
  }
};

export const OBJECT_TYPE_LIST_RECEIVER = [
  {
    receiver_model: "App\\Models\\User",
    text: "Khách hàng"
  },
  {
    receiver_model: "App\\Models\\Supplier",
    text: "Nhà cung cấp"
  },
  {
    receiver_model: "App\\Models\\Brands",
    text: "Hãng"
  }
];

export const OBJECT_TYPE_LIST_SENDER = [
  {
    receiver_model: "App\\Models\\User",
    text: "Nhân viên"
  }
];
