import { INIT_SITE_CONFIG_SEO_DATA } from "@/core/constant";

export const FULL_HEADERS_PRODUCT_OPTION_TABLE = [
  {
    text: "SKU / MPN",
    align: "start",
    sortable: false,
    value: "SKU"
  },
  {
    text: "Tên sản phẩm",
    align: "start",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Sẵn bán",
    align: "start",
    sortable: true,
    value: "ready_to_sell_qty"
  },
  {
    text: "Bị đặt",
    align: "start",
    sortable: true,
    value: "ordered_qty"
  },
  {
    text: "Khách đặt chờ",
    align: "start",
    sortable: true,
    value: "waiting_ordered_qty"
  },
  {
    text: "Trưng bày",
    align: "start",
    sortable: true,
    value: "exhibited_qty"
  },
  {
    text: "Đang về",
    align: "center",
    sortable: true,
    value: "so_luong_dang_ve"
  },
  {
    text: "Số ngày tồn",
    align: "start",
    sortable: true,
    value: "date_ton_lau"
  },
  {
    text: "Giá nhập",
    align: "center",
    sortable: false,
    value: "gia_von_trung_binh"
  },
  {
    text: "Giá bán",
    align: "center",
    sortable: false,
    value: "price"
  },
  {
    text: "Giá đang KM",
    align: "center",
    sortable: false,
    value: "sale_price"
  },
  {
    text: "",
    align: "center",
    sortable: false,
    value: "action"
  }
];

export const LIST_OPTION_STATUS = [
  { id: "all", text: "Tất cả trạng thái tồn kho" },
  { id: 1, text: "Có sẵn", color: "green", background: "green lighten-5" },
  { id: 2, text: "Đang về", color: "orange", background: "amber lighten-5" },
  { id: 3, text: "Đặt trước", color: "blue" },
  {
    id: 4,
    text: "Liên hệ",
    color: "purple",
    background: "purple lighten-5"
  },
  {
    id: 6,
    text: "Tạm hết",
    color: "red",
    background: "red lighten-5"
  },
  {
    id: 5,
    text: "Ngừng kinh doanh",
    color: "grey darken-3",
    background: "grey lighten-3"
  }
];

export const INIT_PRODUCT_OPTION_VALUE = {
  id: null,
  MPN: null,
  SKU: null,
  attr_details: {},
  articles: [
    { article_id: null, articlet: null, site: "THINKPRO" },
    { article_id: null, article: null, site: "GOODSPACE" }
  ],
  color_id: null,
  degree_360: null,
  demand_id: null,
  demands: [],
  eol_date: null,
  features: "",
  front_display: true,
  gifts: null,
  guarantee_id: 1,
  has_payment_required: 0,
  has_self_info: 0,
  images: [],
  model_name: null,
  name: null,
  nguon_hang: 5,
  none_price: 0,
  price: null,
  product_id: null,
  short_des: null,
  thumbnail: null,
  temporary_thumbnail: {
    path: null,
    expired_time: new Date().toISOString()
  },
  tinh_trang: null,
  ttkd: 1,
  seo_data: JSON.parse(JSON.stringify(INIT_SITE_CONFIG_SEO_DATA)),
  videos: null,
  pre_order_info: {
    isLimited: true,
    limit: null,
    startAt: "",
    endAt: "",
    decreasedPercents: null,
    isEnabled: false,
    quantity: null,
    gifts: "",
    decreasedMoney: null,
    discountType: "PERCENT",
    totalOrdered: null
  }
};

export const SALE_HEADERS_PRODUCT_OPTION_TABLE = [
  {
    text: "SKU / MPN",
    align: "start",
    sortable: false,
    value: "SKU"
  },
  {
    text: "Tên sản phẩm",
    align: "start",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Sẵn bán",
    align: "start",
    sortable: true,
    value: "ready_to_sell_qty"
  },
  {
    text: "Bị đặt",
    align: "start",
    sortable: true,
    value: "ordered_qty"
  },
  {
    text: "Khách đặt chờ",
    align: "start",
    sortable: true,
    value: "waiting_ordered_qty"
  },
  {
    text: "Trưng bày",
    align: "start",
    sortable: true,
    value: "exhibited_qty"
  },
  {
    text: "Số ngày tồn",
    align: "start",
    sortable: true,
    value: "date_ton_lau"
  },
  {
    text: "Đang về",
    align: "center",
    sortable: true,
    value: "so_luong_dang_ve"
  },
  {
    text: "Giá bán",
    align: "center",
    sortable: false,
    value: "price"
  },
  {
    text: "Giá đang KM",
    align: "center",
    sortable: false,
    value: "sale_price"
  },
  {
    text: "",
    align: "center",
    sortable: false,
    value: "action"
  }
];
