/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import PostService from "../service/post.service";
import MediaService from "@/core/service/media.service";
import { catchError } from "@/core/composables/useStore";
import store from "@/core/store";
import Vue from "vue";
import { INIT_POST_VALUE } from "@/modules/Website/constants";

const state = {
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  post: JSON.parse(JSON.stringify(INIT_POST_VALUE)),
  posts: [],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedThumbnail: null
};

const getters = {
  paginationInfo: state => state.paginationInfo,
  post: state => state.post,
  posts: state => state.posts,
  statusRequest: state => state.statusRequest,
  uploadedThumbnail: state => state.uploadedThumbnail
};

const mutations = {
  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_POST: (state, payload) => {
    state.post = payload;
  },

  SET_POSTS: (state, payload) => {
    state.posts = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_THUMBNAIL: (state, payload) => {
    state.uploadedThumbnail = payload;
  },

  SET_POST_THUMBNAIL: (state, payload) => {
    state.post.thumbnail = payload;
  }
};

const actions = {
  createPost: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createPost" });

      const response = await PostService.createPost(payload);

      if (response.status === 200 && response.data) {
        // Set post id
        Vue.set(state.post, "id", response.data.data.id);
        commit("SET_STATUS_REQUEST", { value: "success-createPost" });
      }
    } catch (e) {
      catchError(e, commit, "createPost");
    }
  },

  deletePost: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deletePost" });

      const response = await PostService.deletePost(payload.data);

      if (response.status === 200) {
        // Get post categories
        await store.dispatch("POST/getPosts", payload.routeQuery);
        commit("SET_STATUS_REQUEST", { value: "success-deletePost" });
      }
    } catch (e) {
      catchError(e, commit, "deletePost");
    }
  },

  getPostById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPostById" });

      const response = await PostService.getPostById(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_POST", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getPostById" });
      }
    } catch (e) {
      catchError(e, commit, "getPostById");
    }
  },

  getPosts: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getPosts" });

      const response = await PostService.getPosts(payload);
      const { data, meta } = response.data;

      if (response.status === 200 && data) {
        commit("SET_POSTS", data);
        commit("SET_PAGINATION_INFO", {
          from: meta.from ? meta.from : 0,
          to: meta.to ? meta.to : 0,
          pageTotal: meta.last_page,
          itemTotal: meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getPosts" });

        return data;
      }
    } catch (e) {
      catchError(e, commit, "getPosts");
    }
  },

  getAllPosts: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllPosts" });

      const response = await PostService.getAllPosts();

      if (response.status === 200 && response.data.data) {
        commit("SET_POSTS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAllPosts" });
      }
    } catch (e) {
      catchError(e, commit, "getAllPosts");
    }
  },

  resetPost: ({ commit }) => {
    commit("SET_POST", JSON.parse(JSON.stringify(INIT_POST_VALUE)));
  },

  updatePost: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updatePost" });

      const response = await PostService.updatePost(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_STATUS_REQUEST", { value: "success-updatePost" });
      }
    } catch (e) {
      catchError(e, commit, "updatePost");
    }
  },

  uploadThumbnail: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadThumbnail" });

      payload.append("folder", "social/articles");
      const response = await MediaService.uploadImage(payload);

      if (response.status === 200 && response.data) {
        commit("SET_UPLOADED_THUMBNAIL", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadThumbnail" });
      }
    } catch (e) {
      catchError(e, commit, "uploadThumbnail");
    }
  },

  changeThumbnail: ({ commit }, payload) => {
    commit("SET_POST_THUMBNAIL", payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
