const FundRouter = {
  path: "/fund",
  component: () => import("@/modules/Reports"),
  redirect: { name: "fund_revenue" },
  children: [
    {
      path: "revenue",
      name: "fund_revenue",
      meta: {
        private: true,
        layout: "general",
        title: "Quản lý thu chi | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Quản lý thu chi",
            to: { name: "fund_revenue" }
          }
        ]
      },
      component: () => import("../pages/FundRevenue")
    },

    {
      path: "cod",
      name: "fund_cod",
      meta: {
        private: true,
        layout: "general",
        title: "Quản lý thu COD | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Quản lý thu COD",
            to: { name: "fund_cod" }
          }
        ]
      },
      component: () => import("../pages/FundCOD")
    }
  ]
};

export default FundRouter;
