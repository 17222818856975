import Api from "@/core/apis";

export default {
  updateInfo(data) {
    return Api().post("myAccount/changeInfo", data);
  },
  updatePassword(data) {
    return Api().post("myAccount/changePassword", data);
  }
};
