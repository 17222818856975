export const INIT_SITE_CONFIG_VALUE = {
  fallback_image: null,
  favicon: null,
  footer_banner: null,
  icon: null,
  logo: null,
  number_product_of_line: null,
  online_banner: null,
  online_banner_mobile: null,
  online_sales_policy: null,
  online_seo_data: {
    title: null,
    description: null,
    tags: []
  },
  online_thumbnail: null,
  online_thumbnail_mobile: null,

  online_title: null,
  organization: {
    name: null,
    short_name: null,
    description: null,
    keywords: [],
    author: null
  },
  social: {
    zalo: { href: null },
    telegram: { href: null },
    facebook: { href: null },
    youtube: { href: null },
    tiktok: { href: null }
  },
  tel: null,
  useful_information: {
    warranty_policy: null,
    return_policy: null,
    shipping_policy: null,
    payment_policy: null,
    privacy_policy: null,
    inspection_policy: null,
    shopping_guide_online: null,
    about_us: null
  }
};

export const LIST_SITE_CONFIG_USEFUL_INFORMATION = [
  { value: "warranty_policy", text: "Chính sách bảo hành" },
  { value: "return_policy", text: "Chính sách đổi trả" },
  { value: "shipping_policy", text: "Chính sách vận chuyển" },
  { value: "payment_policy", text: "Chính sách thanh toán" },
  { value: "privacy_policy", text: "Chính sách bảo mật" },
  { value: "inspection_policy", text: "Chính sách kiểm hàng" },
  { value: "shopping_guide_online", text: "Hướng dẫn mua hàng online" },
  { value: "about_us", text: "Về chúng tôi" }
];

export const LIST_SITE_CONFIG_ORGANIZATION_PROPERTIES = [
  { value: "name", text: "Tiêu đề trang" },
  { value: "description", text: "Mô tả trang" },
  { value: "keywords", text: "Từ khóa" },
  { value: "short_name", text: "Tên thương hiệu" },
  { value: "author", text: "Tên công ty sở hữu" }
];

export const LIST_SITE_CONFIG_ONLY_SELL_ONLINE_PROPERTIES = [
  { value: "name", text: "Tiêu đề trang" },
  { value: "description", text: "Mô tả trang" },
  { value: "keywords", text: "Từ khóa" }
];

export const LIST_SITE_CONFIG_SOCIAL_PROPERTIES = [
  { value: "facebook", text: "Facebook" },
  { value: "youtube", text: "Youtube" },
  { value: "tiktok", text: "Tiktok" },
  { value: "telegram", text: "Telegram" },
  { value: "zalo", text: "Zalo" }
];
