import TransactionService from "../service";
import { catchError } from "@/core/composables";

const state = {
  transactions: [],
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0,
    total_amount: 0
  },
  statusRequest: {
    message: null,
    value: null
  }
};

const mutations = {
  SET_TRANSACTIONS: (state, payload) => {
    state.transactions = payload;
  },
  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = {
      from: payload.from,
      to: payload.to,
      pageTotal: payload.last_page,
      itemTotal: payload.total,
      total_amount: payload.total_amount
    };
  },
  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  }
};

const getters = {
  statusRequest: state => state.statusRequest
};

const actions = {
  getRevenueList: async ({ commit }, params) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-getRevenueList"
      });

      const { status, data } = await TransactionService.getTransactionsList(
        params
      );

      if (status === 200 && data.data) {
        commit("SET_TRANSACTIONS", data.data);
        commit("SET_PAGINATION_INFO", data.meta);
        commit("SET_STATUS_REQUEST", {
          value: "success-getRevenueList"
        });
      }
    } catch (e) {
      console.log("getRevenueList", e);
      commit("SET_STATUS_REQUEST", {
        message: e.response.data,
        value: "error-getRevenueList"
      });
    }
  },
  exportReportFile: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-exportReportFile" });

      const response = await TransactionService.exportReportFile(payload);

      if (response.status === 200 && response.data) {
        commit("SET_STATUS_REQUEST", { value: "success-exportReportFile" });

        return response.data;
      }
    } catch (err) {
      catchError(err, commit, "exportReportFile", {
        isRedirected: false
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
