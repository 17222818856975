const SystemRouter = {
  path: "/system",
  component: () => import("@/modules/System"),
  redirect: { name: "system_roles-manager" },
  children: [
    {
      path: "roles-manager",
      name: "system_roles-manager",
      meta: {
        private: true,
        layout: "general",
        title: "Quản lý quyền | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Quản lý quyền",
            to: { name: "system_roles-manager" }
          }
        ]
      },
      component: () => import("../pages/RolesManager")
    },
    {
      path: "bank-accounts",
      name: "system_bank-accounts",
      meta: {
        private: true,
        layout: "general",
        title: "Tài khoản ngân hàng | ThinkPro CMS",
        breadcrumbs: [
          {
            disabled: false,
            exact: true,
            text: "Bảng điều khiển",
            to: { name: "dashboard" }
          },
          {
            disabled: true,
            exact: true,
            text: "Tài khoản ngân hàng",
            to: { name: "system_bank-accounts" }
          }
        ]
      },
      component: () => import("../pages/BankAccounts")
    }
  ]
};

export default SystemRouter;
